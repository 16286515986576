<template>
  <div class="new-daily-feed-popup base-modal" @keydown.esc="closeDialog">
    <BaseButton
      variant="primary"
      @click="openPopup"
      class="new-daily-feed-popup__btn"
    >
      <PlusSign color="black" />
      <span>{{ $t("daily_feeds.user_add.add_button") }}</span>
    </BaseButton>
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="closeDialog"
    >
      <div
        class="new-daily-feed-popup__inner base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="new-daily-feed-popup__head base-modal__head">
          <ArrowBackIcon
            @click="goToUploadView"
            v-if="criteriaView"
            class="new-daily-feed-popup__back-btn"
          />
          <CloseDialogButton @click="closeDialog" class="base-modal__close" />
          <h1 class="new-daily-feed-popup__title base-modal__head-title">
            {{
              criteriaView
                ? $t("daily_feeds.user_add.criteria_title")
                : $t("daily_feeds.user_add.title")
            }}
          </h1>
        </div>
        <div
          class="base-modal__body new-daily-feed-popup__body"
          ref="viewsContainer"
        >
          <form class="new-daily-feed-popup__form base-modal__form base-form">
            <div class="new-daily-feed-popup__form-inner">
              <div class="new-daily-feed-popup__description">
                <p class="new-daily-feed-popup__text">
                  {{ $t("daily_feeds.user_add.description_1") }}
                </p>
                <p class="new-daily-feed-popup__text">
                  {{ $t("daily_feeds.user_add.description_2") }}
                </p>
                <p
                  class="new-daily-feed-popup__text"
                  v-html="$t('daily_feeds.user_add.description_3')"
                ></p>
              </div>
              <div class="new-daily-feed-popup__upload">
                <FormFileInput
                  v-model="$v.form.currentFile.$model"
                  :label="$t('daily_feeds.user_add.upload_media_label')"
                  :hasErrors="
                    fileIsEmpty || fileNotSupported || fileSizeIsTooBig
                  "
                  accept="application/pdf, audio/mp3, video/mp4, video/webm"
                >
                  <template v-slot:errors>
                    <p class="base-form__errors-item" v-if="fileIsEmpty">
                      {{ $t("daily_feeds.user_add.error_file_required") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="fileNotSupported"
                    >
                      {{ $t("daily_feeds.user_add.error_file_not_supported") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="fileSizeIsTooBig"
                    >
                      {{
                        form.contentType === "VIDEO"
                          ? $t("daily_feeds.user_add.error_file_size_video")
                          : $t("daily_feeds.user_add.error_file_size")
                      }}
                    </p>
                  </template>
                </FormFileInput>
              </div>
              <div
                class="new-daily-feed-popup__thumbnail"
                v-if="form.contentType === 'AUDIO' && !showLibrary"
              >
                <FormFileInput
                  v-model="$v.form.thumbnail.$model"
                  accept="image/png, image/jpeg"
                  :hasErrors="thumbnailIsEmpty"
                >
                  <template v-slot:label>
                    <span class="new-daily-feed-popup__lib-label">
                      {{ $t("daily_feeds.user_add.add_thumbnail") }}
                      <button
                        class="new-daily-feed-popup__lib-btn"
                        type="button"
                        @click="showLibrary = true"
                      >
                        {{ $t("daily_feeds.user_add.choose_from_library") }}
                      </button>
                    </span>
                  </template>
                  <template v-slot:errors>
                    <p class="base-form__errors-item" v-if="thumbnailIsEmpty">
                      {{ $t("daily_feeds.user_add.error_file_required") }}
                    </p>
                  </template>
                </FormFileInput>
              </div>
              <div
                class="new-daily-feed-popup__lib"
                v-if="form.contentType === 'AUDIO' && showLibrary"
              >
                <span class="new-daily-feed-popup__lib-label">
                  <button
                    class="new-daily-feed-popup__lib-btn"
                    type="button"
                    @click="showLibrary = false"
                  >
                    Add thumbnail Image [1:1]
                  </button>
                  or choose from our library
                </span>
                <div class="new-daily-feed-popup__lib-wrap">
                  <div
                    class="new-daily-feed-popup__lib-selected"
                    :key="'selected' + selectedImage.id"
                    v-if="selectedImage"
                  >
                    <img
                      :src="createThumbnailUrl(selectedImage.imageId)"
                      alt="thumbnail"
                    />
                  </div>
                  <div class="new-daily-feed-popup__lib-list">
                    <div
                      class="new-daily-feed-popup__lib-item"
                      :class="{
                        'new-daily-feed-popup__lib-item--selected':
                          selectedImage && image.id === selectedImage.id,
                      }"
                      v-for="image in images"
                      :key="image.id"
                      @click="selectedImage = image"
                    >
                      <img
                        :src="createThumbnailUrl(image.imageId)"
                        alt="thumbnail"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="new-daily-feed-popup__thumbnail"
                v-if="form.contentType === 'VIDEO'"
              >
                <FormFileInput
                  v-model="$v.form.thumbnail.$model"
                  :label="'Add thumbnail Image [16:9] (optional)'"
                  accept="image/png, image/jpeg"
                >
                </FormFileInput>
              </div>
              <div class="new-daily-feed-popup__explanation">
                <p class="new-daily-feed-popup__text">
                  {{ $t("daily_feeds.user_add.explanation") }}
                </p>
                <FormTextarea
                  v-model="$v.form.description.$model"
                  :counter="500"
                  :hasErrors="descriptionIsEmpty || descriptionIsTooLong"
                >
                  <template v-slot:errors>
                    <p class="base-form__errors-item" v-if="descriptionIsEmpty">
                      {{ $t("daily_feeds.user_add.error_relevance_required") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="descriptionIsTooLong"
                    >
                      {{ $t("daily_feeds.user_add.error_relevance_length") }}
                    </p>
                  </template>
                </FormTextarea>
              </div>
            </div>

            <div class="new-daily-feed-popup__actions base-modal__actions">
              <div
                class="
                  base-modal__actions-item
                  new-daily-feed-popup__actions-item--left
                "
              >
                <BaseButton variant="underline" @click="showCriteria">
                  {{ $t("daily_feeds.user_add.criteria_title") }}
                </BaseButton>
              </div>
              <div class="base-modal__actions-item">
                <BaseButton variant="secondary" @click="closeDialog">
                  {{ $t("daily_feeds.user_add.button_cancel_upload") }}
                </BaseButton>
              </div>
              <div class="base-modal__actions-item">
                <BaseButton
                  variant="primary"
                  @click="addDailyFeed"
                  :disabled="postButtonDisabled"
                >
                  {{ $t("daily_feeds.user_add.button_save") }}
                </BaseButton>
              </div>
            </div>
          </form>
          <DailyFeedCriteria />
        </div>
      </div>
    </div>
    <UploadFile ref="uploadFile" />
    <SelectThumbnailPopup
      ref="selectThumbnailPopup"
      @thumbnailSelected="showSuccessPopup"
      :section="section"
    />
    <DailyFeedInfoPopup ref="dailyFeedInfo" />
    <Confirm
      ref="confirmClose"
      variant="user"
      :reverse-button-functionality="true"
      :decline-button-text="$t('confirm_close.stay_on_page')"
      :accept-button-text="$t('default.discard')"
    />
  </div>
</template>

<script>
import CREATE_DAILY_FEED from "@/graphql/daily-feeds/mutations/CreateDailyFeed.graphql";
import GET_LIBRARY_IMAGES from "@/graphql/admin/queries/AllLibraryImages.graphql";
import COPY_LIBRARY_IMAGE from "@/graphql/admin/mutations/CopyLibraryImage.graphql";
import FormFileInput from "@/components/forms/FormFileInput";
import FormTextarea from "@/components/forms/FormTextarea";
import DailyFeedInfoPopup from "@/components/library/daily-feed/DailyFeedInfoPopup";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import UploadFile from "@/components/reusables/UploadFile";
import PlusSign from "@/components/reusables/Icons/PlusSign";
import ArrowBackIcon from "@/components/reusables/Icons/ArrowBackIcon";
import SelectThumbnailPopup from "@/components/library/daily-feed/SelectThumbnailPopup";
import DailyFeedCriteria from "@/components/library/daily-feed/DailyFeedCriteria";
import { mapGetters } from "vuex";
import GetThumbnailsMixin from "@/components/mixins/GetThumbnailsMixin";
import { validationMixin } from "vuelidate/src";
import { maxLength, required, requiredIf } from "vuelidate/lib/validators";
import { hasMediaFormat } from "@/service/validationRules";
import errorHandler from "@/service/errorHandler";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";
import { logTrackingData } from "@/service/trackingServices";
import Confirm from "@/components/reusables/Confirm";

export default {
  name: "NewDailyFeedPopup",
  components: {
    Confirm,
    UploadFile,
    FormFileInput,
    DailyFeedInfoPopup,
    CloseDialogButton,
    FormTextarea,
    PlusSign,
    ArrowBackIcon,
    SelectThumbnailPopup,
    DailyFeedCriteria,
  },
  props: {
    section: {
      type: String,
      default: "LIBRARY",
    },
  },
  mixins: [validationMixin, GetThumbnailsMixin],
  watch: {
    dialog() {
      this.dialog
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "auto");
    },
    "form.currentFile"(file) {
      if (file) {
        const fileType = file.type;

        if (fileType === "video/mp4" || fileType === "video/webm") {
          this.form.contentType = "VIDEO";
        } else if (fileType === "application/pdf") {
          this.form.contentType = "READABLE";
        } else if (fileType === "audio/mpeg" || fileType === "audio/mp3") {
          this.form.contentType = "AUDIO";
        }

        this.showLibrary = false;
      }
    },
  },
  data() {
    return {
      form: {
        description: "",
        currentFile: null,
        contentType: "",
        thumbnail: null,
      },
      dialog: false,
      showSubscribeTooltip: false,
      criteriaView: false,
      showLibrary: false,
      images: [],
      selectedImage: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    formHasBeenUpdated() {
      return (
        this.form.description || this.form.currentFile || this.form.thumbnail
      );
    },
    thumbnailIsEmpty() {
      return this.$v.form.thumbnail.$dirty && !this.$v.form.thumbnail.required;
    },
    descriptionIsEmpty() {
      return (
        this.$v.form.description.$dirty && !this.$v.form.description.required
      );
    },
    descriptionIsTooLong() {
      return (
        this.$v.form.description.$dirty && !this.$v.form.description.maxLength
      );
    },
    fileIsEmpty() {
      return (
        this.$v.form.currentFile.$dirty && !this.$v.form.currentFile.required
      );
    },
    fileNotSupported() {
      return (
        this.$v.form.currentFile.$dirty &&
        !this.$v.form.currentFile.hasMediaFormat
      );
    },
    fileSizeIsTooBig() {
      return (
        this.$v.form.currentFile.$dirty && !this.$v.form.currentFile.maxFileSize
      );
    },
    postButtonDisabled() {
      return (
        !this.form.description ||
        !this.form.currentFile ||
        !this.form.contentType ||
        this.descriptionIsEmpty ||
        this.descriptionIsTooLong ||
        this.fileIsEmpty ||
        this.fileNotSupported ||
        this.fileSizeIsTooBig ||
        (this.form.contentType === "AUDIO" &&
          !this.form.thumbnail &&
          !(this.showLibrary && this.selectedImage))
      );
    },
  },
  methods: {
    hideSubscriberTooltip() {
      const box = document.querySelector(".new-daily-feed-popup__btn");
    
      if (box) {
        if (!box.contains(event.target)) {
          this.showSubscribeTooltip = false;
        }
      }
    },
    openPopup() {
      this.dialog = true;
    },
    goToSubscriptions() {
      const userRegistrationInput = {
        emailAddress: this.getNJUser.email,
        njProductId: null,
      };
    
      this.$router.push({
        name: "payment.subscriptions",
        params: {
          userRegistrationInput: userRegistrationInput,
          disabledFreeTrial: true,
        },
      });
    },
    createThumbnailUrl(imageId) {
      return createS3ThumbnailUrl(imageId, 136, 136);
    },
    goToUploadView() {
      this.$refs.viewsContainer.scrollLeft = 0;
      this.criteriaView = false;
    },
    showCriteria() {
      this.criteriaView = true;
      this.$refs.viewsContainer.scrollLeft = 522;
    },
    async closeDialog() {
      if (this.formHasBeenUpdated) {
        if (
          !(await this.$refs.confirmClose.open(
            this.$t("confirm_close.create_generic.title", {
              item: this.$t("confirm_close.daily_feed"),
            }),
            this.$t("confirm_close.create_generic.message", {
              item: this.$t("confirm_close.daily_feed"),
            })
          ))
        ) {
          return;
        }
      }
      this.cancelDialog();
    },
    cancelDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        description: "",
        currentFile: null,
        contentType: "",
        thumbnail: null,
      };
      this.selectedImage = null;
      this.criteriaView = false;
      this.showLibrary = false;
      this.$refs.viewsContainer.scrollLeft = 0;

      this.$v.$reset();
    },
    showSuccessPopup() {
      this.cancelDialog();
      this.$refs.dailyFeedInfo.open(
        "Success!",
        "<p>Thank you for submitting a Daily Feed and sharing your insight with the community.</p>"
      );
    },
    openSelectThumbnailPopup({
      description,
      type,
      userId,
      storageId,
      thumbnailImages,
    }) {
      this.$refs.selectThumbnailPopup.open(
        description,
        type,
        userId,
        storageId,
        thumbnailImages
      );
    },
    async addDailyFeed() {
      this.$v.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let fileId;

      if (this.form.contentType === "VIDEO" && !this.form.thumbnail) {
        fileId = await this.$refs.uploadFile.upload(this.form.currentFile, [
          {
            key: "fetchThumbnails",
            value: true,
          },
        ]);
        let thumbnails = await this.getThumbnailPromise(fileId);

        this.$refs.uploadFile.closeUploadDialog();

        this.openSelectThumbnailPopup({
          description: this.form.description,
          type: this.form.contentType,
          userId: this.getNJUser.id,
          storageId: fileId,
          thumbnailImages: thumbnails,
        });
      } else {
        fileId = await this.$refs.uploadFile.upload(this.form.currentFile);
        let thumbnailId = null;
        let variables = {
          description: this.form.description,
          type: this.form.contentType,
          userId: this.getNJUser.id,
          storageId: fileId,
        };

        if (this.form.contentType !== "READABLE" && !this.showLibrary) {
          thumbnailId = await this.$refs.uploadFile.upload(this.form.thumbnail);
          variables.thumbnailId = thumbnailId;
        } else if (
          this.form.contentType === "AUDIO" &&
          this.showLibrary &&
          this.selectedImage
        ) {
          const res = await this.$apollo.mutate({
            mutation: COPY_LIBRARY_IMAGE,
            variables: {
              imageStorageId: this.selectedImage.imageId,
            },
          });

          variables.thumbnailId = res.data.copyLibraryImage;
        }

        this.$refs.uploadFile.openUploadDialog(
          this.$t("daily_feeds.user_add.save_edit_title"),
          this.$t("default.wait"),
          false
        );

        await this.$apollo
          .mutate({
            mutation: CREATE_DAILY_FEED,
            variables,
          })
          .then(async (data) => {
            logTrackingData({
              section: this.section,
              belongsToSection: "DAILY_FEED",
              activityType: "CREATE_FEED",
              contentId: data.data.dailyFeed.id,
            });
            this.$emit("createdFeed");
            this.$refs.uploadFile.closeUploadDialog();
            this.showSuccessPopup();
          })
          .catch(async (error) => {
            await errorHandler(
              error,
              "CREATE_DAILY_FEED",
              this.$i18n.t("error.library.daily_feeds.file_upload_error")
            );
            this.$refs.uploadFile.closeUploadDialog();
            this.cancelDialog();
          });
      }
    },
  },
  validations() {
    if (this.form.contentType === "VIDEO") {
      return {
        form: {
          description: { required, maxLength: maxLength(500) },
          currentFile: {
            required,
            hasMediaFormat,
            maxFileSize: function (file) {
              if (!file) {
                return false;
              }
              return file.size < 1048576000; // 1Gb
            },
          },
          thumbnail: {},
        },
      };
    } else if (this.form.contentType === "AUDIO") {
      return {
        form: {
          description: { required, maxLength: maxLength(500) },
          currentFile: {
            required,
            hasMediaFormat,
            maxFileSize: function (file) {
              if (!file) {
                return false;
              }
              return file.size < 209715200; //200 Mb
            },
          },
          thumbnail: {
            required: requiredIf(function () {
              return this.showLibrary && this.form.thumbnail;
            }),
          },
        },
      };
    } else {
      return {
        form: {
          description: { required, maxLength: maxLength(500) },
          currentFile: {
            required,
            hasMediaFormat,
            maxFileSize: function (file) {
              if (!file) {
                return false;
              }
              return file.size < 209715200; //200 Mb
            },
          },
        },
      };
    }
  },
  apollo: {
    images: {
      query: GET_LIBRARY_IMAGES,
      update(data) {
        this.selectedImage = data.getAllLibraryImages[0];
        return data.getAllLibraryImages;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_GET_LIBRARY_IMAGES",
          this.$i18n.t("error.library.daily_feeds.fetch_images_error")
        );
      },
    },
  },
  mounted() {
    document.addEventListener("click", this.hideSubscriberTooltip);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideSubscriberTooltip);
  },
};
</script>

<style lang="scss" scoped>
.new-daily-feed-popup {
  color: var(--nj-black);

  &--hidden {
    background: transparent;

    .base-modal__dialog {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__inner {
    text-align: left;
    width: 100%;
    max-width: 522px;
    height: 700px;
  }

  &__head {
    padding-top: 7px;
  }

  &__back-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }

  &__body {
    position: absolute;
    height: calc(100% - 60px);
    top: 60px;
    width: 100%;
    // overflow changed because of iOS 15.4 scroll issue
    //overflow: hidden;
    overflow: auto;
    display: flex;
    scroll-behavior: smooth;
    padding: 0;
  }

  &__form {
    padding: 24px;
    min-width: 100%;
    overflow: auto;
  }

  &__title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__text {
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    margin-top: 0 !important;

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }

  &__upload {
    margin-top: 35px;
    margin-bottom: 10px;
  }

  &__explanation {
    margin-top: 30px;
  }

  &__btn {
    display: inline-flex;
    align-items: center;

    span {
      font-weight: var(--font-weight-medium);
      html[data-theme='oneclub'] & {
        font-weight: var(--font-weight-light);
      }
    }

    ::v-deep {
      .plus {
        margin-right: 7px;

        &:before {
          width: 2px;
        }

        &:after {
          height: 2px;
        }
      }
    }
  }

  &__actions {
    align-items: center;

    &-item {
      &--left {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }
  
  &__trial-tooltip {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-49%);
    width: 205px;
    text-align: center;
    white-space: normal;
    color: var(--nj-gray-5);
    font-size: 14px;
    line-height: 18px;
    border-radius: 10px;
    border: 1px solid var(--nj-gray-2);
    background-color: var(--nj-gray-1);
    z-index: 3;
    
    &:before {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%) rotate(135deg);
      content: "";
      width: 20px;
      height: 20px;
      background-color: var(--nj-gray-1);
      border: 1px solid var(--nj-gray-2);
      z-index: 1;
    }
    
    &-inner {
      position: relative;
      z-index: 2;
      padding: 16px 10px;
      border-radius: 10px;
      background-color: var(--nj-gray-1);
    }
    
    a {
      color: var(--nj-blue);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__lib {
    &-label {
      font-size: 14px;
      line-height: 17px;
      font-weight: var(--font-weight-medium);
    }

    &-btn {
      font-size: 14px;
      line-height: 17px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
    }

    &-wrap {
      display: flex;
    }

    &-selected {
      margin-right: 21px;
      margin-top: 10px;
      width: 123px;
      height: 123px;
      flex-shrink: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-list {
      margin: 10px -4px 0;
      max-height: 123px;
      overflow: auto;
    }

    &-item {
      padding: 0 4px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 4px;
      width: 48px;
      height: 40px;

      &--selected {
        img {
          border: 2px solid var(--nj-green);
        }

        width: 48px;
        height: 40px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  ::v-deep {
    .form-textarea__field {
      height: 100px;
    }
  }
}
</style>
