<template>
  <div
    :class="['hamburger', { 'hamburger--close': isMobileMenuOpen }]"
    @click="handleMenuState"
  >
    <span class="hamburger__line hamburger__line--top"></span>
    <span class="hamburger__line hamburger__line--middle"></span>
    <span class="hamburger__line hamburger__line--bottom"></span>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Hamburger",
  computed: {
    ...mapState("common", ["isMobileMenuOpen"]),
  },
  methods: {
    ...mapMutations("common", ["setMobileMenuState"]),
    handleMenuState() {
      this.setMobileMenuState(!this.isMobileMenuOpen);
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (this.isMobileMenuOpen) {
        this.setMobileMenuState(false);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.hamburger {
  cursor: pointer;
  display: none;

  &__line {
    transition: all 0.2s ease-out;
  }

  @media screen and (max-width: $mobile-max) {
    display: block;
  }

  &--close {
    .hamburger__line {
      &--middle {
        display: none;
      }
      &--top {
        transform: translateY(4px) rotate(-45deg);
      }
      &--bottom {
        transform: translateY(-4px) rotate(45deg);
      }
    }
  }

  &__line {
    display: block;
    width: 21px;
    height: 2px;
    background-color: var(--nj-black);
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
</style>
