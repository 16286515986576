<template>
  <div
    class="add-new-notebook base-modal"
    ref="addNewNotebook"
    tabindex="0"
    @keydown.esc="closePopup"
  >
    <div
      class="base-modal__dialog"
      :class="
        popupOpen ? 'base-modal__dialog--open' : 'base-modal__dialog--close'
      "
      @click.self="closePopup"
    >
      <div
        class="base-modal__inner"
        :class="
          popupOpen ? 'base-modal__inner--open' : 'base-modal__inner--close'
        "
      >
        <div class="base-modal__head">
          <h1 class="base-modal__head-title">
            {{ $t("notebooks.notebook.title") }}
          </h1>
          <CloseDialogButton class="base-modal__close" @click="closePopup" />
        </div>

        <div class="base-modal__body">
          <FormInput
            :label="$t('notebooks.notebook.new_label')"
            v-model="title"
          />

          <FormActions
            variant="primary"
            :accept-button="$t('default.add')"
            @accept="addNewNotebook"
            @decline="closePopup"
            :disable-accept-button="!title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import FormInput from "@/components/forms/FormInput";
import FormActions from "@/components/forms/FormActions";
import ADD_NEW_NOTEBOOK from "@/graphql/notebooks/mutations/CreateNotebook.graphql";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "AddNewNotebook",
  components: { FormActions, FormInput, CloseDialogButton },
  data() {
    return {
      popupOpen: false,
      title: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  watch: {
    popupOpen() {
      if (this.popupOpen) {
        document.body.style.overflow = "hidden";
        setTimeout(() => {
          this.$refs.addNewNotebook.focus();
        }, 200);
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    open() {
      this.popupOpen = true;
    },
    closePopup() {
      this.title = "";
      this.popupOpen = false;
    },
    addNewNotebook() {
      let variables = {
        userId: this.getNJUser.id,
        notebookName: this.title,
      };

      this.$apollo
        .mutate({
          mutation: ADD_NEW_NOTEBOOK,
          variables: variables,
        })
        .then(() => {
          this.$emit("refetchNotebooks");
          this.trackCreateNote();
          this.closePopup();
        })
        .catch((err) => {
          errorHandler(
            err,
            "ERROR_CREATE_NOTEBOOK",
            this.$t("notebooks.errors.error_create_notebook")
          );
        });
    },
    trackCreateNote() {
      if (this.getNJUser) {
        logTrackingData({
          section: "NOTEBOOKS",
          belongsToSection: "NOTEBOOKS",
          activityType: "CREATE_NOTEBOOK",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.add-new-notebook {
  &__button {
    width: 274px;
    height: 42px;
    border-radius: 10px;
    box-shadow: var(--nj-box-shadow);
    margin-bottom: 16px;
    margin-left: 1px;

    @media screen and (max-width: 830px) {
      width: 100%;
      max-width: 100%;
    }

    &-notebook {
      padding: 12px 24px;
      display: flex;
    }
  }
}
</style>
