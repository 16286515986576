<template>
  <div
    class="filter-dropdown"
    :class="[`filter-dropdown--${positionY}`, `filter-dropdown--${positionX}`]"
  >
    <div class="filter-dropdown__field-wrap">
      <label
        class="filter-dropdown__label body-2-book"
        v-if="label.length && !focused && !value"
      >
        {{ label }}
      </label>
      <VueSelect
        class="filter-dropdown__field"
        :value="value"
        :label="selectedLabel"
        :options="options"
        :placeholder="placeholder"
        @input="changeSelect"
        @search:focus="focused = true"
        @search:blur="focused = false"
        :multiple="multiple"
        :taggable="taggable"
        :push-tags="pushTags"
        :clearable="true"
        :clearSearchOnBlur="clearSearchOnBlur"
      >
        <template v-slot:no-options="{ search }">
          <template>
            {{ noResultsMessage }} <b>{{ search }}</b>
          </template>
        </template>
      </VueSelect>
    </div>
    <p v-if="info" class="base-form__info">{{ info }}</p>
    <div class="base-form__errors" v-if="hasErrors">
      <slot name="errors"></slot>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: "FilterDropdown",
  components: {
    VueSelect: vSelect,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    selectedLabel: {
      type: String,
      default: "label",
    },
    clearSearchOnBlur: {
      type: Function,
      default: function ({ clearSearchOnSelect }) {
        return clearSearchOnSelect;
      },
    },
    noResultsMessage: {
      type: String,
      default: function () {
        return this.$t("forms.select.no_options");
      },
    },
    info: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Array, Object],
    },
    options: {
      type: [],
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    counter: {
      type: Number,
      default: null,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    pushTags: {
      type: Boolean,
      default: false,
    },
    positionY: {
      type: String,
      default: "bottom",
    },
    positionX: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    changeSelect(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped lang="scss">
.filter-dropdown {
  &--top {
    ::v-deep .vs__dropdown-menu {
      bottom: calc(100% + 1px);
      top: auto;
    }
  }

  &--right {
    ::v-deep .vs__dropdown-menu {
      right: 0;
      left: auto;
    }
  }

  &__field {
    &-wrap {
      position: relative;
    }
  }

  &__label {
    position: absolute;
    color: var(--form-input-color);
    top: 8px;
    left: 12px;
  }

  ::v-deep {
    .vs__dropdown-toggle {
      border: var(--nj-gray-4) solid 1px;
      border-radius: 25px;
      color: var(--form-input-color);
      line-height: 20px;
      font-size: 16px;
      padding: 0 10px 4px 10px;

      @media screen and (max-width: $mobile-max) {
        padding: 10px;
        max-height: 40px;
      }
    }

    .vs__dropdown-option--highlight {
      background-color: var(--nj-blue);
    }

    .vs__search {
      padding-left: 0;
    }

    .vs__dropdown-menu {
      transform: translateY(10px);
      border-radius: 10px;
      padding: 0;
      border: none;
      font-size: 16px;
      line-height: 20px;
      max-height: 300px;

      li {
        padding: 10px 16px;
        text-align: left;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }

    .vs__selected {
      color: var(--form-input-color);
      font-size: 14px;
      font-weight: var(--font-weight-regular);
      position: static !important;

      @media screen and (max-width: $mobile-max) {
        display: block;
        margin: 0;
      }
    }

    .vs__dropdown-option {
      min-height: 40px;
    }

    .vs__clear {
      display: none !important;
    }

    .vs--searchable .vs__dropdown-toggle {
      cursor: pointer;
    }
  }
}
</style>
