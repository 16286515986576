<template>
  <div
    v-if="notification"
    :class="[
      'notification-ls-updated',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="goToLiveSession"
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{
          $t("notifications.ls_updated", {
            liveSessionTitle,
            parentTitle,
            date,
            startTime,
          })
        }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserAvatar from "@/components/reusables/UserAvatar";
import { getFormattedDate, getFormattedTime } from "@/service/datetimeUtil";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationLiveSessionUpdated",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  data() {
    return {
      liveSessionTitle: "",
      parentTitle: "",
      startDate: "",
      parentType: "",
      entityId: "",
      parentId: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUserTimezone"]),
    date() {
      if (this.startDate) {
        return getFormattedDate(this.startDate, this.getNJUserTimezone);
      } else {
        return "";
      }
    },
    startTime() {
      if (this.startDate) {
        return getFormattedTime(this.startDate, this.getNJUserTimezone);
      } else {
        return "";
      }
    },
  },
  methods: {
    goToLiveSession() {
      this.$emit("closeNotifications");
      if (this.parentType === "MEMBER_GROUP") {
        this.$router.push({
          name: "liveSession.group.details",
          params: {
            liveSessionId: this.entityId,
            groupId: this.parentId,
          },
        });
      } else if (this.parentType === "CAMPUS_PROGRAM") {
        this.$router.push({
          name: "campus.program.liveSession",
          params: {
            liveSessionId: this.entityId,
            id: this.parentId,
          },
        });
      } else if (this.parentType === "CAMPUS_WORKSHOP") {
        this.$router.push({
          name: "campus.workshop.liveSession",
          params: {
            liveSessionId: this.entityId,
            id: this.parentId,
          },
        });
      }
    },
  },
  async mounted() {
    if (this.notification && this.notification.metaData) {
      const liveSession = this.notification.metaData.find((item) => {
        return item.key === "entityTitle";
      });
      this.liveSessionTitle = liveSession.value;
  
      const entityId = this.notification.metaData.find((item) => {
        return item.key === "entityId";
      });
      if (entityId) {
        this.entityId = entityId.value;
      }

      const parentEntity = this.notification.metaData.find((item) => {
        return item.key === "parentTitle";
      });
      if (parentEntity) {
        this.parentTitle = parentEntity.value;
      }
  
      const parentId = this.notification.metaData.find((item) => {
        return item.key === "parentId";
      });
      if (parentId) {
        this.parentId = parentId.value;
      }
  
      const parentType = this.notification.metaData.find((item) => {
        return item.key === "parentType";
      });
      if (parentType) {
        this.parentType = parentType.value;
      }

      const date = this.notification.metaData.find((item) => {
        return item.key === "startDateAndTime";
      });
      if (date) {
        this.startDate = date.value;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.notification-ls-updated {
  cursor: pointer;
}
</style>
