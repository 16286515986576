<template>
  <div class="video-answers-list">
    <div
      class="video-answers-list__item"
      v-for="answer in answers"
      :key="answer.id"
    >
      <VideoAnswerPerson
        :size="30"
        :user="answer.user"
        @click="goToAnswer(answer.id)"
      />
    </div>
  </div>
</template>

<script>
import VideoAnswerPerson from "@/components/record-answer/VideoAnswerPerson";
import { mapMutations } from "vuex";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "VideoAnswersList",
  components: {
    VideoAnswerPerson,
  },
  props: ["answers", "provocation"],
  methods: {
    ...mapMutations("provocation", ["setProvocation"]),
    goToAnswer(id) {
      this.setProvocation(this.provocation);

      logTrackingData({
        section: "HOME",
        belongsToSection: "PROVOCATION",
        activityType: "CONTENT_PAGE",
        contentType: "PROVOCATION_ANSWER",
        contentId: id,
      });

      this.$router.push({
        name: "video-answer",
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.video-answers-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__item {
    padding: 15px;
  }
}
</style>
