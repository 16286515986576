import XRegExp from "xregexp";

export const validateUsername = (username) => {
  const regExp = /^[a-zA-Z0-9!$*()_+\-',.]*$/g;
  return regExp.test(username);
};

export const validatePrice = (price) => {
  const priceRegex = /^(?:\d{1,3}(?:[.,]\d{3})*(?:[.,]\d{2})?|\d+(?:[.,]\d{2})?)$/;
  return priceRegex.test(price);
};

/**
 * Validates the given password, that must forfill the following policy:
 * <ol>
 *     <li>must be of at least 8 chars length</li>
 *     <li>must contain at least one upper char</li>
 *     <li>must contain at least one lower char</li>
 *     <li>must contain at least one digit</li>
 *     <li>must contain at least one special char from <pre>=+-^$*.[]{}()?"!@#%&/\,><':;|_~`</pre></li>
 * </ol>
 * See <a href="https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html">Cognito Policy</a>
 * @param pw the password to test
 * @returns {boolean} true, if the password is compliant
 */

export const validatePasswordPolicy = (pw) => {
  return /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/.test(
    pw
  );
};

export const validateGrantCode = (grantCode) => {
  return /\d{6}/.test(grantCode);
};
export const hasReadableFormat = (file) => {
  if (!file) {
    return false;
  }
  return (
    file.name &&
    (file.name.split(".").pop() === "pdf" ||
      file.name.split(".").pop() === "PDF")
  );
};

export const hasImageFormat = (file) => {
  if (!file) {
    return false;
  }
  return (
    file.name &&
    (file.name.split(".").pop() === "gif" ||
      file.name.split(".").pop() === "GIF" ||
      file.name.split(".").pop() === "png" ||
      file.name.split(".").pop() === "PNG" ||
      file.name.split(".").pop() === "jpeg" ||
      file.name.split(".").pop() === "JPEG" ||
      file.name.split(".").pop() === "jpg" ||
      file.name.split(".").pop() === "JPG" ||
      file.name.split(".").pop() === "bmp" ||
      file.name.split(".").pop() === "BMP" ||
      file.name.split(".").pop() === "wbmp" ||
      file.name.split(".").pop() === "WBMP")
  );
};

export const hasAudioFormat = (file) => {
  if (!file) {
    return false;
  }
  return (
    file.name &&
    (file.name.split(".").pop() === "mp3" ||
      file.name.split(".").pop() === "MP3")
  );
};

export const hasVideoFormat = (file) => {
  if (!file) {
    return false;
  }
  return (
    file.name &&
    (file.name.split(".").pop() === "mp4" ||
      file.name.split(".").pop() === "webm" ||
      file.name.split(".").pop() === "WEBM" ||
      file.name.split(".").pop() === "MP4")
  );
};

export const hasMediaFormat = (file) => {
  if (!file) {
    return false;
  }
  return (
    file.name &&
    (file.name.split(".").pop() === "mp4" ||
      file.name.split(".").pop() === "MP4" ||
      file.name.split(".").pop() === "mp3" ||
      file.name.split(".").pop() === "MP3" ||
      file.name.split(".").pop() === "pdf" ||
      file.name.split(".").pop() === "PDF")
  );
};
