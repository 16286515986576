var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"daily-provocation-carousel"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.provocations.length > 1 && _vm.carousel && _vm.carousel.index),expression:"provocations.length > 1 && carousel && carousel.index"}],staticClass:"daily-provocation-carousel__go-btn daily-provocation-carousel__go-btn--left",on:{"click":_vm.goLeftInSlider}},[_c('Chevron',{attrs:{"size":"large","direction":"left"}})],1),_c('div',{staticClass:"glide glide-provocations"},[_c('div',{staticClass:"glide__track",class:{
        'glide__track--not-first':
          _vm.provocations.length > 1 && _vm.carousel && _vm.carousel.index,
      },attrs:{"data-glide-el":"track"}},[_c('div',{staticClass:"glide__slides",style:({ opacity: _vm.carousel ? 1 : 0 })},_vm._l((_vm.provocations),function(provocation){return _c('div',{key:provocation.id,staticClass:"glide__slide"},[_c('DailyProvocationItem',{ref:"provocation-item",refInFor:true,attrs:{"id":provocation.id}})],1)}),0)]),(_vm.carousel)?_c('div',{staticClass:"glide__bullets"},[(_vm.total > 4 && _vm.carousel.index >= 4)?_c('button',{staticClass:"daily-provocation-carousel__bullet daily-provocation-carousel__bullet--fake",on:{"click":function($event){return _vm.goToSlide(_vm.carousel.index - 1)}}}):_vm._e(),_vm._l((_vm.clickableDots),function(index){return _c('button',{key:index,staticClass:"daily-provocation-carousel__bullet",class:{
          'daily-provocation-carousel__bullet--active':
            index === _vm.carousel.index,
        },on:{"click":function($event){return _vm.goToSlide(index)}}})}),(_vm.total > 4 && _vm.carousel.index + 4 < _vm.total)?_c('button',{staticClass:"daily-provocation-carousel__bullet daily-provocation-carousel__bullet--fake",on:{"click":function($event){return _vm.goToSlide(_vm.carousel.index + 1)}}}):_vm._e()],2):_vm._e()]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.provocations.length > 1 && _vm.carousel && _vm.carousel.index < _vm.total - 1),expression:"provocations.length > 1 && carousel && carousel.index < total - 1"}],staticClass:"daily-provocation-carousel__go-btn daily-provocation-carousel__go-btn--right",on:{"click":_vm.goRightInSlider}},[_c('Chevron',{attrs:{"size":"large","direction":"right"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }