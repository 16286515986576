var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.signedIn)?_c('nav',{class:['navigation', { 'navigation--open': _vm.isMobileMenuOpen }]},[_c('ul',{on:{"click":_vm.closeMobileMenu}},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'home' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.home"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'dashboard' },"target":_vm.$route.name === 'liveSessionMeet' ? '_blank' : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.dashboard"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'campus' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.campus"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'library' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.library"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'notebooks' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.notebooks"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'community' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.community"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'livesession' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.live_session"))+" ")])],1),_c('li',{staticClass:"navigation__chat"},[_c('router-link',{attrs:{"to":{ name: 'chat' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.chat"))+" ")]),(_vm.newChatMessages.length)?_c('span',{staticClass:"navigation__chat-dot"}):_vm._e()],1),(_vm.isSuperAdmin || _vm.isUserAdmin || _vm.isContentManager)?_c('li',[_c('router-link',{staticClass:"navigation__admin",attrs:{"to":{ name: 'admin' },"target":_vm.$route.name === 'meetUpMeet' ||
          _vm.$route.name === 'liveSessionMeet' ||
          _vm.$route.name === 'roundTableMeet'
            ? '_blank'
            : '_self'}},[_vm._v(" "+_vm._s(_vm.$t("nav.admin"))+" ")])],1):_vm._e()]),(_vm.showLangSwitcher)?_c('div',{staticClass:"navigation__lang"},[_c('LanguageSwitcher')],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }