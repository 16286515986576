<template>
  <div
    class="audio-player"
    id="audio-player-container"
    :ref="`audio-player-container-${id}`"
  >
    <audio
      :src="url"
      preload="metadata"
      :ref="`player-${id}`"
      @timeupdate="sliderOnPlay"
      :muted="muted"
      @play="$emit('play', $event)"
      @pause="$emit('pause', $event)"
      @ended="$emit('ended', $event)"
    ></audio>
    <div class="audio-player__controls">
      <div class="audio-player__controls-container">
        <div class="audio-player__controls-info">
          <svg
            class="audio-player__controls-play-btn"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="isPlaying ? pauseAudio() : playAudio()"
          >
            <path
              v-if="isPlaying"
              d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
              fill="#0564D2"
            />
            <path
              v-if="isPlaying"
              d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
              fill="#0564D2"
            />
            <rect
              v-if="isPlaying"
              x="15"
              y="11"
              width="3"
              height="18"
              fill="white"
              stroke="white"
              stroke-width="1.4"
              stroke-linejoin="round"
            />
            <rect
              v-if="isPlaying"
              x="22"
              y="11"
              width="3"
              height="18"
              fill="white"
              stroke="white"
              stroke-width="1.4"
              stroke-linejoin="round"
            />
            <path
              v-else
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.4384 29.0954L30.4384 20.4351L15.4384 11.7749L15.4384 29.0954Z"
              fill="#0564D2"
            />
          </svg>
          <div v-if="title" class="audio-player__controls-title">
            {{ title }}
          </div>
        </div>
        <div class="audio-player__controls-volume">
          <svg
            v-show="volumeIconType === 'normal'"
            @click="mute"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="volume-icon-image"
          >
            <path
              d="M11.7173 5L6.71729 9H2.71729V15H6.71729L11.7173 19V5Z"
              stroke="#4F4F4F"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.2573 8.45996C17.1947 9.3976 17.7213 10.6691 17.7213 11.995C17.7213 13.3208 17.1947 14.5923 16.2573 15.53"
              stroke="#4F4F4F"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            v-show="volumeIconType === 'loud'"
            @click="mute"
            class="volume-icon-image"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5L6 9H2V15H6L11 19V5Z"
              stroke="#4F4F4F"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.07 4.92969C20.9447 6.80496 21.9979 9.34805 21.9979 11.9997C21.9979 14.6513 20.9447 17.1944 19.07 19.0697M15.54 8.45969C16.4774 9.39733 17.004 10.6689 17.004 11.9947C17.004 13.3205 16.4774 14.592 15.54 15.5297"
              stroke="#4F4F4F"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            v-show="muted"
            @click="unmute"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="volume-icon-image volume-icon-image--muted"
          >
            <path
              d="M11 5L6 9H2V15H6L11 19V5Z"
              stroke="#4F4F4F"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23 9L17 15"
              stroke="#4F4F4F"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17 9L23 15"
              stroke="#4F4F4F"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input
            :ref="`player-volume-${id}`"
            type="range"
            id="volume-slider"
            max="100"
            value="100"
            @input="showRangeProgress"
          />
        </div>
      </div>
      <div class="audio-player__progress">
        <input
          v-if="audioLoaded"
          type="range"
          id="seek-slider"
          :max="audioDuration"
          value="0"
          @input="showRangeProgress"
          @change="sliderChange"
          :ref="`seek-slider-${id}`"
        />
        <div class="audio-player__progress-load">
          <span
            id="current-time"
            class="audio-player__current-time body-1-book"
            :ref="`current-time-${id}`"
          >
            00:00:00
          </span>
          <span
            class="audio-player__progress-load-message"
            v-show="!audioLoaded"
          >
            {{ $t("audio_player.loading") }}
          </span>
          <span
            v-show="audioLoaded"
            v-html="totalTime()"
            class="audio-player__total-time body-1-book"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioPlayer",
  props: {
    url: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      muted: false,
      isPlaying: false,
      audioLoaded: false,
      audioDuration: 100,
      volumeIconType: "loud",
    };
  },
  mounted() {
    this.$nextTick(() => {
      let audio = this.$refs[`player-${this.id}`];
      audio.addEventListener(
        "loadedmetadata",
        function () {
          this.initSlider();
          this.audioLoaded = true;
        }.bind(this)
      );
      audio.addEventListener(
        "canplay",
        function () {
          this.audioLoaded = true;
        }.bind(this)
      );
    });
  },
  methods: {
    // pauseAllAudios() {
    //   const audioTags = document.querySelectorAll("audio");
    //
    //   for (let i = 0; i <= audioTags.length - 1; i++) {
    //     audioTags[i].pause();
    //   }
    // },
    playAudio() {
      // this.pauseAllAudios();
      this.$refs[`player-${this.id}`].play();
      this.isPlaying = true;
    },
    pauseAudio() {
      this.$refs[`player-${this.id}`].pause();
      this.isPlaying = false;
    },
    totalTime() {
      let audio = this.$refs[`player-${this.id}`];
      if (audio) {
        let seconds = audio.duration;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },
    //Display the audio time elapsed so far
    elapsedTime() {
      let audio = this.$refs[`player-${this.id}`];
      if (audio) {
        let seconds = audio.currentTime;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },

    //Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      let hours = seconds / 3600;
      let minutes = (seconds % 3600) / 60;
      return [hours, minutes, seconds % 60].map(format).join(":");
    },

    //Set the range slider max value equal to audio duration
    initSlider() {
      let audio = this.$refs[`player-${this.id}`];
      if (audio) {
        this.audioDuration = Math.round(audio.duration);
      }
    },
    showRangeProgress(el) {
      let playerContainer = this.$refs[`audio-player-container-${this.id}`];
      let seekSlider = this.$refs[`seek-slider-${this.id}`];
      let currentTimeContainer = this.$refs[`current-time-${this.id}`];
      if (el.target === seekSlider) {
        currentTimeContainer.textContent = this.convertTime(el.target.value);
        playerContainer.style.setProperty(
          "--seek-before-width",
          (el.target.value / el.target.max) * 100 + "%"
        );
      } else {
        playerContainer.style.setProperty(
          "--volume-before-width",
          (el.target.value / el.target.max) * 100 + "%"
        );
        this.volume(el);
      }
    },
    sliderChange() {
      let audio = this.$refs[`player-${this.id}`];
      let seekSlider = this.$refs[`seek-slider-${this.id}`];
      if (seekSlider) {
        audio.currentTime = seekSlider.value;
      }
    },
    sliderOnPlay() {
      let audio = this.$refs[`player-${this.id}`];
      let seekSlider = this.$refs[`seek-slider-${this.id}`];
      if (audio) {
        if (seekSlider) {
          seekSlider.value = Math.floor(audio.currentTime);
        }
        this.showSliderValueChange();
        if (audio.currentTime === audio.duration) {
          this.pauseAudio();
        }
      }
    },
    showSliderValueChange() {
      let playerContainer = this.$refs[`audio-player-container-${this.id}`];
      let seekSlider = this.$refs[`seek-slider-${this.id}`];
      let currentTimeContainer = this.$refs[`current-time-${this.id}`];

      if (seekSlider) {
        currentTimeContainer.textContent = this.convertTime(seekSlider.value);
        playerContainer.style.setProperty(
          "--seek-before-width",
          (seekSlider.value / seekSlider.max) * 100 + "%"
        );
      }
    },
    volume(e) {
      let audio = this.$refs[`player-${this.id}`];
      audio.volume = e.target.value / 100;
      this.muted = audio.volume === 0;
      this.handleVolumeIcon(audio.volume);
    },
    mute() {
      let playerContainer = this.$refs[`audio-player-container-${this.id}`];
      let audio = this.$refs[`player-${this.id}`];

      this.muted = true;
      audio.volume = 0;
      this.$refs[`player-volume-${this.id}`].value = 0;
      playerContainer.style.setProperty("--volume-before-width", 0 + "%");
      this.handleVolumeIcon(audio.volume);
    },
    unmute() {
      let playerContainer = this.$refs[`audio-player-container-${this.id}`];
      let audio = this.$refs[`player-${this.id}`];

      this.muted = false;
      audio.volume = 1;
      this.$refs[`player-volume-${this.id}`].value = 100;
      playerContainer.style.setProperty("--volume-before-width", 100 + "%");
      this.handleVolumeIcon(audio.volume);
    },
    handleVolumeIcon(volume) {
      if (!this.muted && volume < 0.7) {
        this.volumeIconType = "normal";
      } else if (!this.muted) {
        this.volumeIconType = "loud";
      } else {
        this.volumeIconType = "muted";
      }
    },
  },
};
</script>

<style lang="scss">
.audio-player {
  margin-left: 16px;

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 145px;
    margin-top: 5px;
    margin-right: 10px;

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-info {
      display: flex;
      align-items: center;
    }

    &-title {
      font-size: 17px;
      line-height: 21px;
      margin-left: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-volume {
      display: flex;
      align-items: center;
    }

    &-play-btn {
      cursor: pointer;
      flex-shrink: 0;
    }
  }

  .volume-icon-image {
    margin-right: 8px;
    cursor: pointer;
    background-color: transparent;

    &--muted {
      margin-right: 9px;
    }
  }

  &__progress {
    &-load {
      display: flex;
      justify-content: space-between;
    }
  }
}

#audio-player-container {
  max-width: 500px;
  height: 132px;
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%;
  /*letter-spacing: -0.5px;*/
}

#volume-slider {
  margin: 10px 2.5%;
  width: 63px;
}

#volume-slider::before {
  width: var(--volume-before-width);
  background-color: var(--nj-gray-5);
}

input[type="range"] {
  position: relative;
  -webkit-appearance: none;
  width: 474px;
}

@media screen and (max-width: 905px) and (min-width: 600px) {
  input[type="range"] {
    width: calc(100vw - 443px);
  }
}

@media screen and (max-width: 599px) {
  input[type="range"] {
    width: calc(100vw - 45px);
    margin-left: -15px;
  }
}

input[type="range"]::before {
  position: absolute;
  content: "";
  left: 0;
  width: var(--seek-before-width);
  height: 3px;
  background-color: var(--nj-blue);
  cursor: pointer;
}

input[type="range"]:focus {
  outline: none;
}

//--- webkit based browsers: chrome, safari, opera

#volume-slider::-webkit-slider-thumb {
  background-color: var(--nj-gray-5);
  border: 1px solid var(--nj-gray-5);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: var(--nj-gray-2);
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid var(--nj-blue);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--nj-blue);
  cursor: pointer;
  margin: -3.5px 0 0 0;
}

input[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--nj-blue);
}

//--- mozilla
#volume-slider::-moz-range-track {
  background: var(--nj-gray-2);
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: var(--nj-gray-2);
}

input[type="range"]::-moz-range-progress {
  background-color: var(--nj-gray-5);
}

input[type="range"]::-moz-focus-outer {
  border: 0;
}

#volume-slider::-moz-range-thumb {
  background-color: var(--nj-gray-5);
  border: 1px solid var(--nj-gray-5);
}

input[type="range"]::-moz-range-thumb {
  border: 1px solid var(--nj-blue);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--nj-blue);
  cursor: pointer;
}

input[type="range"]:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--nj-blue);
}

//--- explorer & edge

#volume-slider::-ms-fill-upper {
  background: var(--nj-gray-2);
}

#seek-slider::-ms-fill-lower {
  width: var(--seek-before-width);
  background-color: var(--nj-gray-5);
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border: solid transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background-color: var(--nj-gray-5);
}

input[type="range"]::-ms-fill-upper {
  background: var(--nj-gray-2);
}

#volume-slider::-ms-thumb {
  background-color: var(--nj-gray-5);
  border: 1px solid var(--nj-gray-5);
}

input[type="range"]::-ms-thumb {
  box-sizing: content-box;
  border: 1px solid var(--nj-blue);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--nj-blue);
  cursor: pointer;
}

input[type="range"]:active::-ms-thumb {
  transform: scale(1.2);
  background: var(--nj-blue);
}
</style>
