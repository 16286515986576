var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'form-input',
    {
      'form-input--errors': _vm.hasErrors,
    },
  ]},[(_vm.label.length)?_c('label',{staticClass:"form-input__label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.optional)?_c('span',{staticClass:"form-input__label-optional"},[_vm._v(" (optional) ")]):_vm._e(),_c('span',{staticClass:"form-input__label-tooltip"},[_vm._t("tooltip")],2)]):_vm._e(),_c('div',{staticClass:"form-input__field-wrap"},[(_vm.showPrice && _vm.currency)?_c('div',{staticClass:"form-input__currency"},[_vm._v(_vm._s(_vm.currency))]):_vm._e(),_c('input',{staticClass:"form-input__field",attrs:{"type":_vm.showPasswordOption ? (_vm.showPassword ? 'text' : 'password') : _vm.type,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.changeInput,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}}),(_vm.showPasswordOption && !_vm.showPassword)?_c('EyeIcon',{staticClass:"form-input__icon",on:{"click":function($event){_vm.showPassword = true}}}):_vm._e(),(_vm.showPasswordOption && _vm.showPassword)?_c('EyeCrossedOutIcon',{staticClass:"form-input__icon",on:{"click":function($event){_vm.showPassword = false}}}):_vm._e(),_vm._t("icon"),(_vm.counter)?_c('div',{class:[
        'base-form__counter',
        { 'base-form__counter--error': this.value.length > this.counter },
      ]},[_vm._v(" "+_vm._s(this.value.length)+" / "+_vm._s(this.counter)+" ")]):_vm._e()],2),(_vm.hasErrors)?_c('div',{staticClass:"base-form__errors"},[_vm._t("errors")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }