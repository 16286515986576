<template>
  <button
    :class="[
      'play-button',
      { 'play-button--sm': size === 'sm' },
      { 'play-button--md': size === 'md' },
    ]"
    type="button"
    @click="$emit('play')"
  >
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.4384 29.0954L30.4384 20.4351L15.4384 11.7749L15.4384 29.0954Z"
        fill="#00D96E"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: "PlayButton",
  props: ["size"],
};
</script>

<style lang="scss" scoped>
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);

  svg {
    font-size: 70px;
    height: 70px;
    width: 70px;

    @media (max-width: 750px) {
      width: 40px;
    }
  }

  &--sm {
    svg {
      font-size: 16px;
      height: 16px;
      width: 16px;

      @media (max-width: 750px) {
        width: 16px;
      }
    }
  }

  &--md {
    svg {
      font-size: 40px;
      height: 40px;
      width: 40px;
    }
  }
}
</style>
