<template>
  <div
    class="daily-feed-info-popup base-modal"
    v-if="dialog"
    @keydown.esc="close"
  >
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="close"
    >
      <div
        class="base-modal__inner daily-feed-info-popup__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="daily-feed-info-popup__head">
          <CloseDialogButton @click="close" class="base-modal__close" />
          <h3 class="daily-feed-info-popup__head-title">{{ title }}</h3>
        </div>
        <div class="daily-feed-info-popup__body" v-html="message"></div>
        <div class="daily-feed-info-popup__actions">
          <div class="daily-feed-info-popup__actions-item">
            <BaseButton @click="close">Ok</BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";

export default {
  name: "DailyFeedInfoPopup",
  components: {
    CloseDialogButton,
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: "",
    message: "",
    options: {
      width: 399,
      zIndex: 200,
    },
  }),
  methods: {
    open(title, message) {
      this.dialog = true;

      this.title = title;
      this.message = message;

      this.options = Object.assign(this.options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.resolve(true);
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.daily-feed-info-popup {
  &__inner {
    position: relative;
    border-radius: 20px;
    background-color: var(--nj-white);
    padding: 15px 40px 40px;
    min-height: 202px;
    max-width: 412px;
    width: 100%;
  }

  &__head {
    margin-bottom: 35px;

    &-title {
      text-align: center;
      font-size: 22px;
      line-height: 28px;
      font-weight: var(--font-weight-medium);
    }
  }

  &__body {
    ::v-deep p {
      margin: 0 !important;
      font-size: 16px;
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: 30px !important;
      }
    }
  }

  &__close-btn {
    position: absolute;
    left: 13px;
    top: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
  }
}
</style>
