var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'notification-user-tagged-in-comment',
    'notification',
    {
      'notification--full': _vm.fullView,
    },
  ],on:{"click":_vm.goToFeed}},[_c('div',{staticClass:"notification__avatar"},[_c('UserAvatar',{attrs:{"size":40,"user":_vm.notification.issuer}})],1),_c('div',{staticClass:"notification__text"},[_c('p',[_c('b',[_vm._v(" "+_vm._s(_vm.notification.issuer.firstName)+" "+_vm._s(_vm.notification.issuer.lastName)+" ")]),_vm._v(" "+_vm._s(_vm.$t("notifications.mentioned_in_comment"))+" ")]),_c('div',{staticClass:"notification__time"},[_vm._v(" "+_vm._s(_vm.durationInDays())+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }