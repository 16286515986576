import Vue from "vue";
import Vuex from "vuex";
import view from "@/store/modules/view";
import pagination from "@/store/modules/pagination";
import auth from "@/store/modules/auth";
import common from "@/store/modules/common";
import provocation from "@/store/modules/provocation";
import notebook from "@/store/modules/notebook";
import chat from "@/store/modules/chat";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth,
    view,
    pagination,
    common,
    provocation,
    notebook,
    chat,
  },
});
