<template>
  <div class="select-thumbnail-popup base-modal">
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
    >
      <div
        class="select-thumbnail-popup__inner base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="base-modal__head">
          <CloseDialogButton class="base-modal__close" @click="closePopup" />
          <h1 class="base-modal__head-title">
            {{ $t("new_provocation.edit_title") }}
          </h1>
        </div>
        <div class="base-modal__body">
          <form class="select-thumbnail-popup__form base-modal__form base-form">
            <div class="select-thumbnail-popup__form-inner">
              <p class="select-thumbnail-popup__text" v-show="!showUploadInput">
                {{ $t("new_provocation.choose_thumbnail_1") }}
                <button
                  class="select-thumbnail-popup__text-btn"
                  type="button"
                  @click="showUploadInput = true"
                >
                  {{ $t("new_provocation.choose_thumbnail_2") }}
                </button>
              </p>
              <div
                class="select-thumbnail-popup__thumbnails"
                v-show="!showUploadInput"
              >
                <div class="select-thumbnail-popup__thumbnails-selected">
                  <div class="select-thumbnail-popup__thumbnails-image">
                    <img
                      :src="
                        form.selectedThumbnail
                          ? createImageUrl(form.selectedThumbnail)
                          : '/default-user-image.png'
                      "
                      alt="thumbnail"
                    />
                  </div>
                </div>
                <div class="select-thumbnail-popup__thumbnails-list">
                  <div
                    class="select-thumbnail-popup__thumbnails-item"
                    v-for="image in thumbnailImages"
                    :key="image"
                  >
                    <div
                      :class="[
                        'select-thumbnail-popup__thumbnails-image',
                        {
                          'select-thumbnail-popup__thumbnails-image--selected':
                            image === form.selectedThumbnail,
                        },
                      ]"
                    >
                      <img
                        :src="createImageUrl(image)"
                        alt="thumbnail"
                        @click="selectThumbnail(image)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="select-thumbnail-popup__upload"
                v-show="showUploadInput"
              >
                <p class="select-thumbnail-popup__text">
                  <button
                    class="select-thumbnail-popup__text-btn"
                    type="button"
                    @click="showUploadInput = false"
                  >
                    {{ $t("new_provocation.choose_upload_1") }}
                  </button>
                  {{ $t("new_provocation.choose_upload_2") }}
                </p>
                <ContentItemImagePicker
                  @update="updateImages"
                  :image-base64="form.thumbnailFile"
                  :src="form.thumbnailFile"
                  :width="552"
                  :height="310"
                  recommendedSize="[16:9]: 378 x 212 px"
                  variant="user"
                >
                </ContentItemImagePicker>
              </div>
              <div class="select-thumbnail-popup__description">
                <p class="select-thumbnail-popup__text">
                  {{ $t("new_provocation.review_title_label") }}
                </p>
                <FormTextarea
                  v-model="$v.form.title.$model"
                  :counter="150"
                  :hasErrors="titleIsEmpty || titleIsTooLong"
                >
                  <template v-slot:errors>
                    <p class="base-form__errors-item" v-if="titleIsEmpty">
                      {{ $t("new_provocation.error_title_required") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="titleIsTooLong"
                    >
                      {{ $t("new_provocation.error_title_length") }}
                    </p>
                  </template>
                </FormTextarea>
              </div>
            </div>

            <div class="base-modal__actions">
              <div
                class="base-modal__actions-item select-thumbnail-popup__record"
              >
                <BaseButton variant="primary" @click="submitProvocation()">
                  {{ $t("new_provocation.publish_button") }}
                </BaseButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Confirm
      variant="primary"
      ref="confirmDiscard"
      accept-button-text="Stay on page"
      decline-button-text="Discard recording"
      :reverse-button-functionality="true"
    />
    <SuccessPopup ref="success" />
    <UploadFile ref="uploadFile" />
  </div>
</template>

<script>
import ADD_PROVOCATION from "@/graphql/provocations/mutations/CreateProvocation.graphql";
import ContentItemImagePicker from "@/components/library/library_reusables/ContentItemImagePicker";
import FormTextarea from "@/components/forms/FormTextarea";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import { validationMixin } from "vuelidate/src";
import SubmitProvocationMixin from "@/components/mixins/SubmitProvocationMixin";
import errorHandler from "@/service/errorHandler";
import Confirm from "@/components/reusables/Confirm";
import SuccessPopup from "@/components/reusables/SuccessPopup";
import UploadFile from "@/components/reusables/UploadFile";
import { logTrackingData } from "@/service/trackingServices";
import { convertImageToFile } from "@/service/convertImage";

export default {
  name: "SelectThumbnailPopup",
  components: {
    UploadFile,
    SuccessPopup,
    Confirm,
    FormTextarea,
    CloseDialogButton,
    ContentItemImagePicker,
  },
  mixins: [validationMixin, SubmitProvocationMixin],
  methods: {
    updateImages(thumbnail) {
      this.form.thumbnailFile = thumbnail.toDataURL();
    },
    async submitProvocation() {
      this.$v.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      this.$refs.uploadFile.openUploadDialog(
        this.$t("default.uploading"),
        "Your provocation is currently uploading, please stay on this page."
      );

      let variables = {
        title: this.form.title,
        uploader: this.getUsername,
        uploadKey: this.form.uploadKey,
        filename: this.form.fileName,
      };
      if (this.showUploadInput && this.form.thumbnailFile) {
        variables.thumbnailId = await this.$refs.uploadFile.upload(
          convertImageToFile(
            this.form.thumbnailFile,
            "provocation_thumbnail_image"
          )
        );
        variables.autoGeneratedThumbnail = false;
      } else {
        variables.thumbnailId = this.form.selectedThumbnail;
        variables.autoGeneratedThumbnail = true;
      }

      this.$apollo
        .mutate({
          mutation: ADD_PROVOCATION,
          variables: variables,
        })
        .then(async (data) => {
          logTrackingData({
            section: "HOME",
            belongsToSection: "PROVOCATION",
            activityType: "CREATE_PROVOCATION",
            contentType: "PROVOCATION",
            contentId: data.data.provocation.id,
          });
          this.dialog = false;
          this.$refs.uploadFile.closeUploadDialog();
          await this.$refs.success.open(
            this.$t("new_provocation.success_title"),
            this.$t("new_provocation.success_message")
          );
          this.$emit("uploaded");
          this.$router.push({ name: "home" }).catch(() => {});
        })
        .catch((error) => {
          this.$refs.uploadFile.closeUploadDialog();
          errorHandler(
            error,
            "ERROR_SUBMIT_PROVOCATION",
            this.$t("new_provocation.error_publish_provocation")
          );
        });
    },
    async open(title, thumbnailImages, uploadKey, fileName) {
      this.thumbnailImages = thumbnailImages.slice(0, 3);
      this.form.title = title;
      this.form.uploadKey = uploadKey;
      this.form.fileName = fileName;
      this.form.selectedThumbnail = this.thumbnailImages[0];
      this.dialog = true;
    },
    async closePopup() {
      if (
        !(await this.$refs.confirmDiscard.open(
          this.$t("new_provocation.unpublished.confirm.title"),
          this.$t("new_provocation.unpublished.confirm.message")
        ))
      ) {
        this.resetForm();
        this.dialog = false;
        this.showUploadInput = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-thumbnail-popup {
  color: var(--nj-black);

  &--hidden {
    background: transparent;

    .base-modal__dialog {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__inner {
    text-align: left;
    width: 100%;
    max-width: 550px;
  }

  &__form {
    &-inner {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
    }
  }

  &__text {
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    font-weight: var(--font-weight-medium);
    margin-top: 0 !important;

    &-btn {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }

  &__description {
    margin-top: 30px;

    ::v-deep {
      .form-textarea__field {
        height: 110px;
      }
    }
  }

  &__thumbnails {
    margin-bottom: 30px;

    &-selected {
      width: 100%;
      margin-bottom: 15px;

      img {
        display: block;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
      }
    }

    &-list {
      display: flex;
      align-items: center;
      margin: 0 -5px;
    }

    &-item {
      cursor: pointer;
      padding: 0 5px;
      width: 33.333333%;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

    &-image {
      padding-top: 75%;
      position: relative;
      border: 1px solid #4f4f4f;
      border-radius: 3px;
      overflow: hidden;

      &--selected {
        border: 1px solid var(--nj-green);
      }

      img {
        display: block;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
      }
    }
  }

  &__upload {
    &-btn {
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 20px;
      font-size: 13px;
      line-height: 18px;
    }

    &-text {
      font-size: 13px;
      line-height: 18px;
      margin: 0;
    }

    &-input {
      margin-bottom: 10px;
    }
  }

  .base-button {
    display: inline-flex;
    align-items: center;
    height: 42px;

    &__text {
      font-weight: var(--font-weight-medium);
    }

    &__icon {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}
</style>
