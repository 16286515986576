var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"community-members-buttons"},[(
      (!_vm.connectionStatus && _vm.statusLoaded) ||
      (_vm.connectionStatus &&
        _vm.connectionStatus.connectToUserRequestStatus === 'DECLINED')
    )?_c('BaseButton',{class:`community-members-buttons__connect-btn--${_vm.recipient.id}`,attrs:{"variant":"secondary"},on:{"click":_vm.openConnectPopup}},[_vm._v(" "+_vm._s(_vm.$t("community.groups.status_buttons.connect"))+" ")]):(
      _vm.connectionStatus &&
      _vm.connectionStatus.connectToUserRequestStatus === 'PENDING' &&
      _vm.connectionStatus.issuer.username === _vm.getUsername
    )?_c('button',{staticClass:"community-members-buttons__cancel-request-button",attrs:{"variant":"secondary"},on:{"click":_vm.cancelConnectRequest}}):(
      _vm.connectionStatus &&
      _vm.connectionStatus.connectToUserRequestStatus === 'PENDING' &&
      _vm.connectionStatus.issuer.username !== _vm.getUsername
    )?_c('div',{staticClass:"community-members-buttons__action"},[_c('div',{staticClass:"community-members-buttons__action-item"},[_c('BaseButton',{attrs:{"variant":"primary"},on:{"click":_vm.acceptConnection}},[_vm._v(" "+_vm._s(_vm.$t("community.groups.status_buttons.accept"))+" ")])],1),_c('div',{staticClass:"community-members-buttons__action-item"},[_c('BaseButton',{attrs:{"variant":"secondary"},on:{"click":_vm.declineConnection}},[_vm._v(" "+_vm._s(_vm.$t("community.groups.status_buttons.decline"))+" ")])],1)]):(
      _vm.connectionStatus &&
      _vm.connectionStatus.connectToUserRequestStatus === 'ACCEPTED'
    )?_c('BaseButton',{attrs:{"variant":"underline"},on:{"click":_vm.removeConnection}},[_vm._v(" "+_vm._s(_vm.$t("community.groups.status_buttons.remove_connection"))+" ")]):_vm._e(),_c('ConnectToUserPopup',{ref:"connectToUserPopup",on:{"sendRequest":_vm.sendConnectRequest}}),_c('Confirm',{ref:"confirm",attrs:{"variant":"primary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }