<template>
  <div class="search-field">
    <input
      class="search-field__input"
      v-model="searchTerm"
      type="text"
      id="name"
      name="name"
      :placeholder="$t('default.search')"
      @input="$emit('input', searchTerm)"
      autocomplete="off"
    />

    <div v-if="searchTerm" @click="clearSearch" class="search-field__clear">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 12.8839L19.0581 19.942L19.942 19.0581L12.8839 12L19.942 4.94199L19.0581 4.05811L12 11.1162L4.94199 4.05811L4.05811 4.94199L11.1162 12L4.05811 19.0581L4.94199 19.942L12 12.8839Z"
          fill="#424242"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchField",
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    clearSearch() {
      this.searchTerm = "";
      this.$emit("clearSearch");
    },
  },
};
</script>

<style scoped lang="scss">
.search-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid var(--nj-gray-4);
  border-radius: 30px;
  padding: 4px;
  height: 42px;
  font-size: 16px;

  &__input {
    display: block;
    margin-left: 21px;
    width: 100%;
  }

  &__clear {
    display: flex;
    align-items: center;
    min-width: 15px;
    height: 15px;
    margin-right: 15px;
    margin-left: 15px;
    cursor: pointer;
  }
}
</style>
