<template>
  <div class="content-item-image-picker">
    <div
      class="content-item-image-picker__inner"
      :class="{ 'content-item-image-picker__inner--error': hasErrors }"
      @click.self="dialog = true"
    >
      <img
        v-if="imageBase64"
        class="content-item-image-picker__preview-img"
        :src="imageForPreview || imageBase64"
        alt="chosen image"
        ref="image"
      />
      <div class="avatar-picker">
        <div
          class="content-item-image-picker__placeholder"
          @click="dialog = true"
        >
          <PaperClipIcon />
          <h3 v-if="imageBase64" class="content-item-image-picker__file-name">
            {{ imageBase64 }}
          </h3>
        </div>

        <div
          class="base-modal__dialog"
          :class="
            dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'
          "
        >
          <div
            class="base-modal__inner"
            :class="
              dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'
            "
          >
            <div class="base-modal__head">
              <h1 class="base-modal__head-title">
                {{ $t("library.cropper_header") }}
              </h1>
              <CloseDialogButton class="base-modal__close" @click="cancel" />
            </div>

            <div class="content-item-image-picker__step">
              <div class="content-item-image-picker__preview-with-text">
                <h4
                  class="
                    content-item-image-picker__step-text
                    content-item-image-picker__step-text-title
                  "
                >
                  {{
                    $t(
                      "avatar_picker.content_item.thumbnail_picker_description"
                    )
                  }}
                  <span class="content-item-image-picker__step-text--red">{{
                    $t(
                      "avatar_picker.content_item.thumbnail_picker_description_red"
                    )
                  }}</span>
                </h4>

                <vue-avatar
                  v-if="dialog"
                  :image="src"
                  :width="width"
                  :height="height"
                  :scale="parseInt(scale)"
                  ref="vueavatar"
                  class="cropper"
                  @vue-avatar-editor:image-ready="onImageReady"
                />
              </div>
              <div class="avatar-picker__scale">
                <label for="scale-input-1">{{
                  $t("avatar_picker.label_scale")
                }}</label>
                <input
                  id="scale-input-1"
                  type="range"
                  v-model="scale"
                  min="1"
                  max="10"
                  step="0.1"
                />
              </div>
            </div>

            <FormActions
              :variant="variant"
              :accept-button="$t('avatar_picker.button_ok')"
              :decline-button="$t('avatar_picker.button_cancel')"
              @accept="accept"
              :disable-accept-button="!imageLoaded"
              @decline="cancel"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="content-item-image-picker__subtitles"
      :class="{
        'content-item-image-picker__subtitles--with-error': hasErrors,
      }"
    >
      <div class="base-form__errors" v-if="hasErrors">
        <slot name="errors"></slot>
      </div>
      <h4 class="content-item-image-picker__subtitles-recommended">
        {{ $t("avatar_picker.recommended_size") }} {{ recommendedSize }}
      </h4>
    </div>
  </div>
</template>

<script>
import PaperClipIcon from "@/components/reusables/Icons/PaperClipIcon";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import VueAvatar from "@/components/reusables/ImageCropper";
import FormActions from "@/components/forms/FormActions";

export default {
  name: "ContentItemImagePicker",
  components: {
    FormActions,
    VueAvatar,
    CloseDialogButton,
    PaperClipIcon,
  },
  props: {
    src: {
      type: String,
      default: "",
    },
    imageBase64: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "admin",
    },
    recommendedSize: {
      type: String,
      default: "",
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
  },
  computed: {
    selectedImageToDataURL() {
      this.selectedImage.toDataURL();
      return this.selectedImage.toDataURL();
    },
  },
  data() {
    return {
      dialog: false,
      imageForPreview: "",
      selectedImage: "",
      imageLoaded: false,
      scale: 1,
    };
  },
  methods: {
    onImageReady() {
      this.imageLoaded = true;
      this.scale = 1;
    },
    accept() {
      this.selectedImage = this.$refs.vueavatar.getImageScaled();
      this.$emit("update", this.selectedImage);
      this.imageForPreview = this.selectedImage.toDataURL();
      this.dialog = false;
    },
    cancel() {
      this.scale = 1;
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.content-item-image-picker {
  margin-bottom: 32px;

  &__header-back {
    position: absolute;
    left: 23px;
    top: 23px;
    cursor: pointer;
  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 24px;

    &-text {
      font-size: 14px;
      text-align: left;

      span {
        color: var(--nj-red);
      }

      &-description {
        font-weight: 450;
        margin-top: 4px;
      }

      &--red {
        color: var(--nj-red);
      }
    }
  }

  &__inner {
    border: 1px solid var(--form-input-border-color);
    border-radius: 4px;
    padding: 9px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--error {
      border: solid var(--form-error-color) 1px;
      background-color: var(--form-error-background);
    }
  }

  &__placeholder {
    cursor: pointer;
    display: flex;

    h3 {
      margin-left: 10px;
    }
  }

  &__preview-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 9px;
  }

  &__color-selector {
    width: 100%;
    margin-top: 30px;
  }

  &__preview-with-text {
    position: relative;
  }

  &__file-name {
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: var(--font-weight-medium);
  }

  .avatar-picker {
    text-align: center;

    &__scale {
      margin: 30px auto 0 auto;
      display: flex;
      width: 100%;

      & input[type="range"] {
        width: 100%;
        margin-left: 10px;
        -webkit-appearance: none;
      }
    }
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid var(--nj-green);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: var(--nj-green);
    cursor: pointer;
    margin: -4.5px 0 0 0;
  }

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    border: 1px solid var(--nj-green);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: var(--nj-green);
    cursor: pointer;
    margin: -4.5px 0 0 0;
  }

  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    border: 1px solid var(--nj-green);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: var(--nj-green);
    cursor: pointer;
    margin: -4.5px 0 0 0;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: var(--nj-gray-2);
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: var(--nj-gray-2);
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: var(--nj-gray-2);
  }

  .cropper {
    ::v-deep {
      #avatarEditorCanvas {
        width: 100% !important;
        margin-top: 40px;
      }
    }

    &-upload-disabled {
      ::v-deep {
        #avatarEditorCanvas {
          pointer-events: none;
        }
      }
    }
  }

  .cropper-normal {
    ::v-deep {
      #avatarEditorCanvas {
        //width: 50% !important;
        margin-top: 40px;
      }
    }
  }

  &__subtitles {
    display: flex;
    justify-content: flex-end;

    &-recommended {
      color: var(--nj-gray-4);
      font-size: 13px;
      line-height: 16px;
      margin-top: 4px;
      text-align: right;
    }

    &--with-error {
      justify-content: space-between;
    }
  }
}
</style>
