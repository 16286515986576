<template>
  <button
      :class="`base-button__${variant}`"
      class="base-button"
      type="button"
      @click="$emit('click')"
      @mouseenter="$emit('mouseenter')"
      @mouseleave="$emit('mouseleave')"
      :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.base-button {
  position: relative;
  border-radius: 30px;
  color: black;
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;

  html[data-theme='oneclub'] & {
    text-transform: uppercase;
    font-weight: var(--font-weight-light);
  }

  &__text {
    html[data-theme='oneclub'] & {
      font-weight: var(--font-weight-light);
    }
    &--booked,
    &--leave {
      html[data-theme='oneclub'] & {
        font-weight: var(--font-weight-light);
      }
    }
  }

  &:focus {
    outline: 0;
  }

  &__primary {
    background-color: var(--nj-green);
    padding: 12px 32px;

    &-icon {
      background-color: var(--nj-green);
      padding: 10px;
      line-height: 10px;
    }

    &--small {
      background-color: var(--nj-green);
      padding: 7px 25px;
      border: solid var(--nj-green) 2px;
    }

    &[disabled] {
      background-color: var(--nj-gray-2);
      box-shadow: none;
      color: var(--nj-gray-4);
      cursor: default;
    }
  }

  &__secondary {
    background-color: transparent !important;
    border: solid var(--nj-green) 2px !important;
    padding: 10px 32px;

    &-icon {
      display: flex;
      align-items: center;
      background-color: transparent !important;
      padding: 10px;
      border: solid var(--nj-green) 1px !important;
      line-height: 10px;
    }

    &--small {
      background-color: transparent !important;
      border: solid var(--nj-green) 2px !important;
      padding: 7px 25px;
    }

    &[disabled] {
      background-color: var(--nj-gray-2) !important;
      border-color: var(--nj-gray-2) !important;
      box-shadow: none;
      color: var(--nj-gray-4);
      cursor: default;
    }
  }

  &__tertiary {
    background-color: transparent;
    border: solid black 2px;
    padding: 10px 32px;
  }

  &__gray {
    background-color: var(--nj-gray-2);
    border: solid var(--nj-gray-2) 2px;
    padding: 10px 32px;

    &[disabled] {
      cursor: default;
    }
  }

  &__disabled {
    background-color: var(--nj-gray-2);
    border: solid var(--nj-gray-2) 2px;
    padding: 10px 32px;
    color: var(--nj-gray-4);

    svg {
      path {
        fill: var(--nj-gray-4);
      }
    }
  }

  &__disabled-secondary {
    background-color: var(--nj-white);
    border: solid var(--nj-gray-2) 2px;
    padding: 10px 32px;
    color: var(--nj-gray-4);
  }

  &__admin {
    background-color: var(--nj-pink);
    color: var(--nj-white);
    padding: 12px 32px;
    border-radius: 30px;
    font-weight: var(--font-weight-medium);
    letter-spacing: 1px;
    text-indent: 0.0892857143em;
    font-size: 14px;
    line-height: 18px;
    height: 42px;
    transition: background-color 0.2s ease-out;

    &[disabled] {
      background-color: var(--nj-gray-3);
      box-shadow: none;
      color: var(--nj-black);
      cursor: default;
    }

    &-icon {
      background-color: var(--nj-pink);
      width: 40px;
      height: 40px;
    }

    &--secondary {
      background-color: var(--nj-white);
      border: var(--nj-pink) 1.5px solid;
      padding: 10px 32px;
      border-radius: 30px;
      font-weight: var(--font-weight-medium);
      letter-spacing: 1px;
      text-indent: 0.0892857143em;
      font-size: 14px;
      line-height: 18px;
      height: 42px;
      transition: background-color 0.2s ease-out;
    }

    &[disabled] {
      background-color: var(--nj-gray-2);
      box-shadow: none;
      color: var(--nj-black);
      cursor: default;
    }
  }

  &__booked {
    background-color: var(--nj-green);
    min-width: 105px;
    height: 42px;
    border: solid 2px var(--nj-green);

    html[data-theme='oneclub'] & {
      min-width: 245px;
    }

    &[disabled] {
      background-color: var(--nj-gray-2);
      box-shadow: none;
      color: var(--nj-gray-4);
      cursor: default;
      border: none;
    }

    &:before {
      margin-right: 5px;
      content: url('data:image/svg+xml; utf8, <svg class="mr-1" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1178 0.884645L4.85373 11.8221L3.98889 11.783L0.324829 6.56581L1.21989 5.9372L4.47689 10.5748L13.2832 0.177734L14.1178 0.884645Z" fill="black"/> </svg>');
    }

    .base-button__text--booked {
      display: inline-block;
    }
    .base-button__text--leave {
      display: none;
    }

    &:hover {
      background-color: white;

      &:before {
        content: "";
      }

      .base-button__text--booked {
        display: none;
      }
      .base-button__text--leave {
        display: inline-block;
      }
    }
  }

  &__underline {
    text-decoration: underline;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    border-radius: 0;

    &[disabled] {
      color: var(--nj-gray-3);
    }
  }

  &__black {
    background-color: var(--nj-black);
    color: var(--nj-white);
    border-radius: 30px;
    border: var(--nj-white) 2px solid;
    padding: 10px 32px;
    height: 42px;
  }
}
</style>
