import { getFormattedTime, getFormattedDate } from "@/service/datetimeUtil";
import { mapGetters } from "vuex";

const liveSessionTimeFormatMixin = {
  computed: {
    ...mapGetters("auth", ["getNJUserTimezone"]),
    date() {
      return getFormattedDate(
        this.liveSession.startTime,
        this.getNJUserTimezone
      );
    },
    startTime() {
      return getFormattedTime(
        this.liveSession.startTime,
        this.getNJUserTimezone
      );
    },
    endTime() {
      return getFormattedTime(this.liveSession.endTime, this.getNJUserTimezone);
    },
  },
};

export default liveSessionTimeFormatMixin;
