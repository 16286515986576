<template>
  <div class="watch-tour-button" ref="watchTourButton">
    <BaseButton variant="underline" @click="openPopupViewer"
      >{{ $t("home.take_tour.watch_button") }}
    </BaseButton>

    <div class="watch-tour-button__tooltip" v-if="showToolTip">
      <h3 class="watch-tour-button__tooltip-message">
        {{ $t("home.take_tour.message") }}
      </h3>

      <div class="watch-tour-button__tooltip-bottom">
        <label class="watch-tour-button__tooltip-checkbox">
          <input
            v-model="checkbox"
            type="checkbox"
            label="Do not show this message again"
            class="watch-tour-button__tooltip-checkbox-input"
          />
          <span class="watch-tour-button__tooltip-checkbox-box"></span>
          <span class="watch-tour-button__tooltip-checkbox-label">
            {{ $t("home.take_tour.checkbox_label") }}</span
          >
        </label>
        <BaseButton
          @click="clickGotIt"
          class="watch-tour-button__tooltip-got-it-btn"
          variant="underline"
        >
          {{ $t("home.take_tour.got_it") }}
        </BaseButton>
      </div>
    </div>

    <div
      class="base-modal__dialog watch-tour-button__popup"
      :class="
        popupViewer ? 'base-modal__dialog--open' : 'base-modal__dialog--close'
      "
      @click.self="closePopupViewer"
    >
      <div
        class="base-modal__inner"
        :class="
          popupViewer ? 'base-modal__inner--open' : 'base-modal__inner--close'
        "
        @keydown.esc="closePopupViewer"
        tabindex="0"
        ref="popupInner"
      >
        <video
          @keydown.esc="closePopupViewer"
          ref="takeATourVideo"
          class="watch-tour-button__video"
          :src="videoUrl"
          controlsList="nodownload"
          controls
          @play="hideTooltipForever"
        />
        <CloseDialogButton
          @click.capture="closePopupViewer"
          color="white"
          class="watch-tour-button__close-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GET_VIDEO_URL from "@/graphql/user/queries/GetTakeATourVideo.graphql";
import { mapGetters, mapState } from "vuex";
import UPDATE_USER_ATTRIBUTES from "@/graphql/user/mutations/UpdateUserAttributes.graphql";
import errorHandler from "@/service/errorHandler";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";

export default {
  name: "WatchTourButton",
  components: { CloseDialogButton },
  data() {
    return {
      popupViewer: false,
      checkbox: false,
      showToolTip: false,
    };
  },
  watch: {
    getNJUser() {
      this.handleTooltip();
    },
    popupViewer() {
      if (!this.popupViewer) {
        this.$refs.takeATourVideo.pause();
      } else {
        this.$refs.popupInner.focus();
        this.$refs.takeATourVideo.play();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    ...mapState("common", ["isTakeATourTooltipPostponed"]),
  },
  mounted() {
    this.handleTooltip();
  },
  methods: {
    handleTooltip() {
      if (this.getNJUser) {
        !this.getNJUser.showTakeatourPopup || this.isTakeATourTooltipPostponed
          ? (this.showToolTip = false)
          : (this.showToolTip = true);
      }
    },
    openPopupViewer() {
      this.showToolTip = false;
      this.$refs.watchTourButton.style.zIndex = "4";
      this.popupViewer = true;
    },
    closePopupViewer() {
      this.popupViewer = false;
      setTimeout(() => {
        this.$refs.watchTourButton.style.zIndex = "1";
      }, 500);
    },
    hideTooltipForever() {
      let variables = {
        ...this.getNJUser,
        showTakeatourPopup: false,
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_ATTRIBUTES,
          variables,
        })
        .then((data) => {
          this.$store.commit("auth/setNJUserProfile", data.data.updateUser);
          this.handleTooltip();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_UPDATE_PROFILE",
            this.$t("error.home.edit_profile_modal")
          );
        });
    },
    clickGotIt() {
      if (this.checkbox) {
        this.hideTooltipForever();
      } else {
        this.$store.commit("common/setTakeATourTooltip", true);
        this.handleTooltip();
      }
    },
  },
  apollo: {
    videoUrl: {
      query: GET_VIDEO_URL,
      update(data) {
        return data.videoUrl;
      },
      error(err) {
        errorHandler(
          err,
          "ERROR_FETCH_TAKEATOUR_VIDEO",
          this.$t("home.take_tour.video_fetch_error")
        );
      },
    },
  },
};
</script>

<style scoped lang="scss">
.watch-tour-button {
  z-index: 1;

  &__close-button {
    position: absolute;
    top: 21px;
    right: 21px;
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 302px;
    height: 141px;
    background-image: url("../../../public/TourTooltipBackground.svg");
    position: absolute;
    padding: 32px 16px 16px 16px;
    border-radius: 20px;
    right: 0;
    top: 28px;

    &-bottom {
      display: flex;
      justify-content: space-between;
    }

    &-message {
      color: var(--nj-white);
      font-size: 14px;
      line-height: 17.71px;
    }

    &-got-it-btn {
      color: var(--nj-white);
      font-size: 12px;
    }

    &-checkbox {
      display: flex;
      align-items: center;

      &-label {
        color: var(--nj-white);
        font-size: 12px;
      }

      &-box {
        display: block;
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 3.5px;
        border: 1px solid var(--nj-white);
        cursor: pointer;
        transition: 0.1s ease-out all;
        margin-right: 4px;

        &:after {
          content: "";
          display: block;
          height: 9px;
          width: 5px;
          border-bottom: 2px solid var(--nj-blue);
          border-right: 2px solid var(--nj-blue);
          transform: rotate(45deg);
          position: absolute;
          left: 5px;
          top: 1px;
          opacity: 0;
        }
      }

      &-input {
        display: none;

        &:checked ~ .watch-tour-button__tooltip-checkbox-box {
          background: var(--nj-white);
          border-color: var(--nj-white);

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &__video {
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  .base-modal {
    &__inner {
      padding: 0 !important;
      width: 100%;
      max-width: 957px;
      max-height: 625px;
      background-color: transparent;
    }
  }
}
</style>
