<template>
  <div
    v-if="notification"
    :class="[
      'notification-ls-deleted',
      'notification',
      { 'notification--full': fullView },
    ]"
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{
          $t("notifications.ls_deleted", {
            liveSessionTitle,
            parentTitle,
          })
        }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationLiveSessionDeleted",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  data() {
    return {
      liveSessionTitle: "",
      parentTitle: "",
    };
  },
  async mounted() {
    if (this.notification && this.notification.metaData) {
      const liveSession = this.notification.metaData.find((item) => {
        return item.key === "entityTitle";
      });
      if (liveSession) {
        this.liveSessionTitle = liveSession.value;
      }

      const parentEntity = this.notification.metaData.find((item) => {
        return item.key === "parentTitle";
      });
      if (parentEntity) {
        this.parentTitle = parentEntity.value;
      }
    }
  },
};
</script>
