import LOG_TRACKING from "@/graphql/tracking/mutations/LogTrackingEvent.graphql";
import store from "@/store";
import { getApolloClient } from "@/plugins/vue-apollo";

export const convertTime = function (seconds) {
  // hh:mm:ss
  return new Date(seconds * 1000).toISOString().slice(11, 19);
};

export const logTrackingData = function (data) {
  const apolloClient = getApolloClient();
  const user = store.getters["auth/getNJUser"];

  apolloClient
    .mutate({
      mutation: LOG_TRACKING,
      variables: {
        userTracking: {
          trackingId: "",
          userId: user ? user.id : null,
          ...data,
        },
      },
    })
    .catch((error) => {
      console.log("Error logging tracking event", error);
    });
};
