<template>
  <div class="notifications-dropdown">
    <div
      v-show="hasUnseenMessages"
      ref="toggleOptionsButtonUnseen"
      class="notifications-dropdown__bell"
      @click="showMenu ? closeMenu() : openMenu()"
    >
      <BellWithDotIcon />
    </div>
    <div
      v-show="!hasUnseenMessages"
      ref="toggleOptionsButtonSeen"
      class="notifications-dropdown__bell"
      @click="showMenu ? closeMenu() : openMenu()"
    >
      <BellWithoutDotIcon />
    </div>
    <ul
      v-show="showMenu"
      v-closable="{
        exclude: ['toggleOptionsButtonUnseen', 'toggleOptionsButtonSeen'],
        handler: 'closeMenuByClickOutside',
      }"
      :class="[
        'notifications-dropdown__list',
        { 'notifications-dropdown__list--with-items': notifications.length },
      ]"
    >
      <li
        v-for="notification in notifications.slice(0, 5)"
        :key="notification.id"
        :class="[
          'notifications-dropdown__list-item',
          {
            'notifications-dropdown__list-item--selected':
              notification.viewedAt === null,
          },
        ]"
      >
        <component
          :is="getNotificationComponent(notification)"
          :notification="notification"
          @closeNotifications="closeMenu"
          @deleteNotification="deleteNotification($event, notification.id)"
        ></component>
      </li>
      <li
        v-if="!notifications.length"
        class="
          notifications-dropdown__list-item
          notifications-dropdown__list-item--center
        "
      >
        <p>{{ $t("notifications.empty_message") }}</p>
      </li>
      <li
        v-if="notifications.length"
        class="
          notifications-dropdown__list-item
          notifications-dropdown__list-item--btn
        "
      >
        <AllNotificationsPopup
          ref="allNotificationsPopup"
          @closeDropdown="closeMenu"
          @refetchNotifications="$apollo.queries.notifications.refetch()"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ALL_NOTIFICATIONS from "@/graphql/notifications/queries/AllNotifications.graphql";
import TICK_OFF_ALL_NOTIFICATIONS from "@/graphql/notifications/mutations/TickOffAllNotifications.graphql";
import SUB_NOTIFICATIONS from "@/graphql/notifications/subscription/NotificationSubscription.graphql";
import DELETE_NOTIFICATION from "@/graphql/notifications/mutations/DeleteNotification.graphql";
import AllNotificationsPopup from "@/components/notifications/AllNotificationsPopup";
import NotificationInvitedToGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationInvitedToGroup";
import NotificationAcceptedInviteToGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationAcceptedInviteToGroup";
import NotificationDeclinedInviteToGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationDeclinedInviteToGroup";
import NotificationDeclinedJoinGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationDeclinedJoinGroup";
import NotificationApprovedJoinGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationApprovedJoinGroup";
import NotificationJoinRequestGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationJoinRequestGroup";
import NotificationCanceledJoinGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationCanceledJoinGroup";
import NotificationCreatedGroupPost from "@/components/notifications/communityMemberGroupNotifications/NotificationCreatedGroupPost";
import NotificationConnect from "@/components/notifications/communityMemberConnectNotifications/NotificationConnect";
import NotificationConnectAccepted from "@/components/notifications/communityMemberConnectNotifications/NotificationConnectAccepted";
import NotificationConnectDeclined from "@/components/notifications/communityMemberConnectNotifications/NotificationConnectDeclined";
import NotificationLiveSessionCreated from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionCreated";
import NotificationLiveSessionDeleted from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionDeleted";
import NotificationLiveSessionUpdated from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionUpdated";
import NotificationLiveSessionReminder from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionReminder";
import BellWithDotIcon from "@/components/reusables/Icons/BellWithDotIcon";
import BellWithoutDotIcon from "@/components/reusables/Icons/BellWithoutDotIcon";
import { mapGetters, mapMutations } from "vuex";
import errorHandler from "@/service/errorHandler";
import NotificationUserTaggedInPost from "@/components/notifications/communityFeedNotifications/NotificationUserTaggedInPost";
import NotificationUserTaggedInComment from "@/components/notifications/communityFeedNotifications/NotificationUserTaggedInComment";
import NotificationUserLikedPost from "@/components/notifications/communityFeedNotifications/NotificationUserLikedPost";
import NotificationUserLikedComment from "@/components/notifications/communityFeedNotifications/NotificationUserLikedComment";
import NotificationUserCommentedOnPost from "@/components/notifications/communityFeedNotifications/NotificationUserCommentedOnPost";
import NotificationUserLikedProvocationAnswer from "@/components/notifications/provocationNotifications/NotificationUserLikedProvocationAnswer";
import NotificationUserLikedProvocation from "@/components/notifications/provocationNotifications/NotificationUserLikedProvocation";
import NotificationNewProvocationAnswer from "@/components/notifications/provocationNotifications/NotificationNewProvocationAnswer";
import NotificationUserLikedDailyFeed from "@/components/notifications/dailyFeedNotifications/NotificationUserLikedDailyFeed";
import NotificationAppointedAsAdmin from "@/components/notifications/communityMemberGroupNotifications/NotificationAppointedAsAdmin";
import NotificationMeetUpCreated from "@/components/notifications/campusNotifications/NotificationMeetUpCreated";
import NotificationMeetUpUpdated from "@/components/notifications/campusNotifications/NotificationMeetUpUpdated";
import NotificationMeetUpDeleted from "@/components/notifications/campusNotifications/NotificationMeetUpDeleted";
import NotificationMeetUpReminder from "@/components/notifications/campusNotifications/NotificationMeetUpReminder";

export default {
  name: "NotificationsDropdown",
  components: {
    BellWithDotIcon,
    BellWithoutDotIcon,
    AllNotificationsPopup,
  },
  data() {
    return {
      showMenu: false,
      hasNotifications: true,
      notifications: [],
      tickInProgress: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
    hasUnseenMessages() {
      return this.notifications.find(
        (notification) => notification.viewedAt === null
      );
    },
  },
  methods: {
    ...mapMutations("common", ["addErrorToast", "setNotificationsMenuState"]),
    getNotificationComponent(notification) {
      if (notification.actionType === "INVITED_TO_GROUP") {
        return NotificationInvitedToGroup;
      } else if (notification.actionType === "INVITATION_ACCEPTED") {
        return NotificationAcceptedInviteToGroup;
      } else if (notification.actionType === "INVITATION_DECLINED") {
        return NotificationDeclinedInviteToGroup;
      } else if (notification.actionType === "REQUESTED_TO_JOIN") {
        return NotificationJoinRequestGroup;
      } else if (notification.actionType === "JOIN_REQUEST_APPROVED") {
        return NotificationApprovedJoinGroup;
      } else if (notification.actionType === "JOIN_REQUEST_DECLINED") {
        return NotificationDeclinedJoinGroup;
      } else if (notification.actionType === "JOIN_REQUEST_CANCELED") {
        return NotificationCanceledJoinGroup;
      } else if (notification.actionType === "REQUESTED_TO_CONNECT") {
        return NotificationConnect;
      } else if (notification.actionType === "CONNECTION_REQUEST_ACCEPTED") {
        return NotificationConnectAccepted;
      } else if (notification.actionType === "CONNECTION_REQUEST_DECLINED") {
        return NotificationConnectDeclined;
      } else if (notification.actionType === "LIVE_SESSION_CREATED") {
        return NotificationLiveSessionCreated;
      } else if (notification.actionType === "LIVE_SESSION_DELETED") {
        return NotificationLiveSessionDeleted;
      } else if (notification.actionType === "LIVE_SESSION_UPDATED") {
        return NotificationLiveSessionUpdated;
      } else if (notification.actionType === "LIVE_SESSION_START_REMINDER") {
        return NotificationLiveSessionReminder;
      } else if (notification.actionType === "D_USER_TAGGED_IN_A_POST") {
        return NotificationUserTaggedInPost;
      } else if (notification.actionType === "E_USER_TAGGED_IN_A_COMMENT") {
        return NotificationUserTaggedInComment;
      } else if (notification.actionType === "A_USER_LIKED_A_POST") {
        return NotificationUserLikedPost;
      } else if (notification.actionType === "C_USER_LIKED_A_COMMENT") {
        return NotificationUserLikedComment;
      } else if (notification.actionType === "B_USER_COMMENTED_ON_A_POST") {
        return NotificationUserCommentedOnPost;
      } else if (notification.actionType === "NEW_PROVOCATION_ANSWER_LIKE") {
        return NotificationUserLikedProvocationAnswer;
      } else if (notification.actionType === "NEW_PROVOCATION_LIKE") {
        return NotificationUserLikedProvocation;
      } else if (notification.actionType === "NEW_DAILY_FEED_LIKE") {
        return NotificationUserLikedDailyFeed;
      } else if (notification.actionType === "NEW_PROVOCATION_ANSWER") {
        return NotificationNewProvocationAnswer;
      } else if (notification.actionType === "MEMBER_BECOME_OWNER_OF_GROUP") {
        return NotificationAppointedAsAdmin;
      } else if (notification.actionType === "MEET_UP_CREATED") {
        return NotificationMeetUpCreated;
      } else if (notification.actionType === "MEET_UP_UPDATED") {
        return NotificationMeetUpUpdated;
      } else if (notification.actionType === "MEET_UP_DELETED") {
        return NotificationMeetUpDeleted;
      } else if (notification.actionType === "MEET_UP_START_REMINDER") {
        return NotificationMeetUpReminder;
      } else if (
        notification.actionType === "F_USER_CREATED_POST_IN_MEMBER_GROUP"
      ) {
        return NotificationCreatedGroupPost;
      }
    },
    deleteNotification(closePopup, notificationId) {
      this.$apollo
        .mutate({
          mutation: DELETE_NOTIFICATION,
          variables: {
            notificationId,
            userId: this.getNJUser.id,
          },
        })
        .then(() => {
          if (closePopup) {
            this.closeMenu();
          } else {
            this.$apollo.queries.notifications.refetch();
          }
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DELETE_NOTIFICATION",
            this.$t("notifications.error.delete")
          );
        });
    },
    closeMenuByClickOutside() {
      this.closeMenu();
    },
    openMenu() {
      if (window.innerWidth <= 768 && this.notifications.length) {
        this.showMenu = true;
        this.$refs.allNotificationsPopup.openPopup();
      } else {
        this.showMenu = true;
      }
      this.setNotificationsMenuState(true);
    },
    closeMenu() {
      if (this.showMenu && this.notifications.length) {
        this.tickOffAllNotifications();
      }
      this.showMenu = false;
      this.setNotificationsMenuState(false);
    },
    tickOffAllNotifications() {
      if (this.tickInProgress) {
        return;
      }

      this.tickInProgress = true;
      this.$apollo
        .mutate({
          mutation: TICK_OFF_ALL_NOTIFICATIONS,
          variables: {
            userId: this.getNJUser.id,
          },
        })
        .then(() => {
          this.$apollo.queries.notifications.refetch();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_TICK_OFF_NOTIFICATIONS",
            this.$t("notifications.error.tick_off")
          );
        })
        .finally(() => {
          this.tickInProgress = false;
        });
    },
  },
  apollo: {
    notifications: {
      query: ALL_NOTIFICATIONS,
      variables() {
        return {
          username: this.getUsername,
          page: {
            page: 0,
            pageSize: 5,
          },
        };
      },
      skip() {
        return !this.getUsername;
      },
      fetchPolicy: "network-only",
      update(data) {
        return data.page.notifications;
      },
      async error(error) {
        await errorHandler(
          error,
          "ERROR_NOTIFICATIONS",
          this.$t("notifications.error.fetch")
        );
      },
      // TODO PROD-1228: added subscription, plz review and approve (do we have to manipulate the cache anyways?
      // there are console warnings by Apollo saying we should use a merger... it's confusing me.)
      subscribeToMore: {
        document: SUB_NOTIFICATIONS,
        updateQuery() {
          this.$apollo.queries.notifications.refetch();
        },
        skip() {
          return !this.getNJUser;
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-dropdown {
  &__bell {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: var(--nj-box-shadow);
    top: 65px;
    right: 25px;
    background-color: white;
    width: 400px;

    &--with-items {
      min-height: 380px;
    }

    @media (max-width: $mobile-max) {
      width: calc(100% - 50px);
    }

    &-item {
      position: relative;
      padding: 11px 95px 11px 16px;
      transition: background-color 0.2s ease-out;

      &:hover {
        &:hover {
          ::v-deep {
            .notification-join-request-group:not(.notification--full),
            .notification-connect:not(.notification--full),
            .notification-invited-to-group:not(.notification--full) {
              .notification__action--short {
                display: flex;
              }

              .notification__time:not(.notification__time--show) {
                display: none;
              }
            }
          }
        }
      }

      &--btn {
        display: flex;
        justify-content: center;
        padding: 11px 33px;
        margin-top: auto;
      }

      &--center {
        text-align: center;
      }

      &--selected {
        background-color: var(--nj-gray-1);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--nj-gray-2);
      }
    }
  }
}
</style>
