<template>
  <div
    v-if="group"
    :class="[
      'notification-invited-to-group',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="goToGroup"
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.invited_to_join", { groupName: group.name }) }}
      </p>
      <div
        class="notification__time"
        :class="{
          'notification__time--show': !showShortActions && !showFullActions,
        }"
      >
        {{ durationInDays() }}
      </div>
    </div>
    <div class="notification__action" @click.stop v-if="showFullActions">
      <BaseButton variant="secondary" @click="declineInvitation">
        {{ $t("community.groups.status_buttons.decline") }}
      </BaseButton>
      <BaseButton @click="acceptInvitation">
        {{ $t("community.groups.status_buttons.join") }}
      </BaseButton>
    </div>
    <div
      class="notification__action notification__action--short"
      v-if="showShortActions"
    >
      <button class="notification__action-btn" @click.stop="declineInvitation">
        <DeclineIcon />
      </button>
      <button class="notification__action-btn" @click.stop="acceptInvitation">
        <AcceptIcon />
      </button>
    </div>
    <LoadingOverlay v-if="loading">
      {{ $t("notifications.loading") }}
    </LoadingOverlay>
  </div>
</template>

<script>
import ACCEPT_INVITATION from "@/graphql/member-group/mutations/AcceptInvitationToJoin.graphql";
import DECLINE_INVITATION from "@/graphql/member-group/mutations/DeclineInvitationToJoin.graphql";
import NotificationGroupMixin from "@/components/mixins/NotificationGroupMixin";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";
import UserAvatar from "@/components/reusables/UserAvatar";
import AcceptIcon from "@/components/reusables/Icons/AcceptIcon";
import DeclineIcon from "@/components/reusables/Icons/DeclineIcon";

export default {
  name: "NotificationInvitedToGroup",
  components: { UserAvatar, AcceptIcon, DeclineIcon },
  props: ["notification", "fullView"],
  mixins: [NotificationGroupMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    showFullActions() {
      return (
        this.fullView &&
        this.joinRequest &&
        this.joinRequest.status === "PENDING" &&
        !this.isGroupMember
      );
    },
    showShortActions() {
      return (
        !this.fullView &&
        this.joinRequest &&
        this.joinRequest.status === "PENDING" &&
        !this.isGroupMember
      );
    },
  },
  methods: {
    goToGroup() {
      this.$emit("closeNotifications");
      this.$router.push({
        // name: "community.groups.info",
        name: "community.groups",
        // params: { groupId: this.group.id },
        query: {
          showGroupInfoPopup: this.group.id,
        },
      });
    },
    acceptInvitation() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: ACCEPT_INVITATION,
          variables: {
            joinRequestId: this.joinRequest.id,
            userId: this.getNJUser.id,
          },
        })
        .then(() => {
          this.$emit("deleteNotification", true);
          this.$router.push({
            name: "group.details",
            params: { groupId: this.group.id },
          });
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_ACCEPT_INVITATION",
            this.$t("error.group.approve_invite_error")
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    declineInvitation() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: DECLINE_INVITATION,
          variables: {
            joinRequestId: this.joinRequest.id,
            userId: this.getNJUser.id,
          },
        })
        .then(() => {
          this.$emit("deleteNotification", false);
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DECLINE_INVITATION",
            this.$t("error.group.decline_invite_error")
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getGroupRequestDetails();
  },
};
</script>

<style scoped lang="scss">
.notification-invited-to-group {
  cursor: pointer;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
</style>
