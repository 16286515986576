<template>
  <div class="select-thumbnail-popup base-modal">
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
    >
      <div
        class="select-thumbnail-popup__inner base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="base-modal__head">
          <CloseDialogButton class="base-modal__close" @click="closePopup" />
          <h1 class="base-modal__head-title">
            {{ $t("daily_feeds.user_add.title") }}
          </h1>
        </div>
        <div class="base-modal__body">
          <form class="select-thumbnail-popup__form base-modal__form base-form">
            <div class="select-thumbnail-popup__form-inner">
              <p class="select-thumbnail-popup__text">
                {{ $t("daily_feeds.user_add.select_thumbnail") }}
              </p>
              <div class="select-thumbnail-popup__thumbnails">
                <div class="select-thumbnail-popup__thumbnails-selected">
                  <div class="select-thumbnail-popup__thumbnails-image">
                    <img
                      :src="createImageUrl(form.selectedThumbnail)"
                      alt="thumbnail"
                    />
                  </div>
                </div>
                <div class="select-thumbnail-popup__thumbnails-list">
                  <div
                    class="select-thumbnail-popup__thumbnails-item"
                    v-for="image in thumbnailImages"
                    :key="image"
                  >
                    <div
                      :class="[
                        'select-thumbnail-popup__thumbnails-image',
                        {
                          'select-thumbnail-popup__thumbnails-image--selected':
                            image === form.selectedThumbnail,
                        },
                      ]"
                    >
                      <img
                        :src="createImageUrl(image)"
                        alt="thumbnail"
                        @click="selectThumbnail(image)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="base-modal__actions">
              <div
                class="base-modal__actions-item select-thumbnail-popup__record"
              >
                <BaseButton variant="primary" @click="submitFeed">
                  Confirm
                </BaseButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <SuccessPopup ref="success" />
    <UploadFile ref="uploadFile" />
  </div>
</template>

<script>
import CREATE_DAILY_FEED from "@/graphql/daily-feeds/mutations/CreateDailyFeed.graphql";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import SuccessPopup from "@/components/reusables/SuccessPopup";
import UploadFile from "@/components/reusables/UploadFile";
import errorHandler from "@/service/errorHandler";
import { mapGetters } from "vuex";
import createPreviewThumbnailUrl from "@/service/createPreviewThumbnailUrl";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "SelectThumbnailPopup",
  components: {
    UploadFile,
    SuccessPopup,
    CloseDialogButton,
  },
  props: {
    section: {
      type: String,
      default: "LIBRARY",
    },
  },
  data() {
    return {
      thumbnailImages: [],
      form: {
        description: "",
        type: null,
        storageId: "",
        selectedThumbnail: "",
      },
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  methods: {
    createImageUrl(id) {
      return createPreviewThumbnailUrl(id);
    },
    resetForm() {
      this.form = {
        description: "",
        type: null,
        storageId: "",
        selectedThumbnail: "",
      };
    },
    selectThumbnail(image) {
      this.form.selectedThumbnail = image;
    },
    async submitFeed() {
      await this.$apollo
        .mutate({
          mutation: CREATE_DAILY_FEED,
          variables: {
            description: this.form.description,
            type: this.form.type,
            userId: this.getNJUser.id,
            storageId: this.form.storageId,
            thumbnailId: this.form.selectedThumbnail,
          },
        })
        .then(async (data) => {
          logTrackingData({
            section: this.section,
            belongsToSection: "DAILY_FEED",
            activityType: "CREATE_FEED",
            contentId: data.data.dailyFeed.id,
          });
          this.$emit("thumbnailSelected");
          this.closePopup();
        })
        .catch(async (error) => {
          await errorHandler(
            error,
            "CREATE_DAILY_FEED",
            this.$i18n.t("error.library.daily_feeds.file_upload_error")
          );
          this.closePopup();
        });
    },
    async open(description, type, userId, storageId, thumbnailImages) {
      this.form.description = description;
      this.form.type = type;
      this.form.userId = userId;
      this.form.storageId = storageId;
      this.thumbnailImages = thumbnailImages.slice(0, 3);
      this.form.selectedThumbnail = thumbnailImages[0];
      this.dialog = true;
    },
    closePopup() {
      this.resetForm();
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-thumbnail-popup {
  color: var(--nj-black);

  &--hidden {
    background: transparent;

    .base-modal__dialog {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__inner {
    text-align: left;
    width: 100%;
    max-width: 550px;
  }

  &__form {
    &-inner {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
    }
  }

  &__text {
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    font-weight: var(--font-weight-medium);
    margin-top: 0 !important;

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }

  &__thumbnails {
    margin-bottom: 30px;

    &-selected {
      width: 100%;
      margin-bottom: 15px;

      img {
        display: block;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        height: 245px !important;
      }
    }

    &-list {
      display: flex;
      align-items: center;
      margin: 0 -5px;
    }

    &-item {
      cursor: pointer;
      padding: 0 5px;
      width: 33.333333%;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

    &-image {
      border: 1px solid #4f4f4f;
      border-radius: 3px;
      overflow: hidden;

      &--selected {
        border: 1px solid var(--nj-green);
      }

      img {
        display: block;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        height: 90px;
      }
    }
  }

  &__upload {
    &-btn {
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 20px;
      font-size: 13px;
      line-height: 18px;
    }

    &-text {
      font-size: 13px;
      line-height: 18px;
      margin: 0;
    }

    &-input {
      margin-bottom: 10px;
    }
  }

  .base-button {
    display: inline-flex;
    align-items: center;
    height: 42px;

    &__text {
      font-weight: var(--font-weight-medium);
    }

    &__icon {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}
</style>
