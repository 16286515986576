<template>
  <div
    v-if="notification"
    :class="[
      'notification-meetup-deleted',
      'notification',
      { 'notification--full': fullView },
    ]"
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p>
        {{
          $t("notifications.meetup_deleted", {
            meetUpTitle,
          })
        }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationMeetUpDeleted",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  data() {
    return {
      meetUpTitle: "",
    };
  },
  async mounted() {
    if (this.notification && this.notification.metaData) {
      const meetUp = this.notification.metaData.find((item) => {
        return item.key === "entityTitle";
      });
      if (meetUp) {
        this.meetUpTitle = meetUp.value;
      }
    }
  },
};
</script>
