<template>
  <div class="home">
    <div class="home__content">
      <router-view />
    </div>
    <div class="home__footer">
      <MainFooter />
    </div>
  </div>
</template>

<script>
import MainFooter from "@/components/reusables/MainFooter";
import { mapGetters } from "vuex";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "Home",
  components: {
    MainFooter,
  },
  watch: {
    getNJUser(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.trackNavigation();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  methods: {
    trackNavigation() {
      if (this.getNJUser) {
        logTrackingData({
          activityType: "HOME",
          activityPerformedOn: "MENU",
        });
      }
    },
  },
  created() {
    this.trackNavigation();
  },
};
</script>
