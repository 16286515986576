import { DateTime } from "luxon";

export function applyUserTimezone(isoDate, timezoneId) {
  if (!isoDate || !timezoneId) {
    console.warn(`can not apply ${timezoneId} to ${isoDate}`);
    return undefined;
  }
  let dateTime = DateTime.fromISO(isoDate, { zone: timezoneId });
  if (dateTime.invalid) {
    console.warn(`can not parse ${isoDate}`);
    return undefined;
  }
  return dateTime;
}

export function of(year, month, dayOfMonth, hour, minutes, timezoneId) {
  if (
    !year ||
    !month ||
    !dayOfMonth ||
    hour === undefined ||
    minutes === undefined ||
    !timezoneId
  ) {
    console.warn(
      `can not create DateTime from ${year}-${month}-${dayOfMonth}-${hour}-${minutes}-${timezoneId}`
    );
    return undefined;
  }

  let dateTime = DateTime.local(
    year,
    month,
    dayOfMonth,
    parseInt(hour),
    parseInt(minutes),
    0,
    0
  );
  dateTime = dateTime
    .setZone(timezoneId)
    .set({ hour: parseInt(hour), minute: parseInt(minutes) });

  if (dateTime.invalid) {
    console.warn(
      `can not create DateTime from ${year}-${month}-${dayOfMonth}-${hour}-${minutes}-${timezoneId}`
    );
    return undefined;
  }
  return dateTime;
}

export function addLeadingZero(i) {
  return i < 10 ? "0" + i : i;
}

export function getFormattedTime(isoDate, timezoneId) {
  const timezoneTime = applyUserTimezone(isoDate, timezoneId);
  const hour = addLeadingZero(timezoneTime.hour);
  const minutes = addLeadingZero(timezoneTime.minute);

  return `${hour}:${minutes}`;
}

export function getFormattedDate(isoDate, timezoneId) {
  const timezoneDate = applyUserTimezone(isoDate, timezoneId);
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  let monthIndex = timezoneDate.month;
  let monthName = months[monthIndex - 1];
  let dayIndex = timezoneDate.weekday;
  let dayName = days[dayIndex];

  return `${dayName}, ${monthName} ${timezoneDate.day}`;
}

export function getDateForChat(isoDate, timezoneId) {
  const timezoneDate = applyUserTimezone(isoDate, timezoneId);
  let monthIndex = timezoneDate.month;

  return `${addLeadingZero(timezoneDate.day)}/${addLeadingZero(
    monthIndex
  )}/${timezoneDate.year.toString().slice(-2)}`;
}

export function getFormattedDateForChat(isoDate, timezoneId) {
  const timezoneDate = applyUserTimezone(isoDate, timezoneId);
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let monthIndex = timezoneDate.month;
  let monthName = months[monthIndex - 1];
  let dayIndex = timezoneDate.weekday;
  let dayName = days[dayIndex];

  return `${dayName}, ${timezoneDate.day} ${monthName} ${timezoneDate.year}`;
}

export function getFormattedDateForPost(isoDate, timezoneId) {
  const timezoneDate = applyUserTimezone(isoDate, timezoneId);
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  let monthIndex = timezoneDate.month;
  let monthName = months[monthIndex - 1];

  return `${timezoneDate.day} ${monthName} ${timezoneDate.year}`;
}

export function getFormattedDateForUserAdmin(isoDate, timezoneId) {
  const timezoneDate = applyUserTimezone(isoDate, timezoneId);
  let monthIndex = timezoneDate.month;

  return `${addLeadingZero(timezoneDate.day)}-${addLeadingZero(monthIndex)}-${addLeadingZero(timezoneDate.year)}`;
}
