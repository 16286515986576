import { Role } from "@/components/security/Role";
import { RouteAccessRights } from "@/components/security/RouteAccess";

const routes = [
  {
    path: "/campus",
    component: () => import(/* webpackChunkName: "campus" */ "@/views/Campus"),
    children: [
      {
        path: "",
        name: "campus",
        redirect: { name: "campus.all" },
      },
      {
        path: "all",
        component: () =>
          import(
            /* webpackChunkName: "campus_all" */ "@/components/campus/CampusView"
          ),
        children: [
          {
            path: "",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            redirect: { name: "campus.all.main" },
            name: "campus.all",
          },
          {
            path: "main",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            name: "campus.all.main",
            component: () =>
              import(
                /* webpackChunkName: "campus_all_main" */ "@/components/campus/CampusAll"
              ),
          },
          {
            path: "your-choice",
            name: "campus.all.yourChoice",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "campus_your_choice" */ "@/components/campus/CampusYourChoice"
              ),
          },
          {
            path: "roundtableCategories",
            name: "campus.all.roundtableCategories",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "category_roundtable" */ "@/components/campus/roundtable/RoundtableCategories"
                ),
          },
          {
            path: "roundtableCategoriesAll/:categoryId",
            name: "campus.all.roundtableCategoriesAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "all_category_roundtables" */ "@/components/campus/roundtable/RoundtableListAll.vue"
                ),
          },
          {
            path: "workshopCategories",
            name: "campus.all.workshopCategories",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "category_workshops" */ "@/components/campus/workshop/WorkshopCategories"
                ),
          },
          {
            path: "workshopCategoriesAll/:categoryId",
            name: "campus.all.workshopCategoriesAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "all_category_workshops" */ "@/components/campus/workshop/WorkshopListAll.vue"
                ),
          },
          {
            path: "programCategories",
            name: "campus.all.programCategories",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "category_programs" */ "@/components/campus/program/ProgramCategories"
                ),
          },
          {
            path: "programCategoriesAll/:categoryId",
            name: "campus.all.programCategoriesAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "all_category_programs" */ "@/components/campus/program/ProgramListAll.vue"
                ),
          },
          {
            path: "meetup-all",
            name: "campus.all.meetUpAll",
            component: () =>
              import(
                /* webpackChunkName: "campus_meetups_all" */ "@/components/campus/meet-ups/AllMeetUps"
              ),
            meta: {
              authorize: [Role.NEURAL_JAMMER],
            },
            props: true,
          },
        ],
      },
      {
        path: "roundtable-overview/:id",
        name: "campus.roundtable.overview",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "campus_roundtable_overview" */ "@/components/campus/roundtable/RoundtableOverview"
          ),
        props: true,
      },
      {
        path: "/roundtable-meet/:id",
        name: "roundTableMeet",
        props: true,
        meta: {
          authorize: [Role.NEURAL_JAMMER],
        },
        component: () =>
          import(
            /* webpackChunkName: "roundtable-meet" */ "@/views/RoundTableMeet.vue"
          ),
      },
      {
        path: "workshop-overview/:id",
        name: "campus.workshop.overview",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "campus_workshop_overview" */ "@/components/campus/workshop/WorkshopOverview"
          ),
        props: true,
      },
      {
        path: "workshop-structure/:id",
        component: () =>
          import(
            /*webpackChunkName: "campus_workshop_structure" */ "@/components/campus/workshop/WorkshopStructure"
          ),
        props: true,
        children: [
          {
            path: "",
            name: "campus.workshop.structure",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.WORKSHOP_PARTICIPANT,
              ],
            },
            redirect: { name: "campus.workshop.structure.overview" },
            props: true,
          },
          {
            path: "overview",
            name: "campus.workshop.structure.overview",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.WORKSHOP_PARTICIPANT,
              ],
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "campus_workshop_structure_overview" */ "@/components/campus/workshop/WorkshopOverviewContent"
              ),
          },
          {
            path: "structure",
            name: "campus.workshop.structure.structure",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.WORKSHOP_PARTICIPANT,
              ],
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "campus_workshop_structure_structure" */ "@/components/campus/workshop/WorkshopStructureContainers"
              ),
          },
          {
            path: "live-sessions",
            name: "campus.workshop.structure.liveSessions",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.WORKSHOP_PARTICIPANT,
              ],
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "campus_workshop_structure_live_sessions" */ "@/components/campus/workshop/WorkshopLiveSessions"
              ),
          },
          {
            path: "resource",
            name: "campus.workshop.structure.resource",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.WORKSHOP_PARTICIPANT,
              ],
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "campus_workshop_structure_resource" */ "@/components/campus/workshop/WorkshopStructureResource"
              ),
          },
          {
            path: "participants",
            name: "campus.workshop.structure.participants",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.WORKSHOP_PARTICIPANT,
              ],
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "campus_workshop_structure_participants" */ "@/components/campus/workshop/WorkshopStructureParticipants"
              ),
          },
        ],
      },
      {
        path: "workshop/:id/live-session/:liveSessionId",
        name: "campus.workshop.liveSession",
        component: () =>
          import(
            /* webpackChunkName: "campus_workshop_live_session" */ "@/components/live-session/LiveSessionDetails"
          ),
        props: true,
        meta: {
          authorize: [
            Role.NEURAL_JAMMER,
            RouteAccessRights.WORKSHOP_PARTICIPANT,
          ],
        },
      },
      {
        path: "program-overview/:id",
        name: "campus.program.overview",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "campus_program_overview" */ "@/components/campus/program/ProgramOverview"
          ),
        props: true,
      },
      {
        path: "program-structure/:id",
        component: () =>
          import(
            /*webpackChunkName: "campus_program_structure" */ "@/components/campus/program/ProgramStructure"
          ),
        props: true,
        children: [
          {
            path: "",
            name: "campus.program.structure",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.PROGRAM_PARTICIPANT,
              ],
            },
            redirect: { name: "campus.program.structure.overview" },
            props: true,
          },
          {
            path: "overview",
            name: "campus.program.structure.overview",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.PROGRAM_PARTICIPANT,
              ],
            },
            component: () =>
              import(
                /* webpackChunkName: "campus_program_structure_overview" */ "@/components/campus/program/ProgramOverviewContent"
              ),
            props: true,
          },
          {
            path: "structure",
            name: "campus.program.structure.structure",
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.PROGRAM_PARTICIPANT,
              ],
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "campus_program_structure_structure" */ "@/components/campus/program/ProgramStructureContainers"
              ),
          },
          {
            path: "resource",
            name: "campus.program.structure.resource",
            props: true,
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.PROGRAM_PARTICIPANT,
              ],
            },
            component: () =>
              import(
                /* webpackChunkName: "campus_program_structure_resource" */ "@/components/campus/program/ProgramStructureResource"
              ),
          },
          {
            path: "participants",
            name: "campus.program.structure.participants",
            props: true,
            meta: {
              authorize: [
                Role.NEURAL_JAMMER,
                RouteAccessRights.PROGRAM_PARTICIPANT,
              ],
            },
            component: () =>
              import(
                /* webpackChunkName: "campus_program_structure_participants_chunk" */ "@/components/campus/program/ProgramStructureParticipants"
              ),
          },
          {
            path: "/live-session/:liveSessionId",
            name: "campus.program.structure.liveSession",
            component: () =>
              import(
                /* webpackChunkName: "campus_program_structure_live_session" */ "@/components/live-session/LiveSessionDetails"
              ),
            props: true,
          },
        ],
      },
      {
        path: "program/:id/live-session/:liveSessionId",
        name: "campus.program.liveSession",
        component: () =>
          import(
            /* webpackChunkName: "campus_program_live_session" */ "@/components/live-session/LiveSessionDetails"
          ),
        props: true,
        meta: {
          authorize: [
            Role.NEURAL_JAMMER,
            RouteAccessRights.PROGRAM_PARTICIPANT,
          ],
        },
      },
      {
        path: "/campus/meetup-details/:meetUpId",
        name: "meetup.details",
        component: () =>
          import(
            /* webpackChunkName: "campus_meetup_details" */ "@/components/live-session/LiveSessionDetails"
          ),
        meta: {
          authorize: [Role.NEURAL_JAMMER],
        },
        props: true,
      },
    ],
  },
  {
    path: "/campus/:campusItemId/readable/:id",
    name: "campus.program.structure.resource.readable",
    component: () =>
        import(
            /* webpackChunkName: "campus_readable_details" */ "@/components/library/readable/ReadableDetails"
            ),
    props: true,
    meta: {
      authorize: [
        Role.NEURAL_JAMMER,
        RouteAccessRights.PROGRAM_PARTICIPANT,
      ],
    },
  },
  {
    path: "/campus/:campusItemId/video/:id",
    name: "campus.program.structure.resource.video",
    component: () =>
        import(
            /* webpackChunkName: "campus_video_details" */ "@/components/library/video/VideoDetails"
            ),
    props: true,
    meta: {
      authorize: [
        Role.NEURAL_JAMMER,
        RouteAccessRights.PROGRAM_PARTICIPANT,
      ],
    },
  },
  {
    path: "/campus/:campusItemId/audio/:id",
    name: "campus.program.structure.resource.audio",
    component: () =>
        import(
            /* webpackChunkName: "campus_audio_details" */ "@/components/library/audio/AudioDetails"
            ),
    props: true,
    meta: {
      authorize: [
        Role.NEURAL_JAMMER,
        RouteAccessRights.PROGRAM_PARTICIPANT,
      ],
    },
  },
  {
    path: "/campus/:campusItemId/video-series/:id",
    name: "campus.program.structure.resource.videoSeries",
    component: () =>
        import(
            /* webpackChunkName: "campus_video_series_details" */ "@/components/library/video/series/VideoSeriesDetails"
            ),
    props: true,
    authorize: [
      Role.NEURAL_JAMMER,
      RouteAccessRights.PROGRAM_PARTICIPANT,
    ],
  },
  {
    path: "/campus/:campusItemId/audio-series/:id",
    name: "campus.program.structure.resource.audioSeries",
    component: () =>
        import(
            /* webpackChunkName: "campus_audio_series_details" */ "@/components/library/audio/series/AudioSeriesDetails"
            ),
    props: true,
    authorize: [
      Role.NEURAL_JAMMER,
      RouteAccessRights.PROGRAM_PARTICIPANT,
    ],
  },

  {
    path: "/campus/:campusItemId/readable/:id",
    name: "campus.workshop.structure.resource.readable",
    component: () =>
        import(
            /* webpackChunkName: "campus_workshop_readable_details" */ "@/components/library/readable/ReadableDetails"
            ),
    props: true,
    meta: {
      authorize: [
        Role.NEURAL_JAMMER,
        RouteAccessRights.WORKSHOP_PARTICIPANT,
      ],
    },
  },
  {
    path: "/campus/:campusItemId/video/:id",
    name: "campus.workshop.structure.resource.video",
    component: () =>
        import(
            /* webpackChunkName: "campus_workshop_video_details" */ "@/components/library/video/VideoDetails"
            ),
    props: true,
    meta: {
      authorize: [
        Role.NEURAL_JAMMER,
        RouteAccessRights.WORKSHOP_PARTICIPANT,
      ],
    },
  },
  {
    path: "/campus/:campusItemId/audio/:id",
    name: "campus.workshop.structure.resource.audio",
    component: () =>
        import(
            /* webpackChunkName: "campus_workshop_audio_details" */ "@/components/library/audio/AudioDetails"
            ),
    props: true,
    meta: {
      authorize: [
        Role.NEURAL_JAMMER,
        RouteAccessRights.WORKSHOP_PARTICIPANT,
      ],
    },
  },
  {
    path: "/campus/:campusItemId/video-series/:id",
    name: "campus.workshop.structure.resource.videoSeries",
    component: () =>
        import(
            /* webpackChunkName: "campus_workshop_video_series_details" */ "@/components/library/video/series/VideoSeriesDetails"
            ),
    props: true,
    authorize: [
      Role.NEURAL_JAMMER,
      RouteAccessRights.WORKSHOP_PARTICIPANT,
    ],
  },
  {
    path: "/campus/:campusItemId/audio-series/:id",
    name: "campus.workshop.structure.resource.audioSeries",
    component: () =>
        import(
            /* webpackChunkName: "campus_workshop_audio_series_details" */ "@/components/library/audio/series/AudioSeriesDetails"
            ),
    props: true,
    authorize: [
      Role.NEURAL_JAMMER,
      RouteAccessRights.WORKSHOP_PARTICIPANT,
    ],
  },
];

export default routes;
