<template>
  <h3 @click="signOut" class="body-1-book">
    {{ $t("sign_out.item_text") }}
  </h3>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import { mapGetters, mapMutations } from "vuex";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "CustomSignOut",
  data() {
    return {
      error: "",
      logger: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser", "getTenant"]),
  },
  methods: {
    ...mapMutations("common", ["returnProfileEditModal"]),
    signOut() {
      const tenant = this.getTenant;
      // just for marketing popup store setting
      this.$store.commit("common/setMarketedEventSignup", false);
      // just for take a tour tooltip store setting
      this.$store.commit("common/setTakeATourTooltip", false);
      //
      this.$Amplify.Auth.signOut()
        .then(() => {
          logTrackingData({
            activityType: "LOGOUT",
          });
          this.$store.commit("auth/signOut");
          this.$log.debug("sign out success");
          AmplifyEventBus.$emit("authState", "signedOut");
          this.returnProfileEditModal();
          this.$router.push(`/?theme=${tenant}`);
        })
        .catch((e) => this.setError(e));
    },
    setError: function (error) {
      this.error = this.$Amplify.I18n.get(error.message || error);
      errorHandler(error, null, this.error);
    },
  },
};
</script>
