var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notification)?_c('div',{class:[
    'notification-connect',
    'notification',
    { 'notification--full': _vm.fullView },
  ]},[_c('div',{staticClass:"notification__avatar",on:{"click":function($event){return _vm.$store.commit('common/setProfileModalUserId', _vm.notification.issuer.id)}}},[_c('UserAvatar',{attrs:{"size":40,"user":_vm.notification.issuer}})],1),_c('div',{staticClass:"notification__text"},[_c('p',[_c('b',[_vm._v(" "+_vm._s(_vm.notification.issuer.firstName)+" "+_vm._s(_vm.notification.issuer.lastName)+" ")]),_vm._v(" "+_vm._s(_vm.$t("notifications.connect_request"))+" ")]),_c('div',{staticClass:"notification__time",class:{
        'notification__time--show': !_vm.showShortActions && !_vm.showFullActions,
      }},[_vm._v(" "+_vm._s(_vm.durationInDays())+" ")])]),(_vm.showFullActions)?_c('div',{staticClass:"notification__action",on:{"click":function($event){$event.stopPropagation();}}},[_c('BaseButton',{attrs:{"variant":"secondary"},on:{"click":_vm.declineRequest}},[_vm._v(" "+_vm._s(_vm.$t("community.groups.status_buttons.decline"))+" ")]),_c('BaseButton',{on:{"click":_vm.acceptRequest}},[_vm._v(_vm._s(_vm.$t("community.groups.status_buttons.accept"))+" ")])],1):_vm._e(),(_vm.showShortActions)?_c('div',{staticClass:"notification__action notification__action--short"},[_c('button',{staticClass:"notification__action-btn",on:{"click":function($event){$event.stopPropagation();return _vm.declineRequest.apply(null, arguments)}}},[_c('DeclineIcon')],1),_c('button',{staticClass:"notification__action-btn",on:{"click":function($event){$event.stopPropagation();return _vm.acceptRequest.apply(null, arguments)}}},[_c('AcceptIcon')],1)]):_vm._e(),(_vm.loading)?_c('LoadingOverlay',[_vm._v(_vm._s(_vm.$t("default.loading")))]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }