<template>
  <svg
    @click="$emit('click')"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.84781 5.44922H2.15212C1.54495 5.44922 1.05273 5.94143 1.05273 6.5486V10.3965C1.05273 11.0036 1.54495 11.4958 2.15212 11.4958H9.84781C10.455 11.4958 10.9472 11.0036 10.9472 10.3965V6.5486C10.9472 5.94143 10.455 5.44922 9.84781 5.44922Z"
      stroke="white"
      stroke-width="0.93"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.25195 5.45V3.25123C3.25127 2.56964 3.50387 1.91211 3.96071 1.40628C4.41756 0.900453 5.04606 0.58242 5.7242 0.513921C6.40234 0.445422 7.08174 0.631343 7.63052 1.03559C8.17929 1.43984 8.55828 2.03358 8.69391 2.70154"
      stroke="white"
      stroke-width="0.93"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UnlockIcon",
};
</script>
