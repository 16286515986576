var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"language-switcher"},[_c('button',{ref:"toggleOptionsButton",staticClass:"language-switcher__button",on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[_c('span',{staticClass:"language-switcher__flag"},[_c('img',{attrs:{"src":`/language-flags/${_vm.selectedLanguage.country.toLowerCase()}.png`,"alt":`${_vm.selectedLanguage.country.toLowerCase()} flag`}})]),_c('span',{staticClass:"language-switcher__button-text"},[_vm._v(" "+_vm._s(_vm.selectedLanguage.title)+" ("+_vm._s(_vm.selectedLanguage.country)+") ")])]),(_vm.showMenu)?_c('ul',{directives:[{name:"closable",rawName:"v-closable",value:({
      exclude: ['toggleOptionsButton'],
      handler: 'closeMenuByClickOutside',
    }),expression:"{\n      exclude: ['toggleOptionsButton'],\n      handler: 'closeMenuByClickOutside',\n    }"}],staticClass:"language-switcher__menu"},_vm._l((_vm.langs),function(lang){return _c('li',{key:`Lang${lang.code}`,staticClass:"language-switcher__menu-item",class:{
        'language-switcher__menu-item--selected':
          lang.code === _vm.selectedLanguage.code,
      },attrs:{"value":lang.code},on:{"click":function($event){return _vm.selectLanguage(lang)}}},[_c('div',{staticClass:"language-switcher__flag"},[_c('img',{attrs:{"src":`/language-flags/${lang.country.toLowerCase()}.png`,"alt":`${lang.country.toLowerCase()} flag`}})]),_c('div',{staticClass:"language-switcher__menu-text"},[_vm._v(" "+_vm._s(lang.title)+" ("+_vm._s(lang.country)+") ")])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }