/**
 * Creates the URL to fetch an image from the backend via REST. The file is supposed to be stored in the thumbnail
 * bucket. This is not a presigned URL to download directly from S3. Instead, the URL is pointing to our very own
 * backend, which then downloads the image from S3 and pass the byte stream to the frontend.
 *
 * @param imageId the id of the thumbnail image
 * @returns {string}
 */
function createPreviewThumbnailUrl(imageId) {
  if (!imageId) {
    return "";
  }

  const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;

  // imageId is supposed to have the format UUID/image.png or UUID/width/height/name.png
  let tokens = imageId.split("/");
  if (tokens.length <= 1) {
    return "";
  }

  const imageURI = tokens.map((t) => encodeURIComponent(t)).join("/");
  return `${baseUrl}/image/download/preview/${imageURI}`;
}

export default createPreviewThumbnailUrl;
