<template>
  <div class="daily-feed-criteria">
    <div class="daily-feed-criteria__requirements">
      <span class="daily-feed-criteria__requirements-item">
        <span class="daily-feed-criteria__requirements-title">
          {{ $t("daily_feeds.requirements.criteria_p1_header") }}
        </span>
        <span class="daily-feed-criteria__requirements-text">
          {{ $t("daily_feeds.requirements.criteria_p1_body") }}
        </span>
      </span>
      <span class="daily-feed-criteria__requirements-item">
        <span class="daily-feed-criteria__requirements-title">
          {{ $t("daily_feeds.requirements.criteria_p2_header") }}
        </span>
        <span class="daily-feed-criteria__requirements-text">
          {{ $t("daily_feeds.requirements.criteria_p2_body") }}
          <span class="daily-feed-criteria__requirements-subtitle">
            {{ $t("daily_feeds.requirements.criteria_p2_sub1_header") }}
          </span>
          <span>{{
            $t("daily_feeds.requirements.criteria_p2_sub1_body")
          }}</span>
          <span class="daily-feed-criteria__requirements-subtitle">
            {{ $t("daily_feeds.requirements.criteria_p2_sub2_header") }}
          </span>
          <span>
            {{ $t("daily_feeds.requirements.criteria_p2_sub2_body") }}
          </span>
          <span class="daily-feed-criteria__requirements-subtitle">
            {{ $t("daily_feeds.requirements.criteria_p2_sub3_header") }}
          </span>
          <span>
            {{ $t("daily_feeds.requirements.criteria_p2_sub3_body") }}
          </span>
        </span>
      </span>
      <span class="daily-feed-criteria__requirements-item">
        <span class="daily-feed-criteria__requirements-title">
          {{ $t("daily_feeds.requirements.criteria_p3_header") }}
        </span>
        <span class="daily-feed-criteria__requirements-text">
          {{ $t("daily_feeds.requirements.criteria_p3_body") }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DailyFeedCriteria",
};
</script>

<style scoped lang="scss">
.daily-feed-criteria {
  padding: 24px;
  min-width: 100%;
  overflow: auto;

  &__requirements {
    padding: 5px 0 5px;

    &-item {
      margin-bottom: 40px;
    }

    &-title {
      font-weight: var(--font-weight-medium);
      margin-bottom: 3px;
    }

    &-subtitle {
      font-weight: var(--font-weight-medium);
      margin-bottom: 3px;
      margin-top: 15px;
    }

    &-text {
      margin-left: 15px;
    }

    span {
      display: block;
    }
  }
}
</style>
