<template>
  <svg
    width="33"
    height="17"
    viewBox="0 0 33 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0457 0.222168H28.3424L18.7174 9.84717L18.5307 10.1038L15.7773 14.9105L17.4224 16.5555L22.229 13.8022L22.4857 13.6155L32.1107 3.99048V2.28719L30.0457 0.222168ZM17.4224 14.9105L19.1841 11.4105L20.8757 13.1022L17.4224 14.9105ZM21.8907 12.5072L19.8256 10.4422L29.159 1.10885L31.224 3.17387L21.8907 12.5072Z"
      fill="white"
    />
    <path d="M0.777344 1H19.444" stroke="white" stroke-width="2" />
    <path d="M0.777344 5.6665H19.444" stroke="white" stroke-width="2" />
    <path d="M0.777344 10.3335H15.5551" stroke="white" stroke-width="2" />
    <path d="M0.777344 15H13.9996" stroke="white" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: "NotebooksIcon-2",
};
</script>

<style scoped></style>
