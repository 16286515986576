<template>
  <svg
    @click="$emit('click')"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.375 13.875H1.125V9.625"
      stroke="#F8F8F8"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.125 13.8748L6.08333 8.9165"
      stroke="#F8F8F8"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.625 1.125H13.875V5.375"
      stroke="#F8F8F8"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8753 1.125L8.91699 6.08333"
      stroke="#F8F8F8"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "MaximizeIcon",
};
</script>
