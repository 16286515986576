<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
    class="arrow-circle"
    :class="`arrow-circle--${direction}`"
  >
    <g clip-path="url(#clip0)">
      <circle
        cx="12"
        cy="12"
        r="11.5"
        transform="rotate(-90 12 12)"
        :stroke="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 10.8333L12.3963 5L11.6038 5L6 10.8333L6.79249 11.6583L11.4396 6.82077L11.4396 19H12.5604L12.5604 6.82077L17.2075 11.6583L18 10.8333Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 24) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ArrowCircleIcon",
  props: {
    color: {
      type: String,
      default: "#4F4F4F",
    },
    direction: {
      type: String,
      default: "up",
    },
  },
};
</script>

<style scoped lang="scss">
.arrow-circle {
  &--down {
    transform: rotate(180deg);
  }
  &--right {
    transform: rotate(90deg);
  }
}
</style>
