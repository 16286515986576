<template>
  <div class="notebook-widget">
    <button
      id="notebook-widget-button"
      class="notebook-widget__button"
      @click="handleClickOnWidget"
      v-show="!isOpen"
    >
      <NotebooksIcon2 />
    </button>

    <div
      v-if="isOpen"
      ref="editor"
      class="notebook-widget__editor"
      :class="{
        'notebook-widget__editor--collapsed': collapsed && !mobile,
        'notebook-widget__editor--collapsed--mobile': collapsed && mobile,
        'notebook-widget__editor--open': !collapsed && !mobile,
        'notebook-widget__editor--open--mobile': !collapsed && mobile,
        'notebook-widget__editor--fullscreen': fullscreen,
      }"
    >
      <div
        ref="editorHeader"
        class="notebook-widget__editor-header"
        :class="{ 'notebook-widget__editor-header--mobile': mobile }"
      >
        <div>
          <CloseDialogButton color="white" :size="15" @click="closeNote" />
          <MaximizeIcon
            v-if="!mobile && !fullscreen && !collapsed"
            class="notebook-widget__editor-header-icon"
            @click="$store.commit('notebook/setFullscreen', true)"
          />
          <MinimizeIcon
            v-if="!mobile && fullscreen"
            class="notebook-widget__editor-header-icon"
            @click="$store.commit('notebook/setFullscreen', false)"
          />
        </div>
        <h3 class="notebook-widget__editor-header-title" v-if="savedNote">
          {{ notebookName }}
        </h3>
        <CollapseDown
          v-if="!collapsed"
          class="notebook-widget__editor-header-icon"
          @click="$store.commit('notebook/setCollapsed', true)"
        />
        <CollapseUp
          ref="unCollapse"
          v-if="collapsed"
          class="notebook-widget__editor-header-icon"
          @click="$store.commit('notebook/setCollapsed', false)"
        />
      </div>

      <NoteEditor
        v-model="noteText"
        ref="noteEditor"
        @fetchTags="fetchTags"
        @saveNewNote="saveNewNote"
        @saveExistingNote="saveExistingNote"
        @setIsEmpty="setIsEmpty"
        :style="collapsed ? 'display: none' : 'display: block'"
      />

      <div
        class="notebook-widget__editor-footer"
        :style="collapsed ? 'display: none' : 'display: flex'"
      >
        <BaseButton
          v-if="!savedNote"
          variant="underline"
          class="notebook-widget__save"
          @click="$refs.noteEditor.fireSaveNewNote()"
          :disabled="isEmpty"
        >
          {{ $t("default.save") }}
        </BaseButton>
        <h3 v-else class="body-2-book">Auto-saved</h3>

        <div class="notebook-widget__tagging" v-if="!tagsDisabled">
          <h3
            :style="
              note.tags && note.tags.length ? 'color: black' : 'color: #BDBDBD'
            "
          >
            Tagging
          </h3>
          <span v-if="note.tags && note.tags.length">:</span>
          <h4
            class="notebook-widget__tagging-tag"
            v-for="(tag, index) in primaryTags"
            :key="index"
          >
            {{ tag.tag }}
          </h4>
          <Chevron
            :color="note.tags && note.tags.length ? 'black' : '#BDBDBD'"
            v-if="!tagsMenu"
            direction="up"
            @click="note.tags && note.tags.length ? (tagsMenu = true) : null"
          />
          <Chevron v-if="tagsMenu" direction="down" @click="tagsMenu = false" />
        </div>

        <TagsPopup v-if="tagsMenu" @sendRawTagsData="sendUserNoteRawData" />
      </div>
    </div>

    <SaveNotePopup ref="saveNotePopup" @deleteNote="confirmDeleteNote" />
    <Confirm
      ref="confirmDelete"
      variant="primary"
      :accept-button-text="$t('default.delete')"
    />
    <SuccessPopup ref="successPopup" />
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import NoteEditor from "@/components/notebooks/NoteEditor";
import NotebooksIcon2 from "@/components/reusables/Icons/NotebooksIcon-2";
import CollapseDown from "@/components/reusables/Icons/CollapseDown";
import CollapseUp from "@/components/reusables/Icons/CollapseUp";
import MaximizeIcon from "@/components/reusables/Icons/MaximizeIcon";
import DragAndResizeMixin from "@/components/mixins/DragAndResizeMixin";
import Confirm from "@/components/reusables/Confirm";
import SaveNotePopup from "@/components/notebooks/SaveNotePopup";
import SuccessPopup from "@/components/reusables/SuccessPopup";
import { mapGetters, mapState } from "vuex";
import SAVE_EXISTING_NOTE from "@/graphql/notebooks/mutations/UpdateNote.graphql";
import Chevron from "@/components/reusables/Icons/Chevron";
import MinimizeIcon from "@/components/reusables/Icons/MinimizeIcon";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";
import TagsMixin from "@/components/notebooks/TagsMixin";
import TagsPopup from "@/components/notebooks/TagsPopup";

const https = require("https");
export default {
  name: "NotebookWidget",
  mixins: [DragAndResizeMixin, TagsMixin],
  components: {
    TagsPopup,
    MinimizeIcon,
    Chevron,
    SuccessPopup,
    SaveNotePopup,
    Confirm,
    MaximizeIcon,
    CollapseUp,
    CollapseDown,
    NotebooksIcon2,
    NoteEditor,
    CloseDialogButton,
  },
  data() {
    return {
      mobile: false,
      isEmpty: true,
      tagsMenu: false,
      tagsDisabled: false,
      buttonMoved: false,
    };
  },
  computed: {
    primaryTags() {
      return this.tags.filter((tag) => tag.tagState === "PRIMARY");
    },
    ...mapGetters("auth", ["getNJUser"]),
    ...mapState({
      isOpen: (state) => state.notebook.isOpen,
      noteText: (state) => state.notebook.note.noteText,
      collapsed: (state) => state.notebook.collapsed,
      savedNote: (state) => state.notebook.savedNote,
      notebookName: (state) => state.notebook.note.notebookName,
      notebookId: (state) => state.notebook.note.notebookId,
      fullscreen: (state) => state.notebook.fullscreen,
      note: (state) => state.notebook.note,
    }),
    tags: {
      get() {
        return this.$store.state.notebook.note.tags;
      },
      set(value) {
        this.$store.commit("notebook/updateTags", value);
      },
    },
    noteText: {
      get() {
        return this.$store.state.notebook.note.noteText;
      },
      set(val) {
        this.$store.commit("notebook/setNoteText", val);
      },
    },
  },
  watch: {
    mobile() {
      if (this.mobile) {
        this.addWidgetDragEventlistener();
      } else {
        const button = document.getElementById("notebook-widget-button");
        this.addWidgetDragEventlistenerDesktop(
          button,
          this.addMouseMoveListener
        );
      }
    },
    note() {
      if (this.isOpen) {
        this.$nextTick(() => {
          this.$refs.noteEditor.fireGetTags();
          this.$refs.noteEditor.setFlatTextToStore();
        });
      }
    },
    isOpen() {
      if (this.isOpen) {
        this.$nextTick(() => {
          this.initDragAndResize(this.$refs.editor, this.$refs.editorHeader);
          this.$refs.noteEditor.setFocus();
        });
      }
      if (!this.isOpen) {
        this.$nextTick(() => {
          this.addWidgetDragEventlistener();
        });
      }
    },
    collapsed() {
      const resizerRight = document.querySelector(".resizer-right");
      if (this.collapsed) {
        this.$store.commit("notebook/setFullscreen", false);
        resizerRight.style.pointerEvents = "none";
        this.dragger.style.pointerEvents = "none";
        setTimeout(() => {
          this.$refs.unCollapse.$el.style.pointerEvents = "auto";
          // this.$refs.unCollapse.$el.draggable = false;
        }, 500);
      } else {
        resizerRight.style.removeProperty("pointer-events");
        this.dragger.style.removeProperty("pointer-events");
      }
    },
  },
  methods: {
    trackOpenNote() {
      logTrackingData({
        activityType: "NOTE_OPENED",
      });
    },
    trackCloseNote() {
      logTrackingData({
        activityType: "NOTE_CLOSED",
      });
    },
    handleClickOnWidget() {
      this.trackOpenNote();

      if (this.mobile) {
        this.$store.commit("notebook/setIsOpen", true);
      }
    },
    addMouseMoveListener(event) {
      const button = document.getElementById("notebook-widget-button");
      let { clientX, clientY } = event;

      button.style.inset = `${clientY - 28}px auto auto ${clientX - 28}px`;
      this.buttonMoved = true;
    },
    stopWidgetDrag() {
      const button = document.getElementById("notebook-widget-button");

      if (parseInt(button.style.left) < window.innerWidth / 2) {
        button.style.inset = "auto auto 32px 32px";
      } else {
        if (parseInt(button.style.top) < window.innerHeight / 2) {
          button.style.inset = "32px 32px auto auto";
        } else {
          button.style.inset = "auto 32px 32px auto";
        }
      }

      this.buttonMoved = false;
    },
    addWidgetDragEventlistenerDesktop(target, whileMove) {
      const endMove = () => {
        if (!this.buttonMoved) {
          this.$store.commit("notebook/setIsOpen", true);
        }

        this.stopWidgetDrag();

        window.removeEventListener("mousemove", whileMove);
        window.removeEventListener("mouseup", endMove);
      };

      target.addEventListener("mousedown", () => {
        window.addEventListener("mousemove", whileMove);
        window.addEventListener("mouseup", endMove);
      });
    },
    addWidgetDragEventlistener() {
      const button = document.getElementById("notebook-widget-button");

      button.addEventListener("touchmove", function (e) {
        e.preventDefault();
        let touchLocation = e.targetTouches[0];
        button.style.inset = `${touchLocation.clientY - 28}px auto auto ${
          touchLocation.clientX - 28
        }px`;
      });
      button.addEventListener("touchend", function () {
        if (parseInt(button.style.left) < window.innerWidth / 2) {
          if (parseInt(button.style.top) < window.innerHeight / 2) {
            button.style.inset = "32px auto auto 32px";
          } else {
            button.style.inset = "auto auto 32px 32px";
          }
        } else {
          if (parseInt(button.style.top) < window.innerHeight / 2) {
            button.style.inset = "32px 32px auto auto";
          } else {
            button.style.inset = "auto 32px 32px auto";
          }
        }
      });
    },
    setIsEmpty(val) {
      this.isEmpty = val;
    },
    saveNewNote(noteJson) {
      this.tagsMenu = false;
      this.$refs.saveNotePopup.open(noteJson);
    },
    deleteAndCloseNote() {
      this.$store.commit("notebook/closeAndReset");
      this.$refs.successPopup.open(
        "Note deleted",
        "Your note has been permanently deleted."
      );
    },
    async confirmDeleteNote() {
      if (
        !(await this.$refs.confirmDelete.open(
          this.$t("notebooks.note.confirm_delete.title"),
          this.$t("notebooks.note.confirm_delete.message")
        ))
      ) {
        return;
      }
      this.deleteAndCloseNote();
    },
    async closeNote() {
      this.trackCloseNote();
      this.tagsMenu = false;

      if (this.isEmpty) {
        if (this.savedNote) {
          this.saveExistingNote();
        }
        this.$store.commit("notebook/closeAndReset");
      } else {
        if (!this.savedNote) {
          this.$refs.saveNotePopup.open();
          return;
        } else {
          this.saveExistingNote();
          setTimeout(() => {
            this.$store.commit("notebook/closeAndReset");
          }, 500);
        }
      }
    },
    saveExistingNote() {
      let variables = {
        noteId: this.$store.state.notebook.note.noteId,
        noteText: JSON.stringify(this.$store.state.notebook.note.noteText),
        previewText:
          this.$store.state.notebook.note.flatText.length > 370
            ? this.$store.state.notebook.note.flatText.slice(0, 370)
            : this.$store.state.notebook.note.flatText,
        tags: this.tags.filter(
          (tag) => tag.locked === true || tag.tagState === "PRIMARY"
        ),
      };

      // console.log("save existing vars: ", variables);
      this.$apollo
        .mutate({
          mutation: SAVE_EXISTING_NOTE,
          variables: variables,
        })
        .then((data) => {
          // console.log("save existing note data: ", data);
          if (this.isOpen) {
            this.$store.commit(
              "notebook/setLastUpdated",
              data.data.updateUserNote.updatedAt
            );
          }
        })
        .catch((err) => {
          errorHandler(
            err,
            "ERROR_UPDATE_NOTE",
            this.$t("notebooks.errors.error_update_note")
          );
        });
    },
    setView() {
      window.innerWidth >= 600 ? (this.mobile = false) : (this.mobile = true);
    },
  },
  mounted() {
    this.setView();
    window.addEventListener("resize", this.setView);

    if (this.mobile) {
      this.addWidgetDragEventlistener();
    } else {
      const button = document.getElementById("notebook-widget-button");
      this.addWidgetDragEventlistenerDesktop(button, this.addMouseMoveListener);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setView);
  },
};
</script>

<style scoped lang="scss">
.notebook-widget {
  z-index: 4;

  &__drop-zone {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    //pointer-events: none;
  }

  &__editor {
    z-index: 5;
    width: 510px;
    display: flex;
    flex-direction: column;
    background-color: var(--nj-white);
    position: fixed;
    bottom: 0;
    right: 32px;
    border-radius: 16px;
    box-shadow: var(--nj-box-shadow);

    &--open {
      height: 631px;

      &--mobile {
        border-radius: 0;
        inset: 0 auto auto 0 !important;
        width: 100vw;
        min-height: -webkit-fill-available;
      }
    }

    &--fullscreen {
      height: 100vh !important;
      width: 100vw !important;
      border-radius: 0;
      inset: 0 auto auto 0 !important;

      & .notebook-widget__editor-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &--collapsed {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: 40px !important;
      width: 510px;
      inset: auto 32px 0 auto !important;

      &--mobile {
        border-radius: 0;
        width: 100vw;
        inset: auto auto 0 0 !important;
      }
    }

    &-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: 40px;
      min-height: 40px;
      background-color: var(--nj-blue);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px;

      &--mobile {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &-icon {
        margin-left: 12px;
        cursor: pointer;
      }

      &-title {
        color: white;
        font-size: 14px;
      }
    }

    &-footer {
      border-top: var(--nj-gray-3) solid 1px;
      justify-content: space-between;
      height: 51px;
      align-items: center;
      padding: 0 16px;
      position: absolute;
      bottom: 0;
      width: 100%;

      h5 {
        font-size: 10px;
      }
    }

    * {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: auto; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently supported by Chrome and Opera */
    }
  }

  &__button {
    background-color: var(--nj-blue);
    height: 56px;
    width: 56px;
    display: flex;
    border-radius: 50%;
    position: fixed;
    inset: auto 32px 32px auto;
    cursor: pointer;
    z-index: 5;

    svg {
      margin: auto;
    }
  }

  &__save {
    font-size: 14px !important;
  }

  &__tagging {
    display: flex;
    position: relative;
    padding-right: 5px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    h3 {
      font-size: 14px;
    }

    .chevron {
      cursor: pointer;
      margin-left: 10px;
    }

    &-tag {
      background-color: var(--nj-gray-5);
      border-radius: 16px;
      margin-left: 4px;
      padding: 1px 8px;
      white-space: nowrap;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }
  }
}
</style>
