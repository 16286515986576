var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notifications-dropdown"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasUnseenMessages),expression:"hasUnseenMessages"}],ref:"toggleOptionsButtonUnseen",staticClass:"notifications-dropdown__bell",on:{"click":function($event){_vm.showMenu ? _vm.closeMenu() : _vm.openMenu()}}},[_c('BellWithDotIcon')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasUnseenMessages),expression:"!hasUnseenMessages"}],ref:"toggleOptionsButtonSeen",staticClass:"notifications-dropdown__bell",on:{"click":function($event){_vm.showMenu ? _vm.closeMenu() : _vm.openMenu()}}},[_c('BellWithoutDotIcon')],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu),expression:"showMenu"},{name:"closable",rawName:"v-closable",value:({
      exclude: ['toggleOptionsButtonUnseen', 'toggleOptionsButtonSeen'],
      handler: 'closeMenuByClickOutside',
    }),expression:"{\n      exclude: ['toggleOptionsButtonUnseen', 'toggleOptionsButtonSeen'],\n      handler: 'closeMenuByClickOutside',\n    }"}],class:[
      'notifications-dropdown__list',
      { 'notifications-dropdown__list--with-items': _vm.notifications.length },
    ]},[_vm._l((_vm.notifications.slice(0, 5)),function(notification){return _c('li',{key:notification.id,class:[
        'notifications-dropdown__list-item',
        {
          'notifications-dropdown__list-item--selected':
            notification.viewedAt === null,
        },
      ]},[_c(_vm.getNotificationComponent(notification),{tag:"component",attrs:{"notification":notification},on:{"closeNotifications":_vm.closeMenu,"deleteNotification":function($event){return _vm.deleteNotification($event, notification.id)}}})],1)}),(!_vm.notifications.length)?_c('li',{staticClass:"notifications-dropdown__list-item notifications-dropdown__list-item--center"},[_c('p',[_vm._v(_vm._s(_vm.$t("notifications.empty_message")))])]):_vm._e(),(_vm.notifications.length)?_c('li',{staticClass:"notifications-dropdown__list-item notifications-dropdown__list-item--btn"},[_c('AllNotificationsPopup',{ref:"allNotificationsPopup",on:{"closeDropdown":_vm.closeMenu,"refetchNotifications":function($event){return _vm.$apollo.queries.notifications.refetch()}}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }