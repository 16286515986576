import { MessageRepository } from "@amityco/js-sdk";

const state = {
  connectedToChat: false,
  newChatMessages: [],
  showFilesBrowser: false,
  showDeleteMessageMode: false,
  showEditMessageMode: false,
  showReplyMessageMode: false,
  parentReplyMessage: null,
  showArchivedChannelsMode: false,
  messagesToDelete: [],
  messageToEdit: null,
};

const actions = {
  async updateNewMessagesState({ commit, rootState }, newChatMessages) {
    for (let i = 0; i < newChatMessages.length; i++) {
      const oldChannel = state.newChatMessages.find(
        (oldChannel) => oldChannel.channelId === newChatMessages[i].channelId
      );

      if (!oldChannel && document.hidden) {
        const messagesCollection = await MessageRepository.queryMessages({
          channelId: newChatMessages[i].channelId,
        });
        if (messagesCollection.models?.length) {
          const lastMessage =
            messagesCollection.models[messagesCollection.models.length - 1];

          if (
            Notification.permission === "granted" &&
            lastMessage?.user?.userId !== rootState.auth.njuser.id
          ) {
            new Notification("Neural Jam", {
              body: "You have a new chat message",
              icon: "/neuraljam_logo.png",
              renotify: true,
              tag: lastMessage.messageId,
            });
          }
        }
      } else if (
        oldChannel &&
        newChatMessages[i].unreadCount > 0 &&
        oldChannel.unreadCount !== newChatMessages[i].unreadCount &&
        document.hidden
      ) {
        const messagesCollection = await MessageRepository.queryMessages({
          channelId: newChatMessages[i].channelId,
        });
        if (messagesCollection.models?.length) {
          const lastMessage =
            messagesCollection.models[messagesCollection.models.length - 1];
          if (
            Notification.permission === "granted" &&
            lastMessage?.user?.userId !== rootState.auth.njuser.id
          ) {
            new Notification("Neural Jam", {
              body: "You have a new chat message",
              icon: "/neuraljam_logo.png",
              renotify: true,
              tag: lastMessage.messageId,
            });
          }
        }
      }
    }
    commit("updateNewMessagesState", newChatMessages);
  },
};

const mutations = {
  connectToChat(state) {
    state.connectedToChat = true;
  },
  disconnectFromChat(state) {
    state.connectedToChat = false;
  },
  updateNewMessagesState(state, newChatMessages) {
    state.newChatMessages = newChatMessages;
  },
  updateMessagesToDelete(state, messagesToDelete) {
    state.messagesToDelete = messagesToDelete;
  },
  updateShowDeleteMessageMode(state, showDeleteMessageMode) {
    state.showDeleteMessageMode = showDeleteMessageMode;
  },
  updateMessageToEdit(state, messageToEdit) {
    state.messageToEdit = messageToEdit;
  },
  updateParentReplyMessage(state, parentReplyMessage) {
    state.parentReplyMessage = parentReplyMessage;
  },
  updateShowEditMessageMode(state, showEditMessageMode) {
    state.showEditMessageMode = showEditMessageMode;
  },
  updateShowReplyMessageMode(state, showReplyMessageMode) {
    state.showReplyMessageMode = showReplyMessageMode;
  },
  updateShowArchivedChannelsMode(state, showArchivedChannelsMode) {
    state.showArchivedChannelsMode = showArchivedChannelsMode;
  },
  updateShowFilesBrowser(state, showFilesBrowser) {
    state.showFilesBrowser = showFilesBrowser;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
