<template>
  <div
    tabindex="0"
    ref="uploadProvocationPopup"
    class="upload-provocation-popup base-modal"
    @keydown.esc="closePopup"
  >
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="closePopup"
    >
      <div
        class="upload-provocation-popup__inner base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="base-modal__head">
          <CloseDialogButton @click="closePopup" class="base-modal__close" />
          <h1 class="base-modal__head-title">
            {{ $t("new_provocation.upload_title") }}
          </h1>
        </div>
        <div class="base-modal__body">
          <form
            class="upload-provocation-popup__form base-modal__form base-form"
          >
            <div class="upload-provocation-popup__form-inner">
              <div class="upload-provocation-popup__description">
                <p class="upload-provocation-popup__label">
                  {{ $t("new_provocation.description") }}
                </p>
                <FormTextarea
                  v-model="$v.form.title.$model"
                  :counter="150"
                  :hasErrors="titleIsEmpty || titleIsTooLong"
                >
                  <template v-slot:errors>
                    <p class="base-form__errors-item" v-if="titleIsEmpty">
                      {{ $t("new_provocation.error_title_required") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="titleIsTooLong"
                    >
                      {{ $t("new_provocation.error_title_length") }}
                    </p>
                  </template>
                </FormTextarea>
              </div>
              <p class="upload-provocation-popup__tip">
                {{ $t("new_provocation.tip") }}
              </p>
              <div class="upload-provocation-popup__upload">
                <p class="upload-provocation-popup__label">
                  {{ $t("new_provocation.file_label") }}
                </p>
                <BaseFileInput
                  class="upload-provocation-popup__upload-input"
                  v-model="$v.form.currentFile.$model"
                  :hasErrors="
                    fileIsEmpty ||
                    fileNotSupported ||
                    fileDurationIsToLong ||
                    fileSizeIsTooBig
                  "
                >
                  <template v-slot:errors>
                    <p class="base-form__errors-item" v-if="fileIsEmpty">
                      {{ $t("daily_feeds.user_add.error_file_required") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="fileNotSupported"
                    >
                      {{ $t("daily_feeds.user_add.error_file_not_supported") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="fileDurationIsToLong"
                    >
                      {{ $t("daily_feeds.user_add.error_file_duration") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="fileSizeIsTooBig"
                    >
                      {{ $t("daily_feeds.user_add.error_file_size") }}
                    </p>
                  </template>
                </BaseFileInput>
                <p class="upload-provocation-popup__text">
                  {{ $t("new_provocation.file_type_requirement") }}
                </p>
                <p class="upload-provocation-popup__text">
                  {{ $t("new_provocation.file_size_requirement") }}
                </p>
              </div>
            </div>

            <div class="upload-provocation-popup__actions base-modal__actions">
              <div
                class="
                  base-modal__actions-item
                  upload-provocation-popup__upload
                "
              >
                <BaseButton variant="primary" @click="uploadFile">
                  {{ $t("new_provocation.confirm_button") }}
                </BaseButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Confirm
      variant="primary"
      ref="confirmDiscard"
      :accept-button-text="$t('confirm_close.stay_on_page')"
      :decline-button-text="$t('default.discard')"
    />
    <UploadFile ref="uploadFile" />
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import FormTextarea from "@/components/forms/FormTextarea";
import BaseFileInput from "@/components/forms/BaseFileInput";
import GetThumbnailsMixin from "@/components/mixins/GetThumbnailsMixin";
import { validationMixin } from "vuelidate/src";
import { maxLength, required } from "vuelidate/lib/validators";
import { hasVideoFormat } from "@/service/validationRules";
import Confirm from "@/components/reusables/Confirm";
import UploadFile from "@/components/reusables/UploadFile";

export default {
  name: "UploadProvocationPopup",
  components: {
    UploadFile,
    Confirm,
    FormTextarea,
    BaseFileInput,
    CloseDialogButton,
  },
  mixins: [validationMixin, GetThumbnailsMixin],
  watch: {
    "form.currentFile"(file) {
      if (file) {
        this.getDuration("video", file);
      }
    },
    dialog() {
      if (this.dialog) {
        this.$refs.uploadProvocationPopup.focus();
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  data() {
    return {
      form: {
        title: "",
        currentFile: null,
        mediaDuration: 0,
      },
      dialog: false,
    };
  },
  computed: {
    titleIsEmpty() {
      return this.$v.form.title.$dirty && !this.$v.form.title.required;
    },
    titleIsTooLong() {
      return this.$v.form.title.$dirty && !this.$v.form.title.maxLength;
    },
    fileIsEmpty() {
      return (
        this.$v.form.currentFile.$dirty && !this.$v.form.currentFile.required
      );
    },
    fileNotSupported() {
      return (
        this.$v.form.currentFile.$dirty &&
        !this.$v.form.currentFile.hasVideoFormat
      );
    },
    fileDurationIsToLong() {
      return (
        this.$v.form.currentFile.$dirty && !this.$v.form.currentFile.maxDuration
      );
    },
    fileSizeIsTooBig() {
      return (
        this.$v.form.currentFile.$dirty && !this.$v.form.currentFile.maxFileSize
      );
    },
  },
  methods: {
    getDuration(type, file) {
      const mediaEl = document.createElement(type);

      mediaEl.preload = "metadata";
      mediaEl.onloadedmetadata = () => {
        window.URL.revokeObjectURL(mediaEl.src);
        this.form.mediaDuration = mediaEl.duration;
        const duration = mediaEl.duration;
      };

      mediaEl.src = URL.createObjectURL(file);
    },
    resetForm() {
      this.form = {
        title: "",
        currentFile: null,
        mediaDuration: 0,
      };
      this.$v.$reset();
    },
    cancelDialog() {
      this.dialog = false;
      this.resetForm();
    },
    async closePopup() {
      if (!(this.form.title || this.form.currentFile)) {
        this.cancelDialog();
        return;
      }

      if (
        !(await this.$refs.confirmDiscard.open(
          this.$t("new_provocation.unpublished.confirm.title"),
          this.$t("new_provocation.unpublished.confirm.message")
        ))
      ) {
        this.cancelDialog();
      }
    },
    open() {
      this.dialog = true;
    },
    async uploadFile() {
      this.$v.$touch();

      if (this.$v.form.$invalid) {
        return;
      }
      const fileStorageId = await this.$refs.uploadFile.upload(
        this.form.currentFile,
        [{ key: "fetchThumbnails", value: true }]
      );
      let thumbnails = await this.getThumbnailPromise(fileStorageId);
      this.$refs.uploadFile.openUploadDialog(
        this.$t("default.uploading"),
        "",
        false
      );
      this.$emit("videoUploaded", {
        title: this.form.title,
        thumbnailImages: thumbnails,
        uploadKey: fileStorageId,
        fileName: this.form.currentFile.name,
      });
      this.$refs.uploadFile.closeUploadDialog();
      this.cancelDialog();
    },
  },
  validations() {
    return {
      form: {
        title: { required, maxLength: maxLength(150) },
        currentFile: {
          required,
          hasVideoFormat,
          maxDuration: function () {
            return this.form.mediaDuration < 120;
          },
          maxFileSize: function (file) {
            if (!file) {
              return false;
            }
            return file.size < 314572800; //300Mb
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-provocation-popup {
  color: var(--nj-black);

  &--hidden {
    background: transparent;

    .base-modal__dialog {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__inner {
    text-align: left;
    width: 100%;
    max-width: 520px;
  }

  &__form {
    &-inner {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
    }
  }

  &__description {
    ::v-deep {
      .base-textarea__field {
        height: 75px;
      }
    }
  }

  &__tip {
    font-size: 14px;
    line-height: 19px;
    margin-top: 45px;
    margin-bottom: 30px;
  }

  &__text {
    font-size: 13px;
    line-height: 18px;
    margin: 0;
  }

  &__label {
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    font-weight: var(--font-weight-medium);
    margin-top: 0 !important;

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }

  &__upload {
    &-input {
      margin-bottom: 10px;
    }
  }

  &__actions {
    margin-top: 20px;
  }

  ::v-deep {
    .form-textarea__field {
      height: 100px;
    }
  }

  .base-button {
    display: inline-flex;
    align-items: center;
    height: 42px;

    &__text {
      font-weight: var(--font-weight-medium);
    }

    &__icon {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}
</style>
