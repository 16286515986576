<template>
  <div class="daily-feed-video-details">
    <CloseDialogButton
      class="
        daily-feed-video-details__close daily-feed-video-details__close--mobile
      "
      color="white"
      @click="$emit('closePopUp')"
    />
    <div class="daily-feed-video-details__media">
      <div class="daily-feed-video-details__video-container">
        <video
          class="daily-feed-video-details__video"
          preload="metadata"
          :src="videoUrl"
          :poster="posterVideoUrl"
          :controls="videoIsPlaying"
          :ref="`video-${id}`"
          @play="videoPlayed"
          @pause="videoPaused"
          @ended="videoEnded"
          controlsList="nodownload"
        ></video>
        <PlayButton v-if="!videoIsPlaying" @play="playVideo" />
      </div>
    </div>
    <div
      class="daily-feed-video-details__info"
      :style="{ backgroundColor: color }"
    >
      <CloseDialogButton
        class="
          daily-feed-video-details__close
          daily-feed-video-details__close--desktop
        "
        color="white"
        @click="$emit('closePopUp')"
      />
      <div class="daily-feed-video-details__info-top">
        <div class="daily-feed-video-details__author" v-if="dailyFeed">
          <UserAvatar
            :size="36"
            :user="dailyFeed.uploader"
            class="daily-feed-video-details__author-avatar"
          />
          <div class="daily-feed-video-details__author-info">
            <div class="daily-feed-video-details__author-name">
              {{ dailyFeed.uploader.firstName }}
              {{ dailyFeed.uploader.lastName }}
            </div>
            <div class="daily-feed-video-details__author-role">
              {{ dailyFeed.uploader.title }}
            </div>
          </div>
        </div>
      </div>
      <p class="daily-feed-video-details__info-text" v-if="dailyFeed">
        {{ dailyFeed.description }}
      </p>
      <div
        class="daily-feed-video-details__like"
        @click.stop="likedByUser ? dislikeFeed() : likeFeed()"
      >
        <div class="daily-feed-video-details__like-icon">
          <HeartIcon
            :color="likedByUser ? '#ffffff' : '#ffffff'"
            :fill="likedByUser ? '#ffffff' : 'transparent'"
          />
        </div>
        <div class="daily-feed-video-details__like-number">
          {{ likes.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayButton from "@/components/reusables/PlayButton";
import HeartIcon from "@/components/reusables/Icons/HeartIcon";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import UserAvatar from "@/components/reusables/UserAvatar";
import DailyFeedDetailsMixin from "@/components/mixins/DailyFeedDetailsMixin";
import { convertTime, logTrackingData } from "@/service/trackingServices";
import { mapGetters } from "vuex";

export default {
  name: "DailyFeedVideoDetails",
  components: {
    UserAvatar,
    CloseDialogButton,
    PlayButton,
    HeartIcon,
  },
  props: {
    id: {
      type: [String, Number],
    },
    color: {
      type: String,
      default: "#0564D2",
    },
    render: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: "LIBRARY",
    },
  },
  mixins: [DailyFeedDetailsMixin],
  data() {
    return {
      videoIsPlaying: false,
      videoUrl: undefined,
      posterVideoUrl: undefined,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  methods: {
    videoPlayed(event) {
      logTrackingData({
        section: this.section,
        belongsToSection: "DAILY_FEED",
        activityType: "VIDEO_PLAYED",
        contentType: "VIDEO",
        contentId: this.id,
        contentLength: convertTime(event.target.duration),
        contentStartStopTime: convertTime(event.target.currentTime),
        contentCompleted: false,
      });
    },
    videoPaused(event) {
      if (event.target.duration !== event.target.currentTime) {
        logTrackingData({
          section: this.section,
          belongsToSection: "DAILY_FEED",
          activityType: "VIDEO_PAUSED",
          contentType: "VIDEO",
          contentId: this.id,
          contentLength: convertTime(event.target.duration),
          contentStartStopTime: convertTime(event.target.currentTime),
          contentCompleted: false,
        });
      }
    },
    loadItemData(videoId) {
      this.loadVideo(videoId);
    },
    loadVideo(videoId) {
      const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
      this.$http
        .get(`${baseUrl}/content/dailyFeed/${videoId}`)
        .then((response) => {
          this.videoUrl = response.data;
        });
    },
    pauseVideo() {
      if (this.$refs[`video-${this.id}`]) {
        this.$refs[`video-${this.id}`].pause();
      }
    },
    playVideo() {
      this.$refs[`video-${this.id}`].play();
      this.videoIsPlaying = true;
    },
    videoEnded() {
      this.$refs[`video-${this.id}`].load();
      this.videoIsPlaying = false;

      logTrackingData({
        section: this.section,
        belongsToSection: "DAILY_FEED",
        activityType: "VIDEO_PAUSED",
        contentType: "VIDEO",
        contentId: this.id,
        contentLength: convertTime(event.target.duration),
        contentStartStopTime: convertTime(event.target.currentTime),
        contentCompleted: true,
      });
    },
    resetData() {
      this.videoUrl = "";
      this.videoIsPlaying = false;
      this.posterVideoUrl = undefined;
      this.creatorAvatar = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.daily-feed-video-details {
  display: flex;
  position: relative;

  @media screen and (max-width: $mobile-max) {
    height: 100%;
    flex-direction: column;
    background-color: var(--nj-blue);
  }

  &__close {
    cursor: pointer;

    &--mobile {
      display: none;
      @media screen and (max-width: $mobile-max) {
        display: block;
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }

    &--desktop {
      position: absolute;
      right: 15px;
      top: 15px;
      @media screen and (max-width: $mobile-max) {
        display: none;
      }
    }
  }

  &__media {
    position: relative;
    width: 67%;
    max-width: 67%;
    flex-basis: 67%;
    @media screen and (max-width: $mobile-max) {
      margin-top: 90px;
      max-width: 100%;
      flex-basis: 100%;
      width: 100%;
      min-height: 294px;
      max-height: 294px;
      padding: 70px 25px;
    }
  }

  &__video {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--nj-black);

    @media screen and (max-width: $mobile-max) {
      border-radius: 0;
    }
  }

  &__info {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: var(--nj-blue);
    flex-grow: 1;
    max-width: 34%;
    min-height: 445px;
    padding: 23px;
    text-align: left;
    @media screen and (max-width: $mobile-max) {
      position: relative;
    }

    &-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
      @media screen and (max-width: $mobile-max) {
        margin-bottom: 0;
      }
    }

    &-text {
      color: var(--nj-white);
      font-size: 16px;
      line-height: 24px;
      @media screen and (max-width: $mobile-max) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: $mobile-max) {
      max-width: 100%;
      background-color: var(--nj-blue) !important;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 20px;

    &-avatar {
      background-color: white;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 7px;
    }

    &-name {
      font-size: 15px;
      line-height: 18px;
      color: var(--nj-white);
      font-weight: var(--font-weight-medium);
    }

    &-role {
      font-size: 15px;
      line-height: 18px;
      color: var(--nj-white);
    }
  }

  &__like {
    display: flex;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
    @media screen and (max-width: $mobile-max) {
      position: absolute;
      right: 25px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }

    &-number {
      font-size: 16px;
      line-height: 24px;
      color: var(--nj-white);
    }
  }

  .loading-overlay {
    left: 3.5%;
  }
}
</style>
