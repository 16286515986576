<template>
  <div class="daily-feed-readable" @click="openPopup">
    <div class="daily-feed-readable__inner">
      <div class="daily-feed-readable__head">
        <div class="daily-feed-readable__author">
          <div class="daily-feed-readable__author-avatar">
            <UserAvatar :user="item.uploader" :size="24" />
          </div>
          <div class="daily-feed-readable__author-info">
            <div class="daily-feed-readable__author-name">
              {{ item.uploader.firstName }} {{ item.uploader.lastName }}
            </div>
            <div class="daily-feed-readable__author-role">
              {{ item.uploader.title }}
            </div>
          </div>
        </div>
        <div class="daily-feed-readable__head-actions">
          <div
            class="daily-feed-readable__like"
            @click.stop="likedByUser ? dislikeFeed() : likeFeed()"
          >
            <div class="daily-feed-readable__like-icon">
              <HeartIcon
                :color="likedByUser ? '#EC3797' : '#ffffff'"
                :fill="likedByUser ? '#EC3797' : 'transparent'"
              />
            </div>
            <div class="daily-feed-readable__like-number">
              {{ likes.length }}
            </div>
          </div>
          <div
            class="daily-feed-readable__options"
            v-if="isCreatorOfFeed || isContentManager"
          >
            <DailyFeedAdminMenu
              v-model="showOptions"
              :isCreatorOfFeed="isCreatorOfFeed"
              :isContentManager="isContentManager"
              :isNotCreatorButAdmin="isNotCreatorButAdmin"
              @editFeed="editDailyFeed"
              @deleteFeed="deleteDailyFeed"
            />
          </div>
        </div>
      </div>
      <div class="daily-feed-readable__content">
        <p class="daily-feed-readable__text">
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HeartIcon from "@/components/reusables/Icons/HeartIcon";
import UserAvatar from "@/components/reusables/UserAvatar";
import DailyFeedAdminMenu from "@/components/library/daily-feed/DailyFeedAdminMenu";
import { mapGetters } from "vuex";
import DailyFeedItemMixin from "@/components/mixins/DailyFeedItemMixin";

export default {
  name: "DailyFeedReadable",
  components: {
    DailyFeedAdminMenu,
    UserAvatar,
    HeartIcon,
  },
  mixins: [DailyFeedItemMixin],
  props: ["item"],
  watch: {
    item(newValue) {
      if (newValue) {
        this.fetchLikes();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["isContentManager"]),
  },
  mounted() {
    this.fetchLikes();
  },
};
</script>

<style lang="scss" scoped>
.daily-feed-readable {
  text-align: left;
  background-color: #333333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 10px;

  &--odd {
    background-color: var(--nj-gray-4);
  }

  &__inner {
    padding: 10px 23px 24px;
  }

  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    position: relative;

    &-actions {
      z-index: 2;
      display: flex;
      align-items: center;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-right: 30px;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;

    &-info {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-avatar {
      background-color: white;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 7px;
      flex-shrink: 0;
    }

    &-name {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-white);
      font-weight: var(--font-weight-medium);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-role {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-white);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__like {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }

    &-number {
      font-size: 16px;
      line-height: 24px;
      color: var(--nj-white);
    }
  }

  &__text {
    color: var(--nj-white);
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
  }
}
</style>
