<template>
  <div class="daily-feed-audio-details">
    <CloseDialogButton
      class="
        daily-feed-audio-details__close daily-feed-audio-details__close--mobile
      "
      color="white"
      @click="$emit('closePopUp')"
    />
    <div class="daily-feed-audio-details__media">
      <div class="daily-feed-audio-details__audio" v-if="audioUrl">
        <img
          :src="thumbnailUrl"
          class="daily-feed-audio-details__audio-image"
          alt="audio thumbnail image"
        />
        <AudioPlayer
          :url="audioUrl"
          :id="id"
          :ref="`audio-player-${id}`"
          @play="audioPlayed"
          @pause="audioPaused"
          @ended="audioEndReached"
        />
      </div>
    </div>
    <div
      class="daily-feed-audio-details__info"
      :style="{ backgroundColor: color }"
    >
      <CloseDialogButton
        class="
          daily-feed-audio-details__close
          daily-feed-audio-details__close--desktop
        "
        color="white"
        @click="$emit('closePopUp')"
      />
      <div class="daily-feed-audio-details__info-top">
        <div class="daily-feed-audio-details__author" v-if="dailyFeed">
          <div class="daily-feed-audio-details__author-avatar">
            <UserAvatar :size="36" :user="dailyFeed.uploader" />
          </div>
          <div class="daily-feed-audio-details__author-info">
            <div class="daily-feed-audio-details__author-name">
              {{ dailyFeed.uploader.firstName }}
              {{ dailyFeed.uploader.lastName }}
            </div>
            <div class="daily-feed-audio-details__author-role">
              {{ dailyFeed.uploader.title }}
            </div>
          </div>
        </div>
      </div>
      <p class="daily-feed-audio-details__info-text" v-if="dailyFeed">
        {{ dailyFeed.description }}
      </p>
      <div
        class="daily-feed-audio-details__like"
        @click.stop="likedByUser ? dislikeFeed() : likeFeed()"
      >
        <div class="daily-feed-audio-details__like-icon">
          <HeartIcon
            :color="likedByUser ? '#ffffff' : '#ffffff'"
            :fill="likedByUser ? '#ffffff' : 'transparent'"
          />
        </div>
        <div class="daily-feed-audio-details__like-number">
          {{ likes.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from "@/components/library/audio/AudioPlayer";
import HeartIcon from "@/components/reusables/Icons/HeartIcon";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import UserAvatar from "@/components/reusables/UserAvatar";
import DailyFeedDetailsMixin from "@/components/mixins/DailyFeedDetailsMixin";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";
import { convertTime, logTrackingData } from "@/service/trackingServices";
import { mapGetters } from "vuex";

export default {
  name: "DailyFeedAudioDetails",
  components: {
    UserAvatar,
    CloseDialogButton,
    AudioPlayer,
    HeartIcon,
  },
  props: {
    id: {
      type: [Number, String],
    },
    color: {
      type: String,
      default: "#EC3797",
    },
    render: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: "LIBRARY",
    },
  },
  mixins: [DailyFeedDetailsMixin],
  data() {
    return {
      audioUrl: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    thumbnailUrl() {
      return createS3ThumbnailUrl(this.dailyFeed.thumbnailId, 400, 400);
    },
  },
  methods: {
    audioPlayed(event) {
      logTrackingData({
        section: this.section,
        belongsToSection: "DAILY_FEED",
        activityType: "AUDIO_PLAYED",
        contentType: "AUDIO",
        contentId: this.id,
        contentLength: convertTime(event.target.duration),
        contentStartStopTime: convertTime(event.target.currentTime),
        contentCompleted: false,
      });
    },
    audioPaused(event) {
      if (event.target.duration !== event.target.currentTime) {
        logTrackingData({
          section: this.section,
          belongsToSection: "DAILY_FEED",
          activityType: "AUDIO_PAUSED",
          contentType: "AUDIO",
          contentId: this.id,
          contentLength: convertTime(event.target.duration),
          contentStartStopTime: convertTime(event.target.currentTime),
          contentCompleted: false,
        });
      }
    },
    audioEndReached(event) {
      logTrackingData({
        section: this.section,
        belongsToSection: "DAILY_FEED",
        activityType: "AUDIO_PAUSED",
        contentType: "AUDIO",
        contentId: this.id,
        contentLength: convertTime(event.target.duration),
        contentStartStopTime: convertTime(event.target.currentTime),
        contentCompleted: true,
      });
    },
    loadItemData(audioId) {
      this.loadAudio(audioId);
    },
    pauseAudio() {
      if (this.$refs[`audio-player-${this.id}`]) {
        this.$refs[`audio-player-${this.id}`].pauseAudio();
      }
    },
    loadAudio(audioId) {
      const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
      this.$http
        .get(`${baseUrl}/content/dailyFeed/${audioId}`)
        .then((response) => {
          this.audioUrl = response.data;
        });
    },
    resetData() {
      this.audioUrl = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.daily-feed-audio-details {
  display: flex;
  background-color: var(--nj-white);
  border-radius: 10px;
  position: relative;

  @media screen and (max-width: $mobile-max) {
    height: 100%;
    flex-direction: column;
    background-color: var(--nj-pink);
    border-radius: 0;
  }

  &__close {
    cursor: pointer;

    &--mobile {
      display: none;

      @media screen and (max-width: $mobile-max) {
        display: block;
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }

    &--desktop {
      position: absolute;
      right: 15px;
      top: 15px;

      @media screen and (max-width: $mobile-max) {
        display: none;
      }
    }
  }

  &__media {
    display: flex;
    flex-direction: column;
    width: 67%;
    max-width: 67%;
    flex-basis: 67%;
    padding: 25px 25px 70px 25px;

    @media screen and (max-width: $mobile-max) {
      margin-top: 90px;
      background-color: var(--nj-white);
      max-width: 100%;
      flex-basis: 100%;
      width: 100%;
      height: 294px;
      max-height: 294px;
      padding: 70px 25px;
    }
  }

  &__audio {
    position: relative;
    margin-top: auto;
    display: flex;
    align-items: flex-start;
    padding-bottom: 50px;

    @media screen and (max-width: $mobile-max) {
      padding-bottom: 30px;
    }

    &-image {
      border-radius: 8px;
      object-fit: cover;
      width: 197px;
      height: 197px;
      margin-right: 40px;

      @media screen and (max-width: 830px) {
        width: 130px;
        height: 130px;
        margin-right: 20px;
      }
    }

    ::v-deep .audio-player {
      flex-grow: 1;

      &__controls {
        height: 197px;
        margin: 0;

        input[type="range"] {
          width: 100%;
        }

        &-container {
          @media screen and (max-width: 665px) and (min-width: $mobile-max) {
            flex-direction: column;
            height: 130px;
          }
        }
      }

      &__progress {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -15px;

        &-load > span {
          @media screen and (max-width: $mobile-max) {
            font-size: 9px;
          }
        }
      }

      &__controls-play-btn {
        width: 50px;
        height: 50px;
      }

      .volume-icon-image {
        width: 30px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 34%;
    min-height: 445px;
    padding: 23px;
    text-align: left;
    overflow: hidden;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    @media screen and (max-width: $mobile-max) {
      max-width: 100%;
      background-color: var(--nj-pink) !important;
      position: relative;
    }

    &-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
      @media screen and (max-width: $mobile-max) {
        margin-bottom: 10px;
      }
    }

    &-text {
      color: var(--nj-white);
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-right: 30px;

    &-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 7px;

      img {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }

    &-name {
      font-size: 15px;
      line-height: 18px;
      color: var(--nj-white);
      font-weight: var(--font-weight-medium);
    }

    &-role {
      font-size: 15px;
      line-height: 18px;
      color: var(--nj-white);
    }
  }

  &__like {
    display: flex;
    align-items: center;
    margin-top: auto;
    cursor: pointer;

    @media screen and (max-width: $mobile-max) {
      position: absolute;
      right: 25px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }

    &-number {
      font-size: 16px;
      line-height: 24px;
      color: var(--nj-white);
    }
  }

  .loading-overlay {
    left: 3.5%;
  }
}
</style>
