<template>
  <div class="loading-overlay">
    <div class="loading-overlay__text headline-medium">
      <slot></slot>
    </div>
    <DotSpin />
    <div class="loading-overlay__loading-info">
      <slot name="loading-info"></slot>
    </div>
  </div>
</template>

<script>
import DotSpin from "@/components/reusables/DotSpin";

export default {
  name: "LoadingOverlay",
  components: { DotSpin },
};
</script>

<style scoped lang="scss">
.loading-overlay {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.51);
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  border-radius: inherit;

  &__text {
    color: var(--nj-gray-2);
    margin-bottom: 42px;
  }

  &__loading-info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
