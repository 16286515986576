<template>
  <div class="save-note-popup base-modal">
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="dialog = false"
    >
      <div
        class="base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="base-modal__head">
          <CloseDialogButton
            @click="dialog = false"
            class="base-modal__close"
          />
          <h1 class="base-modal__head-title">
            {{ $t("notebooks.note.save.title") }}
          </h1>
        </div>

        <div class="base-modal__body">
          <h3 class="save-note-popup__subtitle">
            {{ $t("notebooks.note.save.select_title") }}
          </h3>
          <h3
            class="save-note-popup__message"
            v-if="notebooks && !notebooks.length"
          >
            {{ $t("notebooks.note.save.no_notebooks") }}
          </h3>

          <div
            class="save-note-popup__notebooks"
            v-if="notebooks && notebooks.length"
          >
            <button
              v-for="notebook in notebooks"
              :key="notebook.notebookId"
              class="save-note-popup__notebooks-button"
              @click="saveNote(notebook.notebookId)"
            >
              {{ notebook.notebookName }} ({{ notebook.notesCount }})
            </button>
          </div>

          <div class="base-modal__actions--center">
            <BaseButton
              class="base-modal__actions-item"
              variant="secondary"
              @click="deleteAndClose"
            >
              {{ $t("notebooks.note.save.delete_btn") }}
            </BaseButton>
            <BaseButton
              class="base-modal__actions-item"
              @click="$refs.addNewNotebook.open()"
            >
              {{ $t("notebooks.notebook.title") }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <AddNewNotebook
      ref="addNewNotebook"
      @refetchNotebooks="$apollo.queries.notebooks.refetch()"
    />
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import AddNewNotebook from "@/components/notebooks/AddNewNotebook";
import { mapGetters, mapState } from "vuex";
import NOTEBOOKS from "@/graphql/notebooks/queries/GetNotebooksByUserId.graphql";
import SAVE_NOTE from "@/graphql/notebooks/mutations/CreateNote.graphql";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "SaveNotePopup",
  components: { AddNewNotebook, CloseDialogButton },
  data() {
    return {
      dialog: false,
      noteJson: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    ...mapState({
      fetchedTags: (state) => state.notebook.fetchedTags,
      note: (state) => state.notebook.note,
    }),
  },
  methods: {
    saveNote(notebookId) {
      let variables = {
        notebookId,
        noteText: JSON.stringify(this.$store.state.notebook.note.noteText),
        previewText:
          this.$store.state.notebook.note.flatText.length > 370
            ? this.$store.state.notebook.note.flatText.slice(0, 370)
            : this.$store.state.notebook.note.flatText,
        // tags:
        //   this.note.tags.length > 3
        //     ? this.note.tags.slice(0, 3)
        //     : this.note.tags,
        tags: this.note.tags.filter(
          (tag) => tag.locked === true || tag.tagState === "PRIMARY"
        ),
      };
      console.log("saved tags: ", variables.tags);
      this.$apollo
        .mutate({
          mutation: SAVE_NOTE,
          variables: variables,
        })
        .then((data) => {
          console.log("data from saveNew: ", data);
          this.$store.commit("notebook/closeAndReset");
          this.dialog = false;
          this.trackCreateNote();
        })
        .catch((err) => {
          errorHandler(
            err,
            "ERROR_CREATE_NOTE",
            this.$t("notebooks.errors.error_create_note")
          );
        });
    },
    trackCreateNote() {
      logTrackingData({
        activityType: "CREATE_NOTE",
      });
    },
    open(noteJson) {
      this.$apollo.queries.notebooks.refetch();
      this.noteJson = noteJson;
      this.dialog = true;
    },
    deleteAndClose() {
      this.dialog = false;
      this.$emit("deleteNote");
    },
  },
  apollo: {
    notebooks: {
      query: NOTEBOOKS,
      variables() {
        return {
          userId: this.getNJUser.id,
        };
      },
      fetchPolicy: "network-only",
      skip() {
        return !this.getNJUser || !this.getNJUser.id;
      },
      update(data) {
        return data.getUserNotebooksByUserId;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_FETCH_NOTEBOOKS",
          "Error fetching notebooks."
        );
      },
    },
  },
};
</script>

<style scoped lang="scss">
.save-note-popup {
  &__subtitle {
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    margin-bottom: 25px;
  }

  &__message {
    padding-bottom: 20px;
    color: var(--nj-gray-4);
  }

  &__notebooks {
    margin-bottom: 48px;
    overflow: scroll;
    max-height: 300px;

    &-button {
      width: 100%;
      height: 42px;
      border-radius: 10px;
      box-shadow: var(--nj-box-shadow);
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 24px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.base-modal {
  &__inner {
    width: 440px;
  }

  &__body {
    padding: 14px 60px;
  }
}
</style>
