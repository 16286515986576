import LIKE_DAILY_FEED from "@/graphql/daily-feeds/mutations/LikeDailyFeed.graphql";
import DISLIKE_DAILY_FEED from "@/graphql/daily-feeds/mutations/DisikeDailyFeed.graphql";
import LIST_DAILY_FEED_LIKES from "@/graphql/daily-feeds/queries/ListDailyFeedLikes.graphql";
import errorHandler from "@/service/errorHandler";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";
import { mapGetters } from "vuex";
import { logTrackingData } from "@/service/trackingServices";

const DailyFeedItemMixin = {
  props: ["section"],
  data() {
    return {
      thumbnail: "",
      likes: [],
      likedByUser: false,
      showOptions: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isContentManager", "getNJUser", "getUsername"]),
    isCreatorOfFeed() {
      return (
        this.getNJUser &&
        this.item &&
        this.item.uploader &&
        this.item.uploader.id === this.getNJUser.id
      );
    },
    isNotCreatorButAdmin() {
      return !this.isCreatorOfFeed && this.isContentManager;
    },
  },
  methods: {
    async loadThumbnail(type = "audio") {
      if (type === "video") {
        this.thumbnail = createS3ThumbnailUrl(this.item.thumbnailId, 298, 168); // video
      } else {
        this.thumbnail = createS3ThumbnailUrl(this.item.thumbnailId, 400, 400); // audio
      }
    },
    openPopup() {
      this.showOptions = false;
      this.$emit("openPopup", this.item);
    },
    editDailyFeed() {
      this.$emit("editFeed");
    },
    deleteDailyFeed() {
      this.$emit("deleteFeed");
    },
    fetchLikes() {
      this.$apollo
        .query({
          query: LIST_DAILY_FEED_LIKES,
          variables: {
            dailyFeedId: this.item.id,
          },
          fetchPolicy: "network-only",
        })
        .then((data) => {
          this.likes = data.data.likes;

          if (this.likes.length) {
            this.likedByUser = this.likes.find(
              (item) => item.username === this.getUsername
            );
          } else {
            this.likedByUser = false;
          }
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_FETCH_LIKES",
            this.$t("error.library.daily_feeds.fetch_likes_error")
          );
        });
    },
    likeFeed() {
      this.showOptions = false;
      this.$apollo
        .mutate({
          mutation: LIKE_DAILY_FEED,
          variables: {
            username: this.getUsername,
            dailyFeedId: this.item.id,
          },
        })
        .then(() => {
          logTrackingData({
            section: this.section,
            belongsToSection: "DAILY_FEED",
            activityType: "LIKE_DAILY_FEED",
            contentType: "DAILY_FEED",
            contentId: this.item.id,
          });
          this.fetchLikes();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_LIKE_FEED",
            this.$t("error.library.daily_feeds.like_error")
          );
        });
    },
    dislikeFeed() {
      this.showOptions = false;
      this.$apollo
        .mutate({
          mutation: DISLIKE_DAILY_FEED,
          variables: {
            username: this.getUsername,
            dailyFeedId: this.item.id,
          },
        })
        .then(() => {
          this.fetchLikes();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DISLIKE_FEED",
            this.$t("error.library.daily_feeds.dislike_error")
          );
        });
    },
  },
};

export default DailyFeedItemMixin;
