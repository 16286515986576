import { Role } from "@/components/security/Role";
import { RouteAccessRights } from "@/components/security/RouteAccess";

const routes = [
  {
    path: "/community",
    component: () =>
      import(/*webpackChunkName: "community"*/ "@/views/Community"),
    children: [
      {
        path: "",
        name: "community",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        redirect: { name: "community.feed" },
      },
      {
        path: "feed",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        name: "community.feed",
        component: () =>
          import(
            /*webpackChunkName: "community_feed_page"*/ "@/components/community/feedTab/CommunityFeedPage"
          ),
      },
      {
        path: "feedItem/:feedItemId",
        name: "feedItem",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        props: true,
        component: () =>
          import(
            /*webpackChunkName: "community_feed_feed_item_page"*/ "@/components/community/feedTab/FeedItemPage"
          ),
      },
      {
        path: "groups",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        name: "community.groups",
        component: () =>
          import(
            /*webpackChunkName: "community_groups_page"*/ "@/components/community/groupsTab/CommunityGroupsPage"
          ),
        // children: [
        //   {
        //     path: ":groupId",
        //     name: "community.groups.info",
        //     props: true,
        //     meta: { authorize: [Role.NEURAL_JAMMER] },
        //     component: () =>
        //       import(
        //         /*webpackChunkName: "group_info_popup"*/ "@/components/community/groupsTab/GroupInfoPopUp"
        //       ),
        //   },
        // ],
      },
      {
        path: "groups/:categoryId",
        name: "community.groupsCategoriesAll",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "all_category_groups" */ "@/components/community/groupsTab/GroupsListAll"
            ),
      },
      {
        path: "members",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        name: "community.members",
        component: () =>
          import(
            /*webpackChunkName: "community_members_page"*/ "@/components/community/membersTab/CommunityMembersPage"
          ),
      },
    ],
  },
  {
    path: "/community/group-details/:groupId",
    component: () =>
      import(
        /*webpackChunkName: "group_details_page"*/ "@/components/community/groupsTab/groupDetails/GroupDetailsPage"
      ),
    props: true,
    children: [
      {
        path: "",
        name: "group.details",
        props: true,
        meta: {
          authorize: [
            Role.NEURAL_JAMMER,
            RouteAccessRights.COMMUNITY_GROUP_MEMBER,
          ],
        },
        redirect: { name: "group.details.overview" },
      },
      {
        path: "overview",
        name: "group.details.overview",
        props: true,
        meta: {
          authorize: [
            Role.NEURAL_JAMMER,
            RouteAccessRights.COMMUNITY_GROUP_MEMBER,
          ],
        },
        component: () =>
          import(
            /*webpackChunkName: "group_details_overview"*/ "@/components/community/groupsTab/groupDetails/GroupOverviewTab"
          ),
      },
      {
        path: "content",
        props: true,
        meta: {
          authorize: [
            Role.NEURAL_JAMMER,
            RouteAccessRights.COMMUNITY_GROUP_MEMBER,
          ],
        },
        component: () =>
          import(
            /*webpackChunkName: "group_details_content_all"*/ "@/components/community/groupsTab/groupDetails/groupContentTab/GroupContentTab"
          ),
        children: [
          {
            path: "",
            name: "group.details.content.main",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            redirect: { name: "group.details.content.main.all" },
          },
          {
            path: "all",
            name: "group.details.content.main.all",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /*webpackChunkName: "group_details_content_all"*/ "@/components/community/groupsTab/groupDetails/groupContentTab/GroupContentAll"
              ),
          },
          {
            path: "photoListAll",
            name: "group.details.content.main.photoListAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /*webpackChunkName: "group_details_content_photo"*/ "@/components/community/groupsTab/groupDetails/groupContentTab/GroupContentPhotosAll"
              ),
          },
          {
            path: "readableListAll",
            name: "group.details.content.main.readableListAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /*webpackChunkName: "group_details_content_readable"*/ "@/components/community/groupsTab/groupDetails/groupContentTab/GroupContentReadableAll"
              ),
          },
          {
            path: "videoListAll",
            name: "group.details.content.main.videoListAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /*webpackChunkName: "group_details_content_video"*/ "@/components/community/groupsTab/groupDetails/groupContentTab/GroupContentVideoAll"
              ),
          },
          {
            path: "audioListAll",
            name: "group.details.content.main.audioListAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /*webpackChunkName: "group_details_content_audio"*/ "@/components/community/groupsTab/groupDetails/groupContentTab/GroupContentAudioAll"
              ),
          },
        ],
      },
      {
        path: "live-sessions",
        name: "group.details.liveSessions",
        props: true,
        meta: {
          authorize: [
            Role.NEURAL_JAMMER,
            RouteAccessRights.COMMUNITY_GROUP_MEMBER,
          ],
        },
        component: () =>
          import(
            /*webpackChunkName: "group_details_live_sessions"*/ "@/components/community/groupsTab/groupDetails/liveSessionsTab/LiveSessionsTab"
          ),
      },
    ],
  },
  {
    path: "/community/group-details/:groupId/live-session/:liveSessionId",
    name: "liveSession.group.details",
    component: () =>
      import(
        /* webpackChunkName: "live_session_group_details" */ "@/components/live-session/LiveSessionDetails"
      ),
    meta: {
      authorize: [Role.NEURAL_JAMMER, RouteAccessRights.COMMUNITY_GROUP_MEMBER],
    },
    props: true,
  },
];

export default routes;
