<template>
  <div class="what-is-new-admin-menu">
    <button class="what-is-new-admin-menu__btn">
      <ThreeDotsIcon color="#EC3797" />
    </button>
    <div class="what-is-new-admin-menu__menu">
      <div
        v-if="!firstItem"
        class="what-is-new-admin-menu__menu-item"
        @click="moveWhatsNewUp"
      >
        <div class="what-is-new-admin-menu__menu-icon">
          <ArrowCircleIcon color="#EC3797" />
        </div>
        <div class="what-is-new-admin-menu__menu-text">
          {{ $t("what_is_new.admin.move_up") }}
        </div>
      </div>
      <div
        v-if="!lastItem"
        class="what-is-new-admin-menu__menu-item"
        @click="moveWhatsNewDown"
      >
        <div class="what-is-new-admin-menu__menu-icon">
          <ArrowCircleIcon color="#EC3797" direction="down" />
        </div>
        <div class="what-is-new-admin-menu__menu-text">
          {{ $t("what_is_new.admin.move_down") }}
        </div>
      </div>
      <div class="what-is-new-admin-menu__menu-item" @click="removeWhatsNew">
        <div class="what-is-new-admin-menu__menu-icon">
          <TrashIcon color="#EC3797" />
        </div>
        <div class="what-is-new-admin-menu__menu-text">
          {{ $t("what_is_new.admin.remove") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DELETE_WHATS_NEW from "@/graphql/whats-new/mutations/DeleteWhatsNew.graphql";
import ArrowCircleIcon from "@/components/reusables/Icons/ArrowCircleIcon";
import errorHandler from "@/service/errorHandler";

const ThreeDotsIcon = () =>
  import("@/components/reusables/Icons/ThreeDotsIcon");
const TrashIcon = () => import("@/components/reusables/Icons/TrashCan");

export default {
  name: "WhatIsNewAdminMenu",
  components: {
    ArrowCircleIcon,
    ThreeDotsIcon,
    TrashIcon,
  },
  props: ["itemId", "firstItem", "lastItem"],
  methods: {
    updateCarousel() {
      this.$emit("updateCarousel");
    },
    moveWhatsNewUp() {
      this.$emit("moveWhatsNew", {
        id: this.itemId,
        direction: "up",
      });
    },
    moveWhatsNewDown() {
      this.$emit("moveWhatsNew", {
        id: this.itemId,
        direction: "down",
      });
    },
    removeWhatsNew() {
      const variables = {
        whatsNewId: this.itemId,
      };

      this.$apollo
        .mutate({
          mutation: DELETE_WHATS_NEW,
          variables,
          update: (store, { data: { whatsNew } }) => {
            if (whatsNew) {
              store.evict({
                id: store.identify({
                  __typename: "WhatsNew",
                  id: whatsNew.id,
                }),
              });

              store.gc();
              this.updateCarousel();
            }
          },
          catch() {
            this.$emit("error");
          },
        })
        .then((data) => {
          this.$log.debug("Delete whats new SUCCESS", data);
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_REMOVE_WHATS_NEW",
            this.$t("error.whats_new.delete_error")
          );
        });
    },
  },
};
</script>

<style scoped lang="scss">
.what-is-new-admin-menu {
  position: relative;
  margin-left: 16px;

  &:hover {
    .what-is-new-admin-menu__menu {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    height: 3px;
  }

  &__menu {
    position: absolute;
    top: 10px;
    left: 0;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    background-color: var(--nj-white);
    min-width: 141px;
    z-index: 3;
    opacity: 0;
    pointer-events: none;

    &-item {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }

    &-icon {
      height: 24px;
      width: 24px;
      margin-right: 9px;
    }

    &-text {
      font-size: 13px;
      line-height: 28px;
      color: var(--nj-pink);
      white-space: nowrap;
    }
  }
}
</style>
