<template>
  <div
    :class="[
      'video-answer-person',
      { 'video-answer-person--selected': selected },
    ]"
    @click="$emit('click')"
  >
    <div class="video-answer-person__icon">
      <UserAvatar :size="size" :user="user" :prevent-open-profile="true" />
    </div>
    <PlayButton size="sm" />
  </div>
</template>

<script>
import PlayButton from "@/components/reusables/PlayButton";
import UserAvatar from "@/components/reusables/UserAvatar";

export default {
  name: "VideoAnswerPerson",
  components: {
    UserAvatar,
    PlayButton,
  },
  props: ["user", "selected", "size"],
};
</script>

<style lang="scss" scoped>
.video-answer-person {
  cursor: pointer;
  overflow: hidden;
  width: 55px;
  height: 55px;
  border: 1px solid transparent;
  background-color: #e0e0e0;
  border-radius: 50%;
  position: relative;

  &--selected {
    border: 1px solid var(--nj-green);

    .play-button {
      opacity: 1 !important;
    }
  }

  &__icon {
    display: block;
    width: 100%;
    max-width: 100%;
  }

  .play-button {
    opacity: 0;
  }

  &:hover {
    border: 1px solid var(--nj-green);

    .play-button {
      opacity: 1;
    }
  }
}
</style>
