<template>
  <svg
    @click="$emit('click')"
    :width="size"
    :height="size"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 1.49966C8.63132 1.36834 8.78722 1.26417 8.95881 1.1931C9.13039 1.12203 9.31428 1.08545 9.5 1.08545C9.68572 1.08545 9.86962 1.12203 10.0412 1.1931C10.2128 1.26417 10.3687 1.36834 10.5 1.49966C10.6313 1.63098 10.7355 1.78689 10.8066 1.95847C10.8776 2.13005 10.9142 2.31395 10.9142 2.49966C10.9142 2.68538 10.8776 2.86928 10.8066 3.04086C10.7355 3.21244 10.6313 3.36834 10.5 3.49966L3.75 10.2497L1 10.9997L1.75 8.24966L8.5 1.49966Z"
      :stroke="color"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EditPencil",
  props: {
    color: {
      type: String,
      default: "black",
    },
    size: {
      type: Number,
      default: 20,
    },
  },
};
</script>

<style scoped lang="scss"></style>
