import { Role } from "@/components/security/Role";

const routes = [
  {
    path: "/livesession",
    component: () =>
      import(/* webpackChunkName: "live_session" */ "@/views/LiveSession"),
    children: [
      {
        path: "",
        name: "livesession",
        redirect: { name: "livesession.allsessions" },
      },
      {
        path: "allsessions",
        name: "livesession.allsessions",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "all_livesessions" */ "@/components/live-session/LiveSessionsAll"
          ),
      },
    ],
  },
];

export default routes;
