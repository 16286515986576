<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0658 15.8603C19.689 14.7118 19.4916 13.5095 19.4916 12.2892V9.29239C19.5095 7.39021 18.8455 5.54186 17.5894 4.1242C16.3332 2.68859 14.6105 1.77339 12.7263 1.5401C11.6854 1.43243 10.6267 1.5401 9.62175 1.88106C8.61682 2.20407 7.70162 2.74242 6.92998 3.46023C6.15834 4.16009 5.53026 5.02145 5.11752 5.97255C4.70479 6.92364 4.4715 7.94651 4.4715 9.00527V12.3072C4.4715 13.5095 4.2741 14.7118 3.89726 15.8603L3 18.5162L3.7178 19.5032H8.97572C8.97572 20.2928 9.29874 21.0644 9.85504 21.6207C10.4113 22.177 11.183 22.5 11.9726 22.5C12.7621 22.5 13.5338 22.177 14.0901 21.6207C14.6464 21.0644 14.9694 20.2928 14.9694 19.5032H20.2273L20.9451 18.5162L20.0658 15.8603ZM13.0313 20.5799C12.7442 20.867 12.3674 21.0285 11.9726 21.0285C11.5778 21.0285 11.2009 20.867 10.9138 20.5799C10.6267 20.2928 10.4652 19.9159 10.4652 19.5211H13.462C13.4799 19.9159 13.3184 20.2928 13.0313 20.5799ZM4.75862 18.0137L5.31492 16.3448C5.7456 15.0348 5.97889 13.671 5.97889 12.3072V9.00527C5.97889 8.16185 6.15834 7.33637 6.4993 6.58268C6.84026 5.81104 7.32477 5.12912 7.95285 4.57283C8.58093 3.99858 9.31668 3.5679 10.1063 3.31667C10.9138 3.04749 11.7572 2.95776 12.5827 3.04749C14.0901 3.24489 15.4898 3.99858 16.4768 5.14707C17.4817 6.29556 18.0021 7.785 17.9842 9.31034V12.3251C17.9842 13.6889 18.1995 15.0528 18.6481 16.3628L19.2044 18.0317H4.75862V18.0137Z"
      fill="#424242"
    />
  </svg>
</template>

<script>
export default {
  name: "BellWithoutDotIcon",
};
</script>

<style scoped lang="scss"></style>
