<template>
  <div class="confirm base-modal" @keydown.esc="close" tabindex="0" ref="popup">
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="close"
    >
      <div
        class="base-modal__inner confirm__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div
          class="base-modal__head"
          :style="{ backgroundColor: options.color }"
        >
          <CloseDialogButton class="base-modal__close" @click="close()" />
          <h1 class="base-modal__head-title">{{ title }}</h1>
        </div>
        <div class="base-modal__body">
          <pre
            v-show="!!message"
            class="base-modal__body-text confirm__message"
            v-html="message"
          ></pre>
          <p v-if="options.date" class="confirm__date">{{ options.date }}</p>
          <p v-if="options.time" class="confirm__time">{{ options.time }}</p>
          <div class="base-modal__actions--center">
            <FormActions
              class="confirm__form-actions"
              :variant="variant"
              :decline-button="
                reverseButtonFunctionality
                  ? acceptButtonText
                  : declineButtonText
              "
              :accept-button="
                reverseButtonFunctionality
                  ? declineButtonText
                  : acceptButtonText
              "
              @accept="reverseButtonFunctionality ? cancel() : agree()"
              @decline="reverseButtonFunctionality ? agree() : cancel()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormActions from "@/components/forms/FormActions";
import i18n from "@/plugins/i18n";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";

export default {
  components: { CloseDialogButton, FormActions },
  props: {
    variant: {
      type: String,
      default: "admin",
    },
    acceptButtonText: {
      type: String,
      default: i18n.t("confirm_dialog.button_text_yes"),
    },
    declineButtonText: {
      type: String,
      default: i18n.t("confirm_dialog.button_text_no"),
    },
    reverseButtonFunctionality: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: "",
      title: null,
      options: {
        color: "primary",
      },
    };
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      this.$refs.popup.focus();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
      this.$emit("agree");
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.$emit("cancel");
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm {
  z-index: 200;

  &__time,
  &__date {
    text-align: center;
    margin: 0;
    font-size: 16px;
  }

  &__inner {
    width: 440px;

    @media screen and (max-width: 472px) {
      margin: 0 16px;
    }
  }

  &__message {
    padding: 0 36px;
    white-space: pre-line;

    @media screen and (max-width: $mobile-max) {
      padding: 0;
    }
  }
}

.form-actions {
  ::v-deep &__btn:first-child {
    margin-left: 0;
  }
}
</style>
