<template>
  <div
    class="custom-authenticator"
    :class="{
      'custom-authenticator--signin': displayMap.showSignIn,
      'custom-authenticator--signup':
        displayMap.showSignUp || displayMap.showConfirmSignUp,
      'custom-authenticator--reset': displayMap.showForgotPassword,
    }"
  >
    <div
      class="custom-authenticator__image-container"
      :class="{
        'custom-authenticator__image-container--reset':
          displayMap.showForgotPassword,
      }"
    >
      <img
        src="/globe-full.png"
        class="custom-authenticator__globe"
        alt="globe image"
      />

      <div
        v-if="
          displayMap.showSignUp ||
          displayMap.showSignIn ||
          displayMap.showConfirmSignUp
        "
        class="custom-authenticator__image-container-text"
        :class="{
          'custom-authenticator__image-container-text--signin':
            displayMap.showSignIn,
          'custom-authenticator__image-container-text--signup':
            displayMap.showSignUp || displayMap.showConfirmSignUp,
        }"
      >
        <div>
          <svg
            v-if="displayMap.showSignIn"
            width="171"
            height="32"
            viewBox="0 0 171 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M-17 16L169 16" stroke="#0564D2" stroke-width="2" />
            <path d="M154 1L169 16L154 31" stroke="#0564D2" stroke-width="2" />
          </svg>
          <h1 class="custom-authenticator__image-container-text-welcome">
            {{ $t("custom_authenticator.welcome_message") }}
          </h1>
        </div>
        <div>
          <svg
            width="186"
            height="32"
            viewBox="0 0 186 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M-17 16L169 16" stroke="#0564D2" stroke-width="2" />
            <path d="M154 1L169 16L154 31" stroke="#0564D2" stroke-width="2" />
          </svg>
          <h1 class="custom-authenticator__image-container-text-signin">
            {{
              displayMap.showSignIn
                ? $t("custom_authenticator.sign_in")
                : $t("custom_authenticator.sign_up")
            }}
          </h1>
        </div>
      </div>
    </div>

    <div
      class="custom-authenticator__forms"
      :class="{
        'custom-authenticator__forms--reset':
          displayMap.showForgotPassword || displayMap.requireNewPassword,
      }"
    >
      <sign-in
        class="custom-authenticator__forms-signin"
        v-if="displayMap.showSignIn"
        :signInConfig="options.signInConfig"
      ></sign-in>

      <sign-up
        v-if="displayMap.showSignUp"
        class="custom-authenticator__forms-signup"
      ></sign-up>

      <confirm-sign-up
        v-if="displayMap.showConfirmSignUp"
        :confirmSignUpConfig="options.confirmSignUpConfig"
      ></confirm-sign-up>

      <forgot-password v-if="displayMap.showForgotPassword"></forgot-password>
    </div>
    <!-- Confirm Sign Up / MFA not supported at the moment - must be implemented if enabled in cognito -->
    <!--    <amplify-set-mfa v-if="displayMap.showMfa" v-bind:mfaConfig="options.mfaConfig"></amplify-set-mfa>-->
    <!--    <amplify-confirm-sign-in v-if="displayMap.showConfirmSignIn" v-bind:confirmSignInConfig="options.confirmSignInConfig"></amplify-confirm-sign-in>-->
  </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import errorHandler from "@/service/errorHandler";

export default {
  name: "CustomAuthenticator",
  props: ["authConfig"],
  data() {
    return {
      user: {
        username: null,
      },
      displayMap: {},
      error: "",
    };
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        usernameAttributes: "username",
      };
      return Object.assign(defaults, this.authConfig || {});
    },
  },
  beforeDestroy() {
    document.querySelector(".app").classList.remove("app--white");
  },
  async mounted() {
    document.querySelector(".app").classList.add("app--white");

    AmplifyEventBus.$on("localUser", (user) => {
      this.user = user;
      this.options.signInConfig.usernameOrPassword = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
    });
    AmplifyEventBus.$on("authState", (data) => {
      this.displayMap = this.updateDisplayMap(data);
    });

    this.getUser()
      .then((val) => {
        if (val instanceof Error) {
          if (
            this.$route.name === "paymentRegister" &&
            this.$route.params.productName
          ) {
            this.$router.push({ name: "paymentRegister" });
          }
          if (
            this.$route.name === "prefilledPaymentSignUp" &&
            this.$route.params.grantcode &&
            this.$route.params.useremail &&
            this.$route.params.firstName &&
            this.$route.params.lastName
          ) {
            return (this.displayMap = this.updateDisplayMap("signUp"));
          }
          if (
            this.$route.name === "prefilledSignUp" &&
            this.$route.params.grantcode &&
            this.$route.params.useremail
          ) {
            return (this.displayMap = this.updateDisplayMap("signUp"));
          }
          return (this.displayMap = this.updateDisplayMap("signedOut"));
        } else {
          this.user = val;
          this.$router.push({ name: "home" });
          return (this.displayMap = this.updateDisplayMap("signedIn"));
        }
      })
      .catch((e) => this.setError(e));
  },
  methods: {
    updateDisplayMap: (state) => {
      return {
        showSignIn: state === "signedOut" || state === "signIn",
        showSignUp: state === "signUp",
        showConfirmSignUp: state === "confirmSignUp",
        showConfirmSignIn: state === "confirmSignIn",
        showForgotPassword: state === "forgotPassword",
        showSignOut: state === "signedIn",
        showMfa: state === "setMfa",
      };
    },
    setError(error) {
      this.error = this.$Amplify.I18n.get(error.message || error);

      errorHandler(error, null, this.error);
    },
    getUser() {
      return this.$Amplify.Auth.currentAuthenticatedUser()
        .then((user) => {
          if (!user) {
            return null;
          }
          return user;
        })
        .catch((e) => {
          return new Error(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-authenticator {
  display: flex;
  align-items: flex-start;
  margin: 0 -30px;

  @media (max-width: $mobile-max) {
    flex-direction: column;
    margin: 0;
  }

  &--signin {
    @media (max-width: $mobile-max) {
      margin-top: -231px;
    }
  }

  &--signup {
    @media (max-width: $mobile-max) {
      margin-top: -302px;
    }
  }

  &--reset {
    margin: 0;
    @media (max-width: $mobile-max) {
      margin-top: -247px;
    }
  }

  &__image-container {
    position: relative;
    width: 35%;
    padding: 750px 10px 0 0;
    border-right: var(--nj-green) 2px solid;

    @media (max-width: $mobile-max) {
      left: -12vw;
      border-right: none;
      width: 69%;
      padding-top: 405px;
    }

    &--reset {
      border-right: none;
      left: -18vw;

      @media (max-width: $mobile-max) {
        left: -12vw;
        border-right: none;
        width: 73%;
      }
    }

    &-text {
      position: absolute;
      width: 600px;
      right: 0;
      padding-right: 16px;

      @media (max-width: $mobile-max) {
        padding-right: 24px;
        right: -174px;
      }

      &--signin {
        top: 63%;

        @media (max-width: $mobile-max) {
          top: 300px;
        }
      }

      &--signup {
        top: 27%;

        @media (max-width: $mobile-max) {
          top: 310px;
        }
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      & > :first-child svg {
        @media (max-width: $mobile-max) {
          display: none;
        }
      }

      & > :nth-child(2) svg {
        @media (min-width: $mobile-max) {
          display: none;
        }
      }

      &-welcome {
        margin-left: 10px;
        color: var(--nj-green);
        font-size: 34px;
        font-weight: var(--font-weight-regular);
        line-height: 43px;
        text-align: right;
        padding-right: 5px;

        @media (max-width: 1400px) and (min-width: $mobile-max) {
          font-size: 2.4vw;
        }
        @media (max-width: $mobile-max) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      &-signin {
        font-size: 95px;
        font-weight: var(--font-weight-bold);
        line-height: 110px;
        text-align: right;

        @media (max-width: 1400px) and (min-width: $mobile-max) {
          font-size: 6vw;
          line-height: 6vw;
        }
        @media (max-width: $mobile-max) {
          font-size: 48px;
          line-height: 61px;
        }
      }
    }
  }

  &__globe {
    width: 640px;
    background-color: transparent;
    position: absolute;
    top: 100px;
    right: 10px;

    @media (max-width: $mobile-max) {
      width: 300px;
    }
  }

  &__forms {
    width: 445px;
    margin: 0 auto;

    @media (max-width: 700px) and (min-width: $mobile-max) {
      padding: 0 16px;
    }
    @media (max-width: $mobile-max) {
      width: 100%;
      margin-top: 25px;
    }

    &--reset {
      position: absolute;
      width: 100%;
    }

    &-signin {
      margin-top: 252px;

      @media (max-width: $tablet-max) {
        margin-top: 120px;
      }
      @media (max-width: $mobile-max) {
        margin-top: 0;
      }
    }

    &-signup {
      margin-top: 13px;

      @media (max-width: $mobile-max) {
        margin-top: 0;
      }
    }
  }
}
</style>
