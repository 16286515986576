<template>
  <div
    :class="[
      'form-input',
      {
        'form-input--errors': hasErrors,
      },
    ]"
  >
    <label class="form-input__label" v-if="label.length">
      {{ label }}
      <span v-if="optional" class="form-input__label-optional">
        (optional)
      </span>
      <span class="form-input__label-tooltip">
        <slot name="tooltip"></slot>
      </span>
    </label>
    <div class="form-input__field-wrap">
      <div v-if="showPrice && currency" class="form-input__currency">{{ currency }}</div>
      <input
        class="form-input__field"
        :value="value"
        @input="changeInput"
        @focus="focused = true"
        @blur="focused = false"
        :type="showPasswordOption ? (showPassword ? 'text' : 'password') : type"
        :autocomplete="autocomplete"
        :disabled="disabled"
      />
      <EyeIcon
        v-if="showPasswordOption && !showPassword"
        class="form-input__icon"
        @click="showPassword = true"
      />
      <EyeCrossedOutIcon
        v-if="showPasswordOption && showPassword"
        class="form-input__icon"
        @click="showPassword = false"
      />
      <slot name="icon" class="form-input__icon"></slot>
      <div
        :class="[
          'base-form__counter',
          { 'base-form__counter--error': this.value.length > this.counter },
        ]"
        v-if="counter"
      >
        {{ this.value.length }} / {{ this.counter }}
      </div>
    </div>
    <div class="base-form__errors" v-if="hasErrors">
      <slot name="errors"></slot>
    </div>
  </div>
</template>

<script>
import EyeIcon from "@/components/reusables/Icons/EyeIcon";
import EyeCrossedOutIcon from "@/components/reusables/Icons/EyeCrossedOutIcon";
import { mapState } from "vuex";

export default {
  name: "FormInput",
  components: { EyeCrossedOutIcon, EyeIcon },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    counter: {
      type: Number,
      default: null,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showPasswordOption: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      showPassword: false,
    };
  },
  computed: {
    ...mapState("common", ["currency"]),
  },
  methods: {
    changeInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  position: relative;
  text-align: left;
  margin-bottom: 24px;
  cursor: auto;

  &--errors {
    .form-input {
      &__field-wrap {
        border-color: var(--form-error-color) !important;
        background-color: var(--form-error-background);
      }

      &__counter {
        color: var(--form-error-color);
      }
    }
  }

  &__label {
    position: relative;
    display: inline-block;
    color: var(--form-input-label-color);
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: var(--font-weight-medium);
    @media screen and (max-width: $mobile-max) {
      font-size: 16px;
    }

    &-tooltip {
      position: absolute;
      left: calc(100% + 10px);
    }

    &-optional {
      color: var(--nj-gray-4);
    }
  }

  &__currency {
    position: relative;
    padding: 3px 10px 0;
    font-size: 20px;
    line-height: 20px;
    color: var(--nj-gray-3);

    &:after {
      content: "";
      position: absolute;
      top: -4px;
      bottom: -4px;
      right: 0;
      width: 1px;
      background-color: var(--nj-gray-3);
    }
  }

  &__field {
    display: block;
    color: var(--form-input-color);
    line-height: 20px;
    font-size: 16px;
    padding: 8px 10px;
    width: 100%;

    &-wrap {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 4px;
      display: flex;
      position: relative;
      border: 1px solid var(--form-input-border-color);
      padding: 1px 0;
    }

    &:focus,
    &:active {
      border-color: var(--nj-green);
    }

    &[disabled] {
      background-color: var(--form-disabled-background);
      cursor: initial;
    }
  }

  &__icon {
    margin-right: 10px;
  }
}
</style>
