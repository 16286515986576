<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="14.5" stroke="#4F4F4F" />
    <path
      d="M22 8L8 22"
      stroke="#4F4F4F"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 8L22 22"
      stroke="#4F4F4F"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DeclineIcon",
  props: {
    color: {
      type: String,
      default: "black",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style scoped></style>
