<template>
  <div class="daily-provocation-item">
    <div class="daily-provocation-item__video-container" v-if="provocation">
      <video
        class="daily-provocation-item__video"
        :src="videoUrl"
        preload="metadata"
        :controls="videoIsPlaying"
        :ref="`video-${provocation.id}`"
        @play="videoPlayed"
        @pause="videoPaused"
        @ended="videoEnded"
        :poster="posterUrl"
        controlsList="nodownload"
      ></video>
      <PlayButton v-if="!videoIsPlaying" @play="playVideo" />
    </div>
    <div class="daily-provocation-item__info">
      <div class="daily-provocation-item__info-author" v-if="provocation">
        <PersonBadge
          :userId="provocation.uploader.id"
          alt="Creator avatar"
          :name="`${provocation.uploader.firstName} ${provocation.uploader.lastName}`"
          :title="provocation.uploader.title"
          size="small"
        />
      </div>
      <h2 class="daily-provocation-item__info-title" v-if="provocation">
        {{ provocation.title }}
      </h2>
      <BaseButton
        v-if="provocation && !alreadyAnswered"
        class="daily-provocation-item__add"
        variant="primary"
        @click="goToRecording(provocation)"
      >
        <span class="base-button__icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.875 7.11L17 9.93V6.75L16.25 6H2.75L2 6.75V17.25L2.75 18H16.25L17 17.25V14.25L21.875 17.055L23 16.35V7.77L21.875 7.11ZM15.5 16.5H3.5V7.5H15.5V16.5ZM21.5 15L17 12.45V11.67L21.5 9V15Z"
              fill="#424242"
            />
          </svg>
        </span>
        <span class="daily-provocation-item__add-text">{{
          $t("daily_provocation.record_button")
        }}</span>
      </BaseButton>
      <div class="daily-provocation-item__answers" v-if="provocation">
        <div
          class="daily-provocation-item__answers-top"
          v-if="provocation.provocationAnswers.length"
        >
          <div class="daily-provocation-item__answers-title">
            {{ $t("daily_provocation.view_latest") }}
          </div>
        </div>
        <div
          class="daily-provocation-item__answers-list"
          v-if="provocation.provocationAnswers.length"
        >
          <VideoAnswersList
            :answers="provocation.provocationAnswers.slice(0, 6)"
            :provocation="provocation"
          />
          <button
            v-if="provocation.provocationAnswers.length > 5"
            class="daily-provocation-item__answers-view-all"
            @click="goToDetails(provocation.id)"
          >
            {{ $t("daily_provocation.view_all") }}
          </button>
        </div>
        <div
          class="daily-provocation-item__answers-empty"
          v-if="!provocation.provocationAnswers.length"
        >
          {{ $t("daily_provocation.first_provocation") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PROVOCATION_BY_ID from "@/graphql/provocations/queries/ProvocationById.graphql";
import PlayButton from "@/components/reusables/PlayButton";
import PersonBadge from "@/components/reusables/PersonBadge";
import VideoAnswersList from "@/components/record-answer/VideoAnswersList";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";
import { convertTime, logTrackingData } from "@/service/trackingServices";

export default {
  name: "DailyProvocationItem",
  components: {
    PlayButton,
    PersonBadge,
    VideoAnswersList,
  },
  props: ["id"],
  data() {
    return {
      provocation: null,
      videoIsPlaying: false,
      showSubscribeTooltip: false,
      videoUrl: "",
      posterUrl: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
    alreadyAnswered() {
      const users = this.provocation.provocationAnswers.map((answer) => {
        return answer.user.username;
      });
      return users.includes(this.getUsername);
    },
  },
  methods: {
    goToSubscriptions() {
      const userRegistrationInput = {
        emailAddress: this.getNJUser.email,
        njProductId: null,
      };
    
      this.$router.push({
        name: "payment.subscriptions",
        params: {
          userRegistrationInput: userRegistrationInput,
          disabledFreeTrial: true,
        },
      });
    },
    videoPlayed(event) {
      logTrackingData({
        section: "HOME",
        belongsToSection: "PROVOCATION",
        activityType: "VIDEO_PLAYED",
        contentType: "VIDEO",
        contentId: this.id,
        contentLength: convertTime(event.target.duration),
        contentStartStopTime: convertTime(event.target.currentTime),
        contentCompleted: false,
      });
    },
    videoPaused(event) {
      if (event.target.duration !== event.target.currentTime) {
        logTrackingData({
          section: "HOME",
          belongsToSection: "PROVOCATION",
          activityType: "VIDEO_PAUSED",
          contentType: "VIDEO",
          contentId: this.id,
          contentLength: convertTime(event.target.duration),
          contentStartStopTime: convertTime(event.target.currentTime),
          contentCompleted: false,
        });
      }
    },
    async loadThumbnailUrl(thumbnailId) {
      this.posterUrl = await createS3ThumbnailUrl(thumbnailId, 420, 315);
    },
    loadVideo(provocationId) {
      const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
      this.$http
        .get(`${baseUrl}/content/provocation/${provocationId}`)
        .then((response) => {
          this.videoUrl = response.data;
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_LOAD_VIDEO",
            this.$t("error.home.provocation_video_error")
          );
        });
    },
    playVideo() {
      this.$refs[`video-${this.provocation.id}`].play();
      this.videoIsPlaying = true;
    },
    videoEnded(event) {
      this.$refs[`video-${this.provocation.id}`].load();
      this.videoIsPlaying = false;

      logTrackingData({
        section: "HOME",
        belongsToSection: "PROVOCATION",
        activityType: "VIDEO_PAUSED",
        contentType: "VIDEO",
        contentId: this.id,
        contentLength: convertTime(event.target.duration),
        contentStartStopTime: convertTime(event.target.currentTime),
        contentCompleted: true,
      });
    },
    goToRecording(provocation) {
      this.$router.push({
        name: "recordAnswer",
        params: {
          provocation,
        },
      });
    },
    goToDetails(id) {
      logTrackingData({
        section: "HOME",
        belongsToSection: "PROVOCATION_ANSWERS",
        activityType: "VIEW_ALL",
        contentType: "PROVOCATION",
        contentId: id,
      });

      this.$router.push({ name: "provocation", params: { id } });
    },
    pauseVideo() {
      this.$refs[`video-${this.provocation.id}`].pause();
    },
    hideSubscriberTooltip() {
      const box = document.querySelector(".daily-provocation-item__add");
    
      if (box) {
        if (!box.contains(event.target)) {
          this.showSubscribeTooltip = false;
        }
      }
    },
  },
  apollo: {
    provocation: {
      query: PROVOCATION_BY_ID,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.getNJUser;
      },
      update(data) {
        this.loadThumbnailUrl(data.provocation.thumbnailId);
        this.loadVideo(data.provocation.id);
        return data.provocation;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_PROVOCATION",
          this.$t("error.library.provocation.fetch_error")
        );
      },
    },
  },
  mounted() {
    document.addEventListener("click", this.hideSubscriberTooltip);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideSubscriberTooltip);
  },
};
</script>
<style lang="scss" scoped>
.daily-provocation-item {
  display: flex;
  width: 100%;

  @media (max-width: $laptop-max) {
    display: block;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    max-width: 330px;
    margin-left: 25px;

    @media (max-width: $laptop-max) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 40px;
      margin-left: 0;
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: var(--font-weight-medium);
      margin-bottom: auto;

      @media (max-width: $laptop-max) {
        margin-bottom: 30px;
      }
    }

    &-author {
      margin-bottom: 8px;
    }

    .base-button {
      display: inline-flex;
      align-items: center;
      height: 42px;

      &__icon {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
  
  &__trial-tooltip {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    width: 205px;
    text-align: center;
    white-space: normal;
    color: var(--nj-gray-5);
    font-size: 14px;
    line-height: 18px;
    border-radius: 10px;
    border: 1px solid var(--nj-gray-2);
    background-color: var(--nj-gray-1);
    z-index: 3;
    
    &:before {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%) rotate(135deg);
      content: "";
      width: 20px;
      height: 20px;
      background-color: var(--nj-gray-1);
      border: 1px solid var(--nj-gray-2);
      z-index: 1;
    }
    
    &-inner {
      position: relative;
      z-index: 2;
      padding: 16px 10px;
      border-radius: 10px;
      background-color: var(--nj-gray-1);
    }
    
    a {
      color: var(--nj-blue);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__video {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    height: 315px;
    //object-fit: cover;

    @media (max-width: $laptop-max) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &-container {
      position: relative;
      overflow: hidden;
      flex-grow: 1;
      max-width: 420px;
      width: 100%;
      background: var(--nj-black);
      border-radius: 14px;

      @media (max-width: $laptop-max) {
        max-width: 100%;
        margin-bottom: 35px;
      }

      @media (max-width: $laptop-max) {
        padding-top: 75% !important;
      }

      ::v-deep {
        .play-button svg {
          width: 39px;
          height: 39px;
        }
      }
    }
  }

  &__answers {
    max-width: 670px;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 5px;
    }

    &-empty {
      font-size: 14px;
      line-height: 19px;
    }

    &-view-all {
      font-size: 9px;
      line-height: 11px;
      text-decoration: underline;
      margin-left: 15px;
    }

    &-list {
      display: flex;
      align-items: center;
    }

    ::v-deep {
      .video-answer-person {
        width: 32px;
        height: 32px;
      }

      .play-button {
        width: 10px;
        height: 10px;
      }

      .video-answers-list {
        margin: 0 -5px;

        &__item {
          padding: 5px;
        }
      }
    }
  }

  &__add {
    margin-bottom: 25px;
    padding: 12px 18px !important;

    &-text {
      font-weight: var(--font-weight-medium);
    }
  }
}
</style>
