<template>
  <div class="all-notifications-popup base-modal">
    <BaseButton
      class="all-notifications-popup__button"
      variant="secondary"
      @click="openPopup"
    >
      {{ $t("notifications.see_all_btn") }}
    </BaseButton>
    <div
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      class="all-notifications-popup__dialog base-modal__dialog"
    >
      <div
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
        class="all-notifications-popup__inner base-modal__inner"
      >
        <div class="all-notifications-popup__head base-modal__head">
          <CloseDialogButton
            class="all-notifications-popup__close"
            @click="closePopup"
          />
          <h1 class="base-modal__head-title">
            {{ $t("notifications.title") }}
          </h1>
          <div
            class="all-notifications-popup__settings"
            @click="goToAppSettings"
          >
            <WheelIcon />
          </div>
        </div>
        <div
          :class="{
            'all-notifications-popup__body--empty': !notifications.length,
          }"
          class="all-notifications-popup__body base-modal__body"
          @scroll="scrollListener"
        >
          <ul v-if="notifications.length" class="all-notifications-popup__list">
            <li
              v-for="notification in notifications"
              :key="notification.id"
              :class="[
                'all-notifications-popup__list-item',
                {
                  'all-notifications-popup__list-item--selected':
                    notification.viewedAt === null,
                },
              ]"
            >
              <component
                :is="getNotificationComponent(notification)"
                :fullView="true"
                :notification="notification"
                @closeNotifications="closePopup"
                @deleteNotification="
                  deleteNotification($event, notification.id)
                "
              ></component>
            </li>
          </ul>
          <div v-else class="all-notifications-popup__loading">
            <DotSpin />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ALL_NOTIFICATIONS from "@/graphql/notifications/queries/AllNotifications.graphql";
import SUB_NOTIFICATIONS from "@/graphql/notifications/subscription/NotificationSubscription.graphql";
import DELETE_NOTIFICATION from "@/graphql/notifications/mutations/DeleteNotification.graphql";
import DotSpin from "@/components/reusables/DotSpin";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import NotificationInvitedToGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationInvitedToGroup";
import NotificationAcceptedInviteToGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationAcceptedInviteToGroup";
import NotificationDeclinedInviteToGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationDeclinedInviteToGroup";
import NotificationDeclinedJoinGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationDeclinedJoinGroup";
import NotificationApprovedJoinGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationApprovedJoinGroup";
import NotificationCanceledJoinGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationCanceledJoinGroup";
import NotificationJoinRequestGroup from "@/components/notifications/communityMemberGroupNotifications/NotificationJoinRequestGroup";
import NotificationCreatedGroupPost from "@/components/notifications/communityMemberGroupNotifications/NotificationCreatedGroupPost";
import NotificationConnect from "@/components/notifications/communityMemberConnectNotifications/NotificationConnect";
import NotificationConnectAccepted from "@/components/notifications/communityMemberConnectNotifications/NotificationConnectAccepted";
import NotificationConnectDeclined from "@/components/notifications/communityMemberConnectNotifications/NotificationConnectDeclined";
import NotificationLiveSessionCreated from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionCreated";
import NotificationLiveSessionDeleted from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionDeleted";
import NotificationLiveSessionUpdated from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionUpdated";
import NotificationLiveSessionReminder from "@/components/notifications/liveSessionsNotifications/NotificationLiveSessionReminder";
import NotificationUserCommentedOnPost from "@/components/notifications/communityFeedNotifications/NotificationUserCommentedOnPost";
import NotificationUserLikedComment from "@/components/notifications/communityFeedNotifications/NotificationUserLikedComment";
import NotificationUserLikedPost from "@/components/notifications/communityFeedNotifications/NotificationUserLikedPost";
import NotificationUserTaggedInComment from "@/components/notifications/communityFeedNotifications/NotificationUserTaggedInComment";
import NotificationUserTaggedInPost from "@/components/notifications/communityFeedNotifications/NotificationUserTaggedInPost";
import NotificationUserLikedProvocationAnswer from "@/components/notifications/provocationNotifications/NotificationUserLikedProvocationAnswer";
import NotificationUserLikedProvocation from "@/components/notifications/provocationNotifications/NotificationUserLikedProvocation";
import NotificationNewProvocationAnswer from "@/components/notifications/provocationNotifications/NotificationNewProvocationAnswer";
import NotificationUserLikedDailyFeed from "@/components/notifications/dailyFeedNotifications/NotificationUserLikedDailyFeed";
import NotificationAppointedAsAdmin from "@/components/notifications/communityMemberGroupNotifications/NotificationAppointedAsAdmin";
import WheelIcon from "@/components/reusables/Icons/WheelIcon";
import { mapGetters, mapMutations } from "vuex";
import errorHandler from "@/service/errorHandler";
import NotificationMeetUpCreated from "@/components/notifications/campusNotifications/NotificationMeetUpCreated";
import NotificationMeetUpUpdated from "@/components/notifications/campusNotifications/NotificationMeetUpUpdated";
import NotificationMeetUpDeleted from "@/components/notifications/campusNotifications/NotificationMeetUpDeleted";
import NotificationMeetUpReminder from "@/components/notifications/campusNotifications/NotificationMeetUpReminder";

let notificationsPage = 0;

export default {
  name: "AllNotificationsPopup",
  components: {
    CloseDialogButton,
    WheelIcon,
    DotSpin,
  },
  data() {
    return {
      notifications: [],
      dialog: false,
      tickInProgress: false,
      maxPage: 0,
      pageSize: 10,
      fetching: true,
      fetched: false,
    };
  },
  watch: {
    notifications() {
      this.$nextTick(() => {
        this.handlePageHeight();
      });
    },
    dialog(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        notificationsPage = 0;
        this.maxPage = 0;
        this.notifications = [];
        this.$apollo.subscriptions.notifications.refresh();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
  },
  methods: {
    ...mapMutations("common", ["setOpenedSettingsContainer"]),
    getNotificationComponent(notification) {
      if (notification.actionType === "INVITED_TO_GROUP") {
        return NotificationInvitedToGroup;
      } else if (notification.actionType === "INVITATION_ACCEPTED") {
        return NotificationAcceptedInviteToGroup;
      } else if (notification.actionType === "INVITATION_DECLINED") {
        return NotificationDeclinedInviteToGroup;
      } else if (notification.actionType === "REQUESTED_TO_JOIN") {
        return NotificationJoinRequestGroup;
      } else if (notification.actionType === "JOIN_REQUEST_APPROVED") {
        return NotificationApprovedJoinGroup;
      } else if (notification.actionType === "JOIN_REQUEST_DECLINED") {
        return NotificationDeclinedJoinGroup;
      } else if (notification.actionType === "JOIN_REQUEST_CANCELED") {
        return NotificationCanceledJoinGroup;
      } else if (notification.actionType === "REQUESTED_TO_CONNECT") {
        return NotificationConnect;
      } else if (notification.actionType === "CONNECTION_REQUEST_ACCEPTED") {
        return NotificationConnectAccepted;
      } else if (notification.actionType === "CONNECTION_REQUEST_DECLINED") {
        return NotificationConnectDeclined;
      } else if (notification.actionType === "LIVE_SESSION_CREATED") {
        return NotificationLiveSessionCreated;
      } else if (notification.actionType === "LIVE_SESSION_DELETED") {
        return NotificationLiveSessionDeleted;
      } else if (notification.actionType === "LIVE_SESSION_UPDATED") {
        return NotificationLiveSessionUpdated;
      } else if (notification.actionType === "LIVE_SESSION_START_REMINDER") {
        return NotificationLiveSessionReminder;
      } else if (notification.actionType === "D_USER_TAGGED_IN_A_POST") {
        return NotificationUserTaggedInPost;
      } else if (notification.actionType === "E_USER_TAGGED_IN_A_COMMENT") {
        return NotificationUserTaggedInComment;
      } else if (notification.actionType === "A_USER_LIKED_A_POST") {
        return NotificationUserLikedPost;
      } else if (notification.actionType === "C_USER_LIKED_A_COMMENT") {
        return NotificationUserLikedComment;
      } else if (notification.actionType === "B_USER_COMMENTED_ON_A_POST") {
        return NotificationUserCommentedOnPost;
      } else if (notification.actionType === "NEW_PROVOCATION_ANSWER_LIKE") {
        return NotificationUserLikedProvocationAnswer;
      } else if (notification.actionType === "NEW_PROVOCATION_LIKE") {
        return NotificationUserLikedProvocation;
      } else if (notification.actionType === "NEW_DAILY_FEED_LIKE") {
        return NotificationUserLikedDailyFeed;
      } else if (notification.actionType === "NEW_PROVOCATION_ANSWER") {
        return NotificationNewProvocationAnswer;
      } else if (notification.actionType === "MEMBER_BECOME_OWNER_OF_GROUP") {
        return NotificationAppointedAsAdmin;
      } else if (notification.actionType === "MEET_UP_CREATED") {
        return NotificationMeetUpCreated;
      } else if (notification.actionType === "MEET_UP_UPDATED") {
        return NotificationMeetUpUpdated;
      } else if (notification.actionType === "MEET_UP_DELETED") {
        return NotificationMeetUpDeleted;
      } else if (notification.actionType === "MEET_UP_START_REMINDER") {
        return NotificationMeetUpReminder;
      } else if (
        notification.actionType === "F_USER_CREATED_POST_IN_MEMBER_GROUP"
      ) {
        return NotificationCreatedGroupPost;
      }
    },
    deleteNotification(closePopup, notificationId) {
      this.$apollo
        .mutate({
          mutation: DELETE_NOTIFICATION,
          variables: {
            notificationId,
            userId: this.getNJUser.id,
          },
        })
        .then(() => {
          if (closePopup) {
            this.closePopup();
          } else {
            this.$emit("refetchNotifications");
          }
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DELETE_NOTIFICATION",
            this.$t("notifications.error.delete")
          );
        });
    },
    goToAppSettings() {
      this.closePopup();
      this.setOpenedSettingsContainer(2);
      this.$router
        .push({
          name: "profile.settings",
        })
        .catch(() => {});
    },
    openPopup() {
      this.dialog = true;
    },
    closePopup() {
      this.$emit("closeDropdown");
      this.dialog = false;
    },
    fetchMoreNotifications() {
      if (
        notificationsPage + 1 !== this.maxPage &&
        !(this.fetched && this.maxPage === 0)
      ) {
        notificationsPage++;

        this.fetching = true;

        let variables = {
          username: this.getUsername,
          page: {
            page: notificationsPage,
            pageSize: this.pageSize,
          },
        };

        this.$apollo.queries.notifications.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newNotifications = fetchMoreResult.page.notifications;
            this.maxPage = fetchMoreResult.page.maxPage;

            return {
              page: {
                __typename: fetchMoreResult.page.__typename,
                size: fetchMoreResult.page.size,
                maxPage: fetchMoreResult.page.maxPage,
                total: fetchMoreResult.page.total,
                notifications: [...this.notifications, ...newNotifications],
              },
            };
          },
        });
      }
    },
    scrollListener() {
      const scrollableArea = document.querySelector(
        ".all-notifications-popup__body"
      );
      if (
        scrollableArea.scrollTop + scrollableArea.clientHeight >=
          scrollableArea.scrollHeight - 300 &&
        !this.fetching
      ) {
        this.fetchMoreNotifications();
      }
    },
    handlePageHeight() {
      const scrollableArea = document.querySelector(
        ".all-notifications-popup__body"
      );
      if (
        scrollableArea.clientHeight === scrollableArea.scrollHeight &&
        !this.fetching
      ) {
        this.fetchMoreNotifications();
      }
    },
  },
  apollo: {
    notifications: {
      query: ALL_NOTIFICATIONS,
      variables() {
        return {
          username: this.getUsername,
          page: {
            page: notificationsPage,
            pageSize: this.pageSize,
          },
        };
      },
      skip() {
        return !this.dialog;
      },
      fetchPolicy: "network-only",
      update(data) {
        this.maxPage = data.page.maxPage;
        this.fetching = false;
        this.fetched = true;

        return data.page.notifications;
      },
      async error(error) {
        await errorHandler(
          error,
          "ERROR_NOTIFICATIONS",
          this.$t("notifications.error.fetch")
        );
      },
      // TODO PROD-1228: added subscription, plz review and approve (do we have to manipulate the cache anyways?
      // there are console warnings by Apollo saying we should use a merger... it's confusing me.)
      subscribeToMore: {
        document: SUB_NOTIFICATIONS,
        updateQuery(previousResult, { subscriptionData }) {
          if (subscriptionData.data.event.eventType === "CREATED") {
            const copyPreviousResult = [...previousResult.page.notifications];
            let newMaxPage = previousResult.page.maxPage;
            let newTotal = previousResult.page.total + 1;

            if (copyPreviousResult.length === 10) {
              copyPreviousResult.pop();
              newMaxPage += 1;
              this.maxPage += 1;
            }

            return {
              page: {
                maxPage: newMaxPage,
                size: previousResult.page.size,
                total: newTotal,
                notifications: [
                  subscriptionData.data.event.notification,
                  ...copyPreviousResult,
                ],
              },
            };
          } else if (subscriptionData.data.event.eventType === "DELETED") {
            let updatedResult = previousResult.page.notifications.filter(
              (notification) =>
                notification.id !== subscriptionData.data.event.notification.id
            );
            let newMaxPage = previousResult.page.maxPage;
            let newTotal = previousResult.page.total - 1;

            notificationsPage = 0;
            this.$apollo.queries.notifications.refetch();

            return {
              page: {
                maxPage: newMaxPage,
                size: previousResult.page.size,
                total: newTotal,
                notifications: [...updatedResult],
              },
            };
          }
        },
        skip() {
          return !this.getNJUser;
        },
      },
    },
  },
  mounted() {
    notificationsPage = 0;
    window.addEventListener("resize", this.handlePageHeight);
  },
  beforeDestroy() {
    notificationsPage = 0;
    window.removeEventListener("resize", this.handlePageHeight);
  },
};
</script>

<style lang="scss" scoped>
.all-notifications-popup {
  &__dialog {
    @media (max-width: 768px) {
      top: 58px;
      max-height: calc(100vh - 58px);
    }
  }

  &__close {
    position: absolute;
    left: 25px;
    cursor: pointer;
  }

  &__inner {
    overflow: hidden;
    text-align: left;
    width: 100%;
    max-width: 790px;
    max-height: 825px;

    @media (max-width: 768px) {
      max-height: 100%;
      border: none;
      border-radius: 0;
      max-width: 100%;
    }
  }

  &__head {
    padding-top: 7px;
    margin-bottom: 0;
  }

  &__settings {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
  }

  &__body {
    padding: 0 0 50px 0;
    overflow-y: auto;
    height: calc(100vh - 124px);

    &--empty {
      height: 300px !important;
    }

    @media (max-width: 768px) {
      overflow-y: auto;
      height: calc(100vh - 133px);

      &--empty {
        height: calc(100vh - 133px) !important;
      }
    }
  }

  &__approve-all {
    text-decoration: underline;
    font-size: 14px;
    line-height: 28px;
    font-weight: var(--font-weight-medium);
  }

  &__list {
    &-item {
      position: relative;
      padding: 11px 23px 11px 40px;
      transition: background-color 0.2s ease-out;
      border-bottom: 1px solid var(--nj-gray-2);

      @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &--selected {
        background-color: var(--nj-gray-1);
      }

      &:first-child {
        border-top: 1px solid var(--nj-gray-2);
      }
    }
  }

  &__loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
