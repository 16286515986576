<template>
  <img
    v-if="user || getUser"
    :style="avatarSize"
    alt="user image"
    :src="user || userId ? createThumbnailUrl : '/default-user-image.png'"
    class="participant-avatar"
    :class="{ 'participant-avatar--disabled': disabled }"
    @click="
      preventOpenProfile
        ? null
        : $store.commit('common/setProfileModalUserId', userId || user.id)
    "
  />
</template>

<script>
import GET_USER_IMAGE_ID from "@/graphql/user/queries/UserImageByUserId.graphql";
import errorHandler from "@/service/errorHandler";
import { mapGetters } from "vuex";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";

export default {
  name: "UserAvatar",
  props: {
    preventOpenProfile: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    size: {
      type: [String, Number],
    },
    userId: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarImageBase64: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
    avatarSize() {
      return {
        "--size": this.size + "px",
      };
    },
    createThumbnailUrl() {
      let imageUrl;
      let imageId;

      if (this.user) {
        imageId = this.user.avatarStorageId;
      } else if (this.userId && this.getUser) {
        imageId = this.getUser.avatarStorageId;
      }
      if (!imageId) {
        imageId = "no-avatar-image";
      }
      imageUrl = createS3ThumbnailUrl(imageId, this.size, this.size);
      return imageUrl;
    },
  },
  apollo: {
    getUser: {
      query: GET_USER_IMAGE_ID,
      variables() {
        return {
          id: this.userId,
        };
      },
      fetchPolicy: "network-only",
      skip() {
        return !this.userId;
      },
      update(data) {
        // console.log(data);
        return data.userImageByUserId;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_IMAGE_ID",
          this.$t("error.user.avatar_id_fetch_error")
        );
      },
    },
  },
};
</script>

<style scoped lang="scss">
.participant-avatar {
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: transparent;

  &--disabled {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
  }
}
</style>
