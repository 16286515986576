<template>
  <div class="what-is-new-program" v-if="program">
    <div class="what-is-new-program__inner">
      <div class="what-is-new-program__image">
        <img
          :src="
            program.mainImageIdThumbnailView &&
            program.mainImageIdThumbnailView !== 'banner'
              ? createThumbnailUrl(program.mainImageIdThumbnailView)
              : createThumbnailUrl(program.mainImageIdBannerView)
          "
          alt="thumbnail"
        />
      </div>
      <div class="what-is-new-program__info">
        <div class="what-is-new-program__info-top">
          <h3 class="what-is-new-program__info-type">
            {{ $t("what_is_new.type_program") }}
          </h3>
          <div class="what-is-new-program__admin" v-if="isContentManager">
            <WhatIsNewAdminMenu
              :itemId="item.id"
              :firstItem="firstItem"
              :lastItem="lastItem"
              @moveWhatsNew="moveWhatsNew"
              @updateCarousel="updateCarousel"
              @error="displayError"
            />
          </div>
        </div>
        <div class="what-is-new-program__info-title">
          {{ program.title }}
        </div>
        <div class="what-is-new-program__info-date">
          Starting: {{ startDate }} ({{ weeks }}
          {{ weeks > 1 ? "weeks" : "week" }})
        </div>
      </div>
      <button class="what-is-new-program__btn" @click="goToItem">
        <ArrowCircleIcon direction="right" />
      </button>
    </div>
  </div>
</template>

<script>
import PROGRAM_BY_ID from "@/graphql/programs/queries/getProgramById.graphql";
import CHECK_BOOKED_PROGRAM from "@/graphql/programs/queries/CheckBookedProgram.graphql";
import ArrowCircleIcon from "@/components/reusables/Icons/ArrowCircleIcon";
import { mapGetters } from "vuex";
import WhatsNewItemMixin from "@/components/mixins/WhatsNewItemMixin";
import { applyUserTimezone, getFormattedDate } from "@/service/datetimeUtil";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";

const WhatIsNewAdminMenu = () =>
  import("@/components/what-is-new/WhatIsNewAdminMenu");

export default {
  name: "WhatIsNewLiveSession",
  components: {
    ArrowCircleIcon,
    WhatIsNewAdminMenu,
  },
  props: ["item", "firstItem", "lastItem"],
  mixins: [WhatsNewItemMixin],
  computed: {
    ...mapGetters("auth", [
      "isContentManager",
      "getNJUserTimezone",
      "getUsername",
    ]),
    startDate() {
      return getFormattedDate(this.program.startDate, this.getNJUserTimezone);
    },
    endDate() {
      return getFormattedDate(this.program.endDate, this.getNJUserTimezone);
    },
    weeks() {
      const startDate = applyUserTimezone(
        this.program.startDate,
        this.getNJUserTimezone
      );
      const endDate = applyUserTimezone(
        this.program.endDate,
        this.getNJUserTimezone
      );

      return Math.round((endDate - startDate) / 604800000);
    },
  },
  methods: {
    logNavigation() {
      logTrackingData({
        section: "HOME",
        belongsToSection: "WHATS_NEW",
        activityType: "CONTENT_PAGE",
        contentType: "PROGRAM",
        contentId: this.item.itemId,
        activityPerformedOn: "CONTENT",
      });
    },
    async checkBookedProgram(id) {
      return this.$apollo.query({
        query: CHECK_BOOKED_PROGRAM,
        variables: {
          username: this.getUsername,
          programId: id,
        },
      });
    },
    async goToItem() {
      let booked = await this.checkBookedProgram(this.item.itemId);

      this.logNavigation();

      if (booked.data.checkBookedProgram.isBooked) {
        this.$router.push({
          name: "campus.program.structure",
          params: { id: this.item.itemId },
        });
      } else {
        this.$router.push({
          name: "campus.program.overview",
          params: { id: this.item.itemId },
        });
      }
    },
  },
  apollo: {
    program: {
      query: PROGRAM_BY_ID,
      variables() {
        return {
          programId: this.item.itemId,
        };
      },
      update(data) {
        return data.program;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_PROGRAM",
          this.$t("error.program.load_fail")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.what-is-new-program {
  text-align: left;

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-right: 45px;
  }

  &__image {
    width: 72px;
    height: 72px;
    background-color: var(--nj-green);
    border-radius: 4px;
    overflow: hidden;
    margin-right: 16px;
    flex-shrink: 0;

    img {
      display: block;
      width: 72px;
      height: 72px;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__info {
    padding-top: 5px;
    flex-grow: 1;
    width: 100%;
    max-width: 227px;

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    &-type {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-gray-4);
      text-transform: uppercase;
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-date {
      font-size: 10px;
      line-height: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
