const paginationModules = [
  "roundtableQuestions",
  "roundtablesQuestioned",
  "signUpGrants",
  "applicationApprovals",
  "adminUsers",
  "emails",
  "categories",
];
const paginationModuleOptions = {
  namespaced: true,
  state: () => ({
    page: 1,
  }),
  mutations: {
    pagePlus(state) {
      state.page = state.page + 1;
    },
    pageMinus(state) {
      state.page = state.page - 1;
    },
    setPage(state, pageNum) {
      state.page = pageNum;
    },
  },
};
const generatePaginationModules = (paginationModules, storeOptions) => {
  const modules = {};

  paginationModules.forEach((module) => {
    modules[module] = { ...storeOptions };
  });

  return modules;
};

export default {
  namespaced: true,
  modules: {
    ...generatePaginationModules(paginationModules, paginationModuleOptions),
  },
};
