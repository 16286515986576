<template>
  <div
    :class="[
      'notification',
      'notification-created-group-post',
      { 'notification--full': fullView },
    ]"
    @click="goToGroup"
  >
    <div class="notification__avatar">
      <UserAvatar :size="40" :user="notification.issuer" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }} {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.posted_in_group", { groupName: groupTitle }) }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationCreatedGroupPost",
  components: { UserAvatar },
  mixins: [NotificationDurationMixin],
  props: ["notification", "fullView"],
  data() {
    return {
      groupTitle: "",
      groupId: "",
    };
  },
  methods: {
    goToGroup() {
      this.$emit("closeNotifications");
      this.$router.push({
        name: "group.details",
        params: { groupId: this.groupId },
      });
    },
  },
  async mounted() {
    if (this.notification && this.notification.metaData) {
      const groupTitle = this.notification.metaData.find((item) => {
        return item.key === "parentTitle";
      });
      if (groupTitle) {
        this.groupTitle = groupTitle.value;
      }

      const entityId = this.notification.metaData.find((item) => {
        return item.key === "parentId";
      });
      if (entityId) {
        this.groupId = entityId.value;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.notification-created-group-post {
  cursor: pointer;
}
</style>
