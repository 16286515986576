import { Role } from "@/components/security/Role";

const routes = [
  {
    path: "/library",
    component: () =>
      import(/* webpackChunkName: "library" */ "@/views/Library"),
    children: [
      {
        path: "",
        name: "library",
        redirect: { name: "library.all" },
      },
      {
        path: "all",
        component: () =>
          import(
            /* webpackChunkName: "library_all_tab" */ "@/components/library/tabs/AllTab"
          ),
        children: [
          {
            path: "",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            redirect: { name: "library.all.main" },
            name: "library.all",
          },
          {
            name: "library.all.main",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            path: "main",
            component: () =>
              import(
                /* webpackChunkName: "library_library_all" */ "@/components/library/tabs/LibraryAll"
              ),
          },
          {
            path: "readableCategories",
            name: "library.all.readableCategories",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "category_readables" */ "@/components/library/readable/ReadableCategories.vue"
              ),
          },
          {
            path: "readableCategoriesAll/:categoryId",
            name: "library.all.readableCategoriesAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "all_category_readables" */ "@/components/library/readable/ReadableListAll.vue"
                ),
          },
          {
            path: "videoCategories",
            name: "library.all.videoCategories",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "category_videos" */ "@/components/library/video/VideoCategories.vue"
              ),
          },
          {
            path: "videoCategoriesAll/:categoryId",
            name: "library.all.videoCategoriesAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "all_category_videos" */ "@/components/library/video/VideoListAll.vue"
                ),
          },
          {
            path: "audioCategories",
            name: "library.all.audioCategories",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "category_audios" */ "@/components/library/audio/AudioCategories.vue"
              ),
          },
          {
            path: "audioCategoriesAll/:categoryId",
            name: "library.all.audioCategoriesAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "all_category_audios" */ "@/components/library/audio/AudioListAll.vue"
                ),
          },
        ],
      },
      {
        path: "campusResources",
        component: () =>
          import(
            /* webpackChunkName: "campus_resources_tab" */ "@/components/library/campus-resources/CampusResources"
          ),
        children: [
          {
            path: "",
            redirect: { name: "library.campusResources.overView" },
            name: "library.campusResources",
          },
          {
            path: "overView",
            name: "library.campusResources.overView",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "campus_resources_overview" */ "@/components/library/campus-resources/CampusResourcesOverview"
              ),
          },
          {
            path: "campusResources/items/:campusItemId/:campusItemTitle",
            name: "library.campusResources.items",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "campus_resources_all" */ "@/components/library/campus-resources/CampusResourcesAll"
              ),
          },
          {
            path: "allOfAllCampusResources",
            name: "library.campusResources.allOfAll",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "campus_resources_all_of_all" */ "@/components/library/campus-resources/CampusResourcesAllAll"
              ),
          },
          {
            path: "allOfSavedCampusResources",
            name: "library.campusResources.allOfSaved",
            meta: { authorize: [Role.NEURAL_JAMMER] },
            component: () =>
              import(
                /* webpackChunkName: "campus_resources_all_of_saved" */ "@/components/library/campus-resources/CampusResourcessAllSaved"
              ),
          },
        ],
      },
      {
        path: "yourCollections",
        component: () =>
          import(
            /* webpackChunkName: "your_collections_tab" */ "@/components/library/collections/YourCollections"
          ),
        children: [
          {
            path: "",
            redirect: { name: "library.yourCollections.overView" },
            name: "library.yourCollections",
          },
          {
            path: "overView",
            component: () =>
              import(
                /* webpackChunkName: "your_collections_overview" */ "@/components/library/collections/CollectionsOverview"
              ),
            meta: { authorize: [Role.NEURAL_JAMMER] },
            name: "library.yourCollections.overView",
          },
          {
            path: "all/:collectionId",
            name: "library.yourCollections.all",
            component: () =>
              import(
                /* webpackChunkName: "your_collection_detail" */ "@/components/library/collections/CollectionAll"
              ),
            meta: { authorize: [Role.NEURAL_JAMMER] },
            props: true,
          },
          {
            path: "provocations/:collectionId",
            name: "library.yourCollections.provocations",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "your_collections_provocations" */ "@/components/library/collections/YourCollectionProvocations"
              ),
            meta: { authorize: [Role.NEURAL_JAMMER] },
          },
        ],
      },
      {
        path: "provocations",
        name: "library.provocations",
        component: () =>
          import(
            /* webpackChunkName: "provocations" */ "@/components/library/provocations/Provocations"
          ),
      },
      {
        path: "daily-feed",
        name: "library.dailyFeed",
        component: () =>
          import(
            /* webpackChunkName: "library_daily_feed" */ "@/components/library/daily-feed/DailyFeedAll"
          ),
      },
    ],
  },
  {
    path: "/video-series/:id",
    name: "videoSeries",
    component: () =>
      import(
        /* webpackChunkName: "video_series_details" */ "@/components/library/video/series/VideoSeriesDetails"
      ),
    props: true,
    meta: { authorize: [Role.NEURAL_JAMMER] },
  },
  {
    path: "/audio-series/:id",
    name: "audioSeries",
    component: () =>
      import(
        /* webpackChunkName: "audio_series_details" */ "@/components/library/audio/series/AudioSeriesDetails"
      ),
    props: true,
    meta: { authorize: [Role.NEURAL_JAMMER] },
  },
  {
    path: "/library/readable/:id",
    name: "readable",
    component: () =>
      import(
        /* webpackChunkName: "readable_details" */ "@/components/library/readable/ReadableDetails"
      ),
    props: true,
    meta: { authorize: [Role.NEURAL_JAMMER] },
  },
  {
    path: "/library/video/:id",
    name: "video",
    component: () =>
      import(
        /* webpackChunkName: "video_details" */ "@/components/library/video/VideoDetails"
      ),
    props: true,
    meta: { authorize: [Role.NEURAL_JAMMER] },
  },
  {
    path: "/library/audio/:id",
    name: "audio",
    component: () =>
      import(
        /* webpackChunkName: "audio_details" */ "@/components/library/audio/AudioDetails"
      ),
    props: true,
    meta: { authorize: [Role.NEURAL_JAMMER] },
  },
  {
    path: "/library/provocation/:id",
    name: "provocation",
    component: () =>
      import(
        /* webpackChunkName: "provocation_details" */ "@/components/library/provocations/ProvocationDetails"
      ),
    props: true,
    meta: { authorize: [Role.NEURAL_JAMMER] },
  },
  {
    path: "/library/video-answer/:id",
    name: "video-answer",
    component: () =>
      import(
        /* webpackChunkName: "video_answer_details" */ "@/components/record-answer/VideoAnswerDetails"
      ),
    props: true,
    meta: { authorize: [Role.NEURAL_JAMMER] },
  },
];

export default routes;
