<template>
  <div class="tags-popup">
    <div
      class="tags-popup__primary"
      id="primary-tags-target"
      @drop="onDropPrimary"
      @dragleave="onDragLeave('primary', $event)"
      @dragenter="onDragEnter('primary', $event)"
      @dragover="onDragOver('primary', $event)"
      :class="{ 'tags-popup__drag-hover': primaryDrag }"
    >
      <h3 v-if="primaryDrag && primaryTags.length >= 3">
        {{ $t("notebooks.tags.limit_message") }}
      </h3>
      <div
        v-else
        v-for="(tag, index) in primaryTags"
        :key="index"
        class="tags-popup__item"
        :id="tag.tag"
      >
        <div
          class="tags-popup__item-tag tags-popup__item-tag--primary"
          draggable="true"
          @dragstart="dragStart($event, 'primary')"
          :id="tag.tag"
          @touchmove="touchDragMove($event, tag)"
          @touchend="touchDragEnd($event, tag)"
        >
          <div class="tags-popup__item-tag-icons">
            <LockIcon
              @click="lockOnClick(tag.tag, 'unlock')"
              v-if="tag.locked"
            />
            <UnlockIcon
              @click="lockOnClick(tag.tag, 'lock')"
              v-if="!tag.locked"
            />
          </div>
          <h4>{{ tag.tag }}</h4>
          <TrashCan
            @click="deleteTag(tag.tag)"
            :size="17"
            color="white"
            class="tags-popup__item-tag-icons"
          />
        </div>
      </div>
    </div>

    <div class="tags-popup__divider">
      <div class="tags-popup__divider-dash"></div>
      <h3 class="tags-popup__divider-label">Drag & Drop</h3>
      <div class="tags-popup__divider-dash"></div>
    </div>

    <div
      class="tags-popup__secondary"
      :class="{ 'tags-popup__drag-hover': secondaryDrag }"
      id="secondary-tags-target"
      @drop="onDropSecondary"
      @dragleave="onDragLeave('secondary', $event)"
      @dragenter="onDragEnter('secondary', $event)"
      @dragover="onDragOver('secondary', $event)"
    >
      <div
        v-for="(tag, index) in secondaryTags"
        :key="index"
        class="tags-popup__item"
        :id="tag.tag"
      >
        <div
          :id="tag.tag"
          class="tags-popup__item-tag tags-popup__item-tag--secondary"
          draggable="true"
          @dragstart="dragStart($event, 'secondary')"
          @touchmove="touchDragMove($event, tag)"
          @touchend="touchDragEnd($event, tag)"
        >
          <h4>{{ tag.tag }}</h4>
          <TrashCan
            @click="deleteTag(tag.tag)"
            :size="14"
            color="white"
            class="tags-popup__item-tag-icons"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LockIcon from "@/components/reusables/Icons/LockIcon";
import UnlockIcon from "@/components/reusables/Icons/UnlockIcon";
import TrashCan from "@/components/reusables/Icons/TrashCan";

export default {
  name: "TagsPopup",
  components: { LockIcon, UnlockIcon, TrashCan },
  data() {
    return {
      primaryDrag: false,
      secondaryDrag: false,
      dragStartField: null,
    };
  },
  computed: {
    tags: {
      get() {
        return this.$store.state.notebook.note.tags;
      },
      set(value) {
        this.$store.commit("notebook/updateTags", value);
      },
    },
    primaryTags() {
      return this.tags.filter((tag) => tag.tagState === "PRIMARY");
    },
    secondaryTags() {
      return this.tags.filter((tag) => tag.tagState === "SECONDARY");
    },
  },
  methods: {
    deleteTag(tagName) {
      this.$emit("sendRawTagsData", tagName);
      this.$store.commit("notebook/updateTagState", {
        index: this.findIndexOfTag(tagName),
        tagState: "DELETED",
      });
      this.$store.commit("notebook/updateLockedState", {
        index: this.findIndexOfTag(tagName),
        lockedState: true,
      });
    },
    findIndexOfTag(tagName) {
      return this.tags.map((tagObj) => tagObj.tag).indexOf(tagName);
    },
    onDropPrimary(e) {
      e.preventDefault();
      this.dragStartField = null;
      this.primaryDrag = false;
      let draggedData = JSON.parse(e.dataTransfer.getData("text/plain"));
      if (
        draggedData.draggedFrom === "primary" ||
        this.primaryTags.length >= 3
      ) {
        return;
      }

      this.$store.commit("notebook/updateLockedState", {
        index: this.findIndexOfTag(draggedData.tagName),
        lockedState: true,
      });
      this.$store.commit("notebook/updateTagState", {
        index: this.findIndexOfTag(draggedData.tagName),
        tagState: "PRIMARY",
      });

      this.$emit("sendRawTagsData");
    },
    onDropSecondary(e) {
      e.preventDefault();
      this.dragStartField = null;
      this.secondaryDrag = false;
      let draggedData = JSON.parse(e.dataTransfer.getData("text/plain"));
      if (draggedData.draggedFrom === "secondary") {
        return;
      }

      this.$store.commit("notebook/updateLockedState", {
        index: this.findIndexOfTag(draggedData.tagName),
        lockedState: true,
      });

      this.$store.commit("notebook/updateTagState", {
        index: this.findIndexOfTag(draggedData.tagName),
        tagState: "SECONDARY",
      });

      this.$emit("sendRawTagsData");
    },
    dragStart(e, dragStart) {
      this.dragStartField = dragStart;
      e.dataTransfer.setData(
        "text/plain",
        JSON.stringify({ tagName: e.target.id, draggedFrom: dragStart })
      );
    },
    dragTargetEvent(e) {
      e.preventDefault();
    },
    onDragEnter(field, e) {
      // e.preventDefault()
      if (this.dragStartField === field) {
        return;
      } else {
        field === "primary"
          ? (this.primaryDrag = true)
          : (this.secondaryDrag = true);
      }
    },
    onDragLeave(field, e) {
      e.preventDefault();
      field === "primary"
        ? (this.primaryDrag = false)
        : (this.secondaryDrag = false);
    },
    onDragOver(field, e) {
      e.preventDefault();
      field === "primary"
        ? (this.primaryDrag = true)
        : (this.secondaryDrag = true);
    },
    lockOnClick(tagName, action) {
      if (action === "lock") {
        this.$store.commit("notebook/updateLockedState", {
          index: this.findIndexOfTag(tagName),
          lockedState: true,
        });
      } else if (action === "unlock") {
        this.$store.commit("notebook/updateLockedState", {
          index: this.findIndexOfTag(tagName),
          lockedState: false,
        });
      }
      this.$emit("sendRawTagsData");
    },

    // drag & drop for touchpad

    touchDragMove(e, tag) {
      let posX = e.changedTouches[0].clientX;
      let posY = e.changedTouches[0].clientY;

      let element = document.getElementById(`${tag.tag}`);
      let parentEl = document
        .getElementsByClassName("tags-popup")[0]
        .getBoundingClientRect();

      element.style.position = "absolute";
      element.style.inset = `${posY - parentEl.top}px auto auto ${posX - 30}px`;

      if (this.withinSecondaryDropoff(posX, posY)) {
        if (tag.tagState === "SECONDARY") {
          this.secondaryDrag = false;
          this.primaryDrag = false;
        } else {
          this.secondaryDrag = true;
          this.primaryDrag = false;
        }
      } else if (this.withinPrimaryDropoff(posX, posY)) {
        if (tag.tagState === "PRIMARY") {
          this.secondaryDrag = false;
          this.primaryDrag = false;
        } else {
          this.secondaryDrag = false;
          this.primaryDrag = true;
        }
      } else {
        this.secondaryDrag = false;
        this.primaryDrag = false;
      }
    },
    touchDragEnd(e, tag) {
      let posX = e.changedTouches[0].clientX;
      let posY = e.changedTouches[0].clientY;
      this.secondaryDrag = false;
      this.primaryDrag = false;

      let element = document.getElementById(`${tag.tag}`);
      element.style.position = "unset";
      if (
        (tag.tagState === "PRIMARY" && this.withinPrimaryDropoff(posX, posY)) ||
        (tag.tagState === "SECONDARY" &&
          this.withinSecondaryDropoff(posX, posY))
      ) {
        return;
      }

      if (this.withinPrimaryDropoff(posX, posY)) {
        if (tag.tagState === "PRIMARY") {
          return;
        } else {
          this.$store.commit("notebook/updateLockedState", {
            index: this.findIndexOfTag(tag.tag),
            lockedState: true,
          });
          this.$store.commit("notebook/updateTagState", {
            index: this.findIndexOfTag(tag.tag),
            tagState: "PRIMARY",
          });
          this.$emit("sendRawTagsData");
        }
      }

      if (this.withinSecondaryDropoff(posX, posY)) {
        if (tag.tagState === "SECONDARY") {
          return;
        } else {
          this.$store.commit("notebook/updateLockedState", {
            index: this.findIndexOfTag(tag.tag),
            lockedState: true,
          });

          this.$store.commit("notebook/updateTagState", {
            index: this.findIndexOfTag(tag.tag),
            tagState: "SECONDARY",
          });

          this.$emit("sendRawTagsData");
        }
      }
    },
    withinPrimaryDropoff(touchX, touchY) {
      let rect = document
        .getElementById("primary-tags-target")
        .getBoundingClientRect();
      return (
        touchX > rect.left &&
        touchX < rect.right &&
        touchY > rect.top &&
        touchY < rect.bottom
      );
    },
    withinSecondaryDropoff(touchX, touchY) {
      let rect = document
        .getElementById("secondary-tags-target")
        .getBoundingClientRect();
      return (
        touchX > rect.left &&
        touchX < rect.right &&
        touchY > rect.top &&
        touchY < rect.bottom
      );
    },
  },
};
</script>

<style scoped lang="scss">
.tags-popup {
  position: absolute;
  left: 0;
  bottom: 51px;
  box-shadow: rgb(0 0 0 / 20%) 0px -6px 6px -6px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--nj-white);
  width: 100%;
  z-index: 4;
  overflow: hidden;

  &__drag-hover {
    background-color: var(--nj-gray-2);
  }

  &__primary {
    display: flex;
    padding: 16px;
    margin-bottom: -8px;
  }

  &__secondary {
    display: flex;
    padding: 16px;
    margin-top: -8px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__divider {
    width: 100%;
    display: flex;
    color: var(--nj-gray-3);

    &-dash {
      height: 9px;
      width: 100%;
      overflow: hidden;
      border-bottom: var(--nj-gray-3) dashed 1px;
    }

    &-label {
      font-size: 12px;
      width: 67px;
      min-width: 67px;
      margin: 0 8px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding-right: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;

    &-tag {
      border-radius: 19px;
      transform: translate(0, 0);

      display: flex;
      padding: 2px 8px;
      justify-content: space-between;
      font-size: 14px;
      align-items: center;

      &:hover {
        .tags-popup__item-tag-icons {
          display: flex;
        }
      }

      h4 {
        white-space: nowrap;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 170px;
        padding: 0 10px 0 4px;
      }

      &-icons {
        display: none;

        svg:first-child {
          margin-right: 4px;
        }
      }

      &--primary {
        background-color: var(--nj-gray-5);
      }

      &--secondary {
        background-color: var(--nj-gray-2);
        color: var(--nj-gray-5);

        h4 {
          color: var(--nj-gray-5);
        }
      }
    }
  }
}
</style>
