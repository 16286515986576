<template>
  <div
    :class="[
      'notification-appointed-as-admin',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="goToGroup"
  >
    <div class="notification__avatar">
      <UserAvatar :size="40" :user="notification.issuer" />
    </div>
    <div class="notification__text" v-if="group">
      <p>
        <b>
          {{ notification.issuer.firstName }} {{ notification.issuer.lastName }}
        </b>
        {{
          $t("notifications.appointed_as_admin", {
            groupName: group.name,
          })
        }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import GET_GROUP_BY_ID from "@/graphql/member-group/queries/MemberGroupById.graphql";
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";
import errorHandler from "@/service/errorHandler";

export default {
  name: "NotificationAppointedAsAdmin",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  data() {
    return {
      group: null,
    };
  },
  methods: {
    goToGroup() {
      this.$emit("closeNotifications");

      this.$router.push({
        name: "group.details",
        params: { groupId: this.notification.entityId },
      });
    },
    async getGroupById() {
      return this.$apollo
        .query({
          query: GET_GROUP_BY_ID,
          variables: {
            memberGroupId: this.notification.entityId,
          },
        })
        .then((data) => {
          this.group = data.data.memberGroup;
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_FETCHING_GROUP",
            this.$t("error.group.fetch_by_id_error")
          );
        });
    },
  },
  async mounted() {
    await this.getGroupById();
  },
};
</script>

<style scoped>
.notification-appointed-as-admin {
  cursor: pointer;
}
</style>
