<template>
  <div class="snippet" data-title=".dot-spin">
    <div class="stage">
      <div class="dot-spin" :class="{ 'dot-spin--dark': dark }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DotSpin",
  props: {
    dark: {
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 var(--nj-gray-2), 12.72984px -12.72984px 0 0 var(--nj-gray-2),
    18px 0 0 0 var(--nj-gray-2), 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;

  &--dark {
    animation: dotSpinDark 1.5s infinite linear;
    box-shadow: 0 -18px 0 0 var(--nj-gray-4), 12.72984px -12.72984px 0 0 var(--nj-gray-4),
      18px 0 0 0 var(--nj-gray-4), 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
      0 18px 0 0 rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
      -18px 0 0 0 rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotSpinDark {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 var(--nj-gray-4), 12.72984px -12.72984px 0 0 var(--nj-gray-4),
      18px 0 0 0 var(--nj-gray-4), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 var(--nj-gray-4), 18px 0 0 0 var(--nj-gray-4),
      12.72984px 12.72984px 0 0 var(--nj-gray-4), 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 0 var(--nj-gray-4), 12.72984px 12.72984px 0 0 var(--nj-gray-4),
      0 18px 0 0 var(--nj-gray-4),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 var(--nj-gray-4),
      0 18px 0 0 var(--nj-gray-4), -12.72984px 12.72984px 0 0 var(--nj-gray-4),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 var(--nj-gray-4),
      -12.72984px 12.72984px 0 0 var(--nj-gray-4), -18px 0 0 0 var(--nj-gray-4),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 0 var(--nj-gray-4), -18px 0 0 0 var(--nj-gray-4),
      -12.72984px -12.72984px 0 0 var(--nj-gray-4);
  }
  75% {
    box-shadow: 0 -18px 0 0 var(--nj-gray-4),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 0 var(--nj-gray-4), -12.72984px -12.72984px 0 0 var(--nj-gray-4);
  }
  87.5% {
    box-shadow: 0 -18px 0 0 var(--nj-gray-4), 12.72984px -12.72984px 0 0 var(--nj-gray-4),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 0 var(--nj-gray-4);
  }
}
@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 var(--nj-gray-2), 12.72984px -12.72984px 0 0 var(--nj-gray-2),
      18px 0 0 0 var(--nj-gray-2), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 var(--nj-gray-2), 18px 0 0 0 var(--nj-gray-2),
      12.72984px 12.72984px 0 0 var(--nj-gray-2), 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 0 var(--nj-gray-2), 12.72984px 12.72984px 0 0 var(--nj-gray-2),
      0 18px 0 0 var(--nj-gray-2),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 var(--nj-gray-2),
      0 18px 0 0 var(--nj-gray-2), -12.72984px 12.72984px 0 0 var(--nj-gray-2),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 var(--nj-gray-2),
      -12.72984px 12.72984px 0 0 var(--nj-gray-2), -18px 0 0 0 var(--nj-gray-2),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 0 var(--nj-gray-2), -18px 0 0 0 var(--nj-gray-2),
      -12.72984px -12.72984px 0 0 var(--nj-gray-2);
  }
  75% {
    box-shadow: 0 -18px 0 0 var(--nj-gray-2),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 0 var(--nj-gray-2), -12.72984px -12.72984px 0 0 var(--nj-gray-2);
  }
  87.5% {
    box-shadow: 0 -18px 0 0 var(--nj-gray-2), 12.72984px -12.72984px 0 0 var(--nj-gray-2),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 0 var(--nj-gray-2);
  }
}
</style>
