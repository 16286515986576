<template>
  <div class="what-is-new-roundtable" v-if="roundtable">
    <div class="what-is-new-roundtable__inner">
      <div class="what-is-new-roundtable__image">
        <img
          :src="
            roundtable.mainImageIdThumbnailView &&
            roundtable.mainImageIdThumbnailView !== 'banner'
              ? createThumbnailUrl(roundtable.mainImageIdThumbnailView)
              : createThumbnailUrl(roundtable.mainImageIdBannerView)
          "
          alt="thumbnail"
        />
      </div>
      <div class="what-is-new-roundtable__info">
        <div class="what-is-new-roundtable__info-top">
          <h3 class="what-is-new-roundtable__info-type">
            {{ $t("what_is_new.type_roundtable") }}
          </h3>
          <div class="what-is-new-roundtable__admin" v-if="isContentManager">
            <WhatIsNewAdminMenu
              :itemId="item.id"
              :firstItem="firstItem"
              :lastItem="lastItem"
              @moveWhatsNew="moveWhatsNew"
              @updateCarousel="updateCarousel"
              @error="displayError"
            />
          </div>
        </div>
        <div class="what-is-new-roundtable__info-title">
          {{ roundtable.title }}
        </div>
        <div class="what-is-new-roundtable__info-date">
          {{ date }}, {{ startTime }} - {{ endTime }} ({{ getNJUserTimezone }})
        </div>
      </div>
      <button class="what-is-new-roundtable__btn" @click="goToItem">
        <ArrowCircleIcon direction="right" />
      </button>
    </div>
  </div>
</template>

<script>
import ROUNDTABLE_BY_ID from "@/graphql/roundtables/queries/getRoundtableById.graphql";
import ArrowCircleIcon from "@/components/reusables/Icons/ArrowCircleIcon";
import { mapGetters } from "vuex";
import WhatsNewItemMixin from "@/components/mixins/WhatsNewItemMixin";
import { getFormattedDate, getFormattedTime } from "@/service/datetimeUtil";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";

const WhatIsNewAdminMenu = () =>
  import("@/components/what-is-new/WhatIsNewAdminMenu");

export default {
  name: "WhatIsNewRoundtable",
  components: {
    ArrowCircleIcon,
    WhatIsNewAdminMenu,
  },
  props: ["item", "firstItem", "lastItem"],
  mixins: [WhatsNewItemMixin],
  computed: {
    ...mapGetters("auth", ["isContentManager", "getNJUserTimezone"]),
    date() {
      return getFormattedDate(this.roundtable.date, this.getNJUserTimezone);
    },
    startTime() {
      return getFormattedTime(
        this.roundtable.startTime,
        this.getNJUserTimezone
      );
    },
    endTime() {
      return getFormattedTime(this.roundtable.endTime, this.getNJUserTimezone);
    },
  },
  methods: {
    logNavigation() {
      logTrackingData({
        section: "HOME",
        belongsToSection: "WHATS_NEW",
        activityType: "CONTENT_PAGE",
        contentType: "ROUNDTABLE",
        contentId: this.item.itemId,
        activityPerformedOn: "CONTENT",
      });
    },
    goToItem() {
      this.logNavigation();

      this.$router.push({
        name: `campus.roundtable.overview`,
        params: { id: this.item.itemId },
      });
    },
  },
  apollo: {
    roundtable: {
      query: ROUNDTABLE_BY_ID,
      variables() {
        return {
          roundTableId: this.item.itemId,
        };
      },
      update(data) {
        return data.roundtable;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_ROUNDTABLE",
          this.$t("error.roundtable.fetch_error")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.what-is-new-roundtable {
  text-align: left;

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-right: 45px;
  }

  &__image {
    width: 72px;
    height: 72px;
    background-color: var(--nj-green);
    border-radius: 4px;
    overflow: hidden;
    margin-right: 16px;
    flex-shrink: 0;

    img {
      display: block;
      width: 72px;
      height: 72px;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__info {
    padding-top: 5px;
    flex-grow: 1;
    width: 100%;
    max-width: 227px;

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    &-type {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-gray-4);
      text-transform: uppercase;
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-date {
      font-size: 10px;
      line-height: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
