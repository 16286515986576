<template>
  <svg
    class="close-dialog-button"
    @click="$emit('click')"
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 1L1 15"
      :stroke="color"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 1L15 15"
      :stroke="color"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseDialogButton",
  props: {
    color: {
      type: String,
      default: "black",
    },
    size: {
      type: Number,
      default: 14,
    },
  },
};
</script>

<style lang="scss">
.close-dialog-button {
  cursor: pointer;
}
</style>
