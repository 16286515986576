import { Role } from "@/components/security/Role";

const routes = [
  {
    path: "/admin",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin"),
    children: [
      {
        path: "",
        name: "admin",
        redirect: { name: "admin.contributors" },
      },
      {
        path: "contributors",
        name: "admin.contributors",
        component: () =>
          import(
            /* webpackChunkName: "admin_contributors" */ "@/components/admin/contributors/Contributors"
          ),
        meta: {
          authorize: [Role.CONTENT_MANAGER, Role.SUPER_ADMIN, Role.USER_ADMIN],
        },
      },
      {
        path: "users",
        name: "admin.users",
        component: () =>
          import(
            /* webpackChunkName: "admin_users" */ "@/components/admin/users/UsersAdmin"
          ),
        meta: {
          authorize: [Role.SUPER_ADMIN, Role.USER_ADMIN],
        },
      },
      {
        path: "signup",
        name: "admin.signup",
        component: () =>
          import(
            /* webpackChunkName: "admin_signup" */ "@/components/admin/signup/SignupAdmin"
          ),
        meta: {
          authorize: [Role.SUPER_ADMIN, Role.USER_ADMIN],
        },
      },
      {
        path: "files",
        name: "admin.files",
        component: () =>
          import(
            /* webpackChunkName: "admin_files" */ "@/components/admin/Files"
          ),
        meta: {
          authorize: [Role.SUPER_ADMIN],
        },
      },
      {
        path: "emails",
        name: "admin.emails",
        component: () =>
          import(
            /* webpackChunkName: "admin_emails" */ "@/components/admin/Emails"
          ),
        meta: {
          authorize: [Role.SUPER_ADMIN, Role.USER_ADMIN],
        },
      },
      {
        path: "/admin/campus",
        component: () =>
          import(
            /*webpackChunkName: "admin_campus" */ "@/components/admin/campus/CampusAdmin"
          ),
        meta: {
          authorize: [Role.CONTENT_MANAGER, Role.SUPER_ADMIN, Role.USER_ADMIN],
        },
        children: [
          {
            path: "",
            name: "admin.campus.main",
            meta: { authorize: [Role.CONTENT_MANAGER] },
            redirect: { name: "admin.campus.roundtables" },
          },
          {
            path: "/admin/campus/roundtables",
            name: "admin.campus.roundtables",
            meta: {
              authorize: [
                Role.CONTENT_MANAGER,
                Role.SUPER_ADMIN,
                Role.USER_ADMIN,
              ],
            },
            component: () =>
              import(
                /* webpackChunkName: "admin_campus_roundtables" */ "@/components/admin/campus/RoundtablesQuestioned"
              ),
          },
          {
            path: "/admin/campus/roundtables/:id",
            name: "admin.campus.submissions",
            props: true,
            meta: {
              authorize: [
                Role.CONTENT_MANAGER,
                Role.SUPER_ADMIN,
                Role.USER_ADMIN,
              ],
            },
            component: () =>
              import(
                /* webpackChunkName: "admin_campus_submissions" */ "@/components/admin/campus/RoundtableSubmissions"
              ),
          },
          {
            path: "/admin/campus/question-details/:id",
            name: "admin.campus.question",
            meta: {
              authorize: [
                Role.CONTENT_MANAGER,
                Role.SUPER_ADMIN,
                Role.USER_ADMIN,
              ],
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "admin_campus_question" */ "@/components/admin/campus/RoundtableQuestionDetails"
              ),
          },
        ],
      },
      {
        path: "images-library",
        name: "admin.imagesLibrary",
        component: () =>
          import(
            /* webpackChunkName: "admin_images_library" */ "@/components/admin/image-library/ImagesLibrary"
          ),
        meta: {
          authorize: [Role.CONTENT_MANAGER, Role.SUPER_ADMIN, Role.USER_ADMIN],
        },
      },
      {
        path: "categorisation",
        name: "admin.categorisation",
        component: () =>
          import(
            /* webpackChunkName: "admin_categorisation" */ "@/components/admin/categorisation/Categorisation"
          ),
        meta: { authorize: [Role.CONTENT_MANAGER] },
      },
    ],
  },
  {
    path: "/contributor/:id",
    name: "contributor",
    component: () =>
      import(
        /* webpackChunkName: "contributor_page" */ "@/components/admin/contributors/ContributorDetails"
      ),
    props: true,
    meta: {
      authorize: [Role.CONTENT_MANAGER, Role.SUPER_ADMIN, Role.USER_ADMIN],
    },
  },
];

export default routes;
