<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_359_5788)">
      <g filter="url(#filter0_d_359_5788)">
        <path
          d="M11 5L6 9H2V15H6L11 19V5Z"
          stroke="white"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g filter="url(#filter1_d_359_5788)">
        <path
          d="M19.07 4.93018C20.9447 6.80545 21.9979 9.34853 21.9979 12.0002C21.9979 14.6518 20.9447 17.1949 19.07 19.0702M15.54 8.46018C16.4774 9.39781 17.004 10.6694 17.004 11.9952C17.004 13.321 16.4774 14.5925 15.54 15.5302"
          stroke="white"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_359_5788"
        x="-2.69995"
        y="2.2998"
        width="18.3999"
        height="23.4004"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_5788"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_5788"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_359_5788"
        x="10.8401"
        y="2.22998"
        width="15.8578"
        height="23.54"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_5788"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_5788"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_359_5788">
        <rect width="24" height="24" :fill="color" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SpeakerIcon",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>

<style scoped></style>
