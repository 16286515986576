import { mapGetters } from "vuex";
import { maxLength, required } from "vuelidate/lib/validators";
import createPreviewThumbnailUrl from "@/service/createPreviewThumbnailUrl";

const SubmitProvocationMixin = {
  watch: {
    thumbnailFile(newVal) {
      if (newVal) {
        this.form.selectedThumbnail = newVal;
      }
    },
  },
  data() {
    return {
      thumbnailImages: [],
      form: {
        title: this.title,
        thumbnailFile: null,
        selectedThumbnail: this.thumbnailId,
        fileName: "",
      },
      dialog: false,
      showUploadInput: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername"]),
    titleIsEmpty() {
      return this.$v.form.title.$dirty && !this.$v.form.title.required;
    },
    titleIsTooLong() {
      return this.$v.form.title.$dirty && !this.$v.form.title.maxLength;
    },
  },
  methods: {
    createImageUrl(id) {
      return createPreviewThumbnailUrl(id);
    },
    resetForm() {
      this.form = {
        title: this.title,
        thumbnailFile: null,
        selectedThumbnail: this.thumbnailId,
        fileName: "",
      };
      this.showUploadInput = false;
    },
    cancelUpload() {
      if (this.upload.uploadRequest) {
        this.upload.uploadRequest.abort();
      }
    },
    selectThumbnail(image) {
      this.form.selectedThumbnail = image;
      this.thumbnailFile = null;
    },
  },
  validations() {
    return {
      form: {
        title: { required, maxLength: maxLength(150) },
      },
    };
  },
};

export default SubmitProvocationMixin;
