<template>
  <div class="upload-file">
    <div class="uploading-progress-popup base-modal" v-if="uploadDialog">
      <div
        class="base-modal__dialog"
        :class="
          uploadDialog
            ? 'base-modal__dialog--open'
            : 'base-modal__dialog--close'
        "
      >
        <div
          class="base-modal__inner uploading-progress-popup__inner"
          :class="
            uploadDialog
              ? 'base-modal__inner--open'
              : 'base-modal__inner--close'
          "
        >
          <div class="base-modal__head">
            <CloseDialogButton
              v-if="cancelButton"
              class="base-modal__close"
              @click="cancelUpload"
            />
            <h3 class="base-modal__head-title">{{ title }}</h3>
          </div>
          <div class="base-modal__body">
            <div
              class="uploading-progress-popup__progress"
              v-if="showPercentage"
            >
              <div class="uploading-progress-popup__progress-wrap">
                <div
                  class="uploading-progress-popup__progress-bar"
                  :style="{ width: `${uploadPercentage}%` }"
                ></div>
              </div>
              <div class="uploading-progress-popup__progress-percents">
                {{ uploadPercentage }}%
              </div>
            </div>
            <div class="uploading-progress-popup__progress--indefinite" v-else>
              <DotSpin />
            </div>
            <p class="uploading-progress-popup__message body-1-book">
              {{ message }}
            </p>
            <div class="base-modal__actions--center">
              <div class="base-modal__actions-item">
                <BaseButton
                  @click="cancelUpload"
                  :disabled="!cancelButton"
                  :variant="variant === 'admin' ? 'admin' : 'primary'"
                >
                  {{ $t("default.cancel") }}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GET_UPLOAD_URL from "@/graphql/shared/queries/GetUploadUrl.graphql";
import errorHandler from "@/service/errorHandler";
import GET_UPLOAD_FILENAME from "@/graphql/provocations/queries/GetUploadedFileName.graphql";
import DotSpin from "@/components/reusables/DotSpin";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";

export default {
  name: "UploadFile",
  components: { CloseDialogButton, DotSpin },
  props: {
    variant: {
      type: String,
      default: "user",
    },
  },
  data() {
    return {
      uploadPercentage: 0,
      uploadKey: "",
      uploadRequest: undefined,
      getUploadFileNameInterval: null,
      newStorageId: null,
      resolve: null,
      reject: null,

      uploadDialog: false,
      title: "",
      message: "",
      showPercentage: false,
      cancelButton: false,
      requestTags: null,
    };
  },
  methods: {
    openDialog(title, message, showPercentage, cancelButton) {
      this.cancelButton = cancelButton;
      this.uploadDialog = true;
      this.showPercentage = showPercentage;
      this.title = title;
      this.message = message;
    },
    async upload(file, requestTags) {
      this.uploadPercentage = 0;
      if (requestTags) {
        this.requestTags = requestTags;
      }

      this.openDialog(
        this.$t("default.uploading"),
        this.$t("default.stay_on_page"),
        true,
        true
      );

      try {
        const result = await this.getUploadUrl(file.name);
        this.uploadKey = result.storageId;
        this.uploadRequest = this.createRequest(
          result.uploadUrl,
          this.calculateUploadProgress
        );
        this.uploadRequest.send(file);
      } catch (error) {
        await errorHandler(
          error,
          "UPLOAD_ERROR",
          this.$t("upload.errors.upload")
        );
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async getUploadUrl(currentFileName) {
      let result = await this.$apollo
        .query({
          query: GET_UPLOAD_URL,
          variables: {
            filename: currentFileName,
            tags: this.requestTags,
          },
          // important: ALWAYS request a fresh key, or else the progress subscription might be gone in the backend
          fetchPolicy: "network-only",
        })
        .catch((err) => {
          errorHandler(
            err,
            "GET_UPLOAD_URL_ERROR",
            this.$t("error.record_video.get_upload_url_error")
          );
        });
      return result.data.uploadUrl;
    },
    calculateUploadProgress(totalBytes, bytesTransferred) {
      this.uploadPercentage = Math.floor((bytesTransferred * 100) / totalBytes);
    },
    createRequest(uploadUrl, callback) {
      let req = new XMLHttpRequest();

      req.addEventListener("load", this.transferFinished);
      req.addEventListener("error", this.transferFailed);
      req.addEventListener("abort", this.transferCanceled);
      req.addEventListener("timeout", this.transferTimedout);

      req.upload.addEventListener(
        "progress",
        (event) => {
          callback(event.total, event.loaded);
        },
        false
      );

      req.timeout = 86400000;
      req.open("PUT", uploadUrl);

      // header must be added after open!
      // req.setRequestHeader('Access-Control-Allow-Origin', '*');
      if (this.requestTags) {
        let urlString = "";
        this.requestTags.forEach((tag, i) => {
          if (i !== 0) {
            urlString = `${urlString}&${tag.key}=${tag.value}`;
          } else {
            urlString = `${tag.key}=${tag.value}`;
          }
        });
        req.setRequestHeader("x-amz-tagging", urlString);
      }
      // req.setRequestHeader("tagging", "myrealtag=tagvalue");
      // req.setRequestHeader("Content-Type", "image/png");
      return req;
    },
    async transferFinished(event) {
      const status = event.currentTarget.status;
      if (status === 200) {
        this.message = this.$t("upload.converting_in_progress");
        this.showPercentage = false;

        this.newStorageId = await this.getUploadFileNamePromise(this.uploadKey);
        this.resolve(this.newStorageId);
        this.requestTags = null;
        this.uploadDialog = false;
      } else {
        await errorHandler(
          null,
          "ERROR_TRANSFER_FINISHED",
          this.$t("campus.admin.form.error_uploading_file")
        );
      }
      this.closeUploadDialog();
    },
    transferFailed() {
      this.closeUploadDialog();
      errorHandler(
        null,
        "ERROR_TRANSFER_FAILED",
        this.$t("campus.admin.form.error_uploading_file")
      );
    },
    transferTimedout() {
      this.closeUploadDialog();
      errorHandler(
        null,
        "ERROR_TRANSFER_TIMED_OUT",
        this.$t("campus.admin.form.error_uploading_file")
      );
    },
    transferCanceled() {
      this.closeUploadDialog();
    },
    getUploadFileNameQuery(uploadkey) {
      return this.$apollo
        .query({
          query: GET_UPLOAD_FILENAME,
          variables: {
            storageId: uploadkey,
          },
          fetchPolicy: "network-only",
        })
        .catch((err) => {
          clearInterval(this.getUploadFileNameInterval);
          errorHandler(
            err,
            "GET_UPLOAD_FILENAME_ERROR",
            this.$t("error.record_video.get_upload_filename_error")
          );
        });
    },
    getUploadFileNamePromise(uploadkey) {
      return new Promise((resolve, reject) => {
        this.getUploadFileNameInterval = setInterval(async () => {
          let response = await this.getUploadFileNameQuery(uploadkey);
          if (response.data.getUploadedFileName === null) {
            console.log("waiting for fetching new storage id for: ", uploadkey);
          } else {
            clearInterval(this.getUploadFileNameInterval);
            resolve(response.data.getUploadedFileName);
          }
        }, 2000);
      });
    },
    async cancelUpload() {
      clearInterval(this.getUploadFileNameInterval);
      clearInterval(this.getThumbnailsInterval);
      if (this.uploadRequest) {
        this.uploadRequest.abort();
      }
      this.closeUploadDialog();
    },
    openUploadDialog(title, message, showPercentage) {
      this.openDialog(title, message, showPercentage, false);
    },
    closeUploadDialog() {
      this.requestTags = null;
      this.uploadDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.uploading-progress-popup {
  &__inner {
    min-height: 202px;
    max-width: 412px;
    width: 100%;
  }

  &__body {
    ::v-deep p {
      margin: 0 !important;
      font-size: 16px;
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: 30px !important;
      }
    }
  }

  &__message {
    text-align: center;
    margin-top: 25px;
  }

  &__progress {
    margin: 20px 0 25px 0;

    &-wrap {
      width: 205px;
      height: 5px;
      margin: 0 auto;
      background-color: #eaeaea;
    }

    &-bar {
      height: 5px;
      background-color: var(--nj-green);
    }

    &-percents {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
    }

    &--indefinite {
      width: 100%;
      display: flex;
      justify-content: center;

      & > div {
        margin-top: 20px;
        margin-bottom: 25px;
      }
    }
  }
}
</style>
