<template>
  <div
    tabindex="0"
    ref="recordProvocationPopup"
    class="record-provocation-popup base-modal"
    @keydown.esc="closePopup"
  >
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="closePopup"
    >
      <div
        class="record-provocation-popup__inner base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="base-modal__head">
          <CloseDialogButton @click="closePopup" class="base-modal__close" />
          <h1 class="base-modal__head-title">
            {{ $t("new_provocation.record_title") }}
          </h1>
        </div>
        <div class="base-modal__body">
          <form
            class="record-provocation-popup__form base-modal__form base-form"
          >
            <div class="record-provocation-popup__form-inner">
              <div class="record-provocation-popup__description">
                <p class="record-provocation-popup__text">
                  {{ $t("new_provocation.description") }}
                </p>
                <FormTextarea
                  v-model="$v.form.title.$model"
                  :counter="150"
                  :hasErrors="titleIsEmpty || titleIsTooLong"
                >
                  <template v-slot:errors>
                    <p class="base-form__errors-item" v-if="titleIsEmpty">
                      {{ $t("new_provocation.error_title_required") }}
                    </p>
                    <p
                      class="base-form__errors-item"
                      v-else-if="titleIsTooLong"
                    >
                      {{ $t("new_provocation.error_title_length") }}
                    </p>
                  </template>
                </FormTextarea>
              </div>
              <p class="record-provocation-popup__tip">
                {{ $t("new_provocation.tip") }}
              </p>
            </div>

            <div class="record-provocation-popup__actions base-modal__actions">
              <div
                class="
                  base-modal__actions-item
                  record-provocation-popup__record
                "
              >
                <BaseButton variant="primary" @click="goToRecording()">
                  <span class="base-button__icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.875 7.11L17 9.93V6.75L16.25 6H2.75L2 6.75V17.25L2.75 18H16.25L17 17.25V14.25L21.875 17.055L23 16.35V7.77L21.875 7.11ZM15.5 16.5H3.5V7.5H15.5V16.5ZM21.5 15L17 12.45V11.67L21.5 9V15Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <span class="base-button__text">{{
                    $t("new_provocation.start_record_button")
                  }}</span>
                </BaseButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Confirm
      variant="primary"
      ref="confirmDiscard"
      :decline-button-text="$t('confirm_close.stay_on_page')"
      :accept-button-text="$t('default.discard')"
      :reverse-button-functionality="true"
    />
  </div>
</template>

<script>
import FormTextarea from "@/components/forms/FormTextarea";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import { validationMixin } from "vuelidate/src";
import { maxLength, required } from "vuelidate/lib/validators";
import Confirm from "@/components/reusables/Confirm";

export default {
  name: "RecordProvocationPopup",
  components: {
    Confirm,
    FormTextarea,
    CloseDialogButton,
  },
  mixins: [validationMixin],
  watch: {
    dialog() {
      if (this.dialog) {
        this.$refs.recordProvocationPopup.focus();
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  data() {
    return {
      form: {
        title: "",
      },
      dialog: false,
      resolve: null,
      reject: null,
    };
  },
  computed: {
    titleIsEmpty() {
      return this.$v.form.title.$dirty && !this.$v.form.title.required;
    },
    titleIsTooLong() {
      return this.$v.form.title.$dirty && !this.$v.form.title.maxLength;
    },
  },
  methods: {
    goToRecording() {
      this.$v.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      this.$router.push({
        name: "recordProvocation",
        params: {
          title: this.form.title,
        },
      });
    },
    async closePopup() {
      if (this.form.title) {
        if (
          !(await this.$refs.confirmDiscard.open(
            this.$t("new_provocation.unpublished.confirm.title"),
            this.$t("new_provocation.unpublished.confirm.message")
          ))
        ) {
          return;
        }
      }
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        title: "",
      };
      this.$v.$reset();
    },
    open() {
      this.dialog = true;
    },
  },
  validations() {
    return {
      form: {
        title: { required, maxLength: maxLength(150) },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.record-provocation-popup {
  color: var(--nj-black);

  &--hidden {
    background: transparent;

    .base-modal__dialog {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__inner {
    text-align: left;
    width: 100%;
    max-width: 520px;
  }

  &__form {
    &-inner {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
    }
  }

  &__text {
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    font-weight: var(--font-weight-medium);
    margin-top: 0 !important;

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }

  &__description {
    ::v-deep {
      .base-textarea__field {
        height: 75px;
      }
    }
  }

  &__tip {
    font-size: 14px;
    line-height: 19px;
    margin-top: 45px;
  }

  &__actions {
    margin-top: 50px;
  }

  ::v-deep {
    .form-textarea__field {
      height: 100px;
    }
  }

  .base-button {
    display: inline-flex;
    align-items: center;
    height: 42px;

    &__text {
      font-weight: var(--font-weight-medium);
    }

    &__icon {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}
</style>
