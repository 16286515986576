<template>
  <div
    class="daily-feed-all"
    :class="{ 'daily-feed-all--short-view': shortView }"
  >
    <div class="custom-container">
      <div class="daily-feed-all__inner">
        <div class="daily-feed-all__top">
          <div class="daily-feed-all__bar" v-if="!shortView">
            <div class="daily-feed-all__search">
              <SearchField @clearSearch="clearSearch" @input="debounceSearch" />
            </div>
            <div class="daily-feed-all__filters">
              <div class="daily-feed-all__filters-title">
                {{ $t("daily_feeds.filters.title") }}
              </div>
              <div class="daily-feed-all__filters-content">
                <FilterDropdown
                  :options="filters"
                  :value="currentFilter"
                  @input="onFilterChange"
                />
              </div>
            </div>
            <NewDailyFeedPopup
              ref="newFeed"
              @createdFeed="search"
              :section="section"
            />
          </div>
          <div class="daily-feed-all__nav">
            <div
              :class="[
                'daily-feed-all__nav-item',
                {
                  'daily-feed-all__nav-item--selected':
                    visibleOnMobile === 'video',
                },
              ]"
            >
              <BaseButton
                :variant="visibleOnMobile === 'video' ? 'primary' : 'secondary'"
                @click="visibleOnMobile = 'video'"
              >
                {{ $t("daily_feeds.library.mobile_video_btn") }}
              </BaseButton>
            </div>
            <div
              :class="[
                'daily-feed-all__nav-item',
                {
                  'daily-feed-all__nav-item--selected':
                    visibleOnMobile === 'audio',
                },
              ]"
            >
              <BaseButton
                :variant="visibleOnMobile === 'audio' ? 'primary' : 'secondary'"
                @click="visibleOnMobile = 'audio'"
              >
                {{ $t("daily_feeds.library.mobile_audio_btn") }}
              </BaseButton>
            </div>
            <div
              :class="[
                'daily-feed-all__nav-item',
                {
                  'daily-feed-all__nav-item--selected':
                    visibleOnMobile === 'readable',
                },
              ]"
            >
              <BaseButton
                :variant="
                  visibleOnMobile === 'readable' ? 'primary' : 'secondary'
                "
                @click="visibleOnMobile = 'readable'"
              >
                {{ $t("daily_feeds.library.mobile_readable_btn") }}
              </BaseButton>
            </div>
          </div>
        </div>
        <div
          class="daily-feed-all__content"
          v-show="!(noFeedsUploaded || noFeedsFound)"
        >
          <div class="daily-feed-all__feed">
            <div
              class="
                daily-feed-all__feed-column daily-feed-all__feed-column--video
              "
              :class="{
                'daily-feed-all__feed-column--mobile':
                  visibleOnMobile === 'video',
              }"
            >
              <div
                class="
                  daily-feed-all__feed-item daily-feed-all__feed-item--video
                "
                v-for="video in dailyFeedsVideo"
                :key="video.id"
              >
                <DailyFeedVideo
                  :color="video.assignedColor"
                  :item="video"
                  @openPopup="openPopup(video)"
                  @editFeed="goToEdit(video.id)"
                  @deleteFeed="deleteDailyFeed(video.id)"
                  :section="section"
                />
              </div>
              <div v-if="noVideoUploaded">
                {{ $t("daily_feeds.library.no_video_uploaded") }}
              </div>
              <div
                v-if="noVideoFound"
                v-html="$t('daily_feeds.library.no_video_found')"
              ></div>
            </div>
            <div
              class="
                daily-feed-all__feed-column
                daily-feed-all__feed-column--readable
              "
              :class="{
                'daily-feed-all__feed-column--mobile':
                  visibleOnMobile === 'readable',
              }"
            >
              <div
                class="
                  daily-feed-all__feed-item daily-feed-all__feed-item--readable
                "
                v-for="readable in dailyFeedsReadable"
                :key="readable.id"
              >
                <DailyFeedReadable
                  :color="readable.assignedColor"
                  :item="readable"
                  @openPopup="openPopup(readable)"
                  @editFeed="goToEdit(readable.id)"
                  @deleteFeed="deleteDailyFeed(readable.id)"
                  :section="section"
                />
              </div>
              <div v-if="noReadableUploaded">
                {{ $t("daily_feeds.library.no_readable_uploaded") }}
              </div>
              <div
                v-if="noReadableFound"
                v-html="$t('daily_feeds.library.no_readable_found')"
              ></div>
            </div>
            <div
              class="
                daily-feed-all__feed-column daily-feed-all__feed-column--audio
              "
              :class="{
                'daily-feed-all__feed-column--mobile':
                  visibleOnMobile === 'audio',
              }"
            >
              <div
                class="
                  daily-feed-all__feed-item daily-feed-all__feed-item--audio
                "
                v-for="audio in dailyFeedsAudio"
                :key="audio.id"
              >
                <DailyFeedAudio
                  :color="audio.assignedColor"
                  :item="audio"
                  @openPopup="openPopup(audio)"
                  @editFeed="goToEdit(audio.id)"
                  @deleteFeed="deleteDailyFeed(audio.id)"
                  :section="section"
                />
              </div>
              <div v-if="noAudioUploaded">
                {{ $t("daily_feeds.library.no_audio_uploaded") }}
              </div>
              <div
                v-if="noAudioFound"
                v-html="$t('daily_feeds.library.no_audio_found')"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="daily-feed-all__empty"
          v-show="noFeedsUploaded || noFeedsFound"
        >
          <div v-if="noFeedsUploaded">
            {{ $t("daily_feeds.library.no_feeds_uploaded") }}
          </div>
          <div
            v-if="noFeedsFound"
            v-html="$t('daily_feeds.library.no_feeds_found')"
          ></div>
        </div>
      </div>
    </div>

    <DailyFeedPopup
      v-if="popupViewer"
      :items="popupItems"
      :popup-viewer="popupViewer"
      :slider-start="popupStartPage"
      @closePopup="popupViewer = false"
      @fetchMoreReadables="fetchMoreReadables"
      @fetchMoreVideos="fetchMoreVideos"
      @fetchMoreAudios="fetchMoreAudios"
      :section="section"
      ref="popup"
    />
    <Confirm
      ref="deleteConfirmPopup"
      variant="primary"
      :accept-button-text="$t('default.delete')"
    />
    <EditDailyFeedPopup ref="editDailyFeedPopup" @editedFeed="search" />
  </div>
</template>

<script>
import DAILY_FEEDS from "@/graphql/daily-feeds/queries/DailyFeeds.graphql";
import DELETE_FEED from "@/graphql/daily-feeds/mutations/DeleteDailyFeed.graphql";
import FilterDropdown from "@/components/reusables/FilterDropdown";
import DailyFeedAudio from "@/components/library/daily-feed/DailyFeedAudio";
import DailyFeedReadable from "@/components/library/daily-feed/DailyFeedReadable";
import DailyFeedVideo from "@/components/library/daily-feed/DailyFeedVideo";
import SearchField from "@/components/reusables/SearchField";
import NewDailyFeedPopup from "@/components/library/daily-feed/NewDailyFeedPopup";
import DailyFeedPopup from "@/components/library/daily-feed/dailyFeedPopUpViewer/DailyFeedPopup";
import EditDailyFeedPopup from "@/components/library/daily-feed/EditDailyFeedPopup";
import Confirm from "@/components/reusables/Confirm";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";

let pageVideo = 0;
let pageAudio = 0;
let pageReadable = 0;

export default {
  name: "DailyFeedAll",
  components: {
    FilterDropdown,
    DailyFeedPopup,
    DailyFeedAudio,
    DailyFeedReadable,
    DailyFeedVideo,
    SearchField,
    NewDailyFeedPopup,
    EditDailyFeedPopup,
    Confirm,
  },
  props: {
    section: {
      type: String,
      default: "LIBRARY",
    },
    shortView: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getNJUser(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.trackNavigation();
      }
    },
  },
  data() {
    return {
      dailyFeedsReadable: [],
      dailyFeedsAudio: [],
      dailyFeedsVideo: [],
      visibleOnMobile: "video",
      debounce: null,
      maxPageAudio: null,
      maxPageVideo: null,
      maxPageReadable: null,
      searchText: "",
      popupViewer: false,
      popupStartPage: 0,
      popupItems: [],
      pageSize: 6,

      currentFilter: {
        label: this.$t("daily_feeds.filters.all"),
        value: null,
      },
      filters: [
        {
          label: this.$t("daily_feeds.filters.all"),
          value: null,
        },
        {
          label: this.$t("daily_feeds.filters.popular"),
          value: "POPULAR",
        },
        {
          label: this.$t("daily_feeds.filters.liked"),
          value: "LIKED",
        },
        {
          label: this.$t("daily_feeds.filters.yours"),
          value: "YOUR_FEED",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
    noVideoUploaded() {
      return (
        !this.dailyFeedsVideo.length &&
        !this.$apollo.loading &&
        !(this.searchText || this.currentFilter.value)
      );
    },
    noVideoFound() {
      return (
        !this.dailyFeedsVideo.length &&
        !this.$apollo.loading &&
        (this.searchText || this.currentFilter.value)
      );
    },
    noAudioUploaded() {
      return (
        !this.dailyFeedsAudio.length &&
        !this.$apollo.loading &&
        !(this.searchText || this.currentFilter.value)
      );
    },
    noAudioFound() {
      return (
        !this.dailyFeedsAudio.length &&
        !this.$apollo.loading &&
        (this.searchText || this.currentFilter.value)
      );
    },
    noReadableUploaded() {
      return (
        !this.dailyFeedsReadable.length &&
        !this.$apollo.loading &&
        !(this.searchText || this.currentFilter.value)
      );
    },
    noReadableFound() {
      return (
        !this.dailyFeedsReadable.length &&
        !this.$apollo.loading &&
        (this.searchText || this.currentFilter.value)
      );
    },
    noFeedsUploaded() {
      return (
        this.noVideoUploaded && this.noAudioUploaded && this.noReadableUploaded
      );
    },
    noFeedsFound() {
      return this.noVideoFound && this.noAudioFound && this.noReadableFound;
    },
  },
  methods: {
    trackNavigation() {
      if (this.getNJUser && this.section === "LIBRARY") {
        logTrackingData({
          section: "LIBRARY",
          activityType: "DAILY_FEEDS",
          activityPerformedOn: "SUBMENU",
        });
      }
    },
    async deleteDailyFeed(dailyFeedId) {
      if (
        !(await this.$refs.deleteConfirmPopup.open(
          this.$t("daily_feeds.delete.confirm.title"),
          this.$t("daily_feeds.delete.confirm.message")
        ))
      ) {
        return;
      }

      this.$apollo
        .mutate({
          mutation: DELETE_FEED,
          variables: {
            dailyFeedId,
          },
        })
        .then(() => {
          this.search();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DELETE_FEED",
            this.$t("error.library.daily_feeds.delete_error")
          );
        });
    },
    onFilterChange(val) {
      this.resetPages();
      this.currentFilter = val;
    },
    clearSearch() {
      this.resetPages();
      this.searchText = "";
    },
    debounceSearch(searchText) {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.search(searchText);
      }, 500);
    },
    search(searchText = "") {
      this.resetPages();
      this.searchText = searchText;
    },
    openPopup(item) {
      let idArray;
      let indexOfItemInFeeds;
      if (item.type === "AUDIO") {
        idArray = this.dailyFeedsAudio.map((i) => i.id);
        this.popupItems = this.dailyFeedsAudio;
      } else if (item.type === "VIDEO") {
        idArray = this.dailyFeedsVideo.map((i) => i.id);
        this.popupItems = this.dailyFeedsVideo;
      } else {
        idArray = this.dailyFeedsReadable.map((i) => i.id);
        this.popupItems = this.dailyFeedsReadable;
      }
      indexOfItemInFeeds = idArray.indexOf(item.id);
      this.popupStartPage = indexOfItemInFeeds;
      this.popupViewer = true;
    },
    updatePopupWithNewFeeds(newFeeds) {
      this.popupStartPage = this.popupItems.length;
      this.popupItems = [...this.popupItems, ...newFeeds];

      setTimeout(() => {
        this.$refs.popup.updateSlider(true);
      }, 100);
    },
    fetchMoreVideos() {
      if (pageVideo + 1 < this.maxPageVideo) {
        pageVideo++;

        let variables = {
          options: {
            contentType: "VIDEO",
            searchText: this.searchText,
            sort: this.currentFilter.value,
            username: this.getUsername,
          },
          page: {
            page: pageVideo,
            pageSize: this.pageSize,
          },
        };

        this.$apollo.queries.dailyFeedsVideo.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newFeeds = fetchMoreResult.page.dailyFeeds;
            this.maxPageVideo = fetchMoreResult.page.maxPage;
            if (this.popupViewer && newFeeds.length) {
              let newFeedsWithColor = this.assignColors(newFeeds, "VIDEO");
              this.updatePopupWithNewFeeds(newFeedsWithColor);
            }
            return {
              page: {
                __typename: previousResult.page.__typename,
                size: fetchMoreResult.page.size,
                maxPage: fetchMoreResult.page.maxPage,
                total: fetchMoreResult.page.total,
                dailyFeeds: [...previousResult.page.dailyFeeds, ...newFeeds],
              },
            };
          },
        });
      } else {
        if (this.popupViewer) {
          this.$refs.popup.updateSlider(false);
        }
      }
    },
    fetchMoreAudios() {
      if (pageAudio + 1 < this.maxPageAudio) {
        pageAudio++;

        let variables = {
          options: {
            contentType: "AUDIO",
            searchText: this.searchText,
            sort: this.currentFilter.value,
            username: this.getUsername,
          },
          page: {
            page: pageAudio,
            pageSize: this.pageSize,
          },
        };

        this.$apollo.queries.dailyFeedsAudio.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newFeeds = fetchMoreResult.page.dailyFeeds;
            this.maxPageAudio = fetchMoreResult.page.maxPage;
            if (this.popupViewer && newFeeds.length) {
              let newFeedsWithColor = this.assignColors(newFeeds, "AUDIO");
              this.updatePopupWithNewFeeds(newFeedsWithColor);
            }
            return {
              page: {
                __typename: previousResult.page.__typename,
                size: fetchMoreResult.page.size,
                maxPage: fetchMoreResult.page.maxPage,
                total: fetchMoreResult.page.total,
                dailyFeeds: [...previousResult.page.dailyFeeds, ...newFeeds],
              },
            };
          },
        });
      } else {
        if (this.popupViewer) {
          this.$refs.popup.updateSlider(false);
        }
      }
    },
    fetchMoreReadables() {
      if (pageReadable + 1 < this.maxPageReadable) {
        pageReadable++;

        let variables = {
          options: {
            contentType: "READABLE",
            searchText: this.searchText,
            sort: this.currentFilter.value,
            username: this.getUsername,
          },
          page: {
            page: pageReadable,
            pageSize: this.pageSize,
          },
        };

        this.$apollo.queries.dailyFeedsReadable.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newFeeds = fetchMoreResult.page.dailyFeeds;
            this.maxPageReadable = fetchMoreResult.page.maxPage;
            if (this.popupViewer && newFeeds.length) {
              let newFeedsWithColor = this.assignColors(newFeeds, "READABLE");
              this.updatePopupWithNewFeeds(newFeedsWithColor);
            }

            return {
              page: {
                __typename: previousResult.page.__typename,
                size: fetchMoreResult.page.size,
                maxPage: fetchMoreResult.page.maxPage,
                total: fetchMoreResult.page.total,
                dailyFeeds: [...previousResult.page.dailyFeeds, ...newFeeds],
              },
            };
          },
        });
      } else {
        if (this.popupViewer) {
          this.$refs.popup.updateSlider(false);
        }
      }
    },
    addScrollListeners() {
      const columns = ["video", "audio", "readable"];

      columns.forEach((column) => {
        const columnEl = document.querySelector(
          `.daily-feed-all__feed-column--${column}`
        );

        columnEl.addEventListener("scroll", (event) => {
          let element = event.target;

          if (
            element.scrollHeight - element.scrollTop ===
            element.clientHeight
          ) {
            if (column === "video") {
              this.fetchMoreVideos();
            } else if (column === "audio") {
              this.fetchMoreAudios();
            } else if (column === "readable") {
              this.fetchMoreReadables();
            }
          }
        });
      });
    },
    goToEdit(id) {
      this.$refs.editDailyFeedPopup.open(id);
    },
    resetPages() {
      pageVideo = 0;
      pageAudio = 0;
      pageReadable = 0;
    },
    assignColors(feedsArray, type) {
      let extensibleObj;
      let itemsWithAssignedColor = [];
      feedsArray.forEach((feed, i) => {
        if (i === 0 || i % 3 === 0) {
          if (type === "VIDEO") {
            extensibleObj = { ...feed, assignedColor: "#0564D2" };
          } else {
            extensibleObj = { ...feed, assignedColor: "#F4714E" };
          }
        } else if (i % 2 === 1) {
          if (type === "VIDEO") {
            extensibleObj = { ...feed, assignedColor: "#F4714E" };
          } else {
            extensibleObj = { ...feed, assignedColor: "#EC3797" };
          }
        } else {
          if (type === "VIDEO") {
            extensibleObj = { ...feed, assignedColor: "#EC3797" };
          } else {
            extensibleObj = { ...feed, assignedColor: "#0564D2" };
          }
        }
        itemsWithAssignedColor.push(extensibleObj);
      });
      return itemsWithAssignedColor;
    },
  },
  apollo: {
    dailyFeedsAudio: {
      query: DAILY_FEEDS,
      variables() {
        return {
          options: {
            contentType: "AUDIO",
            searchText: this.searchText,
            sort: this.currentFilter.value,
            username: this.getUsername,
          },
          page: {
            page: pageAudio,
            pageSize: this.pageSize,
          },
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        this.maxPageAudio = data.page.maxPage;
        return this.assignColors(data.page.dailyFeeds, "AUDIO");
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_DAILY_FEEDS_AUDIO",
          this.$t("error.library.daily_feeds.audios_fetch_error")
        );
      },
    },
    dailyFeedsVideo: {
      query: DAILY_FEEDS,
      variables() {
        return {
          options: {
            contentType: "VIDEO",
            searchText: this.searchText,
            sort: this.currentFilter.value,
            username: this.getUsername,
          },
          page: {
            page: pageVideo,
            pageSize: this.pageSize,
          },
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        this.maxPageVideo = data.page.maxPage;
        return this.assignColors(data.page.dailyFeeds, "VIDEO");
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_DAILY_FEEDS_VIDEO",
          this.$t("error.library.daily_feeds.video_fetch_error")
        );
      },
    },
    dailyFeedsReadable: {
      query: DAILY_FEEDS,
      variables() {
        return {
          options: {
            contentType: "READABLE",
            searchText: this.searchText,
            sort: this.currentFilter.value,
            username: this.getUsername,
          },
          page: {
            page: pageReadable,
            pageSize: this.pageSize,
          },
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        this.maxPageReadable = data.page.maxPage;
        return this.assignColors(data.page.dailyFeeds, "READABLE");
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_DAILY_FEED_READABLE",
          this.$t("error.library.daily_feeds.readables_fetch_error")
        );
      },
    },
  },
  created() {
    this.trackNavigation();
  },
  mounted() {
    this.resetPages();
    if (!this.shortView) {
      this.addScrollListeners();
    }
  },
  beforeDestroy() {
    this.resetPages();
  },
  beforeRouteLeave(to, from, next) {
    this.resetPages();
    next();
  },
};
</script>

<style lang="scss" scoped>
.daily-feed-all {
  padding: 24px 0 0;
  margin-bottom: -40px;

  @media (max-width: $tablet-max) {
    padding-top: 20px;
  }

  &--short-view {
    .daily-feed-all__feed-column {
      max-height: 750px;
      height: 100%;
      min-height: 460px;
    }

    .daily-feed-all__nav {
      margin-top: 0;
    }

    ::v-deep {
      .daily-feed-video {
        &__head {
          height: 168px;
        }

        &__thumbnail {
          img {
            height: 168px;
          }
        }
      }

      .daily-feed-audio {
        &__head {
          height: 274px;
        }

        &__thumbnail {
          img {
            height: 274px;
          }
        }
      }
    }
  }

  &__empty {
    margin-top: 40px;
  }

  &__search {
    max-width: 380px;
    width: 100%;

    @media (max-width: $tablet-max) {
      margin-right: 15px;
    }
  }

  &__feed {
    display: flex;
    align-items: flex-start;
    margin: 0 -8px;

    &-column {
      padding: 0 8px;
      width: 33.33333%;
      max-width: 33.33333%;
      flex-basis: 33.33333%;
      height: calc(100vh - 210px);
      overflow: scroll;
      scrollbar-width: none !important;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: $tablet-max) {
        display: none;

        &--mobile {
          display: block;
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;
          height: calc(100vh - 363px);
        }
      }
    }

    &-item {
      cursor: pointer;
      padding: 0 7px;
      @media screen and (max-width: $mobile-max) {
        padding: 0;
      }

      &:not(:first-child) {
        margin-top: 30px;
      }

      &--readable {
        &:nth-child(2n + 2) {
          ::v-deep .daily-feed-readable {
            background-color: var(--nj-gray-4);
          }
        }
      }
    }
  }

  &__bar {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    
    ::v-deep {
      .new-daily-feed-popup__trial-tooltip {
        left: 30%;
      }
    }

    @media (max-width: $tablet-max) {
      margin-bottom: 19px;
    }
  }

  &__nav {
    display: none;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    margin: 30px 0;

    ::v-deep {
      .base-button {
        &__primary {
          padding: 12px 28px !important;
        }

        &__secondary {
          padding: 10px 28px !important;
        }
      }
    }

    @media (max-width: $tablet-max) {
      display: flex;
      margin-top: 19px;
      margin-bottom: 0;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 20px;

        @media (max-width: $tablet-max) {
          margin-right: 10px;
        }
      }
    }
  }

  &__top {
    position: relative;

    @media (max-width: $tablet-max) {
      padding-bottom: 65px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;

    &-title {
      margin-right: 10px;
    }

    ::v-deep {
      .vs__dropdown-toggle {
        min-width: 135px;
      }

      .vs__dropdown-menu {
        left: auto;
        right: 0;
      }
    }

    @media (max-width: $tablet-max) {
      position: absolute;
      bottom: 20px;
      right: 0;
      margin-right: 0;
    }
  }
}
</style>
