<template>
  <div>
    <custom-authenticator :authConfig="authConfig"></custom-authenticator>
  </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import CustomAuthenticator from "@/components/amplify-customized/CustomAuthenticator";

export default {
  name: "Frontdoor",
  components: { CustomAuthenticator },
  props: ["signUp", "useremail"],
  data() {
    return {
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: true,
          header: "Sign in to Neural Jam",
        },
      },
    };
  },
  mounted() {
    AmplifyEventBus.$on("authState", (eventInfo) => {
      if (eventInfo === "signedIn") {
        this.$router.push({ name: "home" }, () => {});
      } else if (eventInfo === "signedOut") {
        this.$router.push({ name: "frontdoor" }, () => {});
      }
    });
  },
};
</script>
