import axios from "axios";
import errorHandler from "@/service/errorHandler";
import SEND_DATA from "@/graphql/notebooks/mutations/AddUserNoteTagsRawData.graphql";

const TagsMixin = {
  computed: {
    hasLocked() {
      let hasLocked = false;
      this.note.tags.forEach((tag) => {
        if (tag.locked) {
          hasLocked = true;
          return;
        }
      });
      return hasLocked;
    },
  },
  methods: {
    sendUserNoteRawData(deleteTag) {
      let acceptedTags = "";
      let topTags = "";
      let discardedTags = "";

      this.tags.forEach((t, i) => {
        acceptedTags += t.tag;
        if (i < this.tags.length) {
          acceptedTags += ", ";
        }
      });

      let primaryTags = this.tags.filter((tag) => tag.tagState === "PRIMARY");
      let secondaryTags = this.tags.filter(
        (tag) => tag.tagState === "SECONDARY"
      );
      primaryTags.forEach((t, i) => {
        topTags += t.tag;
        if (i < primaryTags.length) {
          topTags += ", ";
        }
      });
      secondaryTags.forEach((t, i) => {
        discardedTags += t.tag;
        if (i < secondaryTags.length) {
          discardedTags += ", ";
        }
      });

      let variables = {
        userId: this.getNJUser.id,
        noteText: this.$store.state.notebook.note.flatText,
        acceptedTags,
        topTags,
        discardedTags,
      };

      if (deleteTag) {
        // console.log("del tag true!");
        variables.deletedTags = deleteTag;
      }

      // console.log("variables: ", variables);
      this.$apollo
        .mutate({
          mutation: SEND_DATA,
          variables,
        })
        .then((data) => {
          console.log("data from sendData: ", data);
        })
        .catch((err) => {
          console.log("send data err: ", err);
        });

      this.fetchTags(this.$store.state.notebook.note.flatText);
    },
    async fetchTags(text) {
      if (this.tagsDisabled) {
        return;
      }
      let jsonBody = JSON.stringify({ sentText: text, selectTag: 1 });
      const url = process.env.VUE_APP_BACKEND_BASE_URL + "/tagging/keywords";
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: jsonBody,
        });

        let fetchedTags = response.data.result.Keyword;
        // console.log("ALL FETCHED TAGS: ", fetchedTags);
        if (fetchedTags) {
          let tagsArrayForStore = [];

          if (!this.note.tags || !this.note.tags.length || !this.hasLocked) {
            //
            // all shown tags are freshly loaded tags from the tagsAPI
            //
            // console.log(
            //   "all shown tags are freshly loaded tags from the tagsAPI"
            // );
            fetchedTags.forEach((tag) => {
              if (tagsArrayForStore.length < 3) {
                tagsArrayForStore.push({
                  tag,
                  locked: false,
                  tagState: "PRIMARY",
                });
              } else {
                tagsArrayForStore.push({
                  tag,
                  locked: false,
                  tagState: "SECONDARY",
                });
              }
            });
          } else {
            //
            // push locked tags into list of tags to show
            //

            // console.log("NOTE>TAGS: ", this.note.tags);
            // console.log("tagsArrForStore: ", tagsArrayForStore);
            this.note.tags.forEach((tag) => {
              if (tag.locked === true) {
                tagsArrayForStore.push({
                  locked: true,
                  tag: tag.tag,
                  tagState: tag.tagState,
                });
              }
            });
            // console.log(
            //   "push locked tags into list of tags to show",
            //   tagsArrayForStore
            // );

            // remove secondary tags that aren't in the list of fetched tags
            // this means that a tag previously marked (and locked) as secondary will disappear if its
            // no longer being returned by the tags API. If it  would once again be returned, then it
            // could again end up among the primary tags

            tagsArrayForStore
              .filter((t) => t.tagState === "SECONDARY")
              .forEach((secTag) => {
                if (!fetchedTags.includes(secTag.tag)) {
                  // console.log(
                  //   "fetched doesn't include this locked sec tag therefore will not be shown: ",
                  //   secTag
                  // );
                  let ind = tagsArrayForStore.indexOf(secTag.tag);
                  tagsArrayForStore.splice(ind, 1);
                }
              });

            // add only fetched tags that aren't equal to any locked ones
            // console.log(
            //   "add only fetched tags that aren't equal to any locked ones",
            //   fetchedTags.filter(
            //     (tag) => !tagsArrayForStore.some((t) => t.tag === tag)
            //   )
            // );

            fetchedTags
              .filter((tag) => !tagsArrayForStore.some((t) => t.tag === tag))
              .forEach((fetchedTag) => {
                // assign PRIMARY or SECONDARY to incoming tags
                if (
                  tagsArrayForStore.filter((t) => t.tagState === "PRIMARY")
                    .length < 3
                ) {
                  tagsArrayForStore.push({
                    tag: fetchedTag,
                    locked: false,
                    tagState: "PRIMARY",
                  });
                } else {
                  tagsArrayForStore.push({
                    tag: fetchedTag,
                    locked: false,
                    tagState: "SECONDARY",
                  });
                }
              });
          }

          this.$store.commit("notebook/updateTags", tagsArrayForStore);
        }
      } catch (error) {
        await errorHandler(
          { error },
          "FETCH_TAGS_ERROR",
          this.$t("notebooks.errors.error_fetch_tags")
        );
      }
    },
  },
};

export default TagsMixin;
