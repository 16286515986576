<template>
  <div class="language-switcher">
    <button
      class="language-switcher__button"
      @click="showMenu = !showMenu"
      ref="toggleOptionsButton"
    >
      <span class="language-switcher__flag">
        <img
          :src="`/language-flags/${selectedLanguage.country.toLowerCase()}.png`"
          :alt="`${selectedLanguage.country.toLowerCase()} flag`"
        />
      </span>
      <span class="language-switcher__button-text">
        {{ selectedLanguage.title }} ({{ selectedLanguage.country }})
      </span>
    </button>
    <ul
      class="language-switcher__menu"
      v-if="showMenu"
      v-closable="{
        exclude: ['toggleOptionsButton'],
        handler: 'closeMenuByClickOutside',
      }"
    >
      <li
        class="language-switcher__menu-item"
        :class="{
          'language-switcher__menu-item--selected':
            lang.code === selectedLanguage.code,
        }"
        v-for="lang in langs"
        :key="`Lang${lang.code}`"
        :value="lang.code"
        @click="selectLanguage(lang)"
      >
        <div class="language-switcher__flag">
          <img
            :src="`/language-flags/${lang.country.toLowerCase()}.png`"
            :alt="`${lang.country.toLowerCase()} flag`"
          />
        </div>
        <div class="language-switcher__menu-text">
          {{ lang.title }} ({{ lang.country }})
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import UPDATE_PREFERRED_LANG from "@/graphql/user/mutations/UpdatePreferredLanguage.graphql";
import errorHandler from "@/service/errorHandler";

export default {
  name: "LanguageSwitcher",
  data() {
    return {
      showMenu: false,
      selectedLanguage: {
        code: "en",
        title: "English",
        country: "US",
      },
      langs: [
        {
          code: "en",
          title: "English",
          country: "US",
        },
        {
          code: "de",
          title: "German",
          country: "DE",
        },
        {
          code: "es",
          title: "Spanish",
          country: "ES",
        },
        {
          code: "he",
          title: "Hebrew",
          country: "IL",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserPreferredLocale", "getUsername"]),
  },
  methods: {
    ...mapMutations("auth", ["setPreferredLang"]),
    selectLanguage(lang) {
      this.savePreferredLang(lang.code);
      this.setPreferredLang(lang.code);

      this.$i18n.locale = lang.code;
      this.selectedLanguage = lang;
      this.showMenu = false;
    },
    preselectLanguage() {
      const savedLang = this.getUserPreferredLocale;

      this.savePreferredLang(savedLang);
      this.$i18n.locale = savedLang;
      this.selectedLanguage = this.langs.find(
        (lang) => lang.code === savedLang
      );
    },
    closeMenuByClickOutside() {
      this.showMenu = false;
    },
    savePreferredLang(lang) {
      localStorage.setItem("nj-app-lang", lang);

      if (this.getUsername) {
        this.$apollo
          .mutate({
            mutation: UPDATE_PREFERRED_LANG,
            variables: {
              username: this.getUsername,
              preferredLocale: lang || "en",
            },
          })
          .catch((error) => {
            errorHandler(
              error,
              "ERROR_UPDATE_LANG",
              this.$t("language_picker.error_update_lang")
            );
          });
      }
    },
  },
  mounted() {
    this.preselectLanguage();
  },
};
</script>

<style scoped lang="scss">
.language-switcher {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-text {
      font-size: 12px;
      line-height: 24px;
      color: var(--nj-gray-6);
      white-space: nowrap;
    }
  }
  &__flag {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
  &__menu {
    position: absolute;
    bottom: calc(100% + 15px);
    left: 0;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background: var(--nj-white);

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 16px;
      background: var(--nj-white);

      &:hover {
        background: var(--nj-gray-2);
      }

      &--selected {
        background: var(--nj-blue) !important;

        .language-switcher__menu-text {
          color: var(--nj-white) !important;
        }
      }
    }
    &-text {
      font-size: 13px;
      line-height: 16px;
      color: var(--nj-gray-6);
      white-space: nowrap;
    }
  }
}
</style>
