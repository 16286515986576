import { getApolloClient, onLogin, onLogout } from "@/plugins/vue-apollo";
import { Hub } from "aws-amplify";
import store from "@/store";
import { apolloProvider } from "@/main";

const listener = async (data) => {
  const apolloClient = getApolloClient();

  switch (data.payload.event) {
    case "signIn":
      try {
        await store.dispatch("auth/setUserInfo", data.payload.data);
        await onLogin(apolloClient, data.payload.data);
      } catch (err) {
        console.log(`error handling signIn: ${err}`);
      }
      break;
    case "signOut":
      try {
        await store.dispatch("auth/setUserInfo", null);
        await store.commit("auth/signOut");
        await onLogout(apolloClient);
      } catch (err) {
        console.log(`error handling signOut: ${err}`);
      }
      break;
    case "tokenRefresh_failure":
      console.log("tokenRefresh_failure, data: ", data);
      if (data.payload.data.code === "NotAuthorizedException") {
        await store.dispatch("auth/setUserInfo", null);
        await store.commit("auth/signOut");
        await onLogout(apolloProvider.defaultClient);
      }
      break;
    default:
      console.log("Auth listener handling event ", data.payload.event);
  }
};

export default function configureAuthEventListener() {
  Hub.listen("auth", listener);
}
