<template>
  <div class="what-is-new">
    <h2 class="what-is-new__title">{{ $t("what_is_new.title") }}</h2>
    <div class="what-is-new__content">
      <div class="what-is-new__carousel" v-if="whatsNews.length">
        <div class="glide glide-whats-new">
          <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides">
              <li class="glide__slide">
                <div
                  class="what-is-new__carousel-item"
                  v-for="item in whatsNews.filter((item, i) => i <= 3)"
                  :key="item.id"
                >
                  <WhatIsNewCarouselItem
                    :item="item"
                    @updateCarousel="updateCarousel"
                    @moveWhatsNew="sortWhatsNews"
                    @error="showRemovingError"
                    :firstItem="firstItem(item)"
                    :lastItem="lastItem(item)"
                  />
                </div>
              </li>
              <li class="glide__slide" v-if="whatsNews.length > 4">
                <div
                  class="what-is-new__carousel-item"
                  v-for="item in whatsNews.filter((item, i) => i > 3)"
                  :key="item.id"
                >
                  <WhatIsNewCarouselItem
                    :item="item"
                    @updateCarousel="updateCarousel"
                    @moveWhatsNew="sortWhatsNews"
                    @error="showRemovingError"
                    :firstItem="firstItem(item)"
                    :lastItem="lastItem(item)"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div
            class="glide__bullets"
            data-glide-el="controls[nav]"
            v-if="whatsNews.length > 4"
          >
            <button class="glide__bullet" data-glide-dir="=0"></button>
            <button class="glide__bullet" data-glide-dir="=1"></button>
          </div>
        </div>
      </div>
      <div class="what-is-new__empty" v-else-if="!loading">
        {{ $t("what_is_new.empty") }}
      </div>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
import WhatIsNewCarouselItem from "@/components/what-is-new/WhatIsNewCarouselItem";
import ALL_WHATS_NEWS from "@/graphql/whats-new/queries/AllWhatsNews.graphql";
import gql from "graphql-tag";
import errorHandler from "@/service/errorHandler";

export default {
  name: "WhatIsNew",
  components: {
    WhatIsNewCarouselItem,
  },
  data() {
    return {
      carousel: null,
      carouselTimeout: null,
      loading: true,
      whatsNews: [],
    };
  },
  watch: {
    whatsNews(newValue) {
      if (newValue.length) {
        this.mountCarousel();
      }
    },
  },
  mounted() {
    this.carousel = new Glide(".glide-whats-new", {
      type: "slider",
      startAt: 0,
    });
  },
  apollo: {
    whatsNews: {
      query: ALL_WHATS_NEWS,
      update(data) {
        this.loading = false;
        return data.whatsNews;
      },
      error(error) {
        this.loading = false;

        errorHandler(
          error,
          "ERROR_WHATS_NEW",
          this.$t("what_is_new.error_fetching_items")
        );
      },
    },
  },
  methods: {
    mountCarousel() {
      this.carouselTimeout = setTimeout(() => {
        this.carousel.mount();
      }, 1000);
    },
    firstItem(item) {
      return (
        this.whatsNews.findIndex((whatsNew) => item.id === whatsNew.id) === 0
      );
    },
    lastItem(item) {
      return (
        this.whatsNews.findIndex((whatsNew) => item.id === whatsNew.id) ===
        this.whatsNews.length - 1
      );
    },
    updateCarousel() {
      clearTimeout(this.carouselTimeout);
      this.carouselTimeout = null;
      this.carousel.destroy();
      this.carousel = new Glide(".glide", {
        type: "slider",
        startAt: 0,
      });
      this.$apollo.queries.whatsNews.refetch();
    },
    sortWhatsNews({ id, direction }) {
      const oldIndex = this.whatsNews.findIndex((item) => item.id === id);
      const sortedWhatsNews = this.whatsNews.slice();
      let formattedWhatsNews = "";
      let newIndex;

      if (direction === "up") {
        newIndex = oldIndex - 1;
      } else if (direction === "down") {
        newIndex = oldIndex + 1;
      }

      sortedWhatsNews.splice(
        newIndex,
        0,
        sortedWhatsNews.splice(oldIndex, 1)[0]
      );

      sortedWhatsNews.forEach((whatsNew) => {
        formattedWhatsNews += `{ id: "${whatsNew.id}", itemId: "${whatsNew.itemId}", itemName: "${whatsNew.itemName}" },`;
      });

      this.$apollo
        .mutate({
          mutation: gql`
            mutation sortWhatsNew {
              sortWhatsNew(whatsNewInput: [${formattedWhatsNews}]) {
                id
                itemId
                itemName
              }
            }
          `,
        })
        .then((data) => {
          this.whatsNews = data.data.sortWhatsNew;
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_SORT_WHATS_NEW",
            this.$t("what_is_new.error_sort_items")
          );
        });
    },
    showRemovingError() {
      errorHandler(
        null,
        "ERROR_REMOVE_WHATS_NEW",
        this.$t("what_is_new.error_remove_item")
      );
    },
  },
  beforeDestroy() {
    if (this.carouselTimeout) {
      clearTimeout(this.carouselTimeout);
      this.carouselTimeout = null;
    }
    if (this.carousel) {
      this.carousel.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
.what-is-new {
  padding: 55px 20px 20px;

  @media (max-width: 1024px) {
    padding-top: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: $mobile-max) {
    padding: 55px 0 20px;
  }

  &__title {
    font-size: 22px;
    line-height: 28px;
    font-weight: var(--font-weight-medium);
    margin-bottom: 12px;
    text-align: left;
  }

  &__carousel {
    &-item {
      padding: 12px 0;

      &:nth-child(4) {
        ::v-deep {
          .what-is-new-content-item,
          .what-is-new-ls,
          .what-is-new-program {
            .what-is-new-admin-menu__menu {
              top: auto;
              bottom: calc(100% + 10px);
            }
          }
        }
      }
    }
  }

  .glide {
    &__slides {
      padding-left: 0 !important;
      min-height: 385px !important;
    }

    &__bullets {
      position: relative;
      z-index: 2;
    }

    &__bullet {
      cursor: pointer;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--nj-gray-3);
      margin: 0 4px;

      &--active {
        background-color: var(--nj-blue);
      }
    }
  }
}
</style>
