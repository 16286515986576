<template>
  <div class="daily-feed-grid">
    <div class="daily-feed-grid__header">
      <div class="daily-feed-grid__header-text">
        <h1 class="title-medium">{{ $t("daily_feeds.daily_feed") }}</h1>
        <h2 class="body-1-book">
          {{ $t("daily_feeds.home_subtext") }}
        </h2>
      </div>
      <div class="daily-feed-grid__header-buttons">
        <NewDailyFeedPopup section="HOME" />
        <button
          class="daily-feed-grid__header-buttons-viewall"
          @click="viewAll"
        >
          {{ $t("daily_feeds.view_all") }}
        </button>
      </div>
    </div>
    <div class="daily-feed-grid__wrapper">
      <DailyFeedAll :shortView="true" section="HOME" />
    </div>
  </div>
</template>

<script>
import DailyFeedAll from "@/components/library/daily-feed/DailyFeedAll";
import NewDailyFeedPopup from "@/components/library/daily-feed/NewDailyFeedPopup";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "DailyFeedGrid",
  components: {
    DailyFeedAll,
    NewDailyFeedPopup,
  },
  methods: {
    viewAll() {
      logTrackingData({
        section: "HOME",
        belongsToSection: "DAILY_FEED",
        activityType: "VIEW_ALL",
        activityPerformedOn: "CONTENT",
      });

      this.$router.push({
        name: "library.dailyFeed",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.daily-feed-grid {
  background-color: var(--nj-black);
  padding: 40px 10%;
  max-width: 1226px;
  margin: 0 0 40px 0;
  width: 100%;
  border-radius: 10px;

  ::v-deep {
    .daily-feed-all__nav-item {
      .base-button__secondary {
        color: var(--nj-white);
      }
    }
  }

  @media screen and (max-width: 1080px) {
    padding: 40px;
  }
  @media screen and (max-width: $mobile-max) {
    padding: 40px 16px;
  }

  &__wrapper {
    padding-bottom: 40px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: white;
    text-align: left;
    margin-bottom: 40px;

    h2 {
      margin-bottom: 10px;
    }

    &-buttons {
      display: flex;
      align-items: center;

      &-viewall {
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        color: var(--nj-white);
        margin-left: 20px;
      }
    }
  }
}
</style>
