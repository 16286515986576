const DragAndResizeMixin = {
  data() {
    return {
      element: null,
      dragger: null,

      resizeDragStartX: null,
      resizeDragStartY: null,
      resizeStartWidth: null,
      resizeStartHeight: null,
      resizerRight: null,
      resizerBottom: null,
      resizerBoth: null,

      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
    };
  },
  methods: {
    removeResizers() {
      this.element.removeChild(this.resizerBottom);
      this.element.removeChild(this.resizerBoth);
      this.element.removeChild(this.resizerRight);
    },
    initDragAndResize(draggableEl, draggerEl) {
      this.element = draggableEl;
      this.dragger = draggerEl;
      this.initDrag();
      this.initResize();
    },
    initDrag() {
      if (this.dragger) {
        this.dragger.parentPopup = this.element;
        this.dragger.onmousedown = this.dragMouseDown;
      }
    },
    initResize() {
      //attach draggable resizers
      this.resizerRight = document.createElement("div");
      this.resizerRight.className = "resizer-right";
      this.resizerRight.style.cssText = `width: 5px; height: 100%; background: transparent; position: absolute; right: 0; bottom: 0; cursor: e-resize;`;
      this.element.appendChild(this.resizerRight);
      this.resizerRight.addEventListener(
        "mousedown",
        this.initResizeOnDrag,
        false
      );
      this.resizerRight.parentPopup = this.element;

      this.resizerBottom = document.createElement("div");
      this.resizerBottom.className = "resizer-bottom";
      this.resizerBottom.style.cssText = `width: 100%; height: 5px; background: transparent; position: absolute; right: 0; bottom: 0; cursor: n-resize;`;
      this.element.appendChild(this.resizerBottom);
      this.resizerBottom.addEventListener(
        "mousedown",
        this.initResizeOnDrag,
        false
      );
      this.resizerBottom.parentPopup = this.element;

      this.resizerBoth = document.createElement("div");
      this.resizerBoth.className = "resizer-both";
      this.resizerBoth.style.cssText = `width: 5px; height: 5px; background: transparent; z-index: 10; position: absolute; right: 0; bottom: 0; cursor: nw-resize;`;
      this.element.appendChild(this.resizerBoth);
      this.resizerBoth.addEventListener(
        "mousedown",
        this.initResizeOnDrag,
        false
      );
      this.resizerBoth.parentPopup = this.element;
    },

    //  resize methods:

    initResizeOnDrag(e) {
      this.resizeDragStartX = e.clientX;
      this.resizeDragStartY = e.clientY;
      this.resizeStartWidth = parseInt(
        document.defaultView.getComputedStyle(this.element).width,
        10
      );
      this.resizeStartHeight = parseInt(
        document.defaultView.getComputedStyle(this.element).height,
        10
      );
      document.documentElement.addEventListener(
        "mousemove",
        this.doResizeDrag,
        false
      );
      document.documentElement.addEventListener(
        "mouseup",
        this.stopResizeDrag,
        false
      );
    },

    doResizeDrag(e) {
      e.preventDefault();
      let elementWidthNumber = parseInt(
        this.element.style.width.split("px")[0]
      );
      let elementHeightNumber = parseInt(
        this.element.style.height.split("px")[0]
      );
      this.element.style.top = this.element.offsetTop + "px";
      this.element.style.left = this.element.offsetLeft + "px";
      this.element.style.removeProperty("right");
      if (
        elementWidthNumber > 200 ||
        isNaN(elementWidthNumber) ||
        elementWidthNumber >= this.resizeStartWidth
      ) {
        this.element.style.width =
          this.resizeStartWidth + e.clientX - this.resizeDragStartX + "px";
      }
      if (
        elementHeightNumber > 200 ||
        isNaN(elementHeightNumber) ||
        elementHeightNumber >= this.resizeStartHeight
      ) {
        this.element.style.height =
          this.resizeStartHeight + e.clientY - this.resizeDragStartY + "px";
      }
    },
    stopResizeDrag() {
      document.documentElement.removeEventListener(
        "mousemove",
        this.doResizeDrag,
        false
      );
      document.documentElement.removeEventListener(
        "mouseup",
        this.stopResizeDrag,
        false
      );
    },

    //  drag methods:

    dragMouseDown(e) {
      e = e || window.event;
      // get the mouse cursor position at startup:
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.dragElement;
    },
    dragElement(e) {
      if (!this.element || this.collapsed) {
        return;
      }

      e = e || window.event;
      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      // set the element's new position:
      this.element.style.top = this.element.offsetTop - this.pos2 + "px";
      this.element.style.left = this.element.offsetLeft - this.pos1 + "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};

export default DragAndResizeMixin;
