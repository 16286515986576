<template>
  <div class="base-modal">
    <div class="marketing-popup base-modal">
      <div
        class="base-modal__dialog"
        :class="
          dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'
        "
      >
        <div
          class="base-modal__inner edit-profile-modal__inner"
          :class="
            dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'
          "
        >
          <div class="marketing-popup__image">
            <CloseDialogButton
              color="white"
              class="base-modal__close"
              @click="cancel"
            />
            <div class="marketing-popup__text-container">
              <h1 class="marketing-popup__text">
                {{ $t("marketing_popup.metaverse.cta_1") }}
              </h1>
              <h2 class="marketing-popup__text">
                {{ $t("marketing_popup.metaverse.cta_2") }}
              </h2>
              <h3 class="marketing-popup__text">
                {{ $t("marketing_popup.metaverse.title") }}
              </h3>
              <h4 class="marketing-popup__text">
                {{ $t("marketing_popup.metaverse.text") }}
              </h4>
            </div>
          </div>

          <div class="marketing-popup__bottom">
            <FormCheckbox
              class="marketing-popup__checkbox--large"
              label="Do not show this message again"
              v-model="hidePrompt"
            />

            <div class="marketing-popup__buttons">
              <BaseButton @click="cancel" variant="secondary">
                {{ $t("marketing_popup.metaverse.cancel") }}
              </BaseButton>
              <BaseButton @click="signup">
                {{ $t("marketing_popup.metaverse.sign_up") }}
              </BaseButton>
            </div>
            <FormCheckbox
              class="marketing-popup__checkbox--small"
              label="Do not show this message again"
              v-model="hidePrompt"
            />
          </div>
        </div>
      </div>
    </div>
    <LoadingOverlay v-if="signUpInProgress"></LoadingOverlay>
  </div>
</template>

<script>
import FormCheckbox from "@/components/forms/FormCheckbox";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import errorHandler from "@/service/errorHandler";
import { mapGetters } from "vuex";
import UPDATE_USER_ATTRIBUTES from "@/graphql/user/mutations/UpdateUserAttributes.graphql";
import BOOK_CAMPUS_ITEM from "@/graphql/marketingevent/mutations/BookMarketingEventsCampusItem.graphql";

export default {
  name: "MarketingPopup",
  components: { CloseDialogButton, FormCheckbox },
  data() {
    return {
      dialog: false,
      hidePrompt: false,
      signUpInProgress: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
  },
  methods: {
    open() {
      this.dialog = true;
    },
    signup() {
      this.signUpInProgress = true;
      this.$apollo
        .mutate({
          mutation: BOOK_CAMPUS_ITEM,
          variables: {
            username: this.getUsername,
          },
        })
        .then((data) => {
          this.signUpInProgress = false;
          this.dialog = false;
          let campusItemId = data.data.marketingEvent.targetItemId;
          let pathToCampusItem = null;
          switch (data.data.marketingEvent.targetItemType) {
            case "PROGRAM":
              pathToCampusItem = "campus.program.overview";
              break;
            case "WORKSHOP":
              pathToCampusItem = "campus.workshop.overview";
              break;
            case "ROUNDTABLE":
              pathToCampusItem = "campus.roundtable.overview";
              break;
            default:
              console.error(
                "unknown campus item type: ",
                data.data.marketingEvent.targetItemType
              );
          }
          if (pathToCampusItem) {
            this.$router.push({
              name: pathToCampusItem,
              params: {
                id: campusItemId,
              },
            });
          }
        })
        .catch((error) => {
          this.signUpInProgress = false;
          errorHandler(
            error,
            "ERROR_BOOK_CAMPUS_ITEM",
            this.$t("error.marketing.booking_error")
          );
        });
    },
    cancel() {
      if (this.hidePrompt) {
        this.hideForever();
      }
      this.$store.commit("common/setMarketedEventSignup", true);
      this.dialog = false;
    },
    hideForever() {
      let variables = {
        ...this.getNJUser,
        showMarketingPopup: false,
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_ATTRIBUTES,
          variables: variables,
        })
        .then((data) => {
          this.$store.commit("auth/setNJUserProfile", data.data.updateUser);
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_UPDATE_PROFILE",
            this.$t("error.home.edit_profile_modal")
          );
        });
    },
  },
};
</script>

<style scoped lang="scss">
.marketing-popup {
  .base-modal {
    &__inner {
      width: 750px;
      margin: 0 16px;
      padding-bottom: 0;
    }
  }

  &__image {
    width: 750px;
    height: 280px;
    background: url("../../../public/metaverse.png");

    @media screen and (max-width: 564px) {
      background-size: contain;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    flex-wrap: wrap;
    @media screen and (max-width: 574px) {
      justify-content: center;
    }
  }

  &__buttons {
    display: flex;

    & > button:nth-child(2) {
      margin-left: 16px;
    }
  }

  ::v-deep .form-checkbox__input:checked ~ .form-checkbox__box {
    background: var(--nj-blue);
    border-color: var(--nj-blue);
  }

  &__text {
    text-align: center;
    font-weight: 500;

    &-container {
      max-width: 490px;
      position: absolute;
      right: 0;
      margin: 42px 16px 0 0;
      @media screen and (max-width: 655px) {
        max-width: 330px;
      }
      @media screen and (max-width: 585px) {
        max-width: 50%;
      }

      h1,
      h2 {
        color: #003058;
      }

      h3,
      h4 {
        color: white;
      }

      h1 {
        font-size: 48px;
        @media screen and (max-width: 655px) {
          font-size: 31px;
        }
      }

      h2 {
        font-size: 31px;
        margin-bottom: 24px;
        @media screen and (max-width: 655px) {
          font-size: 20px;
          margin-bottom: 12px;
        }
      }

      h3 {
        font-size: 22px;
        margin-bottom: 8px;
        @media screen and (max-width: 655px) {
          font-size: 18px;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 450;
        @media screen and (max-width: 655px) {
          font-size: 14px;
        }
      }
    }
  }

  &__checkbox {
    &--large {
      display: block;
      @media screen and (max-width: 574px) {
        display: none;
      }
    }

    &--small {
      display: none;
      @media screen and (max-width: 574px) {
        margin-top: 24px;
        display: block;
      }
    }
  }
}
</style>
