<template>
  <div
    :class="[
      'notification-user-liked-daily-feed',
      'notification',
      { 'notification--full': fullView },
    ]"
  >
    <div class="notification__avatar">
      <UserAvatar :size="40" :user="notification.issuer" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }} {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.liked_daily_feed") }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationUserLikedDailyFeed",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
};
</script>

<style scoped></style>
