<template>
  <div class="daily-feed-popup base-modal">
    <div
      class="base-modal__dialog"
      :class="
        popupViewer ? 'base-modal__dialog--open' : 'base-modal__dialog--close'
      "
      @click.self="$emit('closePopup')"
    >
      <button
        class="daily-feed-popup__go-left-btn"
        @click="goLeftInSlider"
        :disabled="!carousel || !carousel.index"
      >
        <Chevron
          color="white"
          size="large"
          direction="left"
          :class="
            carousel && carousel.index
              ? null
              : 'daily-feed-popup__chevron--disabled'
          "
        />
      </button>
      <div
        class="daily-feed-popup__inner base-modal__inner"
        :class="
          popupViewer ? 'base-modal__inner--open' : 'base-modal__inner--close'
        "
      >
        <div class="daily-feed-popup__body base-modal__body">
          <div class="glide glide-popup">
            <div class="glide__track" data-glide-el="track">
              <ul class="glide__slides">
                <li
                  class="glide__slide"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <DailyFeedVideoDetails
                    v-if="item.type === 'VIDEO'"
                    :render="carousel && carousel.index === index"
                    :id="item.id"
                    :color="item.assignedColor"
                    ref="video-details"
                    @closePopUp="$emit('closePopup')"
                    :section="section"
                  />
                  <DailyFeedAudioDetails
                    v-if="item.type === 'AUDIO'"
                    :render="carousel && carousel.index === index"
                    :id="item.id"
                    :color="item.assignedColor"
                    ref="audio-details"
                    @closePopUp="$emit('closePopup')"
                    :section="section"
                  />
                  <DailyFeedReadableDetails
                    v-if="item.type === 'READABLE'"
                    :render="carousel && carousel.index === index"
                    @closePopUp="$emit('closePopup')"
                    :id="item.id"
                    :color="item.assignedColor"
                    :section="section"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button class="daily-feed-popup__go-right-btn" @click="goRightInSlider">
        <Chevron
          color="white"
          size="large"
          direction="right"
          :class="
            items.length > 1 ? null : 'daily-feed-popup__chevron--disabled'
          "
        />
      </button>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
import DailyFeedAudioDetails from "@/components/library/daily-feed/dailyFeedPopUpViewer/DailyFeedAudioDetails";
import DailyFeedReadableDetails from "@/components/library/daily-feed/dailyFeedPopUpViewer/DailyFeedReadableDetails";
import DailyFeedVideoDetails from "@/components/library/daily-feed/dailyFeedPopUpViewer/DailyFeedVideoDetails";
import Chevron from "@/components/reusables/Icons/Chevron";

export default {
  name: "DailyFeedPopup",
  components: {
    Chevron,
    DailyFeedVideoDetails,
    DailyFeedReadableDetails,
    DailyFeedAudioDetails,
  },
  props: {
    popupViewer: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    sliderStart: {
      type: Number,
      default: 0,
    },
    noPaginationRequired: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: "LIBRARY",
    },
  },
  methods: {
    pauseAllPlaying() {
      let allAudios = this.$refs["audio-details"];
      let allVideos = this.$refs["video-details"];
      if (allAudios) {
        allAudios.forEach((i) => i.pauseAudio());
      }
      if (allVideos) {
        allVideos.forEach((i) => i.pauseVideo());
      }
    },
    goLeftInSlider() {
      this.pauseAllPlaying();
      this.carousel.go("<");
    },
    goRightInSlider() {
      this.pauseAllPlaying();
      if (
        this.carousel.index < this.items.length - 1 ||
        this.noPaginationRequired
      ) {
        this.carousel.go(">");
      } else {
        if (this.items[0].type === "VIDEO") {
          this.$emit("fetchMoreVideos");
        } else if (this.items[0].type === "AUDIO") {
          this.$emit("fetchMoreAudios");
        } else {
          this.$emit("fetchMoreReadables");
        }
      }
    },
    updateSlider(newFeeds) {
      if (!newFeeds) {
        this.carousel.go(">");
      } else {
        this.carousel.destroy();
        setTimeout(this.mountCarousel, 10);
      }
    },
    mountCarousel() {
      this.carousel = new Glide(".glide-popup", {
        type: "slider",
        startAt: this.sliderStart,
        dragThreshold: false,
      });
      this.carousel.mount();
    },
  },
  data() {
    return {
      carousel: null,
    };
  },
  mounted() {
    if (this.sliderStart || this.sliderStart === 0) {
      this.mountCarousel();
    }
  },
  beforeDestroy() {
    this.carousel.destroy();
  },
};
</script>

<style scoped lang="scss">
.daily-feed-popup {
  .glide {
    @media screen and (max-width: $mobile-max) {
      position: absolute;
      height: 103vh;
      top: -14px;
    }

    &__track {
      @media screen and (max-width: $mobile-max) {
        height: 100%;
      }
    }

    &__slides {
      &--no-scroll {
        transform: none !important;
        will-change: unset;
        transform-style: initial;
      }

      @media screen and (max-width: $mobile-max) {
        height: 100%;
      }
    }
  }

  .base-modal {
    &__inner {
      @media screen and (max-width: $mobile-max) {
        height: 100%;
        border-radius: 0;
      }

      &--open {
        @media screen and (max-width: $mobile-max) {
          max-height: unset;
        }
      }
    }

    &__dialog {
      display: flex;

      &--open {
        display: flex;
      }
    }
  }

  &__inner {
    width: 100%;
    max-width: 945px;
    padding: 0 36px;
    background: transparent;
    z-index: 2;
    @media screen and (max-width: $mobile-max) {
      padding: 0;
    }
  }

  &__body {
    padding: 0;
    border-radius: 10px;
  }

  ul {
    padding: 0;
  }

  li {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
    @media screen and (max-width: $mobile-max) {
      width: 100vw;
    }
  }

  &__go-left-btn {
    color: white;
    margin-left: 10px;
    @media screen and (max-width: $mobile-max) {
      position: absolute;
      top: 50px;
      left: 20px;
      z-index: 10;
    }
  }

  &__go-right-btn {
    color: white;
    margin-right: 10px;
    @media screen and (max-width: $mobile-max) {
      position: absolute;
      top: 50px;
      right: 20px;
      z-index: 10;
    }
  }

  &__chevron--disabled {
    color: transparent;
  }
}
</style>
