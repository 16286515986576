/**
 * Creates a download url for the given thumbnail image and size.
 *
 * @param thumbnailId the storageId of the image
 * @param width the desired width
 * @param height the desired height
 * @returns {string|null} the download url for the image or null any of the given input params is invalid
 */
function createS3ThumbnailUrl(thumbnailId, width, height) {
  if (!thumbnailId) {
    console.error(
      "createThumbnailUrl: invalid params [thumbnailId|width|height]",
      thumbnailId,
      width,
      height
    );
    return "/no-image-icon.png";
  }
  // if there's no avatarId available we pass "no-avatar-id" as thumbnailId to the createThumbnailUrl function and
  // it returns us the default avatar icon
  if (thumbnailId === "no-avatar-image") {
    return "/default-user-image.png";
  }

  const slashPos = thumbnailId.indexOf("/");
  if (slashPos === -1) {
    console.error("Invalid thumbnailId: ", thumbnailId);
    return "/no-image-icon.png";
  }

  const storagePath = encodeURIComponent(thumbnailId.substring(0, slashPos));
  const storageId = encodeURIComponent(thumbnailId.substring(slashPos + 1));

  if (!storagePath || !storageId || !width || !height) {
    console.error(
      "createThumbnailUrl: invalid params [thumbnailId|width|height]",
      thumbnailId,
      width,
      height
    );
    return "/no-image-icon.png";
  }

  const baseUrl = process.env.VUE_APP_THUMBNAIL_BASE_URL;
  return `${baseUrl}/${storagePath}/${width}/${height}/${storageId}`;
}

export default createS3ThumbnailUrl;
