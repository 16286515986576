import AmityClient, { ConnectionStatus, ApiRegion } from "@amityco/js-sdk";

// Amity sample-app apiKey
const client = new AmityClient({
  apiKey: process.env.VUE_APP_AMITY_KEY,
  apiRegion: ApiRegion.EU,
});

export let currentUserId = null;

// promisified client connection
export const connectClient = async (userId, displayName) => {
  return new Promise((resolve) => {
    client.on("connectionStatusChanged", ({ newValue }) => {
      if (newValue === ConnectionStatus.Connected) {
        currentUserId = userId;
        resolve();
      }
    });

    client.registerSession({ userId, displayName });
  });
};

export const updateUserRoles = async () => {
  await client.updateCurrentUser({
    roles: ["channel-moderator", "community-moderator"],
  });
};

export const disconnectClient = async () => {
  return new Promise((resolve) => {
    client.unregisterSession();
    client.removeAllListeners("connectionStatusChanged");
    resolve();
  });
};
