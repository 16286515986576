import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

// TODO: use lazy loading - see https://kazupon.github.io/vue-i18n/guide/lazy-loading.html

export default new VueI18n({
  locale: "en",
  fallbackLocale: "de",
  messages: {
    en: require("@/assets/i18n/en.json"),
    he: require("@/assets/i18n/he.json"),
    es: require("@/assets/i18n/es.json"),
    de: require("@/assets/i18n/de.json"),
  },
});
