<template>
  <div class="app">
    <Header />
    <div class="container">
      <Navigation
        v-if="
          getNJUser &&
          $route.name !== 'paymentRegister' &&
          $route.name !== 'paymentError' &&
          $route.name !== 'paymentCancel' &&
          $route.name !== 'paymentSuccessCreate' &&
          $route.name !== 'paymentSuccessUpdate' &&
          $route.name !== 'paymentSuccessCampus' &&
          $route.name !== 'frontdoor' &&
          $route.name !== 'payment.subscriptions' &&
          $route.name !== 'payment.signup'
        "
      />
      <main
        class="app__main"
        :class="[
          signedIn ? null : 'app__main--center-auth',
          { 'app__main--center-auth': $route.name === 'frontdoor' },
          { 'app__main--center-auth': $route.name === 'payment.subscriptions' },
          $route.name === 'chat' ? 'app__main--chat' : null,
        ]"
      >
        <div class="app__wrapper">
          <router-view />
        </div>
      </main>
      <sed-card />
      <NotebookWidget v-if="signedIn" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Header from "@/components/layout/Header";
import Navigation from "@/components/layout/Navigation";
import store from "@/store";
import browser from "browser-detect";
import gql from "graphql-tag";
import NotebookWidget from "@/components/notebooks/NotebookWidget";
import axios from "axios";
import {
  connectClient,
  disconnectClient,
  updateUserRoles,
} from "@/plugins/amity-chat-setup";
import { ChannelFilter, ChannelRepository, ChannelType } from "@amityco/js-sdk";
import SedCard from "@/components/user-profile/SedCard";
import { logTrackingData } from "@/service/trackingServices";
import GET_CONFIG from "@/graphql/user/queries/GetConfigValues.graphql";

export default {
  name: "App",
  store: store,
  components: {
    NotebookWidget,
    Header,
    Navigation,
    SedCard,
  },
  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapState("chat", ["connectedToChat"]),
    ...mapGetters("auth", ["getNJUser", "getUsername", "getTenant"]),
  },
  watch: {
    getNJUser(newVal) {
      if (newVal && !this.connectedToChat) {
        setTimeout(async () => {
          if (this.getUsername) {
            await this.connectToAmity({
              username: this.getNJUser.id,
              displayName: `${this.getNJUser.firstName} ${this.getNJUser.lastName}`,
            });
            await updateUserRoles();
          }
        }, 1000);
      } else {
        setTimeout(async () => {
          await this.disconnectFromAmity();
        }, 1000);
      }
      document.documentElement.setAttribute('data-theme', this.getTenant)
    },
    $route: {
      handler() {
        if (
          this.$route.path === "/program/resource" ||
          this.$route.path === "/program/participants" ||
          this.$route.path === "/livesession/allsessions" ||
          this.$route.path === "/livesession/yoursessions" ||
          this.$route.path === "/livesession/details/:sessionId" ||
          this.$route.path === "/notebooks" ||
          this.$route.path === "/dashboard/main"
        ) {
          // sets grey background on small screens
          if (document.querySelector(".app")) {
            document.querySelector(".app").classList.add("app--gray");
          }
        } else {
          // unsets gray background on small screens
          if (document.querySelector(".app")) {
            document.querySelector(".app").classList.remove("app--gray");
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      authConfig: {
        hideAllDefaults: false,
        signUpConfig: {
          hiddenDefaults: ["phone_number"],
        },
      },
      userId: this.getUsername,
      collection: [],
      channels: [],
    };
  },
  methods: {
    ...mapMutations("common", ["setBrowser", "setTimezones", "setCurrency", "setPricedCampusItems", "setFeaturedItemsCaroussel", "setKeleaBanner"]),
    ...mapMutations("chat", ["connectToChat", "disconnectFromChat"]),
    ...mapActions("chat", ["updateNewMessagesState"]),
    async connectToAmity({ username, displayName }) {
      this.userId = username;
      await connectClient(username, displayName);
      this.connectToChat();
      this.checkUnreadMessages();
    },
    async disconnectFromAmity() {
      await disconnectClient();
      this.disconnectFromChat();
    },
    checkUnreadMessages() {
      this.collection = ChannelRepository.queryChannels({
        types: [ChannelType.Conversation, ChannelType.Live],
        filter: ChannelFilter.Member,
        isDeleted: false,
        excludeTags: ["hidden"],
      });

      this.channels = this.collection.models
        .map(({ channelId, unreadCount }) => ({
          channelId,
          unreadCount,
        }))
        .filter((channel) => channel.unreadCount > 0);

      this.updateNewMessagesState(this.channels);

      this.collection.on("dataUpdated", (models) => {
        this.channels = models
          .map(({ channelId, unreadCount }) => ({
            channelId,
            unreadCount,
          }))
          .filter((channel) => channel.unreadCount > 0);

        this.updateNewMessagesState(this.channels);
      });
    },
    requestPermission() {
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      } else {
        console.log("Push notifications allowed");
      }
    },
    setLangFromLocalStorage() {
      const defaultLang = localStorage.getItem("nj-app-lang");
      this.$i18n.locale =
        defaultLang && defaultLang !== "null" ? defaultLang : "en";
    },
    userLeaving() {
      logTrackingData({
        activityType: "LOGOUT",
      });
    },
    async getUserIP() {
      try {
        const response = await axios.get(
          "https://www.cloudflare.com/cdn-cgi/trace"
        );
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
  },
  apollo: {
    timezoneIds: {
      query: gql`
        query {
          ids: availableTimezoneIds {
            id
            utcOffset
          }
        }
      `,
      update(data) {
        this.setTimezones(data.ids);
        return data.ids;
      },
      skip() {
        return !this.getNJUser;
      },
    },
    configValues: {
      query: GET_CONFIG,
      update(data) {
        const currency = data.configValues.find(item => item.key === "stripe.currency" )
        const hasPricedCampusItems = data.configValues.find(item => item.key === "pricedCampusItems.enable" )
        const hasFeaturedItemsCaroussel = data.configValues.find(item => item.key === "featuredItemsCaroussel.display" )
        const hasKeleaBanner = data.configValues.find(item => item.key === "keleaBanner.display" )

        this.setCurrency(currency?.value || 'EUR');
        this.setPricedCampusItems(hasPricedCampusItems && hasPricedCampusItems.value === "true");
        this.setFeaturedItemsCaroussel(hasFeaturedItemsCaroussel && hasFeaturedItemsCaroussel.value === "true");
        this.setKeleaBanner(hasKeleaBanner && hasKeleaBanner.value === "true");
        return data.configValues;
      },
      skip() {
        return !this.getNJUser;
      },
    },
  },
  async mounted() {
    const currentBrowser = await browser();
    this.setBrowser(currentBrowser);
    // this.getUserIP();
    this.requestPermission();
    this.setLangFromLocalStorage();
  },
  async beforeDestroy() {
    this.disconnectFromAmity();
    this.updateNewMessagesState(0);
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const theme = urlParams.get('theme');
    document.documentElement.setAttribute('data-theme', theme || 'NeuralJam');
    window.addEventListener("beforeunload", this.userLeaving);
  },
};
</script>

<style lang="scss">
.app {
  //overflow: hidden;
  background-color: var(--nj-gray);

  &--white {
    background-color: var(--nj-white);
  }

  &--gray {
    @media (max-width: $mobile-max) {
      background-color: var(--nj-gray) !important;
    }
  }

  @media (max-width: $mobile-max) {
    background-color: var(--nj-white);
  }

  &__main {
    padding: 60px 0 40px 0;
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    flex-grow: 1;

    &--center-auth {
      padding-left: 30px;
      background-color: white;
      overflow: visible;
      display: flex;
      align-items: center;
    }

    @media (max-width: $mobile-max) {
      padding: 60px 0 0 0;
    }

    &--chat {
      /* Safari mobile fix */
      @media (max-width: $mobile-max) {
        min-height: -webkit-fill-available;
      }
    }
  }

  &__wrapper {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
  }
}
</style>
