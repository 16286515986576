<template>
  <div
    class="daily-feed-video"
    @click="openPopup"
    :style="{ backgroundColor: color }"
  >
    <div class="daily-feed-video__inner">
      <div class="daily-feed-video__head">
        <div class="daily-feed-video__thumbnail">
          <img :src="thumbnail" alt="Video thumbnail" />
        </div>
        <div class="daily-feed-video__head-actions">
          <div
            class="daily-feed-video__like"
            @click.stop="likedByUser ? dislikeFeed() : likeFeed()"
          >
            <div class="daily-feed-video__like-icon">
              <HeartIconWithShadow
                :color="likedByUser ? '#EC3797' : '#ffffff'"
                :fill="likedByUser ? '#EC3797' : 'transparent'"
              />
            </div>
            <div class="daily-feed-video__like-number">
              {{ likes.length }}
            </div>
          </div>
          <div
            class="daily-feed-video__options"
            v-if="isCreatorOfFeed || isContentManager"
          >
            <DailyFeedAdminMenu
              v-model="showOptions"
              :isCreatorOfFeed="isCreatorOfFeed"
              :isContentManager="isContentManager"
              :isNotCreatorButAdmin="isNotCreatorButAdmin"
              @editFeed="editDailyFeed"
              @deleteFeed="deleteDailyFeed"
            />
          </div>
        </div>
        <div class="daily-feed-video__camera">
          <CameraIcon />
        </div>
        <div class="daily-feed-video__author">
          <div class="daily-feed-video__author-avatar">
            <UserAvatar :size="24" :user="item.uploader" />
          </div>
          <div class="daily-feed-video__author-info">
            <div class="daily-feed-video__author-name">
              {{ item.uploader.firstName }} {{ item.uploader.lastName }}
            </div>
            <div class="daily-feed-video__author-role">
              {{ item.uploader.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="daily-feed-video__content">
        <p class="daily-feed-video__text">
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HeartIconWithShadow from "@/components/reusables/Icons/HeartIconWithShadow";
import UserAvatar from "@/components/reusables/UserAvatar";
import DailyFeedAdminMenu from "@/components/library/daily-feed/DailyFeedAdminMenu";
import CameraIcon from "@/components/reusables/Icons/CameraIcon";
import DailyFeedItemMixin from "@/components/mixins/DailyFeedItemMixin";
import { mapGetters } from "vuex";

export default {
  name: "DailyFeedVideo",
  components: {
    DailyFeedAdminMenu,
    UserAvatar,
    HeartIconWithShadow,
    CameraIcon,
  },
  mixins: [DailyFeedItemMixin],
  props: ["item", "color"],
  watch: {
    item(newValue) {
      if (newValue) {
        this.loadThumbnail("video");
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["isContentManager"]),
  },
  mounted() {
    this.loadThumbnail("video");
    this.fetchLikes();
  },
};
</script>

<style lang="scss" scoped>
.daily-feed-video {
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 10px;

  &__head {
    position: relative;
    height: 210px;

    &-actions {
      position: absolute;
      top: 9px;
      right: 10px;
      z-index: 2;
      display: flex;
      align-items: center;
    }
  }

  &__thumbnail {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;

    &:before {
      pointer-events: none;
      width: 100%;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 40px;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      transform: rotate(-180deg);
      z-index: 1;
    }

    img {
      display: block;
      width: 100%;
      height: 210px;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      filter: brightness(85%);
    }
  }

  &__content {
    padding: 9px 15px 15px;
  }

  &__text {
    color: var(--nj-white);
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__camera {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 2;
  }

  &__like {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }

    &-number {
      font-size: 16px;
      line-height: 24px;
      color: var(--nj-white);
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    }
  }

  &__author {
    position: absolute;
    bottom: 10px;
    left: 20px;
    display: flex;
    align-items: center;
    margin-right: 30px;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 2;

    &-info {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 7px;
      flex-shrink: 0;
      background-color: white;
    }

    &-name {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-white);
      font-weight: var(--font-weight-medium);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-role {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-white);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
