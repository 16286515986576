<template>
  <div
    v-if="notification"
    :class="[
      'notification-connect',
      'notification',
      { 'notification--full': fullView },
    ]"
  >
    <div
      class="notification__avatar"
      @click="
        $store.commit('common/setProfileModalUserId', notification.issuer.id)
      "
    >
      <UserAvatar :size="40" :user="notification.issuer" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.connect_request") }}
      </p>
      <div
        class="notification__time"
        :class="{
          'notification__time--show': !showShortActions && !showFullActions,
        }"
      >
        {{ durationInDays() }}
      </div>
    </div>
    <div class="notification__action" @click.stop v-if="showFullActions">
      <BaseButton variant="secondary" @click="declineRequest">
        {{ $t("community.groups.status_buttons.decline") }}
      </BaseButton>
      <BaseButton @click="acceptRequest"
        >{{ $t("community.groups.status_buttons.accept") }}
      </BaseButton>
    </div>
    <div
      class="notification__action notification__action--short"
      v-if="showShortActions"
    >
      <button class="notification__action-btn" @click.stop="declineRequest">
        <DeclineIcon />
      </button>
      <button class="notification__action-btn" @click.stop="acceptRequest">
        <AcceptIcon />
      </button>
    </div>
    <LoadingOverlay v-if="loading">{{ $t("default.loading") }}</LoadingOverlay>
  </div>
</template>

<script>
import ACCEPT_REQUEST from "@/graphql/member-connect/mutations/AcceptConnectToUserRequest.graphql";
import DECLINE_REQUEST from "@/graphql/member-connect/mutations/DeclineConnectToUserRequest.graphql";
import DELETE_NOTIFICATION from "@/graphql/notifications/mutations/DeleteNotificationByEntityAndReceiver.graphql";
import CONNECTION_STATUS from "@/graphql/member-connect/queries/ConnectionStatus.graphql";
import SUB_CONNECT_TO_USER_REQUEST from "@/graphql/member-connect/subscription/ConnectToUserRequestSubscription.graphql";
import UserAvatar from "@/components/reusables/UserAvatar";
import AcceptIcon from "@/components/reusables/Icons/AcceptIcon";
import DeclineIcon from "@/components/reusables/Icons/DeclineIcon";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationConnect",
  components: { UserAvatar, AcceptIcon, DeclineIcon },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser", "getUsername"]),
    showFullActions() {
      return (
        this.fullView &&
        this.connectionStatus &&
        this.connectionStatus.connectToUserRequestStatus === "PENDING"
      );
    },
    showShortActions() {
      return (
        !this.fullView &&
        this.connectionStatus &&
        this.connectionStatus.connectToUserRequestStatus === "PENDING"
      );
    },
  },
  methods: {
    deleteNotification() {
      this.$apollo
        .mutate({
          mutation: DELETE_NOTIFICATION,
          variables: {
            entityName: "CONNECT_TO_USER_REQUEST",
            entityIds: [this.connectionStatus.id],
            receiverId: this.connectionStatus.recipient.id,
          },
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DELETE_NOTIFICATION",
            this.$t("notifications.error.delete")
          );
        });
    },
    acceptRequest() {
      this.loading = true;
      let variables = { connectToUserRequestId: this.connectionStatus.id };

      this.$apollo
        .mutate({
          mutation: ACCEPT_REQUEST,
          variables: variables,
        })
        .then(() => {
          this.deleteNotification();
          this.$apollo.queries.connectionStatus.refetch();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_ACCEPT_REQUEST",
            this.$t("error.group.approve_request_error")
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    declineRequest() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: DECLINE_REQUEST,
          variables: {
            connectToUserRequestId: this.connectionStatus.id,
          },
        })
        .then(() => {
          this.deleteNotification();
          this.$apollo.queries.connectionStatus.refetch();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DECLINE_REQUEST",
            this.$t("error.group.decline_request_error")
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  apollo: {
    connectionStatus: {
      query: CONNECTION_STATUS,
      variables() {
        return {
          issuerUsername: this.getUsername,
          receiverUserName: this.notification.issuer.username,
        };
      },
      update(data) {
        this.statusLoaded = true;
        return data.status;
      },
      fetchPolicy: "network-only",
      async error(error) {
        await errorHandler(
          error,
          "ERROR_CONNECTION_STATUS",
          this.$t("community.groups.members.error.status_fetch")
        ).then(() => {
          this.$apollo.queries.connectionStatus.stopPolling();
        });
      },
      subscribeToMore: {
        document: SUB_CONNECT_TO_USER_REQUEST,
        variables() {
          return {
            otherUserId: this.notification.issuer.id,
          };
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          return {
            status: subscriptionData.data.event.connectToUserRequest,
          };
        },
        skip() {
          return !this.getNJUser || !this.notification.issuer;
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
.notification-connect {
  cursor: pointer;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
</style>
