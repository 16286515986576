<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1 15.9C19.8 15 19.65 14.25 19.5 13.35C19.05 13.5 18.6 13.5 18 13.5C18.15 14.4 18.3 15.45 18.6 16.35L19.2 18H4.8L5.4 16.35C5.7 15 6 13.65 6 12.3V9C6 8.1 6.15 7.35 6.45 6.6C6.75 5.85 7.35 5.1 7.95 4.65C8.55 4.05 9.3 3.6 10.05 3.45C10.5 3.15 10.8 3.15 11.1 3.15C11.4 2.55 11.7 1.95 12 1.5C11.25 1.5 10.35 1.65 9.6 1.95C8.55 2.25 7.65 2.7 6.9 3.45C6.15 4.2 5.55 4.95 5.1 6C4.65 6.9 4.5 7.95 4.5 9V12.3C4.5 13.5 4.2 14.7 3.9 15.9L3 18.45L3.75 19.5H9C9 20.25 9.3 21 9.9 21.6C10.35 22.2 11.25 22.5 12 22.5C12.75 22.5 13.5 22.2 14.1 21.6C14.7 21 15 20.25 15 19.5H20.25L21 18.45L20.1 15.9ZM13.05 20.55C12.75 20.85 12.3 21 12 21C11.7 21 11.25 20.85 10.95 20.55C10.65 20.25 10.5 19.95 10.5 19.5H13.5C13.5 19.95 13.35 20.25 13.05 20.55Z"
      fill="#4F4F4F"
    />
    <path
      d="M18 10.5C20.4853 10.5 22.5 8.48528 22.5 6C22.5 3.51472 20.4853 1.5 18 1.5C15.5147 1.5 13.5 3.51472 13.5 6C13.5 8.48528 15.5147 10.5 18 10.5Z"
      fill="#F4714E"
    />
  </svg>
</template>

<script>
export default {
  name: "BellWithDotIcon",
};
</script>

<style scoped lang="scss"></style>
