<template>
  <svg
    @click="$emit('click')"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5.99805L6 0.998047L1 5.99805"
      stroke="#F8F8F8"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 12.998L6 7.99805L1 12.998"
      stroke="#F8F8F8"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CollapseUp",
};
</script>
