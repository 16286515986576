<template>
  <nav
    :class="['navigation', { 'navigation--open': isMobileMenuOpen }]"
    v-if="signedIn"
  >
    <ul @click="closeMobileMenu">
      <li>
        <router-link
          :to="{ name: 'home' }"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.home") }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'dashboard' }"
          :target="$route.name === 'liveSessionMeet' ? '_blank' : '_self'"
        >
          {{ $t("nav.dashboard") }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'campus' }"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.campus") }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'library' }"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.library") }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'notebooks' }"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.notebooks") }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'community' }"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.community") }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'livesession' }"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.live_session") }}
        </router-link>
      </li>
      <li class="navigation__chat">
        <router-link
          :to="{ name: 'chat' }"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.chat") }}
        </router-link>
        <span class="navigation__chat-dot" v-if="newChatMessages.length"></span>
      </li>
      <li v-if="isSuperAdmin || isUserAdmin || isContentManager">
        <router-link
          :to="{ name: 'admin' }"
          class="navigation__admin"
          :target="
            $route.name === 'meetUpMeet' ||
            $route.name === 'liveSessionMeet' ||
            $route.name === 'roundTableMeet'
              ? '_blank'
              : '_self'
          "
        >
          {{ $t("nav.admin") }}
        </router-link>
      </li>
    </ul>
    <div class="navigation__lang" v-if="showLangSwitcher">
      <LanguageSwitcher />
    </div>
  </nav>
</template>

<script>
import LANGUAGE_PICKER_CONFIG from "@/graphql/user/queries/LanguagePickerConfig.graphql";
import LanguageSwitcher from "@/components/layout/LanguageSwitcher";
import { mapGetters, mapMutations, mapState } from "vuex";
import errorHandler from "@/service/errorHandler";

export default {
  name: "Navigation",
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      showLangSwitcher: false,
    };
  },
  computed: {
    ...mapState("auth", ["userGroups", "signedIn"]),
    ...mapState("common", ["isMobileMenuOpen"]),
    ...mapState("chat", ["newChatMessages"]),
    ...mapGetters("auth", ["isContentManager", "isSuperAdmin", "isUserAdmin"]),
  },
  methods: {
    ...mapMutations("common", ["setMobileMenuState"]),
    closeMobileMenu() {
      this.setMobileMenuState(false);
    },
  },
  apollo: {
    showLangSwitcher: {
      query: LANGUAGE_PICKER_CONFIG,
      update(data) {
        return (
          !data.displayLanguagePicker || data.displayLanguagePicker === "true"
        );
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_LANG_PICKER_CONFIG",
          this.$t("language_picker.error_lang_picker_config")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  flex-direction: column;
  width: 184px;
  height: 100%;
  position: sticky;
  background-color: var(--nj-gray);
  z-index: 3;
  left: 24px;
  top: 60px;
  flex-shrink: 0;

  @media (max-width: $mobile-max) {
    transition: all 0.2s ease-out;
    transform: translateX(-100%);
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    padding-top: 50px;
    position: fixed;
    padding-left: 24px;
    padding-right: 24px;
  }

  &--open {
    @media (max-width: $mobile-max) {
      transform: translateX(0);
    }
  }

  &__lang {
    margin-top: 50px;
  }

  &__chat {
    position: relative;
    padding-right: 10px;

    &-dot {
      position: absolute;
      left: 40px;
      top: 0;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--nj-green);
    }
  }

  ul {
    list-style: none;
  }

  li {
    text-align: left;
    margin: 30px 0;
  }

  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    font-family: var(--titles-font-family);
    color: black;

    html[data-theme='oneclub'] & {
      text-transform: uppercase;
      font-weight: var(--font-weight-regular);
    }

    &.router-link-active {
      color: var(--nj-blue);
    }

    &.navigation__admin {
      color: var(--nj-pink);
    }
  }
}
</style>
