import Vue from "vue";

let handleOutsideClick;

Vue.directive("closable", {
  bind(el, binding, vNode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();

      const { handler, exclude } = binding.value;

      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vNode.context.$refs[refName];

          if (excludedEl && excludedEl.contains) {
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
        }
      });
      if (e.target && !el.contains(e.target) && !clickedOnExcludedEl) {
        vNode.context[handler]();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unbind() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});
