<template>
  <div class="what-is-new-content-item" v-if="contentItem">
    <div class="what-is-new-content-item__inner">
      <div class="what-is-new-content-item__image">
        <img
          :src="createThumbnailUrl(contentItem.thumbnailId)"
          alt="thumbnail"
        />
      </div>
      <div class="what-is-new-content-item__info">
        <div class="what-is-new-content-item__info-top">
          <h3 class="what-is-new-content-item__info-type">
            {{ contentItem.contentType }}
          </h3>
          <div class="what-is-new-content-item__admin" v-if="isContentManager">
            <WhatIsNewAdminMenu
              :itemId="item.id"
              :firstItem="firstItem"
              :lastItem="lastItem"
              @moveWhatsNew="moveWhatsNew"
              @updateCarousel="updateCarousel"
              @error="displayError"
            />
          </div>
        </div>
        <div class="what-is-new-content-item__info-title">
          {{ contentItem.title }}
        </div>
        <div class="what-is-new-content-item__info-author">
          {{ creatorsList }}
        </div>
      </div>
      <button class="what-is-new-content-item__btn" @click="goToItem">
        <ArrowCircleIcon direction="right" />
      </button>
    </div>
  </div>
</template>

<script>
import CONTENT_ITEM_BY_ID from "@/graphql/contentItem/queries/ContentItemById.graphql";
import ArrowCircleIcon from "@/components/reusables/Icons/ArrowCircleIcon";
import { mapGetters } from "vuex";
import WhatsNewItemMixin from "@/components/mixins/WhatsNewItemMixin";
import errorHandler from "@/service/errorHandler";
import { logTrackingData } from "@/service/trackingServices";

const WhatIsNewAdminMenu = () =>
  import("@/components/what-is-new/WhatIsNewAdminMenu");

export default {
  name: "WhatIsNewContentItem",
  components: {
    ArrowCircleIcon,
    WhatIsNewAdminMenu,
  },
  props: ["item", "firstItem", "lastItem"],
  mixins: [WhatsNewItemMixin],
  computed: {
    ...mapGetters("auth", ["isContentManager", "getNJUser"]),
    creatorsList() {
      if (!this.contentItem) {
        return "";
      }

      const creators = this.contentItem.creators.map((creator) => {
        return `${creator.firstName} ${creator.lastName}`;
      });
      return creators.join(", ");
    },
    lowerCaseType() {
      if (!this.contentItem) {
        return "";
      }
      return this.contentItem.contentType.toLocaleLowerCase();
    },
  },
  methods: {
    logNavigation() {
      logTrackingData({
        section: "HOME",
        belongsToSection: "WHATS_NEW",
        activityType: "CONTENT_PAGE",
        contentType: this.contentItem.contentType,
        contentId: this.contentItem.id,
        activityPerformedOn: "CONTENT",
      });
    },
    goToItem() {
      this.logNavigation();

      this.$router.push({
        name: this.lowerCaseType,
        params: { id: this.contentItem.id },
      });
    },
  },
  apollo: {
    contentItem: {
      query: CONTENT_ITEM_BY_ID,
      variables() {
        return {
          contentItemId: this.item.itemId,
        };
      },
      update(data) {
        return data.contentItem;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_CONTENT_ITEM",
          this.$t("error.whats_new.fetch_contentitem_error")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.what-is-new-content-item {
  text-align: left;

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-right: 45px;
  }

  &__image {
    width: 72px;
    height: 72px;
    background-color: var(--nj-green);
    border-radius: 4px;
    overflow: hidden;
    margin-right: 16px;
    flex-shrink: 0;

    img {
      display: block;
      width: 72px;
      height: 72px;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__info {
    padding-top: 5px;
    flex-grow: 1;
    width: 100%;
    max-width: 227px;

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    &-type {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-gray-4);
      text-transform: uppercase;
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-author {
      font-size: 10px;
      line-height: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
