<template>
  <div
    :class="[
      'notification-new-provocation-answer',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="goToProvocation"
  >
    <div class="notification__avatar">
      <UserAvatar :size="40" :user="notification.issuer" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }} {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.provocation_answer") }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationNewProvocationAnswer",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  methods: {
    goToProvocation() {
      this.$emit("closeNotifications");

      this.$router.push({
        name: "video-answer",
        params: { id: this.notification.entityId },
      });
    },
  },
};
</script>

<style scoped>
.notification-new-provocation-answer {
  cursor: pointer;
}
</style>
