var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all-notifications-popup base-modal"},[_c('BaseButton',{staticClass:"all-notifications-popup__button",attrs:{"variant":"secondary"},on:{"click":_vm.openPopup}},[_vm._v(" "+_vm._s(_vm.$t("notifications.see_all_btn"))+" ")]),_c('div',{staticClass:"all-notifications-popup__dialog base-modal__dialog",class:_vm.dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'},[_c('div',{staticClass:"all-notifications-popup__inner base-modal__inner",class:_vm.dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'},[_c('div',{staticClass:"all-notifications-popup__head base-modal__head"},[_c('CloseDialogButton',{staticClass:"all-notifications-popup__close",on:{"click":_vm.closePopup}}),_c('h1',{staticClass:"base-modal__head-title"},[_vm._v(" "+_vm._s(_vm.$t("notifications.title"))+" ")]),_c('div',{staticClass:"all-notifications-popup__settings",on:{"click":_vm.goToAppSettings}},[_c('WheelIcon')],1)],1),_c('div',{staticClass:"all-notifications-popup__body base-modal__body",class:{
          'all-notifications-popup__body--empty': !_vm.notifications.length,
        },on:{"scroll":_vm.scrollListener}},[(_vm.notifications.length)?_c('ul',{staticClass:"all-notifications-popup__list"},_vm._l((_vm.notifications),function(notification){return _c('li',{key:notification.id,class:[
              'all-notifications-popup__list-item',
              {
                'all-notifications-popup__list-item--selected':
                  notification.viewedAt === null,
              },
            ]},[_c(_vm.getNotificationComponent(notification),{tag:"component",attrs:{"fullView":true,"notification":notification},on:{"closeNotifications":_vm.closePopup,"deleteNotification":function($event){return _vm.deleteNotification($event, notification.id)}}})],1)}),0):_c('div',{staticClass:"all-notifications-popup__loading"},[_c('DotSpin')],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }