import GET_THUMBNAILS from "@/graphql/provocations/queries/GetThumbnails.graphql";
import errorHandler from "@/service/errorHandler";

const GetThumbnailsMixin = {
  data() {
    return {
      getThumbnailsInterval: null,
    };
  },
  methods: {
    getThumbnailsQuery(newId) {
      return this.$apollo
        .query({
          query: GET_THUMBNAILS,
          variables: {
            storageId: newId,
          },
          fetchPolicy: "network-only",
        })
        .catch((err) => {
          errorHandler(
            err,
            "GET_THUMBNAILS_ERROR",
            this.$t("error.record_video.get_thumbnails_error")
          );
        });
    },
    getThumbnailPromise(newId) {
      return new Promise((resolve, reject) => {
        this.$refs.uploadFile.openDialog(
          this.$t("upload.preparing_thumbnails"),
          this.$t("upload.please_wait"),
          false,
          false
        );
        this.getThumbnailsInterval = setInterval(async () => {
          let response = await this.getThumbnailsQuery(newId);
          if (response.data.thumbnails.length === 0) {
            console.log("response empty, go again ");
          } else {
            clearInterval(this.getThumbnailsInterval);
            resolve(response.data.thumbnails);
          }
        }, 2000);
      });
    },
  },
};

export default GetThumbnailsMixin;
