import { DateTime } from "luxon";

const NotificationDurationMixin = {
  methods: {
    durationInDays() {
      const timeNow = DateTime.now();
      const timeCreated = DateTime.fromISO(this.notification.issuedAt);

      const diff = timeNow.diff(timeCreated, [
        "months",
        "days",
        "hours",
        "minutes",
      ]);

      const result = diff.toObject();

      if (result.months > 0) {
        return `${Math.ceil(result.months)} month${
          result.months === 1 ? "" : "s"
        }`;
      } else if (result.days > 0) {
        return `${Math.ceil(result.days)} day${result.days === 1 ? "" : "s"}`;
      } else if (result.hours > 0) {
        return `${Math.ceil(result.hours)} hr${result.hours === 1 ? "" : "s"}`;
      } else if (result.minutes > 0) {
        return `${Math.ceil(result.minutes)} min${
          result.minutes === 1 ? "" : "s"
        }`;
      } else {
        return "1 minute";
      }
    },
  },
};

export default NotificationDurationMixin;
