import { render, staticRenderFns } from "./ArrowBackIcon.vue?vue&type=template&id=25d3763a&scoped=true&"
import script from "./ArrowBackIcon.vue?vue&type=script&lang=js&"
export * from "./ArrowBackIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d3763a",
  null
  
)

export default component.exports