<template>
  <div
    :class="[
      'notification',
      'notification-user-commented-post',
      { 'notification--full': fullView },
    ]"
    @click="goToFeed"
  >
    <div class="notification__avatar">
      <UserAvatar :size="40" :user="notification.issuer" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }} {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.commented_on_post") }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationUserCommentedOnPost",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  methods: {
    goToFeed() {
      this.$emit("closeNotifications");
      this.$router.push({
        name: "feedItem",
        params: { feedItemId: this.notification.entityId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.notification-user-commented-post {
  cursor: pointer;
}
</style>
