<template>
  <div
    v-if="group"
    :class="[
      'notification-join-request-group',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="goToRequests"
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.requested_to_join", { groupName: group.name }) }}
      </p>
      <div
        class="notification__time"
        :class="{
          'notification__time--show': !showShortActions && !showFullActions,
        }"
      >
        {{ durationInDays() }}
      </div>
    </div>
    <div class="notification__action" v-if="showFullActions">
      <BaseButton variant="secondary" @click="declineRequest">
        {{ $t("community.groups.status_buttons.decline") }}
      </BaseButton>
      <BaseButton @click="acceptRequest"
        >{{ $t("community.groups.status_buttons.approve") }}
      </BaseButton>
    </div>
    <div
      class="notification__action notification__action--short"
      v-if="showShortActions"
    >
      <button class="notification__action-btn" @click.stop="declineRequest">
        <DeclineIcon />
      </button>
      <button class="notification__action-btn" @click.stop="acceptRequest">
        <AcceptIcon />
      </button>
    </div>
    <LoadingOverlay v-if="loading">
      {{ $t("default.loading") }}
    </LoadingOverlay>
  </div>
</template>

<script>
import ACCEPT_REQUEST from "@/graphql/member-group/mutations/AcceptJoinRequest.graphql";
import DECLINE_REQUEST from "@/graphql/member-group/mutations/DeclineJoinRequest.graphql";
import NotificationGroupMixin from "@/components/mixins/NotificationGroupMixin";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";
import UserAvatar from "@/components/reusables/UserAvatar";
import AcceptIcon from "@/components/reusables/Icons/AcceptIcon";
import DeclineIcon from "@/components/reusables/Icons/DeclineIcon";

export default {
  name: "NotificationJoinRequestGroup",
  components: { UserAvatar, AcceptIcon, DeclineIcon },
  props: ["notification", "fullView"],
  mixins: [NotificationGroupMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    showFullActions() {
      return (
        this.fullView &&
        this.joinRequest &&
        this.joinRequest.status === "PENDING"
      );
    },
    showShortActions() {
      return (
        !this.fullView &&
        this.joinRequest &&
        this.joinRequest.status === "PENDING"
      );
    },
  },
  methods: {
    goToRequests() {
      this.$emit("closeNotifications");
      this.$router
        .push({
          name: "group.details.overview",
          params: {
            groupId: this.group.id,
          },
          query: {
            showRequests: true,
          },
        })
        .catch(() => {});
    },
    acceptRequest() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: ACCEPT_REQUEST,
          variables: {
            joinRequestId: this.joinRequest.id,
            ownerId: this.getNJUser.id,
          },
        })
        .then(() => {
          this.$emit("deleteNotification", true);
          this.$router.push({
            name: "group.details",
            params: { groupId: this.group.id },
          });
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_ACCEPT_REQUEST",
            this.$t("error.group.approve_request_error")
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    declineRequest() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: DECLINE_REQUEST,
          variables: {
            joinRequestId: this.joinRequest.id,
            ownerId: this.getNJUser.id,
          },
        })
        .then(() => {
          this.$emit("deleteNotification", false);
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DECLINE_REQUEST",
            this.$t("error.group.decline_request_error")
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getGroupRequestDetails();
  },
};
</script>

<style scoped lang="scss">
.notification-join-request-group {
  cursor: pointer;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
</style>
