<template>
  <div
    class="chevron"
    :class="`chevron__${direction} chevron--${size}`"
    :style="cssProps"
    @click="$emit('click')"
  ></div>
</template>

<script>
export default {
  name: "Chevron",
  props: {
    direction: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "small",
    },
    color: {
      type: String,
      default: "black",
    },
  },
  computed: {
    cssProps() {
      return {
        "--color": this.color,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.chevron {
  &::before {
    border-style: solid;
    content: "";
    display: inline-block;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    color: var(--color);
  }

  &__up::before {
    vertical-align: unset;
  }

  &__right:before {
    left: 0;
    transform: rotate(45deg);
  }

  &__down:before {
    top: 0;
    left: 0;
    transform: rotate(135deg);
  }

  &__left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }

  &--small::before {
    border-width: 1px 1px 0 0;
    height: 0.55em;
    width: 0.55em;
  }

  &--medium::before {
    height: 0.7em;
    width: 0.7em;
    border-width: 1.5px 1.5px 0 0;
  }

  &--large::before {
    border-width: 1.5px 1.5px 0 0;
    height: 1em;
    width: 1em;
  }
}
</style>
