<template>
  <div @click="$emit('click')">
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 1.50267V5.85535C24 6.22843 23.76 6.47716 23.4 6.47716C23.04 6.47716 22.8 6.22843 22.8 5.85535V2.12448H19.2C18.84 2.12448 18.6 1.87576 18.6 1.50267C18.6 1.12958 18.84 0.880859 19.2 0.880859H23.4C23.76 0.880859 24 1.12958 24 1.50267ZM4.8 0.880859H0.6C0.24 0.880859 0 1.12958 0 1.50267V5.85535C0 6.22843 0.24 6.47716 0.6 6.47716C0.96 6.47716 1.2 6.22843 1.2 5.85535V2.12448H4.8C5.16 2.12448 5.4 1.87576 5.4 1.50267C5.4 1.12958 5.16 0.880859 4.8 0.880859ZM23.4 12.6953C23.04 12.6953 22.8 12.944 22.8 13.3171V17.0479H19.2C18.84 17.0479 18.6 17.2967 18.6 17.6698C18.6 18.0428 18.84 18.2916 19.2 18.2916H23.4C23.76 18.2916 24 18.0428 24 17.6698V13.3171C24 12.944 23.76 12.6953 23.4 12.6953ZM4.8 17.0479H1.2V13.3171C1.2 12.944 0.96 12.6953 0.6 12.6953C0.24 12.6953 0 12.944 0 13.3171V17.6698C0 18.0428 0.24 18.2916 0.6 18.2916H4.8C5.16 18.2916 5.4 18.0428 5.4 17.6698C5.4 17.2967 5.16 17.0479 4.8 17.0479Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "FullScreenEnter",
};
</script>

<style scoped lang="scss"></style>
