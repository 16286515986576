<template>
  <div
    class="pdf-preview-wrapper"
    @keydown.esc="$emit('close', currentPage)"
    tabindex="0"
    ref="pdfViewerWrapper"
  >
    <div class="action-bar">
      <div class="pdf-preview-wrapper__text-search">
        <div class="pdf-preview-wrapper__text-search-box">
          <SearchField
            ref="searchInput"
            v-model="keyword"
            @clearSearch="keyword = ''"
            @input="handleMatchChange(false, canFindNext)"
          />
        </div>
        <div class="pdf-preview-wrapper__text-search-chevrons" v-if="keyword">
          <Chevron
            direction="up"
            class="pdf-preview-wrapper__text-search-chevrons--up"
            @click="handleMatchChange(true, canFindPre)"
            :class="[{ active: canFindNext }]"
          />
          <Chevron
            direction="down"
            class="pdf-preview-wrapper__text-search-chevrons--down"
            :class="[{ active: canFindNext }]"
            @click="handleMatchChange(false, canFindNext)"
          />
        </div>
      </div>
      <div class="pdf-preview-wrapper__controls">
        <!-- pages-->
        <div class="pdf-preview-wrapper__pages">
          <span>{{ currentPage }}</span>
          <span>/{{ pages }}</span>
        </div>

        <!-- zoom in-->
        <div class="pdf-preview-wrapper__scale-controller">
          <span
            class="
              pdf-preview-wrapper__scale-controller-btns
              pdf-preview-wrapper__scale-controller-btns--minus
            "
            :class="[{ active: canNarrow }]"
            @click="handleScaleChange(-25, canNarrow)"
          ></span>
          <span class="pdf-preview-wrapper__scale-percent">
            {{ currentScale }}%
          </span>
          <span
            class="
              pdf-preview-wrapper__scale-controller-btns
              pdf-preview-wrapper__scale-controller-btns--plus
            "
            :class="[{ active: canEnlarge }]"
            @click="handleScaleChange(25, canEnlarge)"
          ></span>
        </div>
      </div>

      <!-- close full screen-->
      <CloseDialogButton
        @click="$emit('close', currentPage)"
        class="pdf-preview-wrapper__screen-icon"
      />
    </div>

    <!-- pdf-->
    <div class="pdf-container">
      <pdf-viewer
        id="pdf"
        ref="pdfViewer"
        :src="src"
        @on-loaded="updateTotalPages"
        @on-search="handleSearchResult"
        @on-page-change="handlePageChangeFromPDFViewer($event, true)"
      ></pdf-viewer>
    </div>
  </div>
</template>

<script>
import SearchField from "@/components/reusables/SearchField";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import PdfViewer from "@/components/library/readable/PdfViewer";
import Chevron from "@/components/reusables/Icons/Chevron";

export default {
  name: "PdfViewerWrapper",
  props: ["src"],
  components: {
    CloseDialogButton,
    Chevron,
    PdfViewer,
    SearchField,
  },
  data() {
    return {
      minScale: 50,
      maxScale: 400,
      currentScale: 100,
      pages: 0,
      currentPage: 1,
      keyword: "",
      currentMatch: 0,
      totalMatch: 0,
    };
  },
  computed: {
    canEnlarge() {
      return this.currentScale < this.maxScale;
    },
    canNarrow() {
      return this.currentScale > this.minScale;
    },
    canPageUp() {
      return this.currentPage < this.pages;
    },
    canPageDown() {
      return this.currentPage > 1;
    },
    canFindPre() {
      return this.currentMatch > 1;
    },
    canFindNext() {
      return this.currentMatch < this.totalMatch;
    },
  },
  watch: {
    keyword(newVal) {
      this.$refs.pdfViewer.cancelSearch();
      if (newVal) this.$refs.pdfViewer.search(newVal);
    },
    showSearchBar(newVal) {
      if (newVal) this.$nextTick(() => this.$refs.searchInput.focus());
      else this.$refs.pdfViewer.cancelSearch();
    },
  },
  mounted() {
    this.$refs.pdfViewerWrapper.focus();
    document.onkeydown = (e) => {
      if (e.ctrlKey && e.key === "f") {
        this.showSearchBar = true;
        e.preventDefault();
      } else if (e.key === "Escape") {
        this.showSearchBar = false;
      }
    };
    //--- grab scroll ---//
    const el = document.getElementsByClassName("viewer-container")[0];
    el.style.cursor = "grab";
    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function (e) {
      el.style.cursor = "grabbing";
      el.style.userSelect = "none";
      pos = {
        left: el.scrollLeft,
        top: el.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };
      el.addEventListener("mousemove", mouseMoveHandler);
      el.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;
      // Scroll the element
      el.scrollTop = pos.top - dy;
      el.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function () {
      el.style.cursor = "grab";
      el.style.removeProperty("user-select");
      el.removeEventListener("mousemove", mouseMoveHandler);
      el.removeEventListener("mouseup", mouseUpHandler);
    };
    // Attach the handler
    el.addEventListener("mousedown", mouseDownHandler);
  },
  methods: {
    updateTotalPages(pages) {
      this.pages = pages;
    },
    handleScaleChange(value, changeable) {
      if (changeable) {
        this.currentScale += value;
        this.$refs.pdfViewer.zoom(this.currentScale / 100);
      }
    },
    handlePageChange(value, changeable) {
      if (changeable) this.currentPage += value;
      this.$refs.pdfViewer.jumpToPage(this.currentPage);
    },
    handlePageChangeFromPDFViewer(page) {
      this.currentPage = page;
      this.$emit("on-page-change", page);
    },
    // handlePageChangeByInput() {
    //   const el = this.$refs.pageInput;
    //   if (isNaN(el.value.trim()))
    //     return this.$message.error(this.$t("error.wrong_page_number"));
    //   const page = parseFloat(el.value.trim());
    //   if (page < 1 || page > this.pages || page % 1 !== 0)
    //     return this.$message.error(this.$t("error.wrong_page_number"));
    //   el.blur();
    //   this.currentPage = page;
    //   this.$refs.pdfViewer.jumpToPage(this.currentPage);
    // },
    handleMatchChange(prev, changeable) {
      if (changeable) {
        this.$refs.pdfViewer.searchAgain(prev);
        this.currentMatch += prev ? -1 : 1;
      }
    },
    handleSearchResult({ current, total }) {
      this.currentMatch = current;
      this.totalMatch = total;
    },
  },
};
</script>

<style scoped lang="scss">
.pdf-preview-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  color: #606470;
  z-index: 10;
  border-top: 1px solid var(--nj-gray-3);
  background-color: var(--nj-white);

  .action-bar {
    height: 64px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    border-bottom: 1px solid var(--nj-gray-3);

    @media screen and (max-width: 600px) {
      padding: 10px !important;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  }
  &__text-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 35px;
    position: relative;

    @media (max-width: $mobile-max) {
      padding-right: 15px;
    }

    &-box {
      max-width: 280px;
      width: 100%;

      ::v-deep {
        .search-field {
          width: 220px;
        }
      }
    }

    &-chevrons {
      position: absolute;
      right: 0;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--up {
        color: var(--nj-gray-3);
        margin-left: 10px;

        &.active {
          color: black;
          cursor: pointer;
        }
      }

      &--down {
        color: var(--nj-gray-3);
        margin: 0 0 5px 10px;

        &.active {
          color: black;
          cursor: pointer;
        }
      }
    }
  }

  &__pages {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    padding: 10px 20px;
    color: var(--nj-gray-5);
    border-radius: 60px;
    border: 1px solid var(--nj-gray-5);
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
  }

  .main-controller {
    width: 30%;
  }

  &__scale-percent {
    width: 40px;
    text-align: center;
    display: block;
    margin: 0 12px;
    color: var(--nj-gray-5);
    font-size: 16px;
    line-height: 24px;
  }

  &__scale-controller {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 42px;
    padding: 10px 20px;
    align-items: center;
    border-radius: 60px;
    border: 1px solid var(--nj-gray-5);

    @media (max-width: $mobile-max) {
      display: none;
    }

    &-btns {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px;

      &--minus {
        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 1px;
          background: var(--nj-gray-5);
        }
      }
      &--plus {
        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 1px;
          background: var(--nj-gray-5);
        }
        &:after {
          content: "";
          width: 1px;
          height: 16px;
          background: var(--nj-gray-5);
          margin-left: -8px;
        }
      }

      &.active {
        color: var(--nj-gray-5);
      }
    }
  }

  .pdf-container {
    width: 100%;
    height: calc(100% - 40px);
    position: relative;
    box-sizing: border-box;
    background: rgba(53, 50, 48, 0.8);
  }

  &__screen-icon {
    cursor: pointer;
    margin-left: 205px;
    flex-shrink: 0;

    @media (max-width: $mobile-max) {
      margin-left: 0;
    }
  }

  .viewer-container {
    overflow-x: auto;
  }
}
</style>
