<template>
  <div
    tabindex="0"
    ref="editDailyFeedPopup"
    @keydown.esc="closeDialog"
    class="edit-daily-feed-popup base-modal"
    v-if="dialog"
    :class="{
      'edit-daily-feed-popup--readable': form.contentType === 'READABLE',
    }"
  >
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="closeDialog"
    >
      <div
        class="edit-daily-feed-popup__inner base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="edit-daily-feed-popup__head base-modal__head">
          <ArrowBackIcon
            @click="goToUploadView"
            v-if="criteriaView"
            class="edit-daily-feed-popup__back-btn"
          />
          <CloseDialogButton @click="cancelDialog" class="base-modal__close" />
          <h1 class="edit-daily-feed-popup__title base-modal__head-title">
            {{
              criteriaView
                ? $t("daily_feeds.user_add.criteria_title")
                : $t("daily_feeds.user_edit.title")
            }}
          </h1>
        </div>
        <div
          class="base-modal__body edit-daily-feed-popup__body"
          ref="viewsContainer"
        >
          <form class="edit-daily-feed-popup__form base-modal__form base-form">
            <div class="edit-daily-feed-popup__form-inner">
              <div class="edit-daily-feed-popup__description">
                <p class="edit-daily-feed-popup__text">
                  {{ $t("daily_feeds.user_add.description_1") }}
                </p>
              </div>
              <div class="edit-daily-feed-popup__upload">
                <div class="form-file-input">
                  <div class="form-file-input__label-wrap">
                    <span class="form-file-input__icon">
                      <PaperClipIcon />
                    </span>
                    <span class="form-file-input__control">
                      <span class="form-file-input__label">
                        {{ $t("daily_feeds.user_add.upload_media_label") }}
                      </span>
                      <span class="form-file-input__field-wrap">
                        <span class="form-file-input__field">
                          {{ currentFileName }}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="edit-daily-feed-popup__thumbnail"
                v-if="form.contentType === 'AUDIO' && !showLibrary"
              >
                <FormFileInput
                  v-model="form.thumbnail"
                  accept="image/png, image/jpeg"
                >
                  <template v-slot:label>
                    <span class="edit-daily-feed-popup__lib-label">
                      {{ $t("daily_feeds.user_edit.add_thumbnail") }}
                      <button
                        class="edit-daily-feed-popup__lib-btn"
                        type="button"
                        @click="showLibrary = true"
                      >
                        {{ $t("daily_feeds.user_edit.choose_from_library") }}
                      </button>
                    </span>
                  </template>
                </FormFileInput>
              </div>
              <div
                class="edit-daily-feed-popup__lib"
                v-if="form.contentType === 'AUDIO' && showLibrary"
              >
                <span class="edit-daily-feed-popup__lib-label">
                  <button
                    class="edit-daily-feed-popup__lib-btn"
                    type="button"
                    @click="showLibrary = false"
                  >
                    {{ $t("daily_feeds.user_edit.add_thumbnail") }}
                  </button>
                  {{ $t("daily_feeds.user_edit.choose_from_library") }}
                </span>
                <div class="edit-daily-feed-popup__lib-list">
                  <div
                    class="edit-daily-feed-popup__lib-item"
                    :key="'selected' + selectedImage.id"
                    v-if="selectedImage"
                  >
                    <img
                      :src="createThumbnailUrl(selectedImage.imageId)"
                      alt="thumbnail"
                    />
                  </div>

                  <div
                    class="edit-daily-feed-popup__lib-item"
                    :class="{
                      'edit-daily-feed-popup__lib-item--selected':
                        selectedImage && image.id === selectedImage.id,
                    }"
                    v-for="image in images"
                    :key="image.id"
                    @click="selectedImage = image"
                  >
                    <img
                      :src="createThumbnailUrl(image.imageId)"
                      alt="thumbnail"
                    />
                  </div>
                </div>
              </div>
              <div
                class="edit-daily-feed-popup__thumbnail"
                v-if="form.contentType === 'VIDEO'"
              >
                <FormFileInput
                  v-model="form.thumbnail"
                  :label="'Add new thumbnail Image [16:9]'"
                  accept="image/png, image/jpeg"
                  :optional="true"
                >
                </FormFileInput>
              </div>
              <div class="edit-daily-feed-popup__explanation">
                <p class="edit-daily-feed-popup__text">
                  {{ $t("daily_feeds.user_add.explanation") }}
                </p>
                <FormTextarea
                  v-model="$v.form.description.$model"
                  :counter="500"
                  :hasErrors="descriptionIsTooLong"
                >
                  <template v-slot:errors>
                    <p
                      class="base-form__errors-item"
                      v-if="descriptionIsTooLong"
                    >
                      {{ $t("daily_feeds.user_add.error_relevance_length") }}
                    </p>
                  </template>
                </FormTextarea>
              </div>
            </div>

            <div class="edit-daily-feed-popup__actions base-modal__actions">
              <div
                class="
                  base-modal__actions-item
                  edit-daily-feed-popup__actions-item--left
                "
              >
                <BaseButton variant="underline" @click="showCriteria">
                  {{ $t("daily_feeds.user_add.criteria_title") }}
                </BaseButton>
              </div>
              <div class="base-modal__actions-item">
                <BaseButton variant="secondary" @click="closeDialog">
                  {{ $t("daily_feeds.user_add.button_cancel_upload") }}
                </BaseButton>
              </div>
              <div class="base-modal__actions-item">
                <BaseButton
                  variant="primary"
                  @click="editDailyFeed"
                  :disabled="postButtonDisabled"
                >
                  {{ $t("daily_feeds.user_edit.button_save") }}
                </BaseButton>
              </div>
            </div>
          </form>
          <DailyFeedCriteria />
        </div>
      </div>
    </div>
    <UploadFile ref="uploadFile" />
    <DailyFeedInfoPopup ref="dailyFeedInfo" />
    <Confirm
      variant="user"
      ref="confirmClose"
      :decline-button-text="$t('confirm_close.stay_on_page')"
      :accept-button-text="$t('default.discard')"
      :reverse-button-functionality="true"
    />
  </div>
</template>

<script>
import EDIT_DAILY_FEED from "@/graphql/daily-feeds/mutations/EditDailyFeed.graphql";
import DAILY_FEED_BY_ID from "@/graphql/daily-feeds/queries/DailyFeedById.graphql";
import GET_LIBRARY_IMAGES from "@/graphql/admin/queries/AllLibraryImages.graphql";
import COPY_LIBRARY_IMAGE from "@/graphql/admin/mutations/CopyLibraryImage.graphql";
import PaperClipIcon from "@/components/reusables/Icons/PaperClipIcon";
import FormFileInput from "@/components/forms/FormFileInput";
import FormTextarea from "@/components/forms/FormTextarea";
import DailyFeedInfoPopup from "@/components/library/daily-feed/DailyFeedInfoPopup";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import UploadFile from "@/components/reusables/UploadFile";
import ArrowBackIcon from "@/components/reusables/Icons/ArrowBackIcon";
import DailyFeedCriteria from "@/components/library/daily-feed/DailyFeedCriteria";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate/src";
import { maxLength } from "vuelidate/lib/validators";
import errorHandler from "@/service/errorHandler";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";
import Confirm from "@/components/reusables/Confirm";

export default {
  name: "EditDailyFeedPopup",
  components: {
    Confirm,
    UploadFile,
    FormFileInput,
    DailyFeedInfoPopup,
    CloseDialogButton,
    FormTextarea,
    ArrowBackIcon,
    PaperClipIcon,
    DailyFeedCriteria,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        description: "",
        contentType: "",
        thumbnail: null,
      },
      dialog: false,
      criteriaView: false,
      showLibrary: false,
      images: [],
      selectedImage: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        document.body.style.overflow = "hidden";
        setTimeout(() => {
          this.$refs.editDailyFeedPopup.focus();
        }, 200);
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    descriptionIsTooLong() {
      return (
        this.$v.form.description.$dirty && !this.$v.form.description.maxLength
      );
    },
    postButtonDisabled() {
      return this.descriptionIsTooLong;
    },
  },
  methods: {
    createThumbnailUrl(imageId) {
      return createS3ThumbnailUrl(imageId, 136, 136);
    },
    open(dailyFeedId) {
      this.initForm(dailyFeedId);
    },
    async getDailyFeed(dailyFeedId) {
      return this.$apollo.query({
        query: DAILY_FEED_BY_ID,
        variables: {
          dailyFeedId,
        },
      });
    },
    async initForm(dailyFeedId) {
      const res = await this.getDailyFeed(dailyFeedId);
      const dailyFeed = res.data.dailyFeed;

      this.dailyFeedId = dailyFeedId;
      this.currentFileName = dailyFeed.storageInfo.originalFilename;
      this.form = {
        description: dailyFeed.description,
        contentType: dailyFeed.type,
      };
      this.dialog = true;
    },
    goToUploadView() {
      this.$refs.viewsContainer.scrollLeft = 0;
      this.criteriaView = false;
    },
    showCriteria() {
      this.criteriaView = true;
      this.$refs.viewsContainer.scrollLeft = 522;
    },
    async closeDialog() {
      if (this.$v.form.$anyDirty || this.form.thumbnail) {
        if (
          !(await this.$refs.confirmClose.open(
            this.$t("confirm_close.changes_generic.title"),
            this.$t("confirm_close.changes_generic.message")
          ))
        ) {
          return;
        }
      }
      this.cancelDialog();
    },
    cancelDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        description: "",
        contentType: "",
        thumbnail: null,
      };
      this.selectedImage = null;
      this.criteriaView = false;
      this.showLibrary = false;
      this.$refs.viewsContainer.scrollLeft = 0;
    },
    showSuccessPopup() {
      this.cancelDialog();
      this.$refs.dailyFeedInfo.open(
        this.$i18n.t("daily_feeds.user_add.upload_success_title"),
        this.$i18n.t("daily_feeds.user_add.upload_success_message")
      );
    },
    async editDailyFeed() {
      this.$v.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let thumbnailId = null;
      let variables = {
        id: this.dailyFeedId,
        description: this.form.description,
        type: this.form.contentType,
        userId: this.getNJUser.id,
      };

      if (this.form.thumbnail && this.form.contentType !== "READABLE") {
        thumbnailId = await this.$refs.uploadFile.upload(this.form.thumbnail);
        variables.thumbnailId = thumbnailId;
      } else if (
        this.form.contentType === "AUDIO" &&
        this.showLibrary &&
        this.selectedImage
      ) {
        const res = await this.$apollo.mutate({
          mutation: COPY_LIBRARY_IMAGE,
          variables: {
            imageStorageId: this.selectedImage.imageId,
          },
        });

        variables.thumbnailId = res.data.copyLibraryImage;
      }

      this.$refs.uploadFile.openUploadDialog(
        this.$t("daily_feeds.user_edit.save_edit_title"),
        this.$t("default.wait"),
        false
      );

      await this.$apollo
        .mutate({
          mutation: EDIT_DAILY_FEED,
          variables,
        })
        .then(async () => {
          this.$refs.uploadFile.closeUploadDialog();
          this.showSuccessPopup();
          this.$emit("editedFeed");
        })
        .catch(async (error) => {
          await errorHandler(
            error,
            "EDIT_DAILY_FEED",
            this.$i18n.t("error.library.daily_feeds.file_upload_error")
          );
          this.$refs.uploadFile.closeUploadDialog();
          this.cancelDialog();
        });
    },
  },
  validations() {
    if (this.dialog) {
      return {
        form: {
          description: { maxLength: maxLength(500) },
        },
      };
    }
  },
  apollo: {
    images: {
      query: GET_LIBRARY_IMAGES,
      update(data) {
        this.selectedImage = data.getAllLibraryImages[0];
        return data.getAllLibraryImages;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_GET_LIBRARY_IMAGES",
          this.$i18n.t("error.library.daily_feeds.fetch_images_error")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-daily-feed-popup {
  color: var(--nj-black);

  &--hidden {
    background: transparent;

    .base-modal__dialog {
      opacity: 0;
      pointer-events: none;
    }
  }

  &--readable {
    .edit-daily-feed-popup {
      &__inner {
        height: 555px;
      }
    }
  }

  &__inner {
    text-align: left;
    width: 100%;
    max-width: 522px;
    height: 655px;
  }

  &__head {
    padding-top: 7px;
  }

  &__back-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }

  &__body {
    position: absolute;
    height: calc(100% - 60px);
    top: 60px;
    width: 100%;
    overflow: hidden;
    display: flex;
    scroll-behavior: smooth;
    padding: 0;
  }

  &__form {
    padding: 24px;
    min-width: 100%;
    overflow: auto;
  }

  &__title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__text {
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    margin-top: 0 !important;

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }

  &__upload {
    margin-top: 35px;
    margin-bottom: 10px;

    .form-file-input {
      position: relative;
      text-align: left;
      margin-bottom: 35px;

      &__label {
        display: block;
        color: var(--form-input-label-color);
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        transition: all 0.3s ease-out;
        white-space: nowrap;
        margin-bottom: 10px;
        font-weight: var(--font-weight-medium);

        &-wrap {
          position: relative;
          display: flex;
        }
      }

      &__control {
        position: relative;
        flex-grow: 1;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-right: 15px;
        width: 24px;
        position: absolute;
        left: 5px;
        top: 36px;
        z-index: 1;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &__file {
        display: block;
        left: 0;
        width: 100%;
        height: 37px;
        position: absolute;
        opacity: 0;
      }

      &__field {
        display: block;
        width: 100%;
        color: var(--form-input-color);
        border: 1px solid var(--form-input-border-color);
        background-color: var(--nj-gray-2);
        border-radius: 4px;
        line-height: 20px;
        font-size: 16px;
        padding: 8px 10px 8px 35px;
        height: 41px;
      }
    }
  }

  &__explanation {
    margin-top: 30px;
  }

  &__btn {
    display: inline-flex;
    align-items: center;

    span {
      font-weight: var(--font-weight-medium);
    }

    ::v-deep {
      .plus {
        margin-right: 7px;

        &:before {
          width: 2px;
        }

        &:after {
          height: 2px;
        }
      }
    }
  }

  &__actions {
    align-items: center;

    &-item {
      &--left {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }

  &__lib {
    &-label {
      font-size: 14px;
      line-height: 17px;
      font-weight: var(--font-weight-medium);
    }

    &-btn {
      font-size: 14px;
      line-height: 17px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
    }

    &-list {
      margin: 10px -4px 0;

      &:after {
        content: "";
        clear: both;
        display: table;
        height: 10px;
      }
    }

    &-item {
      padding: 0 4px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 4px;
      width: 48px;
      height: 40px;

      &--selected {
        img {
          border: 2px solid var(--nj-green);
        }

        width: 48px;
        height: 40px;
      }

      &:first-child {
        width: 144px;
        height: 136px;
        float: left;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  ::v-deep {
    .form-textarea__field {
      height: 100px;
    }
  }
}
</style>
