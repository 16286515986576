<template>
  <div class="add-provocation-popup base-modal" @keydown.esc="closePopup">
    <BaseButton @click="openPopup" class="add-provocation-popup__btn" variant="primary">
      <PlusSign color="black" />
      <span class="base-button__text">
        {{ $t("daily_provocation.provoke_button") }}
      </span>
    </BaseButton>
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="closePopup"
    >
      <div
        class="add-provocation-popup__inner base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="add-provocation-popup__head base-modal__head">
          <CloseDialogButton @click="closePopup" class="base-modal__close" />
          <h1 class="add-provocation-popup__title base-modal__head-title">
            {{ $t("new_provocation.add_title") }}
          </h1>
        </div>
        <div class="add-provocation-popup__body base-modal__body">
          <p class="add-provocation-popup__text">
            {{ $t("new_provocation.text") }}
          </p>
          <p class="add-provocation-popup__text">
            {{ $t("new_provocation.criteria.look") }}
            <span @click="toggleRequirements">
              {{ $t("new_provocation.criteria.criteria_and_requirements") }}
            </span>
            {{ $t("new_provocation.criteria.before_start") }}
          </p>

          <div
            v-if="showRequirements"
            class="add-provocation-popup__requirements"
          >
            <span class="add-provocation-popup__requirements-title">
              {{ $t("new_provocation.criteria.title") }}
            </span>
            <span class="add-provocation-popup__requirements-text">
              <span class="add-provocation-popup__requirements-subtitle">
                {{ $t("new_provocation.criteria.content_title") }}
              </span>
              {{ $t("new_provocation.criteria.content_text") }}
            </span>
            <span class="add-provocation-popup__requirements-text">
              <span class="add-provocation-popup__requirements-subtitle">
                {{ $t("new_provocation.criteria.relevance_title") }}
              </span>
              {{ $t("new_provocation.criteria.relevance_text") }}
            </span>
            <span class="add-provocation-popup__requirements-text">
              <span class="add-provocation-popup__requirements-subtitle">
                {{ $t("new_provocation.criteria.guidelines_title") }}
              </span>
              {{ $t("new_provocation.criteria.guidelines_text") }}
            </span>
          </div>

          <div class="add-provocation-popup__actions base-modal__actions">
            <div
              class="
                add-provocation-popup__actions-item
                base-modal__actions-item
              "
            >
              <BaseButton variant="primary" @click="openRecordPopup()">
                <VideoCameraIcon class="add-provocation-popup__button-icon" />
                <span class="base-button__text">{{
                  $t("new_provocation.record_button")
                }}</span>
              </BaseButton>
            </div>
            <div
              class="
                add-provocation-popup__actions-item
                base-modal__actions-item
              "
            >
              <BaseButton variant="secondary" @click="openUploadPopup()">
                <UploadIcon class="add-provocation-popup__button-icon" />
                <span class="base-button__text">{{
                  $t("new_provocation.upload_button")
                }}</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import PlusSign from "@/components/reusables/Icons/PlusSign";
import VideoCameraIcon from "@/components/reusables/Icons/VideoCameraIcon";
import UploadIcon from "@/components/reusables/Icons/UploadIcon";
import { mapGetters } from "vuex";

export default {
  name: "AddProvocationPopup",
  components: {
    UploadIcon,
    VideoCameraIcon,
    CloseDialogButton,
    PlusSign,
  },
  data() {
    return {
      dialog: false,
      showRequirements: false,
      showSubscribeTooltip: false,
    };
  },
  watch: {
    dialog() {
      this.dialog
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "auto");
    },
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  methods: {
    goToSubscriptions() {
      const userRegistrationInput = {
        emailAddress: this.getNJUser.email,
        njProductId: null,
      };
    
      this.$router.push({
        name: "payment.subscriptions",
        params: {
          userRegistrationInput: userRegistrationInput,
          disabledFreeTrial: true,
        },
      });
    },
    toggleRequirements() {
      this.showRequirements = !this.showRequirements;
    },
    openRecordPopup() {
      this.$emit("openRecordPopup");
      this.closePopup();
    },
    openUploadPopup() {
      this.$emit("openUploadPopup");
      this.closePopup();
    },
    openPopup() {
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
      this.showRequirements = false;
    },
    hideSubscriberTooltip() {
      const box = document.querySelector(".add-provocation-popup__btn");
    
      if (box) {
        if (!box.contains(event.target)) {
          this.showSubscribeTooltip = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.hideSubscriberTooltip);
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    document.removeEventListener("click", this.hideSubscriberTooltip);
  },
};
</script>

<style lang="scss" scoped>
.add-provocation-popup {
  color: var(--nj-black);

  &__button-icon {
    margin-right: 9px;
  }

  &__inner {
    width: 100%;
    max-width: 650px;
  }

  &__body {
    padding: 10px 55px 20px;
  }

  &__btn {
    padding: 12px 25px !important;
  }

  &__text {
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    font-weight: var(--font-weight-medium);
    margin-top: 0 !important;

    html[data-theme='oneclub'] & {
      font-weight: var(--font-weight-regular);
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
      cursor: pointer;

      html[data-theme='oneclub'] & {
        font-weight: var(--font-weight-regular);
      }
    }

    &:not(:first-child) {
      margin-top: 24px !important;
    }
  }
  
  &__trial-tooltip {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-49%);
    width: 205px;
    text-align: center;
    white-space: normal;
    color: var(--nj-gray-5);
    font-size: 14px;
    line-height: 18px;
    border-radius: 10px;
    border: 1px solid var(--nj-gray-2);
    background-color: var(--nj-gray-1);
    z-index: 3;
    
    &:before {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%) rotate(135deg);
      content: "";
      width: 20px;
      height: 20px;
      background-color: var(--nj-gray-1);
      border: 1px solid var(--nj-gray-2);
      z-index: 1;
    }
    
    &-inner {
      position: relative;
      z-index: 2;
      padding: 16px 10px;
      border-radius: 10px;
      background-color: var(--nj-gray-1);
    }
    
    a {
      color: var(--nj-blue);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__actions {
    margin-top: 50px;

    @media (max-width: $mobile-max) {
      flex-direction: column;
      align-items: center;
    }

    &-item {
      &:not(:first-child) {
        @media (max-width: $mobile-max) {
          margin: 20px 0 0 0;
        }
      }
    }
  }

  &__requirements {
    padding: 5px 0 5px;

    &-title {
      font-weight: var(--font-weight-medium);
      margin-bottom: 5px;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &-subtitle {
      font-weight: var(--font-weight-medium);
    }

    &-text {
      margin-bottom: 8px;
    }

    span {
      display: block;
    }
  }

  .base-button {
    display: inline-flex;
    align-items: center;
    height: 42px;

    &__text {
      font-size: 14px;
      line-height: 18px;
      font-weight: var(--font-weight-medium);

      html[data-theme='oneclub'] & {
        //font-size: 18px;
        font-weight: var(--font-weight-light);
      }
    }

    ::v-deep {
      .plus {
        margin-right: 7px;

        &:before {
          width: 2px;
        }

        &:after {
          height: 2px;
        }
      }
    }
  }
}
</style>
