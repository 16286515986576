<template>
  <div
    :class="[
      'form-textarea',
      {
        'form-textarea--errors': hasErrors,
      },
    ]"
  >
    <label class="form-textarea__label" v-if="label.length">
      {{ label }}
      <span v-if="optional" class="form-textarea__label-optional">
        (optional)
      </span>
    </label>
    <div class="form-textarea__field-wrap">
      <textarea
        ref="textarea"
        class="form-textarea__field"
        :value="value"
        @input="changeInput"
        @focus="focused = true"
        @blur="focused = false"
        :rows="rows"
        :placeholder="placeholder"
      ></textarea>
    </div>
    <div
      :class="[
        'base-form__counter',
        { 'base-form__counter--error': this.value.length > this.counter },
      ]"
      v-if="value && counter"
    >
      {{ value.length }} / {{ counter }}
    </div>
    <div class="base-form__errors" v-if="hasErrors">
      <slot name="errors"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormTextarea",
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    counter: {
      type: Number,
      default: null,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  watch: {
    value() {
      const el = this.$refs.textarea;
      el.style.height = "0";
      el.style.height = el.scrollHeight + "px";
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    changeInput(event) {
      this.$emit("input", event.target.value);
    },
  },
  mounted() {
    setTimeout(() => {
      const el = this.$refs.textarea;
      el.setAttribute(
        "style",
        "height:" + el.scrollHeight + "px;overflow-y:hidden;"
      );
    });
  },
};
</script>

<style lang="scss" scoped>
.form-textarea {
  position: relative;
  text-align: left;
  margin-bottom: 35px;

  &--errors {
    .form-textarea {
      &__field {
        border-color: var(--form-error-color) !important;
        background-color: var(--form-error-background);
      }

      &__counter {
        color: var(--form-error-color);
      }
    }
  }

  .base-form {
    &__counter {
      position: absolute;
      margin-top: 5px;
    }

    &__errors-item {
      position: absolute;
    }
  }

  &__label {
    display: block;
    color: var(--form-input-label-color);
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    transition: all 0.3s ease-out;
    white-space: nowrap;
    margin-bottom: 10px;
    font-weight: var(--font-weight-medium);

    &-optional {
      color: var(--nj-gray-4);
    }
  }

  &__field {
    display: block;
    width: 100%;
    color: var(--form-input-color);
    border: 1px solid var(--form-input-border-color);
    border-radius: 4px;
    line-height: 20px;
    font-size: 16px;
    padding: 8px 10px;
    outline: none;
    height: 40px;
    min-height: 40px;

    &-wrap {
      position: relative;
    }
  }
}
</style>
