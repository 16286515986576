/**
 * Roles must be in sync with the Cognito groups!
 *
 * @type {{SUPER_ADMIN: string, NEURAL_JAMMER: string, NEURAL_JAMMER: string, CONTENT_MANAGER: string, NEURAL_JAMMER: string}}
 */
export const Role = {
  GUEST: "GUEST",
  NEURAL_JAMMER: "NEURAL_JAMMER",
  CONTENT_MANAGER: "CONTENT_MANAGER",
  USER_ADMIN: "USER_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
};

/**
 * Checks, if the given user has any of the roles in <code>requiredRoles</code>
 *
 * @param userGroups the Cognito groups of the user
 * @param requiredRoles the list of Roles required
 * @returns {boolean} true, if any of the given groups is included in the given list of required roles or if the given
 * list of required roles is empty (meaning any authenticated user is allowed)
 */
export function hasAnyRole(userGroups, requiredRoles) {
  if (requiredRoles.length === 0) {
    // no specific role required
    return true;
  }

  return userGroups.some((group) => requiredRoles.includes(group));
}
