<template>
  <div class="pdf-background">
    <div class="pdf-background__section--left">
      <svg
        v-if="!pdfLoading"
        :class="{ visible: page > 1, hidden: page <= 1 }"
        @click="pageMinus"
        class="pdf-background__chevron--left"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="black" />
        <path
          d="M22 26L14.1475 18.6383L22 11.2766"
          stroke="white"
          stroke-width="2.08333"
        />
      </svg>
      <div v-if="!pdfLoading" class="pdf-background__page-numbers">
        {{ page }}/{{ pageCount }}
      </div>
    </div>
    
    <div class="pdf-background__pdf-container" v-if="pdfUrl">
      <VuePdfEmbed
        ref="pdfRef"
        :source="pdfUrl"
        @progress="pdfLoadingProcess"
        @loaded="pdfLoaded"
        :page="page"
        class="pdf-background__pdf"
        @rendered="handleDocumentRender"
      ></VuePdfEmbed>
    </div>

    <div class="pdf-background__section--right">
      <svg
        @click="pagePlus"
        class="pdf-background__chevron--right"
        :class="{
          visible: page < pageCount,
          hidden: page === pageCount,
        }"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="black" />
        <path d="M14 11L22 18.5L14 26" stroke="white" stroke-width="2" />
      </svg>
      <FullScreenEnter
        @click="openToFullScreen"
        v-if="!pdfLoading"
        class="pdf-background__fullscreen-enter"
      />
    </div>

    <PdfViewerWrapper
      v-if="fullScreenView"
      :src="pdfUrl"
      @close="closeToFullScreen"
      @on-page-change="onPageChange"
    />
  </div>
</template>

<script>
// import pdf from "vue-pdf";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import PdfViewerWrapper from "@/components/library/readable/PdfViewerWrapper";
import FullScreenEnter from "@/components/reusables/Icons/FullScreenEnter";
import { mapGetters } from "vuex";

export default {
  name: "PdfViewerSmall",
  components: { FullScreenEnter, PdfViewerWrapper, VuePdfEmbed },
  props: ["pdfUrl"],
  data() {
    return {
      pageCount: 1,
      page: 1,
      pdfLoading: false,
      fullScreenView: false,
      textSearch: "conditionals",
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  methods: {
    handleDocumentRender() {
      this.pdfLoaded();
      this.pageCount = this.$refs.pdfRef.pageCount
    },
    onPageChange(event) {
      this.$emit("scrolled", {
        pageCount: this.pageCount,
        page: event,
      });
    },
    openToFullScreen() {
      this.$emit("opened", {
        pageCount: this.pageCount,
        page: 1,
      });
      this.fullScreenView = true;

      if (
        document.querySelector(
          ".group-content-viewer .glide-readable .glide__slides"
        )
      ) {
        document
          .querySelector(".group-content-viewer .glide-readable .glide__slides")
          .classList.add("glide__slides--no-scroll");
      }
      if (document.querySelector(".daily-feed-popup .glide__slides")) {
        document
          .querySelector(".daily-feed-popup .glide__slides")
          .classList.add("glide__slides--no-scroll");
      }
      if (document.querySelector(".daily-feed-popup__close")) {
        document.querySelector(".daily-feed-popup__close").style.zIndex = 1;
      }
      if (document.querySelector(".daily-feed-popup__go-left-btn")) {
        document.querySelector(
          ".daily-feed-popup__go-left-btn"
        ).style.zIndex = 0;
      }
      if (document.querySelector(".daily-feed-popup__go-right-btn")) {
        document.querySelector(
          ".daily-feed-popup__go-right-btn"
        ).style.zIndex = 0;
      }
    },
    closeToFullScreen(event) {
      this.$emit("closed", {
        pageCount: this.pageCount,
        page: event,
      });
      this.page = event;
      this.fullScreenView = false;
      if (
        document.querySelector(
          ".group-content-viewer .glide-readable .glide__slides"
        )
      ) {
        document
          .querySelector(".group-content-viewer .glide-readable .glide__slides")
          .classList.remove("glide__slides--no-scroll");
      }
      if (document.querySelector(".daily-feed-popup .glide__slides")) {
        document
          .querySelector(".daily-feed-popup .glide__slides")
          .classList.remove("glide__slides--no-scroll");
      }
      if (document.querySelector(".daily-feed-popup__close")) {
        document.querySelector(".daily-feed-popup__close").style.zIndex = 3;
      }
      if (document.querySelector(".daily-feed-popup__go-left-btn")) {
        document.querySelector(
          ".daily-feed-popup__go-left-btn"
        ).style.zIndex = 10;
      }
      if (document.querySelector(".daily-feed-popup__go-right-btn")) {
        document.querySelector(
          ".daily-feed-popup__go-right-btn"
        ).style.zIndex = 10;
      }
    },
    pagePlus() {
      this.page += 1;
      this.$emit("scrolled", {
        pageCount: this.pageCount,
        page: this.page,
      });
    },
    pageMinus() {
      this.page -= 1;
      this.$emit("scrolled", {
        pageCount: this.pageCount,
        page: this.page,
      });
    },
    pdfLoadingProcess() {
      this.pdfLoading = true;
    },
    pdfLoaded() {
      this.pdfLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.pdf-background {
  background-color: var(--nj-gray-2);
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  min-height: 200px;

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
    pointer-events: none;
  }

  &__section {
    &--left {
      position: relative;
      display: flex;
    }

    &--right {
      position: relative;
      display: flex;
    }
  }

  &__chevron {
    &--left {
      cursor: pointer;
      margin: 0 4px 0 24px;
      align-self: center;
    }

    &--right {
      cursor: pointer;
      margin: 0 24px 0 4px;
      align-self: center;
    }
  }

  &__page-numbers {
    position: absolute;
    bottom: 0;
    margin: 24px;
  }

  &__pdf {
    display: inline-block;
    width: 253px;

    @media (max-width: 600px) {
      width: 140px;
    }

    &-container {
      display: flex;
      align-items: center;
      padding: 55px 0 65px 0;

      @media (max-width: 600px) {
        padding: 13px 0 13px 0;
      }
    }
  }

  &__fullscreen-enter {
    position: absolute;
    bottom: 0;
    right: 2px;
    margin: 24px;
    cursor: pointer;
  }

  &__loading-text {
    align-self: center;
    margin: 0 auto;
  }

  /* Chrome, Safari, Edge, Opera */
  input ::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
