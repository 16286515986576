import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Frontdoor from "@/views/Frontdoor";
import HomeMain from "@/components/home/HomeMain";
import { hasAnyRole, Role } from "@/components/security/Role";
import { hasRedirectBasedOnAccessRights } from "@/components/security/RouteAccess";
import adminRoutes from "./admin-routes";
import campusRoutes from "./campus-routes";
import libraryRoutes from "./library-routes";
import communityRoutes from "./community-routes";
import liveSessionRoutes from "./live-session-routes";
import paymentRoutes from "./payment-routes";

Vue.use(VueRouter);

const routes = [
  {
    name: "frontdoor",
    component: Frontdoor,
    path: "/",
    children: [
      {
        name: "prefilledSignUp",
        path: "/prefilledSignUp/:useremail/:grantcode",
      },
      {
        name: "prefilledPaymentSignUp",
        path: "/prefilledPaymentSignUp/:useremail/:firstName/:lastName/:grantcode/",
      },
    ],
  },
  {
    path: "/home",
    component: Home,
    meta: { authorize: [] },
    children: [
      {
        path: "",
        name: "home",
        redirect: { name: "home.main" },
      },
      {
        path: "main",
        name: "home.main",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: HomeMain,
      },
      {
        path: "privacypolicy",
        name: "home.privacypolicy",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "privacy_policy" */ "@/components/misc/PrivacyPolicy"
          ),
      },
      {
        path: "termsAndConditions",
        name: "home.termAndConditions",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "terms_of_use" */ "@/components/misc/TermsAndConditions"
          ),
      },
    ],
  },
  {
    path: "/notebooks",
    name: "notebooks",
    meta: { authorize: [Role.NEURAL_JAMMER] },
    component: () =>
      import(/* webpackChunkName: "notebooks_main" */ "@/views/Notebooks"),
  },
  {
    path: "/record-answer",
    name: "recordAnswer",
    meta: { authorize: [Role.NEURAL_JAMMER] },
    component: () =>
      import(
        /* webpackChunkName: "record-answer" */ "@/components/record-answer/Record"
      ),
    props: true,
  },
  {
    path: "/record-provocation",
    name: "recordProvocation",
    meta: { authorize: [Role.NEURAL_JAMMER] },
    component: () =>
      import(
        /* webpackChunkName: "record-provocation" */ "@/components/provocation/Record"
      ),
    props: true,
  },
  {
    path: "/live-session-meet/:id",
    name: "liveSessionMeet",
    props: true,
    meta: {
      authorize: [Role.NEURAL_JAMMER],
    },
    component: () =>
      import(
        /* webpackChunkName: "live-session-meet" */ "@/views/LiveSessionMeet.vue"
      ),
  },
  {
    path: "/meet-up-meet/:id",
    name: "meetUpMeet",
    props: true,
    meta: {
      authorize: [Role.NEURAL_JAMMER],
    },
    component: () =>
      import(
        /* webpackChunkName: "meet-up-meet" */ "@/components/campus/meet-ups/MeetUpMeet"
      ),
  },
  {
    path: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard"),
    children: [
      {
        path: "",
        name: "dashboard",
        redirect: { name: "dashboard.main" },
      },
      {
        path: "main",
        name: "dashboard.main",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "dashboard_main" */ "@/components/dashboard/DashboardMain"
          ),
      },
    ],
  },
  {
    path: "/profile",
    component: () =>
      import(
        /* webpackChunkName: "user_profile" */ "@/components/user-profile/Profile"
      ),
    children: [
      {
        path: "",
        name: "profile.main",
        redirect: { name: "profile.user" },
      },
      {
        path: "user",
        name: "profile.user",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(
            /* webpackChunkName: "user_profile" */ "@/components/user-profile/UserProfile"
          ),
      },
      {
        path: "settings",
        name: "profile.settings",
        meta: { authorize: [Role.NEURAL_JAMMER] },
        component: () =>
          import(/* webpackChunkName: "settings" */ "@/views/AppSettings.vue"),
      },
    ],
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "signup_privacy_policy" */ "@/components/misc/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/terms-and-conditions",
    name: "termsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "signup_terms_of_use" */ "@/components/misc/TermsAndConditions"
      ),
  },
  {
    path: "/chat",
    name: "chat",
    meta: { authorize: [Role.NEURAL_JAMMER] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Chat.vue"),
  },
  {
    // otherwise redirect to home
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes.concat(
    adminRoutes,
    campusRoutes,
    communityRoutes,
    libraryRoutes,
    liveSessionRoutes,
    paymentRoutes
  ),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve(async (to, from, next) => {
  const { authorize } = to.meta;
  let user = null;

  if (authorize) {
    try {
      user = await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
    } catch (e) {
      // error fetching user - redirect to login
      return next({ name: "frontdoor" });
    }
    if (!user) {
      // not logged in so redirect to login page with the return url
      return next({ name: "frontdoor" });
    }
    const payload = user
      .getSignInUserSession()
      .getAccessToken()
      .decodePayload();
    const userGroups = payload["cognito:groups"];

    // check if route is restricted by role
    if (authorize.length && !hasAnyRole(userGroups, authorize)) {
      // role not authorised so redirect to home page
      return next({ name: "home" });
    }

    // check if route is restricted by username
    if (authorize.length) {
      const toRoute = await hasRedirectBasedOnAccessRights(
        payload.username,
        to,
        authorize
      );

      if (toRoute) {
        return next(toRoute);
      }
    }
  }
  // no authorization required or authorization is successful
  next();
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log("router.onError - reloading window.location");
    window.location.reload();
  }
});

export default router;
