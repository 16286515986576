const state = {
  provocation: null,
};

const mutations = {
  setProvocation(state, provocation) {
    state.provocation = provocation;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
