const routes = [
  {
    path: "/payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "@/views/Payment"),
    children: [
      {
        path: "",
        name: "payment",
        redirect: { name: "payment.signup" },
      },
      {
        path: "signup/:productName",
        name: "payment.signup",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "payment_signup" */ "@/components/payment/PaymentSignup"
          ),
      },
      {
        path: "subscriptions",
        name: "payment.subscriptions",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "payment_subscriptions" */ "@/components/payment/PaymentSubscriptions"
          ),
      },
      {
        path: "expired",
        name: "payment.expired",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "payment_expired" */ "@/components/payment/PaymentExpired"
          ),
      },
    ],
  },

  // legacy logic
  {
    path: "/payment-register/:productName",
    name: "paymentRegister",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "payment-register" */ "@/views/PaymentRegister"
      ),
  },
  {
    path: "/payment-success-create",
    name: "paymentSuccessCreate",
    component: () =>
      import(
        /* webpackChunkName: "payment-success-create" */ "@/views/PaymentSuccessCreate"
      ),
  },
  {
    path: "/payment-success-update",
    name: "paymentSuccessUpdate",
    component: () =>
      import(
        /* webpackChunkName: "payment-success-update" */ "@/views/PaymentSuccessUpdate"
      ),
  },
  {
    path: "/payment-success-campus",
    name: "paymentSuccessCampus",
    component: () =>
        import(
            /* webpackChunkName: "payment-success-campus" */ "@/views/PaymentSuccessCampus"
            ),
  },
  {
    path: "/payment-cancel",
    name: "paymentCancel",
    component: () =>
      import(/* webpackChunkName: "payment-cancel" */ "@/views/PaymentCancel"),
  },
  {
    path: "/payment-error/:errorCode",
    name: "paymentError",
    props: true,
    component: () =>
      import(/* webpackChunkName: "payment-error" */ "@/views/PaymentError"),
  },
];

export default routes;
