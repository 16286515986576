import createS3ThumbnailUrl from "@/service/createThumbnailUrl";

const WhatsNewItemMixin = {
  methods: {
    createThumbnailUrl(thumbnailId) {
      return createS3ThumbnailUrl(thumbnailId, 150, 75);
    },
    updateCarousel() {
      this.$emit("updateCarousel");
    },
    displayError() {
      this.$emit("error");
    },
    moveWhatsNew(payload) {
      this.$emit("moveWhatsNew", payload);
    },
  },
};

export default WhatsNewItemMixin;
