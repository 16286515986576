<template>
  <div
    v-if="group"
    :class="[
      'notification-declined-invite-to-group',
      'notification',
      { 'notification--full': fullView },
    ]"
  >
    <div class="notification__avatar">
      <UserAvatar :size="40" :user="notification.issuer" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.invite_declined", { groupName: group.name }) }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import NotificationGroupMixin from "@/components/mixins/NotificationGroupMixin";
import UserAvatar from "@/components/reusables/UserAvatar";

export default {
  name: "NotificationDeclinedInviteToGroup",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationGroupMixin],
  async mounted() {
    await this.getGroupRequestDetails();
  },
};
</script>
