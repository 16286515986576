<template>
  <div class="daily-provocation">
    <div class="daily-provocation__inner">
      <div class="daily-provocation__head">
        <div class="daily-provocation__head-info">
          <h1 class="daily-provocation__head-title">
            {{ $t("daily_provocation.title") }}
          </h1>
          <p class="daily-provocation__head-text">
            {{ $t("daily_provocation.text") }}
          </p>
        </div>
        <AddProvocationPopup
          @openRecordPopup="openRecordPopup"
          @openUploadPopup="openUploadPopup"
        />
      </div>
      <p
        v-if="!provocations.length && !$apollo.queries.provocations.loading"
        class="daily-provocation__empty"
      >
        {{ $t("daily_provocation.no_items") }}
      </p>
      <DailyProvocationCarousel
        v-if="provocations.length"
        :provocations="provocations"
        :total="total"
        :startIndex="startIndex"
        @fetchMoreProvocations="fetchMoreProvocations"
      />
    </div>
    <SelectThumbnailPopup
      ref="selectThumbnailPopup"
      @uploaded="$apollo.queries.provocations.refetch()"
    />
    <RecordProvocationPopup ref="recordProvocation" />
    <UploadProvocationPopup
      ref="uploadProvocation"
      @videoUploaded="openSelectThumbnailPopup"
    />
  </div>
</template>

<script>
import PROVOCATIONS from "@/graphql/provocations/queries/Provocations.graphql";
import DailyProvocationCarousel from "@/components/provocation/DailyProvocationCarousel";
import AddProvocationPopup from "@/components/provocation/AddProvocationPopup";
import RecordProvocationPopup from "@/components/provocation/RecordProvocationPopup";
import UploadProvocationPopup from "@/components/provocation/UploadProvocationPopup";
import SelectThumbnailPopup from "@/components/provocation/SelectThumbnailPopup";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";

let pageProvocation = 0;

export default {
  name: "DailyProvocation",
  components: {
    AddProvocationPopup,
    RecordProvocationPopup,
    UploadProvocationPopup,
    SelectThumbnailPopup,
    DailyProvocationCarousel,
  },
  data() {
    return {
      provocations: [],
      maxPage: null,
      total: 0,
      startIndex: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername"]),
  },
  methods: {
    fetchMoreProvocations() {
      if (pageProvocation + 1 !== this.maxPage) {
        pageProvocation++;

        let variables = {
          page: {
            page: pageProvocation,
            pageSize: 10,
          },
        };

        this.$apollo.queries.provocations.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newProvocations = fetchMoreResult.page.provocations;
            const lastIndex = this.provocations.length;

            this.maxPage = fetchMoreResult.page.maxPage;
            this.total = fetchMoreResult.page.total;
            this.provocations = [...this.provocations, ...newProvocations];
            this.startIndex = lastIndex;

            return {
              page: {
                __typename: previousResult.page.__typename,
                size: fetchMoreResult.page.size,
                maxPage: fetchMoreResult.page.maxPage,
                total: fetchMoreResult.page.total,
                provocations: [
                  ...previousResult.page.provocations,
                  ...newProvocations,
                ],
              },
            };
          },
        });
      }
    },
    openSelectThumbnailPopup({ title, thumbnailImages, uploadKey, fileName }) {
      this.$refs.selectThumbnailPopup.open(
        title,
        thumbnailImages,
        uploadKey,
        fileName
      );
    },
    openRecordPopup() {
      this.$refs.recordProvocation.open();
    },
    openUploadPopup() {
      this.$refs.uploadProvocation.open();
    },
  },
  apollo: {
    provocations: {
      query: PROVOCATIONS,
      variables() {
        return {
          page: {
            page: pageProvocation,
            pageSize: 10,
          },
        };
      },
      update(data) {
        this.maxPage = data.page.maxPage;
        this.total = data.page.total;
        return data.page.provocations;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_PROVOCATIONS",
          this.$t("error.library.provocation.fetch_all_error")
        );
      },
    },
  },
  beforeDestroy() {
    pageProvocation = 0;
  },
};
</script>

<style lang="scss" scoped>
.daily-provocation {
  padding: 12px 0;
  
  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }

  &__content {
    display: flex;
    width: 100%;

    @media (max-width: $laptop-max) {
      display: block;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    justify-content: space-between;

    @media (max-width: $laptop-max) {
      display: block;
    }

    &-title {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 5px;
      font-weight: var(--font-weight-medium);
    }

    &-text {
      @media (max-width: $laptop-max) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
