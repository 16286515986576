var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[_c('Header'),_c('div',{staticClass:"container"},[(
        _vm.getNJUser &&
        _vm.$route.name !== 'paymentRegister' &&
        _vm.$route.name !== 'paymentError' &&
        _vm.$route.name !== 'paymentCancel' &&
        _vm.$route.name !== 'paymentSuccessCreate' &&
        _vm.$route.name !== 'paymentSuccessUpdate' &&
        _vm.$route.name !== 'paymentSuccessCampus' &&
        _vm.$route.name !== 'frontdoor' &&
        _vm.$route.name !== 'payment.subscriptions' &&
        _vm.$route.name !== 'payment.signup'
      )?_c('Navigation'):_vm._e(),_c('main',{staticClass:"app__main",class:[
        _vm.signedIn ? null : 'app__main--center-auth',
        { 'app__main--center-auth': _vm.$route.name === 'frontdoor' },
        { 'app__main--center-auth': _vm.$route.name === 'payment.subscriptions' },
        _vm.$route.name === 'chat' ? 'app__main--chat' : null,
      ]},[_c('div',{staticClass:"app__wrapper"},[_c('router-view')],1)]),_c('sed-card'),(_vm.signedIn)?_c('NotebookWidget'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }