<template>
  <div
    :class="[
      'base-file-input',
      {
        'base-file-input--errors': hasErrors,
      },
    ]"
  >
    <span class="base-file-input__label" v-if="label.length">
      {{ label }}
    </span>
    <label class="base-file-input__label-wrap">
      <span class="base-file-input__control">
        <input
          class="base-file-input__file"
          @input="changeInput"
          @focus="focused = true"
          @blur="focused = false"
          type="file"
          ref="inputFile"
        />
        <span class="base-file-input__field-wrap">
          <span class="base-file-input__field">
            <svg
              v-if="!value"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                stroke="#828282"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 8L12 3L7 8"
                stroke="#828282"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 3V15"
                stroke="#828282"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span v-if="!value" class="base-file-input__field-text"
              >Upload</span
            >
            <span v-if="value">
              {{ filename }} ({{ formatBytes(value.size) }})
            </span>
          </span>
        </span>
      </span>
    </label>
    <div class="base-form__errors" v-if="hasErrors">
      <slot name="errors"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFileInput",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: File,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal && !newVal && this.$refs.inputFile) {
        this.$refs.inputFile.value = null;
      }
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    filename() {
      if (!this.value) {
        return "";
      }

      const filename = this.value.name;

      if (filename.length > 30) {
        return `${filename.substring(0, 10)}...${filename.substring(
          filename.length - 10,
          filename.length
        )}`;
      }

      return filename;
    },
  },
  methods: {
    changeInput(event) {
      this.$emit("input", event.target.files[0]);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 KB";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
};
</script>

<style lang="scss" scoped>
.base-file-input {
  position: relative;
  text-align: left;
  margin-bottom: 35px;

  &--errors {
    .base-file-input {
      &__field {
        border-color: var(--form-error-color);
        background-color: var(--form-error-background);
      }
    }
  }

  &__label {
    display: block;
    color: var(--form-input-label-color);
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    transition: all 0.3s ease-out;
    white-space: nowrap;
    font-weight: var(--font-weight-medium);
    margin-bottom: 10px;

    &-wrap {
      position: relative;
    }
  }

  &__control {
    position: relative;
    flex-grow: 1;
  }

  &__file {
    display: block;
    left: 0;
    width: 100%;
    height: 88px;
    position: absolute;
    opacity: 0;
    z-index: 3;
  }

  &__field {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 185px;
    color: var(--form-input-color);
    line-height: 20px;
    font-size: 16px;
    padding: 8px;
    border: 2px solid var(--form-input-border-color);
    border-radius: 10px;
    height: 88px;

    &-text {
      font-size: 16px;
      line-height: 16px;
      color: var(--nj-gray-4);
    }
  }
}
</style>
