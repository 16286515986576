<template>
  <div
    class="connect-to-users-popup base-modal"
    v-if="dialog"
    @keydown.esc="close"
    tabindex="0"
    ref="connectToUserPopup"
  >
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="close"
    >
      <div
        class="base-modal__inner connect-to-users-popup__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <CloseDialogButton @click="close" class="base-modal__close" />
        <div class="base-modal__head">
          <h3 class="base-modal__head-title">Request to Connect</h3>
        </div>
        <div class="connect-to-users-popup__body base-modal__body">
          <p class="connect-to-users-popup__intro">
            {{ $t("community.groups.members.connect.text", { recipient }) }}
          </p>
          <form class="connect-to-users-popup__form base-form">
            <FormTextarea
              v-model="$v.form.message.$model"
              :hasErrors="messageIsEmpty || messageIsTooLong"
              :counter="150"
              class="connect-to-users-popup__form-message"
            >
              <template v-slot:errors>
                <p class="base-form__errors-item" v-if="messageIsEmpty">
                  {{ $t("community.groups.members.connect.message_required") }}
                </p>
                <p class="base-form__errors-item" v-else-if="messageIsTooLong">
                  {{ $t("community.groups.members.connect.message_too_long") }}
                </p>
              </template>
            </FormTextarea>
          </form>
          <div class="connect-to-users-popup__actions">
            <div class="connect-to-users-popup__actions-item">
              <BaseButton variant="secondary" @click="close">
                {{ $t("default.cancel") }}
              </BaseButton>
            </div>
            <div class="connect-to-users-popup__actions-item">
              <BaseButton @click="sendRequest">
                {{ $t("community.groups.members.connect.send") }}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import FormTextarea from "@/components/forms/FormTextarea";
import { maxLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate/src";

export default {
  name: "ConnectToUsersPopup",
  components: {
    CloseDialogButton,
    FormTextarea,
  },
  mixins: [validationMixin],
  data() {
    return {
      dialog: false,
      form: {
        message: "",
      },
      recipient: "",
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        document.body.style.overflow = "hidden";
        setTimeout(() => {
          this.$refs.connectToUserPopup.focus();
        }, 500);
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  computed: {
    messageIsEmpty() {
      return this.$v.form.message.$dirty && !this.$v.form.message.required;
    },
    messageIsTooLong() {
      return this.$v.form.message.$dirty && !this.$v.form.message.maxLength;
    },
  },
  methods: {
    open(recipient) {
      this.recipient = recipient;
      this.dialog = true;
    },
    close() {
      this.$v.$reset();
      this.form.message = "";
      this.dialog = false;
    },
    sendRequest() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      this.$emit("sendRequest", this.form.message);
      this.close();
    },
  },
  validations: {
    form: {
      message: { required, maxLength: maxLength(150) },
    },
  },
};
</script>

<style scoped lang="scss">
.connect-to-users-popup {
  &__inner {
    max-width: 564px;
  }

  &__body {
    padding: 0 40px 5px;
  }

  &__intro {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
  }

  &__form {
    &-message {
      ::v-deep {
        .form-textarea__field {
          height: 95px;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    &-item {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
</style>
