<template>
  <div class="success-popup">
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="close"
    >
      <div
        class="base-modal__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div class="base-modal__head">
          <CloseDialogButton class="base-modal__close" @click="close" />
          <h1 class="base-modal__head-title">
            {{ title }}
          </h1>
        </div>
        <div class="base-modal__body">
          <div class="base-modal__body-text">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";

export default {
  name: "SuccessPopup",
  components: { CloseDialogButton },
  data() {
    return {
      dialog: false,
      title: "",
      message: "",
      timeout: null,
      resolve: null,
      reject: null,
      options: {},
    };
  },
  methods: {
    open(title, message, options) {
      this.message = message;
      this.title = title;
      this.options = Object.assign(this.options, options);
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;

        this.timeout = setTimeout(() => {
          this.close();
        }, 2500);
      });
    },
    close() {
      this.resolve(true);
      this.dialog = false;
    },
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
</script>

<style scoped lang="scss">
.success-popup {
  .base-modal {
    &__inner {
      width: 440px;

      @media screen and (max-width: 472px) {
        margin: 0 16px;
      }
    }

    &__body {
      padding: 14px 60px;
    }
  }
}
</style>
