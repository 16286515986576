import GET_JOIN_DETAILS from "@/graphql/member-group/queries/JoinGroupRequestDetails.graphql";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import errorHandler from "@/service/errorHandler";

const NotificationGroupMixin = {
  data() {
    return {
      group: null,
      joinRequest: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
    isGroupMember() {
      if (!this.getNJUser) {
        return false;
      }
      return this.group.members.find((m) => m.user.id === this.getNJUser.id);
    },
  },
  methods: {
    getGroupRequestDetails() {
      return this.$apollo
        .query({
          query: GET_JOIN_DETAILS,
          variables: {
            joinGroupRequestId: this.notification.entityId,
          },
        })
        .then((data) => {
          this.joinRequest = data.data.joinGroupRequestDetails;
          this.group = data.data.joinGroupRequestDetails.memberGroup;
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_GET_GROUP_REQUEST",
            this.$t("error.group.fetch_status")
          );
        });
    },
    durationInDays() {
      const timeNow = DateTime.now();
      const timeCreated = DateTime.fromISO(this.notification.issuedAt);

      const diff = timeNow.diff(timeCreated, [
        "months",
        "days",
        "hours",
        "minutes",
      ]);

      const result = diff.toObject();

      if (result.months > 0) {
        return `${Math.ceil(result.months)} month${
          result.months === 1 ? "" : "s"
        }`;
      } else if (result.days > 0) {
        return `${Math.ceil(result.days)} day${result.days === 1 ? "" : "s"}`;
      } else if (result.hours > 0) {
        return `${Math.ceil(result.hours)} hr${result.hours === 1 ? "" : "s"}`;
      } else if (result.minutes > 0) {
        return `${Math.ceil(result.minutes)} min${
          result.minutes === 1 ? "" : "s"
        }`;
      } else {
        return "1 minute";
      }
    },
  },
};

export default NotificationGroupMixin;
