<template>
  <div
    v-if="notification"
    :class="[
      'notification-ls-created',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="goToLiveSession"
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p v-if="parentType === 'MEMBER_GROUP'">
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{
          $t("notifications.ls_created_in_group", {
            liveSessionTitle,
            parentTitle,
          })
        }}
      </p>
      <p v-else>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{
          $t("notifications.ls_created", {
            liveSessionTitle,
            parentTitle,
          })
        }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationLiveSessionCreated",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  data() {
    return {
      liveSessionTitle: "",
      parentTitle: "",
      parentType: "",
      entityId: "",
      parentId: "",
    };
  },
  methods: {
    goToLiveSession() {
      this.$emit("closeNotifications");
      if (this.parentType === "MEMBER_GROUP") {
        this.$router.push({
          name: "liveSession.group.details",
          params: {
            liveSessionId: this.entityId,
            groupId: this.parentId,
          },
        });
      } else if (this.parentType === "CAMPUS_PROGRAM") {
        this.$router.push({
          name: "campus.program.liveSession",
          params: {
            liveSessionId: this.entityId,
            id: this.parentId,
          },
        });
      } else if (this.parentType === "CAMPUS_WORKSHOP") {
        this.$router.push({
          name: "campus.workshop.liveSession",
          params: {
            liveSessionId: this.entityId,
            id: this.parentId,
          },
        });
      }
    },
  },
  async mounted() {
    if (this.notification && this.notification.metaData) {
      const liveSession = this.notification.metaData.find((item) => {
        return item.key === "entityTitle";
      });
      if (liveSession) {
        this.liveSessionTitle = liveSession.value;
      }

      const entityId = this.notification.metaData.find((item) => {
        return item.key === "entityId";
      });
      if (entityId) {
        this.entityId = entityId.value;
      }

      const parentEntity = this.notification.metaData.find((item) => {
        return item.key === "parentTitle";
      });
      if (parentEntity) {
        this.parentTitle = parentEntity.value;
      }

      const parentId = this.notification.metaData.find((item) => {
        return item.key === "parentId";
      });
      if (parentId) {
        this.parentId = parentId.value;
      }

      const parentType = this.notification.metaData.find((item) => {
        return item.key === "parentType";
      });
      if (parentType) {
        this.parentType = parentType.value;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.notification-ls-created {
  cursor: pointer;
}
</style>
