var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-thumbnail-popup base-modal"},[_c('div',{staticClass:"base-modal__dialog",class:_vm.dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'},[_c('div',{staticClass:"select-thumbnail-popup__inner base-modal__inner",class:_vm.dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'},[_c('div',{staticClass:"base-modal__head"},[_c('CloseDialogButton',{staticClass:"base-modal__close",on:{"click":_vm.closePopup}}),_c('h1',{staticClass:"base-modal__head-title"},[_vm._v(" "+_vm._s(_vm.$t("daily_feeds.user_add.title"))+" ")])],1),_c('div',{staticClass:"base-modal__body"},[_c('form',{staticClass:"select-thumbnail-popup__form base-modal__form base-form"},[_c('div',{staticClass:"select-thumbnail-popup__form-inner"},[_c('p',{staticClass:"select-thumbnail-popup__text"},[_vm._v(" "+_vm._s(_vm.$t("daily_feeds.user_add.select_thumbnail"))+" ")]),_c('div',{staticClass:"select-thumbnail-popup__thumbnails"},[_c('div',{staticClass:"select-thumbnail-popup__thumbnails-selected"},[_c('div',{staticClass:"select-thumbnail-popup__thumbnails-image"},[_c('img',{attrs:{"src":_vm.createImageUrl(_vm.form.selectedThumbnail),"alt":"thumbnail"}})])]),_c('div',{staticClass:"select-thumbnail-popup__thumbnails-list"},_vm._l((_vm.thumbnailImages),function(image){return _c('div',{key:image,staticClass:"select-thumbnail-popup__thumbnails-item"},[_c('div',{class:[
                      'select-thumbnail-popup__thumbnails-image',
                      {
                        'select-thumbnail-popup__thumbnails-image--selected':
                          image === _vm.form.selectedThumbnail,
                      },
                    ]},[_c('img',{attrs:{"src":_vm.createImageUrl(image),"alt":"thumbnail"},on:{"click":function($event){return _vm.selectThumbnail(image)}}})])])}),0)])]),_c('div',{staticClass:"base-modal__actions"},[_c('div',{staticClass:"base-modal__actions-item select-thumbnail-popup__record"},[_c('BaseButton',{attrs:{"variant":"primary"},on:{"click":_vm.submitFeed}},[_vm._v(" Confirm ")])],1)])])])])]),_c('SuccessPopup',{ref:"success"}),_c('UploadFile',{ref:"uploadFile"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }