import { Role } from "@/components/security/Role";

const state = {
  // the Cognito user object
  user: null,
  // the backend user entity
  njuser: null,
  signedIn: false,
  userGroups: [],
};

const mutations = {
  setUserInfo(state, { user, userGroups }) {
    state.user = user;
    state.signedIn = true;
    state.userGroups = userGroups;
  },
  signOut(state) {
    state.user = null;
    state.njuser = null;
    state.signedIn = false;
  },
  setNJUserProfile(state, updatedUser) {
    state.njuser = {
      ...state.njuser,
      company: updatedUser.company,
      description: updatedUser.description,
      location: updatedUser.location,
      title: updatedUser.title,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      timezoneId: updatedUser.timezoneId,
      askForProfileCompletion: updatedUser.askForProfileCompletion,
      profileComplete: updatedUser.profileComplete,
      showMarketingPopup: updatedUser.showMarketingPopup,
      showTakeatourPopup: updatedUser.showTakeatourPopup,
      freemium: updatedUser.freemium,
    };
  },
  setNJUserWantsToSeePopup(state, updatedUser) {
    state.njuser = {
      ...state.njuser,
      wantsToSeeJoinPopups: updatedUser.wantsToSeeJoinPopups,
    };
  },
  setNJUser(state, njuser) {
    state.njuser = njuser;
  },
  setUser(state, user) {
    state.user = user;
  },
  setPreferredLang(state, lang) {
    state.njuser = {
      ...state.njuser,
      preferredLocale: lang,
    };
  },
};

const actions = {
  setUserInfo({ commit }, user) {
    if (user) {
      const idToken = user.signInUserSession.idToken;
      const payload = idToken.decodePayload();
      const userGroups = payload["cognito:groups"];

      commit("setUserInfo", { user, userGroups });
    } else {
      commit("setUserInfo", { user, userGroups: [] });
    }
  },
};

const getters = {
  isSuperAdmin: (state) => {
    return (
      state.signedIn &&
      state.userGroups.find((group) => group === Role.SUPER_ADMIN)
    );
  },
  isGuest: (state) => {
    return (
      state.signedIn && state.userGroups.find((group) => group === Role.GUEST)
    );
  },
  isContentManager: (state) => {
    return (
      state.signedIn &&
      state.userGroups.find((group) => group === Role.CONTENT_MANAGER)
    );
  },
  isUserAdmin: (state) => {
    return (
      state.signedIn &&
      state.userGroups.find((group) => group === Role.USER_ADMIN)
    );
  },
  getUsername: (state) => {
    return state.signedIn && state.user ? state.user.username : "";
  },
  getUserPreferredLocale: (state) => {
    return state.njuser && state.njuser.preferredLocale
      ? state.njuser.preferredLocale
      : localStorage.getItem("nj-app-lang")
      ? localStorage.getItem("nj-app-lang")
      : "en";
  },
  getUser: (state) => state.user,
  getNJUser: (state) => state.njuser,
  getTenant: (state) => {
    return state.njuser && state.njuser?.tenantName;
  },
  getNJUserTimezone: (state) => {
    return state.njuser ? state.njuser.timezoneId : "Europe/Berlin";
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
