import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import { Amplify, Auth, I18n } from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import awscognitoconfig from "@/aws-exports";
import "@/directives";

import BaseButton from "@/components/reusables/BaseButton";
import LoadingOverlay from "@/components/reusables/LoadingOverlay";
import Toasted from "vue-toasted";

import "@/styles/main.scss";
import i18n from "@/plugins/i18n";
import VueLogger from "vuejs-logger";
import VueAxios from "vue-axios";
import axios from "@/plugins/axios";
import { createProvider, loadUserFromBackend } from "@/plugins/vue-apollo";
import configureAuthEventListener from "@/aws-listener-setup";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: true,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Amplify.configure(awscognitoconfig);

Vue.component("custom-authenticator", () =>
  import("@/components/amplify-customized/CustomAuthenticator")
);
Vue.component("sign-up", () =>
  import("@/components/amplify-customized/SignUp")
);
Vue.component("sign-in", () =>
  import("@/components/amplify-customized/SignIn")
);
Vue.component("forgot-password", () =>
  import("@/components/amplify-customized/ForgotPassword")
);
Vue.component("confirm-sign-up", () =>
  import("@/components/amplify-customized/ConfirmSignUp")
);
Vue.component("BaseButton", BaseButton);
Vue.component("LoadingOverlay", LoadingOverlay);

Vue.use(VueI18n);
Vue.use(VueLogger, options);
Vue.use(AmplifyPlugin, { Auth, I18n });
Vue.use(VueAxios, axios);
Vue.use(Toasted, {
  closeOnSwipe: true,
  keepOnHover: true,
  duration: 4000,
  action: {
    text: "✕",
    class: "close-toast",
    onClick: (e, toastObj) => {
      toastObj.goAway(0);
    },
  },
});

Vue.config.productionTip = false;

export const apolloProvider = createProvider();
configureAuthEventListener();

Vue.prototype.$Amplify = Amplify;

new Vue({
  router,
  store,
  i18n,
  apolloProvider: apolloProvider,
  render: (h) => h(App),
  beforeCreate() {
    /** according to the aws amplify docs auth info is stored in the local storage. however as items in the local
     * storage never expire (see https://www.w3schools.com/jsref/prop_win_localstorage.asp#:~:text=The%20localStorage%20and%20sessionStorage%20properties,localStorage%20property%20is%20read%2Donly.)
     * we have to check if there is an authenticated user at startup. See
     * https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#managing-user-attributes
     */
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          store
            .dispatch("auth/setUserInfo", data)
            .catch((e) =>
              console.log(`error dispatching sign in to store: ${e}`)
            );

          // reload user data from backend
          loadUserFromBackend(
            apolloProvider.defaultClient,
            store.state.auth.user
          );
        }
      })
      .catch((err) => this.$log.debug(` checking authenticated user: ${err}`));
  },
}).$mount("#app");
