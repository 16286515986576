<template>
  <div
    :class="['form-actions', { 'form-actions--success': type === 'success' }]"
  >
    <BaseButton
      class="form-actions__btn form-actions__btn--cancel"
      @click="decline"
      :variant="variant === 'admin' ? 'admin--secondary' : 'secondary'"
    >
      {{ declineButton }}
    </BaseButton>
    <BaseButton
      class="form-actions__btn form-actions__btn--confirm"
      @click="accept"
      :disabled="disableAcceptButton"
      :variant="variant === 'admin' ? 'admin' : 'primary'"
    >
      {{ acceptButton }}
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: "FormActions",
  props: {
    acceptButton: {
      type: String,
      default: "Accept",
    },
    declineButton: {
      type: String,
      default: "Cancel",
    },
    type: {
      type: String,
      default: "default",
    },
    variant: {
      type: String,
      default: "admin",
    },
    disableAcceptButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    accept() {
      this.$emit("accept");
    },
    decline() {
      this.$emit("decline");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-actions {
  display: flex;
  justify-content: flex-end;
  padding: 8px 24px;
  @media screen and (max-width: 395px) {
    flex-wrap: wrap;
  }

  &__btn {
    margin-left: 30px;

    &--confirm {
      @media screen and (max-width: 395px) {
        margin-top: 10px;
      }
    }
  }
}
</style>
