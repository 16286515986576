<template>
  <div :class="['person-badge', { 'person-badge--small': size === 'small' }]">
    <UserAvatar :size="24" class="person-badge__img" :userId="userId" />
    <div>
      <p class="person-badge__name" v-if="name">
        {{ name }}
      </p>
      <p class="person-badge__title" v-if="title">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";

export default {
  name: "PersonBadge",
  components: { UserAvatar },
  props: {
    size: {
      type: String,
    },
    userId: {
      type: String,
    },
    alt: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.person-badge {
  display: flex;
  align-items: center;

  &--small {
    .person-badge {
      &__img {
        width: 24px;
        margin-right: 8px;
      }

      &__name {
        font-weight: var(--font-weight-medium);
        font-size: 12px;
        line-height: 12px;
        margin: 1px 0 0 0 !important;
      }

      &__title {
        font-size: 10px;
        line-height: 12px;
        text-align: left;
        margin: 0 !important;
        color: var(--nj-gray-4);

        @media (max-width: $laptop-max) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }

  &__img {
    width: 65px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }

  &__name {
    font-size: 22px;
    line-height: 26px;
    text-align: left;
  }

  &__title {
    font-size: 11px;
    line-height: 14px;
    text-align: left;

    @media (max-width: $laptop-max) {
      font-size: 20px;
    }
  }
}
</style>
