<template>
  <div class="home-main">
    <WatchTourButton class="home-main__tour-button" />
    <div class="home-main__inner">
      <div class="home-main__container">
        <div class="home-main__interaction">
          <div class="home-main__provocation">
            <div class="home-main__user">
              <h1 v-if="getNJUser" class="home-main__title">
                {{ $t("home.welcome.headline") }} {{ getNJUser.firstName }}
              </h1>
            </div>
            <DailyProvocation />
          </div>
          <div class="home-main__news">
            <WhatIsNew />
          </div>
        </div>
        <div class="home-main__daily-feed">
          <DailyFeedGrid />
        </div>
      </div>
    </div>
    <EditProfileModal ref="editProfile" />
    <MarketingPopup ref="marketingPopup" />
  </div>
</template>

<script>
import GET_CURRENT_MARKETING_EVENT from "@/graphql/campus/queries/GetCurrentMarketingEvent.graphql";
import CHECK_BOOKED from "@/graphql/campus/queries/GetBookedStatusOfMarketingEvent.graphql";
import DailyProvocation from "@/components/provocation/DailyProvocation";
import WhatIsNew from "@/components/what-is-new/WhatIsNew";
import DailyFeedGrid from "@/components/library/daily-feed/DailyFeedGrid";
import MarketingPopup from "@/components/home/MarketingPopup";
import errorHandler from "@/service/errorHandler";
import { mapGetters, mapState } from "vuex";
import WatchTourButton from "@/components/home/WatchTourButton";

const EditProfileModal = () => import("@/components/home/EditProfileModal");

export default {
  name: "HomeMain",
  components: {
    WatchTourButton,
    MarketingPopup,
    DailyFeedGrid,
    WhatIsNew,
    EditProfileModal,
    DailyProvocation,
  },
  data() {
    return {
      isThereMarketingEvent: false,
    };
  },
  async mounted() {
    this.handleEditProfileModal();
    let marketingEvent = await this.$apollo
      .query({
        query: GET_CURRENT_MARKETING_EVENT,
      })
      .catch((err) => {
        errorHandler(
          err,
          "MARKETING_EVENT_NOT_FOUND",
          "Error fetching the current marketing event"
        );
      });

    if (!marketingEvent.data.getCurrentMarketingEvent) {
      this.isThereMarketingEvent = false;
    } else {
      this.isThereMarketingEvent = true;
      this.handleMarketingPopup();
    }
  },
  watch: {
    getNJUser(newValue, oldValue) {
      if (newValue && oldValue !== newValue) {
        this.handleEditProfileModal();
        if (this.isThereMarketingEvent) {
          this.handleMarketingPopup();
        }
      }
    },
    isSignedUpToMarketedEvent() {
      if (this.isThereMarketingEvent) {
        this.handleMarketingPopup();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getNJUser", "getUsername"]),
    ...mapState("common", [
      "isProfileEditModalPostponed",
      "isMarketedEventSignupPostponed",
    ]),
  },
  methods: {
    handleEditProfileModal() {
      if (
        !this.isProfileEditModalPostponed &&
        this.getNJUser &&
        this.getNJUser.askForProfileCompletion &&
        !this.getNJUser.profileComplete
      ) {
        this.$refs.editProfile.open(
          this.$t("edit_profile_dialog.title"),
          this.$t("edit_profile_dialog.text")
        );
      }
    },
    handleMarketingPopup() {
      if (
        !this.isMarketedEventSignupPostponed &&
        this.isSignedUpToMarketedEvent &&
        !this.isSignedUpToMarketedEvent.isBooked &&
        this.getNJUser &&
        this.getNJUser.showMarketingPopup
      ) {
        this.$refs.marketingPopup.open();
      }
    },
  },
  apollo: {
    isSignedUpToMarketedEvent: {
      query: CHECK_BOOKED,
      variables() {
        return {
          username: this.getUsername,
        };
      },
      fetchPolicy: "network-only",
      skip() {
        return !this.getUsername || !this.isThereMarketingEvent;
      },
      update(data) {
        return data;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_IS_SIGNED_UP",
          "Error checking if signed up to current event."
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.home-main {
  &__tour-button {
    position: absolute;
    top: 52px;
    right: 20px;
  }

  &__daily-feed {
    @media (max-width: 599px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__container {
    max-width: 1250px;
    margin: 0 auto;
  }

  &__interaction {
    display: flex;
    margin-bottom: 70px;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  &__inner {
    padding-top: 40px;
  }

  &__title {
    font-size: 32px;
    line-height: 36px;
    text-align: left;
    padding-right: 12px;
    margin-bottom: 10px;
    font-weight: var(--font-weight-medium);

    @media (max-width: $mobile-max) {
      font-size: 26px;
    }
  }

  &__provocation {
    max-width: 830px;
    width: calc(100% - 420px);

    @media (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__news {
    position: relative;
    padding-left: 20px;
    flex-grow: 1;
    width: 420px;

    @media (max-width: 1024px) {
      padding-left: 0;
      margin-bottom: 40px;
      width: 100%;
    }

    &:before {
      position: absolute;
      top: 50px;
      bottom: 30px;
      left: 14px;
      content: "";
      width: 1px;
      background-color: var(--nj-gray-3);

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
}
</style>
