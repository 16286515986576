import LIKE_DAILY_FEED from "@/graphql/daily-feeds/mutations/LikeDailyFeed.graphql";
import DISLIKE_DAILY_FEED from "@/graphql/daily-feeds/mutations/DisikeDailyFeed.graphql";
import LIST_DAILY_FEED_LIKES from "@/graphql/daily-feeds/queries/ListDailyFeedLikes.graphql";
import DAILY_FEED_BY_ID from "@/graphql/daily-feeds/queries/DailyFeedById.graphql";
import errorHandler from "@/service/errorHandler";
import { mapGetters } from "vuex";
import { logTrackingData } from "@/service/trackingServices";

const DailyFeedDetailsMixin = {
  props: ["section"],
  data() {
    return {
      likes: [],
      likedByUser: false,
      dailyFeed: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername"]),
  },
  methods: {
    fetchLikes() {
      this.$apollo
        .query({
          query: LIST_DAILY_FEED_LIKES,
          variables: {
            dailyFeedId: this.id,
          },
          fetchPolicy: "network-only",
        })
        .then((data) => {
          this.likes = data.data.likes;

          if (this.likes.length) {
            this.likedByUser = this.likes.find(
              (item) => item.username === this.getUsername
            );
          } else {
            this.likedByUser = false;
          }
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_FETCH_LIKES",
            this.$t("error.library.daily_feeds.fetch_likes_error")
          );
        });
    },
    likeFeed() {
      this.$apollo
        .mutate({
          mutation: LIKE_DAILY_FEED,
          variables: {
            username: this.getUsername,
            dailyFeedId: this.id,
          },
        })
        .then(() => {
          logTrackingData({
            section: this.section,
            belongsToSection: "DAILY_FEED_POPUP",
            activityType: "LIKE_DAILY_FEED",
            contentType: "DAILY_FEED",
            contentId: this.id,
          });
          this.fetchLikes();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_LIKE_FEED",
            this.$t("error.library.daily_feeds.like_error")
          );
        });
    },
    dislikeFeed() {
      this.$apollo
        .mutate({
          mutation: DISLIKE_DAILY_FEED,
          variables: {
            username: this.getUsername,
            dailyFeedId: this.id,
          },
        })
        .then(() => {
          this.fetchLikes();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DISLIKE_FEED",
            this.$t("error.library.daily_feeds.dislike_error")
          );
        });
    },
  },
  apollo: {
    dailyFeed: {
      query: DAILY_FEED_BY_ID,
      variables() {
        return {
          dailyFeedId: this.id,
        };
      },
      skip() {
        return !this.render;
      },
      update(data) {
        this.resetData();
        this.loadItemData(data.dailyFeed.id);
        this.fetchLikes();
        return data.dailyFeed;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_DAILY_FEED",
          this.$t("error.library.daily_feeds.fetch_feed_error")
        );
      },
    },
  },
};

export default DailyFeedDetailsMixin;
