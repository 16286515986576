<template>
  <div
    v-if="notification"
    :class="[
      'notification-meetup-created',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="goToItem"
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p>
        {{
          $t("notifications.meetup_created", {
            meetUpTitle,
            date,
            startTime,
          })
        }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";
import { mapGetters } from "vuex";
import { getFormattedDate, getFormattedTime } from "@/service/datetimeUtil";

export default {
  name: "NotificationMeetUpCreated",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
  data() {
    return {
      meetupId: "",
      meetUpTitle: "",
      startDate: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUserTimezone"]),
    date() {
      if (this.startDate) {
        return getFormattedDate(this.startDate, this.getNJUserTimezone);
      } else {
        return "";
      }
    },
    startTime() {
      if (this.startDate) {
        return getFormattedTime(this.startDate, this.getNJUserTimezone);
      } else {
        return "";
      }
    },
  },
  methods: {
    goToItem() {
      this.$emit("closeNotifications");
      this.$router.push({
        name: "meetup.details",
        params: { meetUpId: this.meetupId },
      });
    },
  },
  async mounted() {
    if (this.notification && this.notification.metaData) {
      const meetUp = this.notification.metaData.find((item) => {
        return item.key === "entityTitle";
      });
      if (meetUp) {
        this.meetUpTitle = meetUp.value;
      }

      const date = this.notification.metaData.find((item) => {
        return item.key === "startDateAndTime";
      });
      if (date) {
        this.startDate = date.value;
      }

      const meetupId = this.notification.metaData.find((item) => {
        return item.key === "entityId";
      });
      if (meetUp) {
        this.meetupId = meetupId.value;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.notification-meetup-created {
  cursor: pointer;
}
</style>
