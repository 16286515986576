<template>
  <div class="community-members-buttons">
    <BaseButton
      variant="secondary"
      :class="`community-members-buttons__connect-btn--${recipient.id}`"
      @click="openConnectPopup"
      v-if="
        (!connectionStatus && statusLoaded) ||
        (connectionStatus &&
          connectionStatus.connectToUserRequestStatus === 'DECLINED')
      "
    >
      {{ $t("community.groups.status_buttons.connect") }}
    </BaseButton>
    <button
      class="community-members-buttons__cancel-request-button"
      v-else-if="
        connectionStatus &&
        connectionStatus.connectToUserRequestStatus === 'PENDING' &&
        connectionStatus.issuer.username === getUsername
      "
      variant="secondary"
      @click="cancelConnectRequest"
    ></button>
    <div
      class="community-members-buttons__action"
      v-else-if="
        connectionStatus &&
        connectionStatus.connectToUserRequestStatus === 'PENDING' &&
        connectionStatus.issuer.username !== getUsername
      "
    >
      <div class="community-members-buttons__action-item">
        <BaseButton
          @click="acceptConnection"
          variant="primary"
        >
          {{ $t("community.groups.status_buttons.accept") }}
        </BaseButton>
      </div>
      <div class="community-members-buttons__action-item">
        <BaseButton
          @click="declineConnection"
          variant="secondary"
        >
          {{ $t("community.groups.status_buttons.decline") }}
        </BaseButton>
      </div>
    </div>
    <BaseButton
      v-else-if="
        connectionStatus &&
        connectionStatus.connectToUserRequestStatus === 'ACCEPTED'
      "
      variant="underline"
      @click="removeConnection"
    >
      {{ $t("community.groups.status_buttons.remove_connection") }}
    </BaseButton>
    <ConnectToUserPopup
      ref="connectToUserPopup"
      @sendRequest="sendConnectRequest"
    />
    <Confirm ref="confirm" variant="primary" />
  </div>
</template>

<script>
import CONNECT_TO_USER from "@/graphql/member-connect/mutations/CreateConnectToUserRequest.graphql";
import CANCEL_CONNECT_TO_USER from "@/graphql/member-connect/mutations/CancelConnectToUserRequest.graphql";
import REMOVE_CONNECTION_TO_USER from "@/graphql/member-connect/mutations/RemoveConnectToUserRequest.graphql";
import ACCEPT_CONNECTION_TO_USER from "@/graphql/member-connect/mutations/AcceptConnectToUserRequest.graphql";
import DECLINE_CONNECTION_TO_USER from "@/graphql/member-connect/mutations/DeclineConnectToUserRequest.graphql";
import DELETE_NOTIFICATION from "@/graphql/notifications/mutations/DeleteNotificationByEntityAndReceiver.graphql";
import CONNECTION_STATUS from "@/graphql/member-connect/queries/ConnectionStatus.graphql";
import SUB_CONNECT_TO_USER_REQUEST from "@/graphql/member-connect/subscription/ConnectToUserRequestSubscription.graphql";
import BaseButton from "@/components/reusables/BaseButton";
import ConnectToUserPopup from "@/components/community/membersTab/ConnectToUserPopup";
import { mapGetters, mapMutations } from "vuex";
import errorHandler from "@/service/errorHandler";
import Confirm from "@/components/reusables/Confirm";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "CommunityMembersButtons",
  components: {
    Confirm,
    BaseButton,
    ConnectToUserPopup,
  },
  // TODO PROD-1228: issuer is never used imo. since the recipient is not necessarily the recipient of
  // the monitored ConnectToUserRequest a name like "otherUser" would be more precise, i guess
  props: ["recipient", "issuer"],
  data() {
    return {
      connectionStatus: null,
      statusLoaded: false,
      showSubscribeTooltip: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
  },
  methods: {
    ...mapMutations("common", ["addErrorToast"]),
    goToSubscriptions() {
      const userRegistrationInput = {
        emailAddress: this.getNJUser.email,
        njProductId: null,
      };

      this.$router.push({
        name: "payment.subscriptions",
        params: {
          userRegistrationInput: userRegistrationInput,
          disabledFreeTrial: true,
        },
      });
    },
    openConnectPopup() {
      this.$refs.connectToUserPopup.open(
        `${this.recipient.firstName} ${this.recipient.lastName}`
      );
    },
    deleteNotification() {
      this.$apollo
        .mutate({
          mutation: DELETE_NOTIFICATION,
          variables: {
            entityName: "CONNECT_TO_USER_REQUEST",
            entityIds: [this.connectionStatus.id],
            receiverId: this.connectionStatus.recipient.id,
          },
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DELETE_NOTIFICATION",
            this.$t("notifications.error.delete")
          );
        });
    },
    sendConnectRequest(message) {
      this.$apollo
        .mutate({
          mutation: CONNECT_TO_USER,
          variables: {
            issuerUsername: this.getUsername,
            recipientUsername: this.recipient.username,
            issuerMessage: message,
          },
        })
        .then(() => {
          logTrackingData({
            activityType: "CONNECT",
            contentType: "USER",
            contentId: this.recipient.id,
          });
          this.$apollo.queries.connectionStatus.refetch();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_SEND_CONNECT_REQUEST",
            this.$t("community.groups.members.error.connect_users")
          );
        });
    },
    async cancelConnectRequest() {
      if (
        await this.$refs.confirm.open(
          this.$t("community.groups.members.cancel_connection_confirm.title"),
          this.$t(
            "community.groups.members.cancel_connection_confirm.message",
            {
              firstName: this.recipient.firstName,
              lastName: this.recipient.lastName,
            }
          )
        )
      ) {
        this.$apollo
          .mutate({
            mutation: CANCEL_CONNECT_TO_USER,
            variables: {
              connectToUserRequestId: this.connectionStatus.id,
              username: this.getUsername,
            },
          })
          .then(() => {
            logTrackingData({
              activityType: "CANCEL_CONNECT_REQUEST",
              contentType: "CONNECT_REQUEST",
              contentId: this.connectionStatus.id,
            });
            this.deleteNotification();
            this.connectionStatus = null;
            this.$apollo.queries.connectionStatus.refetch();
          })
          .catch((error) => {
            errorHandler(
              error,
              "ERROR_CANCEL_CONNECT_REQUEST",
              this.$t("community.groups.members.error.cancel_connect")
            );
          });
      }
    },
    async removeConnection() {
      if (
        await this.$refs.confirm.open(
          this.$t("community.groups.members.remove_connection_confirm.title"),
          this.$t(
            "community.groups.members.remove_connection_confirm.message",
            {
              firstName: this.recipient.firstName,
              lastName: this.recipient.lastName,
            }
          )
        )
      ) {
        this.$apollo
          .mutate({
            mutation: REMOVE_CONNECTION_TO_USER,
            variables: {
              connectToUserRequestId: this.connectionStatus.id,
            },
          })
          .then(() => {
            logTrackingData({
              activityType: "REMOVE_CONNECTION",
              contentType: "USER",
              contentId: this.recipient.id,
            });
            this.connectionStatus = null;
            this.$apollo.queries.connectionStatus.refetch();
          })
          .catch((error) => {
            errorHandler(
              error,
              "ERROR_REMOVE_CONNECTION",
              this.$t("community.groups.members.error.remove_connect")
            );
          });
      }
    },
    acceptConnection() {
      this.$apollo
        .mutate({
          mutation: ACCEPT_CONNECTION_TO_USER,
          variables: {
            connectToUserRequestId: this.connectionStatus.id,
          },
        })
        .then(() => {
          logTrackingData({
            activityType: "ACCEPT_CONNECT_REQUEST",
            contentType: "CONNECT_REQUEST",
            contentId: this.connectionStatus.id,
          });
          this.deleteNotification();
          this.$store.commit("common/setProfileModalUserId", null);
          this.$apollo.queries.connectionStatus.refetch();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_ACCEPT_CONNECTION",
            this.$t("community.groups.members.error.accept_connect")
          );
        });
    },
    declineConnection() {
      this.$apollo
        .mutate({
          mutation: DECLINE_CONNECTION_TO_USER,
          variables: {
            connectToUserRequestId: this.connectionStatus.id,
          },
        })
        .then(() => {
          logTrackingData({
            activityType: "DECLINE_CONNECT_REQUEST",
            contentType: "CONNECT_REQUEST",
            contentId: this.connectionStatus.id,
          });
          this.deleteNotification();
          this.$store.commit("common/setProfileModalUserId", null);
          this.$apollo.queries.connectionStatus.refetch();
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_DECLINE_CONNECTION",
            this.$t("community.groups.members.error.decline_connect")
          );
        });
    },
    hideSubscriberTooltip() {
      const box = document.querySelector(`.community-members-buttons__connect-btn--${this.recipient.id}`);
      const box2 = document.querySelector(`.sed-card__buttons .community-members-buttons__connect-btn--${this.recipient.id}`);
  
      if ((!box || !box.contains(event.target)) && (!box2 || !box2.contains(event.target))) {
        this.showSubscribeTooltip = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.hideSubscriberTooltip);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideSubscriberTooltip);
  },
  apollo: {
    connectionStatus: {
      query: CONNECTION_STATUS,
      variables() {
        return {
          issuerUsername: this.getUsername,
          receiverUserName: this.recipient.username,
        };
      },
      update(data) {
        this.statusLoaded = true;
        this.$emit("connectionStatus", data.status);
        return data.status;
      },
      fetchPolicy: "network-only",
      async error(error) {
        await errorHandler(
          error,
          "ERROR_CONNECTION_STATUS",
          this.$t("community.groups.members.error.status_fetch")
        );
      },
      subscribeToMore: {
        document: SUB_CONNECT_TO_USER_REQUEST,
        variables() {
          return {
            otherUserId: this.recipient.id,
          };
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          return {
            status: subscriptionData.data.event.connectToUserRequest,
          };
        },
        skip() {
          return !this.getNJUser || !this.recipient;
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
.community-members-buttons {
  &__action {
    display: flex;
    align-items: center;

    &-item {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  &__cancel-request-button {
    border-radius: 30px;
    color: var(--nj-gray-4);
    background-color: var(--nj-gray-2);
    font-size: 14px;
    line-height: 18px;
    font-weight: var(--font-weight-medium);
    padding: 10px 32px;

    &:after {
      content: "Pending";
    }

    &:hover {
      background-color: transparent;
      border: var(--nj-green) 2px solid;
      color: black;

      &:after {
        content: "Cancel Request";
      }
    }
  }

  &__trial-tooltip {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    width: 190px;
    text-align: center;
    white-space: normal;
    color: var(--nj-gray-5);
    font-size: 14px;
    line-height: 18px;
    border-radius: 10px;
    border: 1px solid var(--nj-gray-2);
    background-color: var(--nj-gray-1);
    z-index: 3;
    
    &--big {
      width: 270px;
    }

    &:before {
      position: absolute;
      top: -10px;
      left: 77%;
      transform: translateX(-50%) rotate(135deg);
      content: "";
      width: 20px;
      height: 20px;
      background-color: var(--nj-gray-1);
      border: 1px solid var(--nj-gray-2);
      z-index: 1;
    }

    &-inner {
      position: relative;
      z-index: 2;
      padding: 16px 10px;
      border-radius: 10px;
      background-color: var(--nj-gray-1);
    }

    a {
      color: var(--nj-blue);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
