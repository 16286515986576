<template>
  <div class="what-is-new-meetup" v-if="meetUp">
    <div class="what-is-new-meetup__inner">
      <div class="what-is-new-meetup__image">
        <img :src="getThumbnail()" alt="thumbnail" />
      </div>
      <div class="what-is-new-meetup__info">
        <div class="what-is-new-meetup__info-top">
          <h3 class="what-is-new-meetup__info-type">
            {{ $t("what_is_new.type_meet_up") }}
          </h3>
          <div class="what-is-new-meetup__admin" v-if="isContentManager">
            <WhatIsNewAdminMenu
              :itemId="item.id"
              :firstItem="firstItem"
              :lastItem="lastItem"
              @moveWhatsNew="moveWhatsNew"
              @updateCarousel="updateCarousel"
              @error="displayError"
            />
          </div>
        </div>
        <div class="what-is-new-meetup__info-title">
          {{ meetUp.title }}
        </div>
        <div class="what-is-new-meetup__info-date">
          {{ date }}, {{ startTime }} - {{ endTime }}
        </div>
      </div>
      <button class="what-is-new-meetup__btn" @click="goToItem">
        <ArrowCircleIcon direction="right" />
      </button>
    </div>
  </div>
</template>

<script>
import GET_MEETUP from "@/graphql/meetUps/queries/GetMeetUpById.graphql";
import ArrowCircleIcon from "@/components/reusables/Icons/ArrowCircleIcon";
import WhatsNewItemMixin from "@/components/mixins/WhatsNewItemMixin";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";
import errorHandler from "@/service/errorHandler";
import WhatIsNewAdminMenu from "@/components/what-is-new/WhatIsNewAdminMenu";
import { mapGetters } from "vuex";
import { getFormattedDate, getFormattedTime } from "@/service/datetimeUtil";

export default {
  name: "WhatIsNewMeetUp",
  components: {
    ArrowCircleIcon,
    WhatIsNewAdminMenu,
  },
  props: ["item", "firstItem", "lastItem"],
  mixins: [WhatsNewItemMixin],
  computed: {
    ...mapGetters("auth", ["isContentManager", "getNJUserTimezone"]),
    date() {
      return getFormattedDate(this.meetUp.startTime, this.getNJUserTimezone);
    },
    startTime() {
      return getFormattedTime(this.meetUp.startTime, this.getNJUserTimezone);
    },
    endTime() {
      return getFormattedTime(this.meetUp.endTime, this.getNJUserTimezone);
    },
  },
  methods: {
    getThumbnail() {
      return createS3ThumbnailUrl(this.meetUp.imageIdBannerView, 80, 80);
    },
    goToItem() {
      this.$router.push({
        name: "meetup.details",
        params: {
          meetUpId: this.meetUp.id,
        },
      });
    },
  },
  apollo: {
    meetUp: {
      query: GET_MEETUP,
      variables() {
        return {
          id: this.item.itemId,
        };
      },
      update(data) {
        return data.meetUpById;
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_FETCH_MEETUP_BY_ID",
          this.$t("meet_up.fetch_error")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.what-is-new-meetup {
  text-align: left;

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-right: 45px;
  }

  &__image {
    width: 72px;
    height: 72px;
    background-color: var(--nj-green);
    border-radius: 4px;
    overflow: hidden;
    margin-right: 16px;
    flex-shrink: 0;

    img {
      display: block;
      width: 72px;
      height: 72px;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__info {
    padding-top: 5px;
    flex-grow: 1;
    width: 100%;
    max-width: 227px;

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    &-type {
      font-size: 10px;
      line-height: 12px;
      color: var(--nj-gray-4);
      text-transform: uppercase;
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-date {
      font-size: 10px;
      line-height: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
