import { getCurrencySign } from "@/service/pricingUtil";

const state = {
  timezones: ["Europe/Berlin"],
  currency: "€",
  hasFeaturedItemsCaroussel: false,
  hasKeleaBanner: false,
  hasPricedCampusItems: false,
  isProfileEditMode: false,
  isProfileEditModalPostponed: false,
  openedProgramContainer: -1,
  openedWorkshopContainer: -1,
  openedSettingsContainer: -1,
  browser: null,
  isMobileMenuOpen: false,
  isNotificationsMenuOpen: false,
  errorToasts: [],
  profileModalUserId: null,
  isMarketedEventSignupPostponed: false,
  isTakeATourTooltipPostponed: false,
};

const getters = {
  isMobileBrowser: (state) => {
    return state.browser && state.browser.mobile;
  },
  isApple: (state) => {
    return (
      state.browser && state.browser.os && state.browser.os.includes("OS X")
    );
  },
  isSafari: (state) => {
    return (
      state.browser && state.browser.name && state.browser.name === "safari"
    );
  },
};

const mutations = {
  cleanErrorToasts(state) {
    state.errorToasts = [];
  },
  addErrorToast(state, { type, message }) {
    const networkError = state.errorToasts.find(
      (toast) => toast.type === "NETWORK_ERROR"
    );
    const toastInStore = state.errorToasts.find((toast) => toast.type === type);

    if (!networkError && !toastInStore) {
      state.errorToasts.push({
        type,
        message,
      });

      this._vm.$toasted.show(message);

      setTimeout(() => {
        this.commit("common/cleanErrorToasts");
      }, 4000);
    }
  },
  setTimezones(state, timezones) {
    state.timezones = timezones;
  },
  setCurrency(state, currency) {
    state.currency = getCurrencySign(currency);
  },
  setPricedCampusItems(state, hasPricedCampusItems) {
    state.hasPricedCampusItems = hasPricedCampusItems;
  },
  setFeaturedItemsCaroussel(state, hasFeaturedItemsCaroussel) {
    state.hasFeaturedItemsCaroussel = hasFeaturedItemsCaroussel;
  },
  setKeleaBanner(state, hasKeleaBanner) {
    state.hasKeleaBanner = hasKeleaBanner;
  },
  setProfileEditMode(state, isProfileEditMode) {
    state.isProfileEditMode = isProfileEditMode;
  },
  postponeProfileEditModal(state) {
    state.isProfileEditModalPostponed = true;
  },
  returnProfileEditModal(state) {
    state.isProfileEditModalPostponed = false;
  },
  setOpenedProgramContainer(state, index) {
    state.openedProgramContainer = index;
  },
  setOpenedWorkshopContainer(state, index) {
    state.openedWorkshopContainer = index;
  },
  setOpenedSettingsContainer(state, index) {
    state.openedSettingsContainer = index;
  },
  setBrowser(state, browser) {
    state.browser = browser;
  },
  setMobileMenuState(state, isMobileMenuOpen) {
    state.isMobileMenuOpen = isMobileMenuOpen;
  },
  setNotificationsMenuState(state, isNotificationsMenuOpen) {
    state.isNotificationsMenuOpen = isNotificationsMenuOpen;
  },
  setProfileModalUserId(state, value) {
    state.profileModalUserId = value;
  },
  setMarketedEventSignup(state, value) {
    state.isMarketedEventSignupPostponed = value;
  },
  setTakeATourTooltip(state, value) {
    state.isTakeATourTooltipPostponed = value;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
