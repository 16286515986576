<template>
  <div class="daily-provocation-carousel">
    <button
      v-show="provocations.length > 1 && carousel && carousel.index"
      class="
        daily-provocation-carousel__go-btn
        daily-provocation-carousel__go-btn--left
      "
      @click="goLeftInSlider"
    >
      <Chevron size="large" direction="left" />
    </button>
    <div class="glide glide-provocations">
      <div
        class="glide__track"
        data-glide-el="track"
        :class="{
          'glide__track--not-first':
            provocations.length > 1 && carousel && carousel.index,
        }"
      >
        <div class="glide__slides" :style="{ opacity: carousel ? 1 : 0 }">
          <div
            class="glide__slide"
            v-for="provocation in provocations"
            :key="provocation.id"
          >
            <DailyProvocationItem :id="provocation.id" ref="provocation-item" />
          </div>
        </div>
      </div>
      <div class="glide__bullets" v-if="carousel">
        <button
          v-if="total > 4 && carousel.index >= 4"
          @click="goToSlide(carousel.index - 1)"
          class="
            daily-provocation-carousel__bullet
            daily-provocation-carousel__bullet--fake
          "
        ></button>
        <button
          v-for="index in clickableDots"
          class="daily-provocation-carousel__bullet"
          :class="{
            'daily-provocation-carousel__bullet--active':
              index === carousel.index,
          }"
          :key="index"
          @click="goToSlide(index)"
        ></button>
        <button
          v-if="total > 4 && carousel.index + 4 < total"
          @click="goToSlide(carousel.index + 1)"
          class="
            daily-provocation-carousel__bullet
            daily-provocation-carousel__bullet--fake
          "
        ></button>
      </div>
    </div>
    <button
      v-show="provocations.length > 1 && carousel && carousel.index < total - 1"
      class="
        daily-provocation-carousel__go-btn
        daily-provocation-carousel__go-btn--right
      "
      @click="goRightInSlider"
    >
      <Chevron size="large" direction="right" />
    </button>
  </div>
</template>

<script>
import DailyProvocationItem from "@/components/provocation/DailyProvocationItem";
import Chevron from "@/components/reusables/Icons/Chevron";
import Glide from "@glidejs/glide";

export default {
  name: "DailyProvocationCarousel",
  components: {
    DailyProvocationItem,
    Chevron,
  },
  watch: {
    provocations() {
      this.destroyCarousel();

      setTimeout(() => {
        this.initCarousel();
      }, 100);
    },
  },
  props: {
    provocations: {
      type: Array,
      default() {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    startIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      carousel: null,
      prevIndex: 0,
    };
  },
  computed: {
    clickableDots() {
      if (!this.carousel) {
        return [];
      }

      const dots = [];

      if (this.carousel.index < 4) {
        if (this.total < 4) {
          for (let dot = 0; dot < this.total; dot++) {
            dots.push(dot);
          }
        } else {
          for (let dot = 0; dot < 4; dot++) {
            dots.push(dot);
          }
        }
        return dots;
      } else {
        if (this.prevIndex <= this.carousel.index) {
          const firstDot =
            this.carousel.index + 4 > this.total - 1
              ? this.total - 4
              : this.carousel.index - 3;
          const lastDot =
            this.carousel.index + 4 > this.total - 1
              ? this.total
              : this.carousel.index + 1;

          for (let dot = firstDot; dot < lastDot; dot++) {
            dots.push(dot);
          }
          return dots;
        } else {
          const firstDot =
            this.carousel.index + 4 > this.total - 1
              ? this.total - 4
              : this.carousel.index;
          const lastDot =
            this.carousel.index + 4 > this.total - 1
              ? this.total
              : this.carousel.index + 4;

          for (let dot = firstDot; dot < lastDot; dot++) {
            dots.push(dot);
          }
          return dots;
        }
      }
    },
  },
  methods: {
    dotsPage(index = 0) {
      return Math.floor(index / 4) + 1;
    },
    initCarousel() {
      this.carousel = new Glide(".glide-provocations", {
        type: "slider",
        startAt: this.startIndex,
      });
      this.carousel.mount();

      this.carousel.on(["run.before"], () => {
        this.prevIndex = this.carousel.index;
        this.pauseAllVideos();
      });
    },
    destroyCarousel() {
      this.carousel.destroy();
      this.carousel = null;
    },
    goToSlide(index) {
      if (index < this.provocations.length) {
        this.carousel.go(`=${index}`);
      } else {
        this.$emit("fetchMoreProvocations");
      }
    },
    goLeftInSlider() {
      this.pauseAllVideos();
      this.carousel.go("<");
    },
    goRightInSlider() {
      this.pauseAllVideos();
      if (this.carousel.index < this.provocations.length - 1) {
        this.carousel.go(">");
      } else {
        this.$emit("fetchMoreProvocations");
      }
    },
    pauseAllVideos() {
      let allVideos = this.$refs["provocation-item"];

      if (allVideos) {
        allVideos.forEach((i) => i.pauseVideo());
      }
    },
  },
  mounted() {
    this.initCarousel();
  },
  beforeDestroy() {
    this.destroyCarousel();
  },
};
</script>

<style lang="scss" scoped>
.daily-provocation-carousel {
  position: relative;

  &__bullet {
    border-radius: 50%;
    background-color: var(--nj-gray-3);
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 3px;

    &--fake {
      width: 4px;
      height: 4px;
    }
    &--active {
      background-color: var(--nj-blue);
    }
  }
  &__go-btn {
    background: var(--nj-gray-2);
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    z-index: 3;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $laptop-max) {
      top: 140px;
      transform: none;
    }

    @media (max-width: 1024px) {
      display: none;
    }

    ::v-deep {
      .chevron--large {
        &:before {
          height: 9px;
          width: 9px;
        }
      }
    }

    &--left {
      left: 16px;

      ::v-deep {
        .chevron__left {
          &:before {
            top: 0;
            left: 1px;
          }
        }
      }
    }

    &--right {
      right: 16px;

      ::v-deep {
        .chevron__right {
          &:before {
            top: 0;
            left: -2px;
          }
        }
      }
    }
  }

  .glide {
    &__slides {
      padding-bottom: 40px;
      
      @media (max-width: 1263px) {
        padding-bottom: 0;
      }
    }
    &__track {
      &--not-first {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 20px;
          z-index: 2;
          background: linear-gradient(
            90deg,
            #f8f8f8 -256.25%,
            rgba(248, 248, 248, 0) 100%
          );
          pointer-events: none;
        }
      }
    }
    &__bullets {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $laptop-max) {
        margin-top: 0;
      }
    }
  }
}
</style>
