var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.group)?_c('div',{class:[
    'notification-join-request-group',
    'notification',
    { 'notification--full': _vm.fullView },
  ],on:{"click":_vm.goToRequests}},[_c('div',{staticClass:"notification__avatar"},[_c('UserAvatar',{attrs:{"user":_vm.notification.issuer,"size":40}})],1),_c('div',{staticClass:"notification__text"},[_c('p',[_c('b',[_vm._v(" "+_vm._s(_vm.notification.issuer.firstName)+" "+_vm._s(_vm.notification.issuer.lastName)+" ")]),_vm._v(" "+_vm._s(_vm.$t("notifications.requested_to_join", { groupName: _vm.group.name }))+" ")]),_c('div',{staticClass:"notification__time",class:{
        'notification__time--show': !_vm.showShortActions && !_vm.showFullActions,
      }},[_vm._v(" "+_vm._s(_vm.durationInDays())+" ")])]),(_vm.showFullActions)?_c('div',{staticClass:"notification__action"},[_c('BaseButton',{attrs:{"variant":"secondary"},on:{"click":_vm.declineRequest}},[_vm._v(" "+_vm._s(_vm.$t("community.groups.status_buttons.decline"))+" ")]),_c('BaseButton',{on:{"click":_vm.acceptRequest}},[_vm._v(_vm._s(_vm.$t("community.groups.status_buttons.approve"))+" ")])],1):_vm._e(),(_vm.showShortActions)?_c('div',{staticClass:"notification__action notification__action--short"},[_c('button',{staticClass:"notification__action-btn",on:{"click":function($event){$event.stopPropagation();return _vm.declineRequest.apply(null, arguments)}}},[_c('DeclineIcon')],1),_c('button',{staticClass:"notification__action-btn",on:{"click":function($event){$event.stopPropagation();return _vm.acceptRequest.apply(null, arguments)}}},[_c('AcceptIcon')],1)]):_vm._e(),(_vm.loading)?_c('LoadingOverlay',[_vm._v(" "+_vm._s(_vm.$t("default.loading"))+" ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }