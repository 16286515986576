export default function parsePriceToCents(price) {
    if (!price) {
        return null;
    }
    // Define the regex for validating the price format
    const priceRegex = /^(?:\d{1,3}(?:[.,]\d{3})*(?:[.,]\d{2})?|\d+(?:[.,]\d{2})?)$/;

    // Check if the price matches the regex
    if (!priceRegex.test(price)) {
        throw new Error('Invalid price format');
    }

    // Remove any non-digit characters except for the decimal separator
    let normalizedPrice = price.replace(/[^0-9.,]/g, '');

    // If the price uses commas for decimals and periods for thousands, normalize accordingly
    if (normalizedPrice.indexOf(',') > normalizedPrice.indexOf('.')) {
        normalizedPrice = normalizedPrice.replace('.', '').replace(',', '.');
    } else if (normalizedPrice.includes(',') && !normalizedPrice.includes('.')) {
        // If there's no period but there are commas, assume commas are used for decimals
        normalizedPrice = normalizedPrice.replace(',', '.');
    } else {
        // Remove commas used as thousand separators
        normalizedPrice = normalizedPrice.replace(/,/g, '');
    }

    // Convert the normalized price string to a float
    let floatPrice = parseFloat(normalizedPrice);

    // Check for NaN after parsing
    if (isNaN(floatPrice)) {
        throw new Error('Invalid price format after normalization');
    }

    // Convert to the smallest currency unit (e.g., cents)
    return Math.round(floatPrice * 100);
}

export function getCurrencySign(name) {
    const currencies = {
        'USD': '$',
        'EUR': '€',
        'GBP': '£',
        'JPY': '¥',
        'CAD': '$',
        'AUD': '$',
        'NZD': '$',
        'CHF': 'CHF',
        'SEK': 'kr',
        'NOK': 'kr',
        'DKK': 'kr',
        'CZK': 'Kč',
        'HUF': 'Ft',
        'PLN': 'zł',
        'BGN': 'лв',
        'RON': 'lei',
        'TRY': '₺',
        'ILS': '₪',
        'MXN': '$',
        'HKD': '$',
        'SGD': '$',
        'THB': '฿',
        'MYR': 'RM',
        'PHP': '₱',
        'IDR': 'Rp',
    }

    return currencies[name]
}
