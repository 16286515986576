import { getApolloClient } from "@/plugins/vue-apollo";
import errorHandler from "@/service/errorHandler";
import i18n from "@/plugins/i18n";
import store from "../../store";
import GET_GROUP from "@/graphql/member-group/queries/MemberGroupById.graphql";
import CHECK_BOOKED_WORKSHOP from "@/graphql/workshops/queries/CheckBookedWorkshop.graphql";
import CHECK_BOOKED_PROGRAM from "@/graphql/programs/queries/CheckBookedProgram.graphql";

export const RouteAccessRights = {
  COMMUNITY_GROUP_MEMBER: "COMMUNITY_GROUP_MEMBER",
  WORKSHOP_PARTICIPANT: "WORKSHOP_PARTICIPANT",
  PROGRAM_PARTICIPANT: "PROGRAM_PARTICIPANT",
};

export async function hasRedirectBasedOnAccessRights(username, to, authorize) {
  let redirectRoute = false;

  // check access based on parameters set in router in authorize array
  if (authorize.includes(RouteAccessRights.COMMUNITY_GROUP_MEMBER)) {
    redirectRoute = await hasAccessToCommunityGroup(username, to);
  } else if (authorize.includes(RouteAccessRights.WORKSHOP_PARTICIPANT)) {
    redirectRoute = await hasAccessToWorkshop(username, to);
  } else if (authorize.includes(RouteAccessRights.PROGRAM_PARTICIPANT)) {
    redirectRoute = await hasAccessToProgram(username, to);
  }
  return redirectRoute;
}

async function hasAccessToCommunityGroup(username, to) {
  let groupMembers = [];
  const apolloClient = getApolloClient();

  await apolloClient
    .query({
      query: GET_GROUP,
      variables: {
        memberGroupId: to.params.groupId,
      },
      fetchPolicy: "network-only",
    })
    .then((data) => {
      groupMembers = data.data.memberGroup.members;
    })
    .catch((error) => {
      errorHandler(
        error,
        "ERROR_FETCHING_GROUP",
        i18n.messages[i18n.locale]["error.group.fetch_status"]
      );
    });

  if (groupMembers.find((member) => member.user.username === username)) {
    return false;
  } else {
    return {
      name: "community.groups",
      query: {
        showGroupInfoPopup: to.params.groupId,
      },
    };
  }
}

async function hasAccessToWorkshop(username, to) {
  let isBooked = false;
  const apolloClient = getApolloClient();
  const campusRoutes = [
    'campus.workshop.structure.resource.readable',
    'campus.workshop.structure.resource.video',
    'campus.workshop.structure.resource.audio',
    'campus.workshop.structure.resource.videoSeries',
    'campus.workshop.structure.resource.audioSeries',
  ]

  await apolloClient
    .query({
      query: CHECK_BOOKED_WORKSHOP,
      variables: {
        username,
        workshopId: campusRoutes.includes(to.name) ? to.params.campusItemId : to.params.id,
      },
      fetchPolicy: "network-only",
    })
    .then((data) => {
      isBooked = data.data.checkBookedWorkshop.isBooked;
    })
    .catch((error) => {
      errorHandler(
        error,
        "ERROR_BOOKING_STATUS",
        i18n.messages[i18n.locale]["error.workshop.check_booked_fail"]
      );
    });

  if (isBooked || store.getters["auth/isContentManager"]) {
    return false;
  } else {
    return {
      name: "campus.workshop.overview",
      params: { id: campusRoutes.includes(to.name) ? to.params.campusItemId : to.params.id },
    };
  }
}

async function hasAccessToProgram(username, to) {
  let isBooked = false;
  const apolloClient = getApolloClient();
  const campusRoutes = [
    'campus.program.structure.resource.readable',
    'campus.program.structure.resource.video',
    'campus.program.structure.resource.audio',
    'campus.program.structure.resource.videoSeries',
    'campus.program.structure.resource.audioSeries',
  ]

  await apolloClient
    .query({
      query: CHECK_BOOKED_PROGRAM,
      variables: {
        username,
        programId: campusRoutes.includes(to.name) ? to.params.campusItemId : to.params.id,
      },
      fetchPolicy: "network-only",
    })
    .then((data) => {
      isBooked = data.data.checkBookedProgram.isBooked;
    })
    .catch((error) => {
      errorHandler(
        error,
        "ERROR_BOOKING_STATUS",
        i18n.messages[i18n.locale]["error.program.check_booked_fail"]
      );
    });

  if (isBooked || store.getters["auth/isContentManager"]) {
    return false;
  } else {
    return {
      name: "campus.program.overview",
      params: { id: campusRoutes.includes(to.name) ? to.params.campusItemId : to.params.id },
    };
  }
}
