<template>
  <footer class="main-footer">
    <ul class="main-footer__menu">
      <li class="main-footer__menu-item" @click="showHelp = false">
        <a
          class="main-footer__menu-gdpr"
          @click.prevent="
            $router.push({ name: 'home.privacypolicy' }, () => {})
          "
        >
          <img src="/gdpr.png" alt="GDPR logo" />
        </a>
        <a
          class="main-footer__link"
          @click.prevent="
            $router.push({ name: 'home.privacypolicy' }, () => {})
          "
          @click="scrollToTop"
        >
          {{ $t("home.privacy_policy") }}
        </a>
      </li>
      <li class="main-footer__menu-item" @click="showHelp = false">
        <a
          class="main-footer__link"
          @click.prevent="
            $router.push({ name: 'home.termAndConditions' }, () => {})
          "
          @click="scrollToTop"
        >
          {{ $t("home.terms_and_conditions") }}
        </a>
      </li>
      <li class="main-footer__menu-item">
        <p v-if="showHelp" class="main-footer__support">
          {{ $t("home.please") }}
          <a
            class="main-footer__support-link"
            href="mailto:support@neuraljam.com"
          >
            {{ $t("home.email") }}
          </a>
          {{ $t("home.support") }}
        </p>
        <button
          class="body-2-medium foot clickable main-footer__link"
          type="button"
          @click="showHelp = !showHelp"
        >
          {{ $t("home.help") }}
        </button>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      showHelp: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-footer {
  padding: 7px 0;

  @media screen and (max-width: $mobile-max) {
    padding-bottom: 30px;
  }

  &__menu {
    display: flex;
    list-style: none;
    padding: 0 !important;
    margin: 0;

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 80px;

        @media (max-width: 599px) {
          margin-right: 40px;
        }
      }
    }

    &-gdpr {
      width: 26px;
      flex-shrink: 0;
      margin-right: 5px;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__link {
    font-size: 16px;
    color: var(--nj-black);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
  }

  &__support {
    position: absolute;
    transform: translateY(-30px);
    font-size: 14px;

    @media screen and (min-width: 525px) and (max-width: 887px) {
      transform: translate(0px, -58px);
      text-align: left;
    }

    @media screen and (max-width: 525px) {
      transform: translate(-85px, -58px);
    }

    &-link {
      color: var(--nj-black);
    }
  }
}
</style>
