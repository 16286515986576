<template>
  <div
    v-if="notification"
    :class="[
      'notification-connect-accepted',
      'notification',
      { 'notification--full': fullView },
    ]"
    @click="
      $store.commit('common/setProfileModalUserId', notification.issuer.id)
    "
  >
    <div class="notification__avatar">
      <UserAvatar :user="notification.issuer" :size="40" />
    </div>
    <div class="notification__text">
      <p>
        <b>
          {{ notification.issuer.firstName }}
          {{ notification.issuer.lastName }}
        </b>
        {{ $t("notifications.accepted_connect_request") }}
      </p>
      <div class="notification__time">
        {{ durationInDays() }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/reusables/UserAvatar";
import NotificationDurationMixin from "@/components/mixins/NotificationDurationMixin";

export default {
  name: "NotificationConnect",
  components: { UserAvatar },
  props: ["notification", "fullView"],
  mixins: [NotificationDurationMixin],
};
</script>

<style scoped lang="scss">
.notification-connect-accepted {
  cursor: pointer;
}
</style>
