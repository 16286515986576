<template>
  <div class="sed-card base-modal">
    <div
      class="base-modal__dialog"
      :class="dialog ? 'base-modal__dialog--open' : 'base-modal__dialog--close'"
      @click.self="close"
    >
      <div
        class="base-modal__inner sed-card__inner"
        :class="dialog ? 'base-modal__inner--open' : 'base-modal__inner--close'"
      >
        <div
          class="base-modal__body sed-card__body"
          v-if="user"
          @click.self="groupsMenu = false"
        >
          <CloseDialogButton
            class="sed-card__close-button"
            color="white"
            @click="close"
          />
          <UserAvatar class="sed-card__avatar" :user="user.user" size="210" />
          <div class="sed-card__name">
            <h2>{{ user.user.firstName }} {{ user.user.lastName }}</h2>
            <button
              class="sed-card__name-icon"
              @click="startChat"
              v-if="
                connectionStatus &&
                connectionStatus.connectToUserRequestStatus === 'ACCEPTED'
              "
            >
              <ChatIcon color="#4f4f4f" />
            </button>
          </div>

          <div class="sed-card__user-info">
            <div class="sed-card__user-info-line" v-if="user.user.location">
              <LocationIcon />
              <h4 v-if="user.user.location">{{ user.user.location }}</h4>
            </div>
            <div
              class="sed-card__user-info-line"
              v-if="user.user.title || user.user.company"
            >
              <SuitcaseIcon />
              <h4>
                <span v-if="user.user.title">{{ user.user.title }}</span>
                <span v-if="user.user.title && user.user.company"> at </span>
                <span v-if="user.user.company">{{ user.user.company }}</span>
              </h4>
            </div>
          </div>

          <div
            class="sed-card__second-part"
            v-if="user.memberGroups.length || user.user.description"
          >
            <h4
              class="sed-card__shared-groups"
              v-if="user.memberGroups.length && getNJUser.id !== user.user.id"
            >
              {{
                $t("user_profile.sed_card.shared_groups1", {
                  firstName: user.user.firstName,
                })
              }}
              <span
                class="sed-card__shared-groups-underlined"
                @click="groupsMenu = !groupsMenu"
              >
                {{
                  $t("user_profile.sed_card.shared_groups2", {
                    length: user.memberGroups.length,
                  })
                }}</span
              >
            </h4>

            <h4 class="sed-card__description">{{ user.user.description }}</h4>
          </div>

          <h4
            v-if="user.issuerMessage"
            class="sed-card__personal-message-title"
          >
            {{ $t("user_profile.sed_card.personal_message") }}
          </h4>
          <h4 v-if="user.issuerMessage" class="sed-card__personal-message">
            "{{ user.issuerMessage }}"
          </h4>

          <CommunityMembersButtons
            v-if="getNJUser.id !== user.user.id"
            :recipient="user.user"
            class="sed-card__buttons"
            @connectionStatus="setConnectionStatus"
          />
        </div>
        <div class="sed-card__groups-menu" v-if="user && groupsMenu">
          <div
            class="sed-card__groups-menu-item"
            v-for="group in user.memberGroups"
            :key="group.id"
            @click="goToGroup(group.id)"
          >
            <img
              :src="createThumbnail(group.thumbnailImageId)"
              alt="group thumbnail image"
            />
            <h3 class="sed-card__groups-menu-text">{{ group.name }}</h3>
          </div>
        </div>
      </div>
    </div>
    <LoadingOverlay v-if="loading"
      >{{ $t("user_profile.sed_card.loading_profile") }}
    </LoadingOverlay>
  </div>
</template>

<script>
import GET_PROFILE from "@/graphql/user/queries/GetUserProfile.graphql";
import { mapGetters, mapState } from "vuex";
import UserAvatar from "@/components/reusables/UserAvatar";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import LocationIcon from "@/components/reusables/Icons/LocationIcon";
import CommunityMembersButtons from "@/components/community/membersTab/CommunityMembersButtons";
import SuitcaseIcon from "@/components/reusables/Icons/SuitcaseIcon";
import ChatIcon from "@/components/reusables/Icons/ChatIcon";
import createS3ThumbnailUrl from "@/service/createThumbnailUrl";
import errorHandler from "@/service/errorHandler";

export default {
  name: "SedCard",
  components: {
    SuitcaseIcon,
    CommunityMembersButtons,
    LocationIcon,
    CloseDialogButton,
    UserAvatar,
    ChatIcon,
  },
  data() {
    return {
      dialog: false,
      userId: null,
      user: null,
      loading: false,
      groupsMenu: false,
      connectionStatus: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getNJUser"]),
    ...mapState("common", ["profileModalUserId"]),
  },
  watch: {
    profileModalUserId() {
      this.profileModalUserId
        ? this.open(this.profileModalUserId)
        : this.close();
    },
  },
  methods: {
    setConnectionStatus(connectionStatus) {
      this.connectionStatus = connectionStatus;
    },
    startChat() {
      this.$router.push({ name: "chat", params: { chatData: this.user } });
      this.close();
    },
    open(userId) {
      this.userId = userId;
      this.loading = true;
      this.$apollo
        .query({
          query: GET_PROFILE,
          variables: {
            userId: this.userId,
            username: this.getUsername,
          },
        })
        .then((data) => {
          this.loading = false;
          this.user = data.data.getUserProfile;
          this.dialog = true;
        })
        .catch((error) => {
          errorHandler(
            error,
            "GET_PROFILE_ERROR",
            this.$t("error.user-profile.fetch_profile")
          );
        });
    },
    close() {
      this.$store.commit("common/setProfileModalUserId", null);
      this.loading = false;
      this.userId = null;
      this.user = null;
      this.groupsMenu = false;
      this.dialog = false;
    },
    goToGroup(groupId) {
      this.$router.push({
        name: "group.details",
        params: { groupId },
      });
      this.close();
    },
    createThumbnail(id) {
      return createS3ThumbnailUrl(id, 28, 28);
    },
  },
};
</script>

<style scoped lang="scss">
.sed-card {
  ::v-deep {
    .community-members-buttons__trial-tooltip {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      
      &:before {
        left: 50%;
      }
    }
  }
  &__name {
    display: flex;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-left: 15px;
      margin-top: 2px;
    }
  }

  &__buttons {
    margin-top: 8px;
  }

  &__second-part {
    border-top: 1px solid var(--nj-gray-2);
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__inner {
    width: 374px;
    padding-bottom: 24px;
  }

  &__body {
    background: linear-gradient(180deg, var(--nj-green) 129px, var(--nj-white) 129px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  &__avatar {
    margin: 12px 0 24px 0;
    cursor: unset;
  }

  &__user-info {
    margin-top: 4px;
    font-size: 12px;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    &-line {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px 0;

      &:nth-child(2) {
        margin-top: 8px;
      }
    }
  }

  &__shared-groups {
    margin-top: 16px;
    font-size: 12px;
    color: var(--nj-gray-4);

    &-underlined {
      text-decoration: underline;
      color: black;
      cursor: pointer;
    }
  }

  &__description {
    font-size: 12px;
    margin: 16px 0;
  }

  &__personal-message {
    font-size: 14px;
    margin-bottom: 32px;
    font-style: italic;

    &-title {
      font-size: 12px;
      padding: 16px 0;
      border-top: var(--nj-gray-2) 1px solid;
      width: 100%;
      text-align: center;
    }
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__groups-menu {
    width: 230px;
    position: absolute;
    top: 394px;
    right: 72px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    background-color: var(--nj-white);
    min-width: 140px;
    z-index: 4;
    max-height: 208px;
    overflow: scroll;

    &-item {
      display: flex;
      align-items: center;
      padding: 12px 10px 12px 18px;
      cursor: pointer;
      transition: background-color 0.2s ease-out;

      &:hover {
        background-color: var(--nj-gray-2);
      }

      &:not(:last-child):after {
        content: "";
        display: block;
        position: absolute;
        width: 77%;
        margin: 39px auto -13px 8px;
        border-bottom: solid 1px var(--nj-gray-2);
      }

      img {
        border-radius: 50%;
        margin-right: 8px;
      }
    }

    &-text {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 140px;
    }
  }

  .base-modal {
    &__inner {
      &--open {
        overflow-y: visible;
      }
    }
  }
}
</style>
