<template>
  <div class="daily-feed-readable-details">
    <CloseDialogButton
      class="
        daily-feed-readable-details__close
        daily-feed-readable-details__close--mobile
      "
      color="white"
      @click="$emit('closePopUp')"
    />
    <div class="daily-feed-readable-details__media">
      <PdfViewerSmall
        :key="pdfUrl"
        :pdfUrl="pdfUrl"
        @opened="documentOpened"
        @scrolled="documentScrolled"
        @closed="documentClosed"
      />
    </div>
    <div
      class="daily-feed-readable-details__info"
      :style="{ backgroundColor: color }"
    >
      <CloseDialogButton
        class="
          daily-feed-readable-details__close
          daily-feed-readable-details__close--desktop
        "
        color="white"
        @click="$emit('closePopUp')"
      />
      <div class="daily-feed-readable-details__info-top">
        <div class="daily-feed-readable-details__author" v-if="dailyFeed">
          <div class="daily-feed-readable-details__author-avatar">
            <UserAvatar :user="dailyFeed.uploader" :size="36" />
          </div>
          <div class="daily-feed-readable-details__author-info">
            <div class="daily-feed-readable-details__author-name">
              {{ dailyFeed.uploader.firstName }}
              {{ dailyFeed.uploader.lastName }}
            </div>
            <div class="daily-feed-readable-details__author-role">
              {{ dailyFeed.uploader.title }}
            </div>
          </div>
        </div>
      </div>
      <p class="daily-feed-readable-details__info-text" v-if="dailyFeed">
        {{ dailyFeed.description }}
      </p>
      <div
        class="daily-feed-readable-details__like"
        @click.stop="likedByUser ? dislikeFeed() : likeFeed()"
      >
        <div class="daily-feed-readable-details__like-icon">
          <HeartIcon
            :color="likedByUser ? '#ffffff' : '#ffffff'"
            :fill="likedByUser ? '#ffffff' : 'transparent'"
          />
        </div>
        <div class="daily-feed-readable-details__like-number">
          {{ likes.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PdfViewerSmall from "@/components/library/readable/PdfViewerSmall";
import CloseDialogButton from "@/components/reusables/Icons/CloseDialogButton";
import HeartIcon from "@/components/reusables/Icons/HeartIcon";
import UserAvatar from "@/components/reusables/UserAvatar";
import DailyFeedDetailsMixin from "@/components/mixins/DailyFeedDetailsMixin";
import { mapGetters } from "vuex";
import { logTrackingData } from "@/service/trackingServices";

export default {
  name: "DailyFeedReadableDetails",
  components: {
    UserAvatar,
    CloseDialogButton,
    PdfViewerSmall,
    HeartIcon,
  },
  props: {
    id: {
      type: [String, Number],
    },
    color: {
      type: String,
      default: "#F4714E",
    },
    render: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: "LIBRARY",
    },
  },
  mixins: [DailyFeedDetailsMixin],
  data() {
    return {
      pdfUrl: "",
      dailyFeed: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  methods: {
    documentOpened(event) {
      logTrackingData({
        section: this.section,
        belongsToSection: "DAILY_FEED",
        activityType: "DOCUMENT_OPENED",
        contentType: "READABLE",
        contentId: this.id,
        contentPageNo: event.page,
        contentTotalPages: event.pageCount,
        contentCompleted: false,
      });
    },
    documentScrolled(event) {
      logTrackingData({
        section: this.section,
        belongsToSection: "DAILY_FEED",
        activityType: "DOCUMENT_SCROLLED",
        contentType: "READABLE",
        contentId: this.id,
        contentPageNo: event.page,
        contentTotalPages: event.pageCount,
        contentCompleted: event.page === event.pageCount,
      });
    },
    documentClosed(event) {
      logTrackingData({
        section: this.section,
        belongsToSection: "DAILY_FEED",
        activityType: "DOCUMENT_CLOSED",
        contentType: "READABLE",
        contentId: this.id,
        contentPageNo: event.page,
        contentTotalPages: event.pageCount,
        contentCompleted: false,
      });
    },
    loadItemData(readableId) {
      this.loadReadable(readableId);
    },
    loadReadable(readableId) {
      const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
      this.$http
        .get(`${baseUrl}/content/dailyFeed/${readableId}`)
        .then((response) => {
          this.pdfUrl = response.data;
        });
    },
    resetData() {
      this.pdfUrl = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.daily-feed-readable-details {
  display: flex;
  position: relative;

  @media screen and (max-width: $mobile-max) {
    flex-direction: column;
    background-color: var(--nj-gray-4);
  }

  &__close {
    cursor: pointer;

    &--mobile {
      display: none;

      @media screen and (max-width: $mobile-max) {
        display: block;
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }

    &--desktop {
      position: absolute;
      right: 15px;
      top: 15px;

      @media screen and (max-width: $mobile-max) {
        display: none;
      }
    }
  }

  &__media {
    width: 67%;
    max-width: 67%;
    flex-basis: 67%;

    @media screen and (max-width: $mobile-max) {
      margin-top: 100px;
      max-width: 100%;
      flex-basis: 100%;
      width: 100%;
      height: 294px;
    }

    ::v-deep {
      .readable-viewer-container {
        flex-grow: 1;
      }

      .small-pdf {
        width: 95%;
        margin: auto;
      }

      .pdf-background {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
        height: 100%;

        @media screen and (max-width: $mobile-max) {
          border-radius: 0 !important;
        }

        &__chevron {
          &--right,
          &--left {
            margin: 0 auto;
            width: 68px;
            flex-shrink: 0;
          }
        }

        &__pdf {
          width: 100%;
          height: 325px;
          display: flex !important;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: $mobile-max) {
            height: 150px;
          }

          canvas {
            width: initial !important;
            max-width: 100%;
            max-height: 325px;

            @media screen and (max-width: $mobile-max) {
              max-height: 150px;
            }
          }
        }

        &__pdf-container {
          display: block;
          width: 100%;

          @media screen and (max-width: $mobile-max) {
            padding: 20px 0;
          }
        }
      }

      .pdf-viewer-small {
        height: 100%;

        &__inner {
          height: 100%;
        }
      }
    }
  }

  &__info {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--nj-orange);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 34%;
    max-height: 90vh;
    min-height: 445px;
    padding: 23px;
    text-align: left;

    @media screen and (max-width: $mobile-max) {
      max-width: 100%;
      justify-content: flex-start;
      position: relative;
      max-height: 100%;
      border-radius: 0;
    }

    &-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &-text {
      color: var(--nj-white);
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-right: 30px;

    &-avatar {
      background-color: white;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 7px;
    }

    &-name {
      font-size: 15px;
      line-height: 18px;
      color: var(--nj-white);
      font-weight: var(--font-weight-medium);
    }

    &-role {
      font-size: 15px;
      line-height: 18px;
      color: var(--nj-white);
    }
  }

  &__like {
    display: flex;
    align-items: center;
    margin-top: auto;
    cursor: pointer;

    @media screen and (max-width: $mobile-max) {
      position: absolute;
      right: 25px;
      margin: 0;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }

    &-number {
      font-size: 16px;
      line-height: 24px;
      color: var(--nj-white);
    }
  }

  .loading-overlay {
    left: 3.5%;
  }
}
</style>
