<template>
  <svg
    @click="$emit('click')"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.8498 5.44922H2.1498C1.54229 5.44922 1.0498 5.94171 1.0498 6.54922V10.3992C1.0498 11.0067 1.54229 11.4992 2.1498 11.4992H9.8498C10.4573 11.4992 10.9498 11.0067 10.9498 10.3992V6.54922C10.9498 5.94171 10.4573 5.44922 9.8498 5.44922Z"
      stroke="white"
      stroke-width="0.93"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.25 5.45V3.25C3.25 2.52065 3.53973 1.82118 4.05546 1.30546C4.57118 0.789731 5.27065 0.5 6 0.5C6.72935 0.5 7.42882 0.789731 7.94454 1.30546C8.46027 1.82118 8.75 2.52065 8.75 3.25V5.45"
      stroke="white"
      stroke-width="0.93"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "LockIcon",
};
</script>
