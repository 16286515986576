<template>
  <div class="user-dropdown">
    <div
      class="user-dropdown__menu-button"
      @click="showMenu = !showMenu"
      ref="toggleOptionsButton"
    >
      <UserAvatar
        :preventOpenProfile="true"
        :size="36"
        :user="getNJUser"
        class="user-dropdown__avatar-pic"
      />
      <Chevron direction="down" class="user-dropdown__menu-button-chevron" />
    </div>

    <ul
      v-if="showMenu"
      class="user-dropdown__menu"
      v-closable="{
        exclude: ['toggleOptionsButton'],
        handler: 'closeMenu',
      }"
    >
      <li class="body-1-book" @click="goToProfile">
        {{ $t("header.my_profile") }}
      </li>
      <li class="body-1-book" @click="showEmailSupport = true">
        {{ $t("default.help") }}
      </li>
      <li @click="showMenu = false">
        <sign-out />
      </li>
    </ul>
    <div
      class="user-dropdown__menu-email-support"
      v-if="showEmailSupport && showMenu"
    >
      <h3 class="body-1-book">
        {{ $t("header.email_support") }}
      </h3>
      <a href="mailto:support@neuraljam">
        <BaseButton
          @click="
            showMenu = false;
            showEmailSupport = false;
          "
          variant="primary"
          >{{ $t("default.email") }}
        </BaseButton>
      </a>
    </div>
  </div>
</template>

<script>
import SignOut from "@/components/amplify-customized/SignOut";
import Chevron from "@/components/reusables/Icons/Chevron";
import { mapGetters } from "vuex";
import UserAvatar from "@/components/reusables/UserAvatar";

export default {
  name: "UserDropdown",
  components: {
    UserAvatar,
    SignOut,
    Chevron,
  },
  data() {
    return {
      userAvatarBase64: null,
      showMenu: false,
      showEmailSupport: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getNJUser"]),
  },
  methods: {
    goToProfile() {
      this.showMenu = false;

      if (this.$route.name === "liveSessionMeet") {
        let routeData = this.$router.resolve({ name: "profile.user" });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({ name: "profile.user" });
      }
    },
    closeMenu() {
      this.showEmailSupport = false;
      this.showMenu = false;
    },
  },
};
</script>

<style scoped lang="scss">
.user-dropdown {
  &__menu {
    padding: 10px 33px;
    position: absolute;
    border-radius: 15px;
    box-shadow: var(--nj-box-shadow);
    top: 65px;
    right: 25px;
    list-style: none;
    background-color: white;
    height: 148px;
    width: 142px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      &-chevron {
        color: #bdbdbd !important;
        margin-left: 10px;

        @media screen and (max-width: $mobile-max) {
          display: none;
        }
      }
    }

    &-email-support {
      width: 340px;
      box-shadow: var(--nj-box-shadow);
      border-radius: 15px;
      background-color: white;
      position: absolute;
      right: 25px;
      top: 240px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;

      h3 {
        margin-bottom: 15px;
      }
    }

    li {
      font-family: var(--titles-font-family) !important;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  &__avatar-pic {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}
</style>
