const state = {
  isOpen: false,
  collapsed: false,
  savedNote: false,
  fullscreen: false,
  note: {
    tags: [],
    flatText: "",
  },
  lastUpdated: null,
};

const mutations = {
  setIsOpen(state, val) {
    state.isOpen = val;
  },
  setNoteText(state, value) {
    state.note.noteText = value;
  },
  setNote(state, value) {
    state.note = value;
  },
  setCollapsed(state, value) {
    state.collapsed = value;
  },
  setSavedNote(state, value) {
    state.savedNote = value;
  },
  setFullscreen(state, value) {
    state.fullscreen = value;
  },
  closeAndReset(state) {
    state.isOpen = false;
    state.collapsed = false;
    state.savedNote = false;
    state.fullscreen = false;
    state.note = {
      tags: [],
    };
    state.lastUpdated = null;
  },
  setLastUpdated(state, value) {
    state.lastUpdated = value;
  },
  updateTags(state, value) {
    state.note.tags = value;
  },
  updateLockedState(state, value) {
    state.note.tags[value.index].locked = value.lockedState;
  },
  updateTagState(state, value) {
    state.note.tags[value.index].tagState = value.tagState;
  },
  setFlatText(state, value) {
    state.note.flatText = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
