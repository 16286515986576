const state = {
  listView: false,
};

const mutations = {
  showTileView(state) {
    state.listView = false;
  },
  showListView(state) {
    state.listView = true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
