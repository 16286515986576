<template>
  <div class="daily-feed-admin-menu" v-if="isCreatorOfFeed || isContentManager">
    <button
      class="daily-feed-admin-menu__btn"
      @click.stop="handleVisibility"
      ref="toggleOptionsButton"
    >
      <MenuDotsIconWithShadow
        :color="isNotCreatorButAdmin ? '#EC3797' : '#fff'"
      />
    </button>
    <div
      class="daily-feed-admin-menu__menu"
      v-show="value && (isCreatorOfFeed || isContentManager)"
      v-closable="{
        exclude: ['toggleOptionsButton'],
        handler: 'closeMenuByClickOutside',
      }"
    >
      <div
        class="daily-feed-admin-menu__menu-item"
        v-if="isCreatorOfFeed"
        @click.stop="editDailyFeed"
      >
        <div class="daily-feed-admin-menu__menu-icon">
          <EditPencil color="#4F4F4F" />
        </div>
        <div class="daily-feed-admin-menu__menu-text">
          {{ $t("daily_feeds.dropdown.edit") }}
        </div>
      </div>
      <div
        class="daily-feed-admin-menu__menu-item"
        @click.stop="deleteDailyFeed"
      >
        <div class="daily-feed-admin-menu__menu-icon">
          <TrashCan :color="isNotCreatorButAdmin ? '#EC3797' : '#4f4f4f'" />
        </div>
        <div
          class="daily-feed-admin-menu__menu-text"
          :class="{
            'daily-feed-admin-menu__menu-text--admin': isNotCreatorButAdmin,
          }"
        >
          {{ $t("daily_feeds.dropdown.delete") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrashCan from "@/components/reusables/Icons/TrashCan";
import MenuDotsIconWithShadow from "@/components/reusables/Icons/MenuDotsIconWithShadow";
import EditPencil from "@/components/reusables/Icons/EditPencil";

export default {
  name: "DailyFeedAdminMenu",
  props: [
    "isCreatorOfFeed",
    "isContentManager",
    "isNotCreatorButAdmin",
    "value",
  ],
  components: {
    MenuDotsIconWithShadow,
    TrashCan,
    EditPencil,
  },
  methods: {
    handleVisibility() {
      if (this.value) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    },
    editDailyFeed() {
      this.closeMenu();
      this.$emit("editFeed");
    },
    deleteDailyFeed() {
      this.closeMenu();
      this.$emit("deleteFeed");
    },
    closeMenuByClickOutside() {
      this.closeMenu();
    },
    closeMenu() {
      this.$emit("input", false);
    },
    openMenu() {
      this.$emit("input", true);
    },
  },
};
</script>

<style scoped lang="scss">
.daily-feed-admin-menu {
  margin-left: 12px;

  &__menu {
    position: absolute;
    top: 30px;
    right: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    background-color: var(--nj-white);
    min-width: 140px;
    z-index: 3;
    overflow: hidden;

    &-item {
      display: flex;
      align-items: center;
      padding: 8px 18px;
      cursor: pointer;
      transition: background-color 0.2s ease-out;

      &:hover {
        background-color: var(--nj-gray-2);
      }
    }

    &-icon {
      height: 24px;
      width: 24px;
      margin-right: 9px;
    }

    &-text {
      font-size: 13px;
      line-height: 28px;
      color: var(--nj-gray-5);
      white-space: nowrap;

      &--admin {
        color: var(--nj-pink);
      }
    }
  }
}
</style>
