import axios from "axios";
import { Auth } from "aws-amplify";

axios.interceptors.request.use(
  async (config) => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        const jwtToken = session.getAccessToken().getJwtToken();

        config.headers = {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      }
    } catch (e) {
      console.log("error axios interceptor: " + e);
    }
    return config;
  },
  (error) => {
    console.log("axios interceptor config error: " + JSON.stringify(error));
    return Promise.reject(error);
  }
);

export default axios;
