import Vue from "vue";
import store from "../store";
import i18n from "@/plugins/i18n";

// There are 3 different types of errors:
// 1. General network error
// 2. Error code is specified in backend documentation
// 3. Error code is NOT specified in backend documentation
//
// If there is general network error, then there is no need to display other error types.
// If error code is specified in backend documentation, appropriate meaningful message should be displayed.
// If error code is NOT specified in backend documentation, default message should be displayed.

export default async function errorHandler(
  error = null,
  type = "NOT_DOCUMENTED_ERROR",
  errorMessage = i18n.messages[i18n.locale]["error.default"]
) {
  Vue.$log.error(type, error);
  if (
    error &&
    (error.message === "Network error: Failed to fetch" ||
      error.message === "Network Error")
  ) {
    store.commit("common/addErrorToast", {
      type: "NETWORK_ERROR",
      message: i18n.messages[i18n.locale]["error.network"],
    });
  } else {
    store.commit("common/addErrorToast", {
      type,
      message: errorMessage,
    });
  }

  return new Promise((resolve) => {
    resolve();
  });
}
