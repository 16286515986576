var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'form-textarea',
    {
      'form-textarea--errors': _vm.hasErrors,
    },
  ]},[(_vm.label.length)?_c('label',{staticClass:"form-textarea__label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.optional)?_c('span',{staticClass:"form-textarea__label-optional"},[_vm._v(" (optional) ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-textarea__field-wrap"},[_c('textarea',{ref:"textarea",staticClass:"form-textarea__field",attrs:{"rows":_vm.rows,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.changeInput,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}})]),(_vm.value && _vm.counter)?_c('div',{class:[
      'base-form__counter',
      { 'base-form__counter--error': this.value.length > this.counter },
    ]},[_vm._v(" "+_vm._s(_vm.value.length)+" / "+_vm._s(_vm.counter)+" ")]):_vm._e(),(_vm.hasErrors)?_c('div',{staticClass:"base-form__errors"},[_vm._t("errors")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }