<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 4.25H3C2.17157 4.25 1.5 4.92157 1.5 5.75V13.25C1.5 14.0784 2.17157 14.75 3 14.75H15C15.8284 14.75 16.5 14.0784 16.5 13.25V5.75C16.5 4.92157 15.8284 4.25 15 4.25Z"
      stroke="#4F4F4F"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 14.75V2.75C12 2.35218 11.842 1.97064 11.5607 1.68934C11.2794 1.40804 10.8978 1.25 10.5 1.25H7.5C7.10218 1.25 6.72064 1.40804 6.43934 1.68934C6.15804 1.97064 6 2.35218 6 2.75V14.75"
      stroke="#4F4F4F"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SuitcaseIcon",
};
</script>
