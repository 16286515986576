<template>
  <div class="what-is-new-carousel-item" v-if="item">
    <WhatIsNewContentItem
      v-if="item.itemName === 'CONTENT_ITEM'"
      :item="item"
      @updateCarousel="updateCarousel"
      @moveWhatsNew="moveWhatsNew"
      @error="displayError"
      :firstItem="firstItem"
      :lastItem="lastItem"
    />
    <WhatIsNewLiveSession
      v-else-if="item.itemName === 'LIVE_SESSION'"
      :item="item"
      @updateCarousel="updateCarousel"
      @moveWhatsNew="moveWhatsNew"
      @error="displayError"
      :firstItem="firstItem"
      :lastItem="lastItem"
    />
    <WhatIsNewProgram
      v-else-if="item.itemName === 'PROGRAM'"
      :item="item"
      @updateCarousel="updateCarousel"
      @moveWhatsNew="moveWhatsNew"
      @error="displayError"
      :firstItem="firstItem"
      :lastItem="lastItem"
    />
    <WhatIsNewWorkshop
      v-else-if="item.itemName === 'WORKSHOP'"
      :item="item"
      @updateCarousel="updateCarousel"
      @moveWhatsNew="moveWhatsNew"
      @error="displayError"
      :firstItem="firstItem"
      :lastItem="lastItem"
    />
    <WhatIsNewRoundtable
      v-else-if="item.itemName === 'ROUNDTABLE'"
      :item="item"
      @updateCarousel="updateCarousel"
      @moveWhatsNew="moveWhatsNew"
      @error="displayError"
      :firstItem="firstItem"
      :lastItem="lastItem"
    />
    <WhatIsNewMeetUp
      v-else-if="item.itemName === 'MEET_UP'"
      :item="item"
      @updateCarousel="updateCarousel"
      @moveWhatsNew="moveWhatsNew"
      @error="displayError"
      :firstItem="firstItem"
      :lastItem="lastItem"
    />
  </div>
</template>

<script>
import WhatIsNewProgram from "@/components/what-is-new/WhatIsNewProgram";
import WhatIsNewLiveSession from "@/components/what-is-new/WhatIsNewLiveSession";
import WhatIsNewRoundtable from "@/components/what-is-new/WhatIsNewRoundtable";
import WhatIsNewWorkshop from "@/components/what-is-new/WhatIsNewWorkshop";
import WhatIsNewContentItem from "@/components/what-is-new/WhatIsNewContentItem";
import WhatIsNewMeetUp from "@/components/what-is-new/WhatIsNewMeetUp";

export default {
  name: "WhatIsNewCarouselItem",
  props: ["item", "firstItem", "lastItem"],
  components: {
    WhatIsNewMeetUp,
    WhatIsNewProgram,
    WhatIsNewLiveSession,
    WhatIsNewContentItem,
    WhatIsNewRoundtable,
    WhatIsNewWorkshop,
  },
  methods: {
    updateCarousel() {
      this.$emit("updateCarousel");
    },
    displayError() {
      this.$emit("error");
    },
    moveWhatsNew(payload) {
      this.$emit("moveWhatsNew", payload);
    },
  },
};
</script>
